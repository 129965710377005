import {rpAppLink} from "@pg-mono/rp-routes";

import {RegionType} from "../../../region/types/RegionType";
import {SearchSort} from "../../list/constants/SearchSort";
import {OfferType} from "../../types/OfferType";
import {offerUrlBuilder} from "../friendly_offer_list/offer_url_builder";
import {IOfferListingSeoRuleParams} from "./constants";

interface ICanonicalQueryParams {
    is_luxury?: boolean;
    priceLowerThanAverageConverted: boolean;
    sort?: string;
    offerType?: number;
    construction_end_date?: string;
    regionsSlug?: string;
}

const transformRuleParamToRegionCanonical = (params: IOfferListingSeoRuleParams): ICanonicalQueryParams => ({
    is_luxury: Boolean(params.filters.is_luxury),
    priceLowerThanAverageConverted: params.filters.price_lower_than_average === "true",
    construction_end_date: params.filters.construction_end_date,
    regionsSlug: params.regions?.[0].slug,
    sort: params.sort,
    offerType: params.offerType
});

export const getCustomOfferCanonical = (params: IOfferListingSeoRuleParams, offerListSubFilter?: string) => {
    const {is_luxury, priceLowerThanAverageConverted, sort, offerType, construction_end_date, regionsSlug} = transformRuleParamToRegionCanonical(params);

    const cityDefArray = [RegionType.CITY, RegionType.TOWN];
    const isFlatsInSpecificRegions =
        params.regions?.length === 1 &&
        (priceLowerThanAverageConverted ? [...cityDefArray, RegionType.DISTRICT] : cityDefArray).includes(params.regions[0].type) &&
        params.offerType === OfferType.FLAT;
    const isHousesInSpecificRegions =
        params.regions?.length === 1 &&
        (priceLowerThanAverageConverted ? [...cityDefArray, RegionType.VOIVODESHIP] : cityDefArray).includes(params.regions[0].type) &&
        params.offerType === OfferType.HOUSE;
    const filters = {
        area_0: params.filters.area_0 ? +params.filters.area_0 : undefined,
        area_1: params.filters.area_1 ? +params.filters.area_1 : undefined,
        rooms_0: params.filters.rooms_0 ? +params.filters.rooms_0 : undefined,
        rooms_1: params.filters.rooms_1 ? +params.filters.rooms_1 : undefined,
        price_0: params.filters.price_0 ? +params.filters.price_0 : undefined,
        price_1: params.filters.price_1 ? +params.filters.price_1 : undefined
    };

    if (priceLowerThanAverageConverted) {
        if (isFlatsInSpecificRegions || isHousesInSpecificRegions) {
            return rpAppLink.offer.listFriendly({
                friendlySlug: offerUrlBuilder({
                    ...filters,
                    price_lower_than_average: priceLowerThanAverageConverted,
                    type: offerType,
                    region: regionsSlug
                }).friendlySlug
            });
        } else {
            return rpAppLink.offer.listFriendly({
                friendlySlug: offerUrlBuilder({
                    price_lower_than_average: priceLowerThanAverageConverted,
                    type: offerType,
                    region: regionsSlug
                }).friendlySlug
            });
        }
    }

    if (construction_end_date === "0") {
        if (isFlatsInSpecificRegions || isHousesInSpecificRegions) {
            return rpAppLink.offer.listFriendly({
                friendlySlug: offerUrlBuilder({
                    ...filters,
                    construction_end_date: construction_end_date,
                    type: offerType,
                    region: regionsSlug
                }).friendlySlug
            });
        } else {
            return rpAppLink.offer.listFriendly({
                friendlySlug: offerUrlBuilder({
                    construction_end_date: construction_end_date,
                    type: offerType,
                    region: regionsSlug
                }).friendlySlug
            });
        }
    }

    const friendlySlug = offerUrlBuilder({
        region: regionsSlug,
        type: offerType,
        sort: sort ? parseInt(sort, 10) : SearchSort.DEFAULT,
        price_lower_than_average: priceLowerThanAverageConverted,
        is_luxury,
        construction_end_date
    }).friendlySlug;

    return offerListSubFilter ? rpAppLink.offer.listFriendlySubFilterGeneral({friendlySlug, offerListSubFilter}) : rpAppLink.offer.listFriendly({friendlySlug});
};
