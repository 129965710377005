/**
 * Excludes some pathnames from getting offer list description. Seo wants to check if removing description raises search results CTR
 * @param pathname
 */
export const getOfferListDescriptionException = (pathname: string) => {
    return blacklistedPathnames.some((p) => p === pathname);
};

const blacklistedPathnames = [
    "/s/nowe-mieszkania-gora-kalwaria/",
    "/s/nowe-mieszkania-ostroda/",
    "/s/nowe-mieszkania-olesnica/",
    "/s/nowe-mieszkania-brzesko/",
    "/s/tanie-mieszkania-wroclaw/",
    "/s/nowe-mieszkania-otwock/",
    "/s/nowe-mieszkania-bilgoraj/",
    "/s/nowe-mieszkania-piaseczno/",
    "/s/nowe-mieszkania-krynica-zdroj/",
    "/s/tanie-mieszkania-krakow/",
    "/s/nowe-mieszkania-gdansk/",
    "/s/nowe-mieszkania-krakow-wzgorza-krzeslawickie/",
    "/s/nowe-mieszkania-i-domy-elblag/",
    "/s/nowe-mieszkania-wroclaw/",
    "/s/nowe-mieszkania-rzeszow/",
    "/s/nowe-mieszkania-suwalki/",
    "/s/nowe-mieszkania-i-domy-grodzisk-mazowiecki/",
    "/s/nowe-domy-warszawa-wawer/",
    "/s/nowe-mieszkania-sobotka/",
    "/s/nowe-mieszkania-wroclaw-zakrzow/",
    "/s/nowe-mieszkania-aleksandrow-lodzki/",
    "/s/nowe-mieszkania-szczecin-jednopokojowe/",
    "/s/nowe-mieszkania-i-domy-rumia/",
    "/s/nowe-mieszkania-poznan-rataje/",
    "/s/nowe-mieszkania-warszawa-tarchomin/",
    "/s/nowe-mieszkania-warszawa-zawady/",
    "/s/nowe-mieszkania-wroclaw-klecina/",
    "/s/nowe-mieszkania-i-domy-pruszkowski-dawidy-bankowe/",
    "/s/nowe-mieszkania-gdansk-ujescisko-lostowice/",
    "/s/gotowe-mieszkania-wroclaw/",
    "/s/gotowe-mieszkania-warszawa/",
    "/s/nowe-mieszkania-gdansk-przymorze-wielkie/",
    "/s/nowe-mieszkania-krakow-nowa-huta/",
    "/s/nowe-mieszkania-lodz-widzew/",
    "/s/nowe-mieszkania-krakow-krowodrza/"
];
