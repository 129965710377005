import {IFetchContext} from "@pg-mono/data-fetcher";
import {pick, removeAdditionalSpaces} from "@pg-mono/nodash";
import {safelyParsePage} from "@pg-mono/string-utils/";

import {rpCommonUrl} from "../../../app/read_rp_environment_variables";
import {IRPStore} from "../../../app/rp_reducer";
import {IRPRequestMeta} from "../../../app/rp_request_meta";
import {MetaDataDispatch, updateMetaData} from "../../../meta_data/actions/update_meta_data";
import {descriptionOfferList} from "../../../meta_data/page_description/offer_list";
import {titleOfferList} from "../../../meta_data/page_title/offer_list";
import {generateMetaRegionName} from "../../../meta_data/utils/generate_meta_region_name";
import {generatePrevNextMetaLinks} from "../../../meta_data/utils/generate_prev_next_links";
import {trimAggregationFromRegion} from "../../../meta_data/utils/trim_aggregation_from_region";
import {parseQueryParamToInt} from "../../../utils/latest_query";
import {OfferMetaRobots} from "../../constants/offer_meta_robots";
import {IOfferListingSeoRuleParams} from "../../helpers/seo/constants";
import {
    createCanonicalAndRobotsHouseFilterOnlyRule,
    createCanonicalAndRobotsHouseFilterWithOtherFiltersRule
} from "../../helpers/seo/offer_listing_cannical_and_robots_house_filter_rules";
import {
    createCanonicalAndRobotsCommonAreaFilterRule,
    createCanonicalAndRobotsFlatAreaFilterRule,
    createCanonicalAndRobotsHouseAreaFilterRule
} from "../../helpers/seo/offer_listing_canonical_and_robots_area_rules";
import {getOfferListingSeoCanonicalByRules, getOfferListingSeoRobotsByRules} from "../../helpers/seo/offer_listing_canonical_and_robots_helpers";
import {
    createCanonicalAndRobotsCommonRoomsFilterRule,
    createCanonicalAndRobotsFlatRoomsFilterRule,
    createCanonicalAndRobotsHouseRoomsFilterRule
} from "../../helpers/seo/offer_listing_canonical_and_robots_rooms_rules";
import {
    createCanonicalAndRobotsCommercialAndMixedTypeFilterRule,
    createCanonicalPageRule,
    createGeneralSeoRobotsRule,
    createRobotsMixedTypeForCustomFilterRule,
    createSpecialListingCanonicalRule,
    createSpecialListingsRobotsRule
} from "../../helpers/seo/offer_listing_canonical_and_robots_rules";
import {
    createRobotsPriceCommercialRule,
    createRobotsPriceFurtherPagesRule,
    createRobotsPriceGeneralRule,
    createRobotsPriceToFilterRule
} from "../../helpers/seo/offer_listing_robots_price_rules";
import {getOfferListSubTypeRobotsAndCanonicalRule} from "../../helpers/seo/offer_listing_sub_type_canonical_and_robots_rules";
import {OFFER_LIST_PAGE_SIZE, PRIVILEGE_OFFER_QUERY_PARAM_NAME} from "../constants/offer_list";
import {OfferListSubType} from "../types/OfferListSubType";
import {getOfferListDescriptionException} from "../utils/get_offer_list_description_exception";

const getCanonicalAndRobotsRulesForHouseFilter = (seoParams: IOfferListingSeoRuleParams, offerCount: number, offerListSubFilter?: string) => [
    createCanonicalAndRobotsHouseFilterOnlyRule(seoParams, offerCount, offerListSubFilter),
    createCanonicalAndRobotsHouseFilterWithOtherFiltersRule(seoParams, offerCount, offerListSubFilter)
];

export const getCanonicalAndRobotsRules = (seoParams: IOfferListingSeoRuleParams) => [
    createRobotsPriceCommercialRule(seoParams),
    createRobotsPriceFurtherPagesRule(seoParams),
    createRobotsPriceToFilterRule(seoParams),
    createCanonicalAndRobotsCommonRoomsFilterRule(seoParams),
    createCanonicalAndRobotsCommonAreaFilterRule(seoParams),
    createCanonicalAndRobotsFlatRoomsFilterRule(seoParams),
    createCanonicalAndRobotsFlatAreaFilterRule(seoParams),
    createCanonicalAndRobotsHouseRoomsFilterRule(seoParams),
    createCanonicalAndRobotsHouseAreaFilterRule(seoParams),
    createCanonicalAndRobotsCommercialAndMixedTypeFilterRule(seoParams),
    createRobotsPriceGeneralRule(seoParams)
];

export const updateOfferListMetaData =
    (ctx: IFetchContext<IRPRequestMeta, {offerListSubType?: OfferListSubType; friendlySlug: string; offerListSubFilter?: string}>) =>
    async (dispatch: MetaDataDispatch, getState: () => IRPStore) => {
        const state = getState();
        const {selectedRegions, search} = state.offerList;
        const {count, latestQuery, sold_offers_count, soon_finished_offers_count, propertiesCount} = getState().offerList.list;
        const regions = selectedRegions.length > 0 ? selectedRegions : undefined;
        const filterOptions = {
            is_luxury: search != null && search.is_luxury,
            holiday_location: search != null && search.holiday_location,
            price_lower_than_average: Boolean(latestQuery.price_lower_than_average),
            construction_end_date: latestQuery.construction_end_date as string
        };
        const {page, ...restQuery} = ctx.route.query;

        const {offerListSubType, offerListSubFilter} = ctx.match.params;
        const shouldUseMetaTagsFromApi = Object.values(ctx.route.query || {}).length === 0 && !offerListSubType && !offerListSubFilter;

        const parsedPage = safelyParsePage(page); // right now we do not set unique meta data

        const data = pick(latestQuery, [
            "type",
            "area_0",
            "area_1",
            "rooms_0",
            "rooms_1",
            "price_0",
            "price_1",
            "price_lower_than_average",
            "is_luxury",
            "construction_end_date",
            "house_type",
            "house_additional_areas",
            "house_storeys"
        ]);

        const convertedQuery = {
            ...data,
            sort: latestQuery.sort,
            price_lower_than_average: Boolean(data.price_lower_than_average),
            is_luxury: Boolean(data.is_luxury),
            type: parseQueryParamToInt(data.type)
        };

        const title =
            search?.show_meta_fields && search?.meta_title
                ? search?.meta_title
                : removeAdditionalSpaces(
                      titleOfferList(
                          {
                              regionName: regions && regions[0] && generateMetaRegionName(regions[0]),
                              ...convertedQuery,
                              regions
                          },
                          parsedPage,
                          filterOptions,
                          offerListSubFilter
                      )
                  );

        const description =
            search?.show_meta_fields && search?.meta_description
                ? search?.meta_description
                : removeAdditionalSpaces(
                      descriptionOfferList({
                          regions,
                          propertiesCount,
                          ...convertedQuery,
                          page: parsedPage,
                          offerType: convertedQuery.type,
                          offerListSubType,
                          offerListSubFilter
                      })
                  );

        // calculate `prev` and `next` links
        const maxStandardListPage = count > 0 ? Math.ceil(count / OFFER_LIST_PAGE_SIZE) : 1;
        const maxClosedListPage = sold_offers_count > 0 ? Math.ceil(sold_offers_count / OFFER_LIST_PAGE_SIZE) : 1;
        const maxPage = maxStandardListPage + maxClosedListPage - 1;
        const hostAndPathname = `${rpCommonUrl}${ctx.route.pathname}`;
        const {prev, next} = generatePrevNextMetaLinks(parsedPage, maxPage, hostAndPathname, restQuery);

        // other meta
        const commonSeoParams = {
            offerType: convertedQuery.type,
            regions: regions,
            filters: data,
            sort: convertedQuery.sort,
            page: page ? parsedPage : undefined
        };

        const commonCanonicalAndRobotsRules = getCanonicalAndRobotsRules(commonSeoParams);

        const offerListSubFilterRobotsAndCanonicalRules = getCanonicalAndRobotsRulesForHouseFilter(commonSeoParams, count, offerListSubFilter);
        const offerListSubTypeRobotsAndCanonicalRules = getOfferListSubTypeRobotsAndCanonicalRule(offerListSubType, commonSeoParams, count, ctx.route);

        const robots =
            getOfferListingSeoRobotsByRules([
                ...offerListSubTypeRobotsAndCanonicalRules,
                ...offerListSubFilterRobotsAndCanonicalRules,
                ...commonCanonicalAndRobotsRules,
                createGeneralSeoRobotsRule({
                    page: page ? parsedPage : undefined,
                    seoDescriptionExists: Boolean(search?.seo_description),
                    sortParamExists: Boolean(restQuery?.sort),
                    routeQuery: ctx.route.query,
                    soonFinishedOffersCount: soon_finished_offers_count,
                    soldOffersCount: sold_offers_count,
                    offersCount: count
                }),
                createSpecialListingsRobotsRule({...commonSeoParams, routePathname: ctx.route.pathname}),
                createRobotsMixedTypeForCustomFilterRule({offerType: commonSeoParams.offerType, query: ctx.route.query}),
                {
                    condition: () => Boolean(restQuery && restQuery[PRIVILEGE_OFFER_QUERY_PARAM_NAME]),
                    robots: OfferMetaRobots.NOINDEX_FOLLOW
                }
            ]) || OfferMetaRobots.INDEX_FOLLOW;

        const customCanonicalPath = getOfferListingSeoCanonicalByRules([
            ...offerListSubTypeRobotsAndCanonicalRules,
            ...offerListSubFilterRobotsAndCanonicalRules,
            createCanonicalPageRule(commonSeoParams),
            createSpecialListingCanonicalRule({
                ...commonSeoParams,
                routePathname: ctx.route.pathname
            }),
            ...commonCanonicalAndRobotsRules
        ]);

        const canonicalFromApi = shouldUseMetaTagsFromApi ? search?.canonical_url ?? null : null;

        const metaDataOptions = {
            title,
            description: getOfferListDescriptionException(ctx.route.pathname) ? "" : description,
            pathname: ctx.route.pathname,
            pageNumber: parsedPage,
            customCanonicalPath: canonicalFromApi || trimAggregationFromRegion(customCanonicalPath || ctx.route.pathname),
            prev,
            next,
            robots
        };

        dispatch(updateMetaData(metaDataOptions));

        return true;
    };
