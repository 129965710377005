import {matchPath} from "react-router";
import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {IRequestMeta} from "@pg-mono/request";

import {addABTestData, IABTestAction, setABTestingInitialized} from "./actions/ab_testing_action";
import {getABTestDataAction} from "./actions/get_ab_test_action";
import {IABTestConfiguration} from "./interfaces/IABTestConfiguration";
import {IABTestingOptions} from "./types/IABTestingOptions";
import {getABTestVariantFromDocumentCookies} from "./utils/ab_testing_utils";

interface IABTestContainerProps {
    id: string;
    variants: number;
    withSSR: boolean;
}

export type CtxType = Partial<IFetchContext<Partial<IRequestMeta>>>;

export interface IABTestingAPI {
    getABTestContainerProps: (name: string) => IABTestContainerProps;
    initOngoingABTestsVariant: (pathname: string, ctx: CtxType) => string[];
    setClientSideABTestsInStore: () => (dispatch: Dispatch) => Promise<void>;
    setServerSideABTestsInStore: (data: string[]) => (dispatch: Dispatch) => Promise<void>;
}

const matchOngoingABTestsByPathname = (config: IABTestConfiguration[], pathname: string): IABTestConfiguration[] => {
    return config.filter(({path, matchOnAllPaths}) => {
        if (matchOnAllPaths) {
            return true;
        }
        if (Array.isArray(path)) {
            return path.some((value) => matchPath(pathname, {path: value, exact: true, strict: true, sensitive: true}));
        }
        return matchPath(pathname, {path, exact: true, strict: true, sensitive: true});
    });
};

const initOngoingABTestsVariant = (config: IABTestConfiguration[], options: IABTestingOptions) => (pathname: string, ctx: CtxType) => {
    const matchedABTests = matchOngoingABTestsByPathname(config, pathname);

    const results: string[] = [];

    if (matchedABTests.length) {
        matchedABTests.forEach((abTest) => {
            const abTestData = getABTestDataAction(abTest, ctx, options.domain);

            if (abTest.withSSR) {
                results.push(abTestData);
            }
        });
    }

    return results;
};

const getABTestContainerProps =
    (config: IABTestConfiguration[]) =>
    (name: string): IABTestContainerProps => {
        const abTestConfig = config.find((abTest) => abTest.name === name);

        if (abTestConfig) {
            return {
                id: abTestConfig.id,
                variants: abTestConfig.variants,
                withSSR: abTestConfig.withSSR
            };
        }

        throw new Error("AB test not found");
    };

const setClientSideABTestsInStore = (config: IABTestConfiguration[], options: IABTestingOptions) => () => async (dispatch: Dispatch<IABTestAction>) => {
    const matchedABTests = matchOngoingABTestsByPathname(config, window.location.pathname);

    if (matchedABTests.length) {
        matchedABTests.forEach((abTest) => {
            const variant = getABTestVariantFromDocumentCookies(abTest.id, options);

            if (variant !== null) {
                dispatch(addABTestData({id: abTest.id, variant}));
            }
        });
    }

    dispatch(setABTestingInitialized());
};

const setServerSideABTestsInStore = (data: string[]) => async (dispatch: Dispatch<IABTestAction>) => {
    data.forEach((item) => {
        const [id, variant] = item.split(".");

        if (id && variant) {
            dispatch(addABTestData({id, variant: parseInt(variant)}));
        }
    });
};

/**
 * Creates AB testing instance
 * @param config {IABTestConfiguration[]} AB tests configuration
 * @param options {IABTestingOptions} instance options object
 * @returns {IABTestingAPI} AB testing instance
 */
export const initABTestingInstance = (config: IABTestConfiguration[], options: IABTestingOptions): IABTestingAPI => {
    return {
        getABTestContainerProps: getABTestContainerProps(config),
        initOngoingABTestsVariant: initOngoingABTestsVariant(config, options),
        setClientSideABTestsInStore: setClientSideABTestsInStore(config, options),
        setServerSideABTestsInStore
    };
};
