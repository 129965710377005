import {combineReducers} from "redux";

import {reduceRequestState, reduceResponse, RequestState} from "@pg-mono/request-state";

import {fetchUserRecommendationsStateRequestType, IRecommendationsStateResponse} from "../actions/fetch_user_recommendations_state_action";

export interface IUserRecommendationsStateStore {
    data?: IRecommendationsStateResponse;
    requestState: RequestState;
}

export const userRecommendationsStateReducer = combineReducers({
    data: reduceResponse<IRecommendationsStateResponse>(fetchUserRecommendationsStateRequestType),
    requestState: reduceRequestState(fetchUserRecommendationsStateRequestType)
});
