import {css} from "@emotion/react";

import {PillSize, PillSizeUnionType} from "./pill_variants";

export const getPillVariantStyle = (variant: PillSizeUnionType) => () => {
    switch (variant) {
        case PillSize.SMALL: {
            return css`
                height: 2.8rem;
            `;
        }
        case PillSize.LARGE: {
            return css`
                height: 4rem;
                padding-left: 1.8rem;
                padding-right: 1.8rem;
            `;
        }
    }
};
