import {IAction, reduceReducers, reduceResponse} from "@pg-mono/request-state";

import {fetchSelectedRegionDataTypes, ISelectedRegion, RESET_FETCH_SELECTED_REGIONS} from "../../list/actions/offer_list_selected_data_actions";

export const offerListSelectedDataReducer = () =>
    reduceReducers(reduceResponse<ISelectedRegion[]>(fetchSelectedRegionDataTypes, []), resetSelectedDataReducer());

/**
 * Additional reducers
 */
const initialState: ISelectedRegion[] = [];
const resetSelectedDataReducer =
    () =>
    (state: ISelectedRegion[] = initialState, action: IAction): ISelectedRegion[] => {
        if (action.type === RESET_FETCH_SELECTED_REGIONS) {
            return initialState;
        }
        return state;
    };
