import {parseSearch} from "@pg-mono/request";
import {userComUserLogin} from "@pg-mono/user-com";

import {IUserProfile} from "../ts/interfaces/IUserProfile";

/*
 * Use only client side
 * This function aims to sync user with user.com data after user login.
 * This is important in a case when already existing - in user.com - user login on another computer.
 * In such situation we need to connect user.com data from both computers.
 */
export const syncUserWithUserCom = (userProfile: IUserProfile) => {
    if (window) {
        const query = parseSearch(location.search);

        if (query.oauth_login) {
            userComUserLogin(userProfile.uuid, userProfile.email);
        }
    }
};
