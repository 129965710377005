import React, {ReactNode} from "react";

import {onDesktop, truncate} from "@pg-design/helpers-css";
import {CloseIcon} from "@pg-design/icons";
import {Text} from "@pg-design/text";
import {IFormFieldProps} from "@pg-mono/form";
import {isEmpty, isEqual} from "@pg-mono/nodash";

import {SearchDropdownArrow} from "../atoms/SearchDropdownArrow";
import {DropdownToOpen} from "../SearchFullFilters";
import {ChildHolder, filterHolder, filterLabel, filterLabelStyle, filterLabelText} from "./SearchRangeFilter";

export interface IFilterChildProps<TValue> extends IFormFieldProps<string, TValue> {
    clearField: () => void;
    errorOnBottom?: boolean;
    setDropdownOpen: (isOpen: boolean) => void;
    label: string;
    id: string;
}

interface IProps<TValue> extends IFormFieldProps<string, TValue> {
    className?: string;
    errorOnBottom?: boolean;
    getLabel: (value?: TValue, hideLabelOnValue?: boolean) => string | ReactNode;
    hideLabelOnValue?: boolean;
    labelId?: string;
    id: string;
    label: string;
    children: (props: IFilterChildProps<TValue>) => ReactNode;
    onClearField?: () => void;
    isDropdownOpened: boolean;
    setDropdownOpen: (dropdownToOpen: DropdownToOpen | null) => void;
    dropdownName: DropdownToOpen;
    rightDropdown?: boolean;
    isMobileWidth: boolean;
    disableClearAction?: boolean;
    isMobileDropdown?: boolean;
    fullWidth?: boolean;
}

export class Filter<T> extends React.PureComponent<IProps<T | []>> {
    // used to verify onAfterChange - remember to update or reset this value
    private previousChangedValue: T | [] | null = null;

    private onAfterChangeValidated = (name: string, value: T | []) => {
        if (!isEqual(value, this.previousChangedValue)) {
            this.props.onAfterChange(this.props.name, value);
            this.previousChangedValue = value;
        }
    };

    private clearField = () => {
        this.props.onClearField && this.props.onClearField();
        this.props.onChange(this.props.name, []);
        this.onAfterChangeValidated(this.props.name, []);
    };

    private onClearFieldClick = (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        this.clearField();
        this.props.setDropdownOpen(null);
    };

    private openDropdown = () => {
        this.props.setDropdownOpen(this.props.dropdownName);
        this.onAfterChangeValidated(this.props.name, this.props.value);
    };

    private closeDropdown = (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        this.onAfterChangeValidated(this.props.name, this.props.value);
        this.props.setDropdownOpen(null);
    };

    private onKeyDownHandler = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === "Escape") {
            this.props.setDropdownOpen(null);
            this.onAfterChangeValidated(this.props.name, this.props.value);
        }

        if (e.key === "Enter") {
            this.props.onChange(this.props.name, this.props.value);
            this.onAfterChangeValidated(this.props.name, this.props.value);
            this.props.setDropdownOpen(null);
        }
    };

    componentDidUpdate(prevProps: Readonly<IProps<[] | T>>) {
        if (prevProps.isDropdownOpened && !this.props.isDropdownOpened) {
            this.onAfterChangeValidated(this.props.name, this.props.value);
        }
    }

    /**
     * Render
     */

    public render() {
        const {isDropdownOpened, value, disableClearAction} = this.props;

        const renderEnchancedChild = () =>
            this.props.children({
                clearField: this.clearField,
                error: this.props.error,
                errorOnBottom: this.props.errorOnBottom,
                name: this.props.name,
                onAfterChange: this.onAfterChangeValidated,
                onChange: this.props.onChange,
                setDropdownOpen: () => this.props.setDropdownOpen(null),
                label: this.props.label,
                value: value,
                id: this.props.id
            });

        return (
            <div css={filterHolder} onKeyDown={this.onKeyDownHandler} className={this.props.className}>
                <div
                    tabIndex={-1}
                    css={[filterLabel, this.props.isMobileDropdown && filterLabelStyle, onDesktop(filterLabelStyle)]}
                    onClick={this.props.isDropdownOpened ? this.closeDropdown : this.openDropdown}
                >
                    <Text variant="body_copy_2" css={[filterLabelText, truncate]}>
                        {this.props.getLabel(value, this.props.hideLabelOnValue)}
                    </Text>

                    {!isEmpty(value) && !disableClearAction ? (
                        <div onClick={(e) => this.onClearFieldClick(e)}>
                            <CloseIcon size="1.4" />
                        </div>
                    ) : (
                        <SearchDropdownArrow isDown={this.props.isDropdownOpened} />
                    )}
                </div>

                {(isDropdownOpened || this.props.isMobileWidth) && (
                    <ChildHolder rightDropdown={this.props.rightDropdown} isMobileDropdown={!!this.props.isMobileDropdown} fullWidth={!!this.props.fullWidth}>
                        {renderEnchancedChild()}
                    </ChildHolder>
                )}
            </div>
        );
    }
}
