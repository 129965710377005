import {VENDOR_LIST_PAGE_SIZE} from "../actions/fetch_vendor_list";

interface IVendorList {
    count: number;
    region:
        | {
              stats: {
                  vendors_count_sold: number;
              };
          }[]
        | null;
}

export const calculateVendorListMaxPage = (vendorList: IVendorList) => {
    const vendorCountSold = vendorList.region?.[0].stats.vendors_count_sold;
    const maxPageVendor = vendorList.count ? Math.ceil(vendorList.count / VENDOR_LIST_PAGE_SIZE) : 1;
    const maxPageSoldVendor = vendorCountSold ? Math.ceil(vendorCountSold / VENDOR_LIST_PAGE_SIZE) : 0;
    // we show sold vendors only on listings shorter than 3 pages
    return maxPageVendor > 2 ? maxPageVendor : maxPageVendor + (maxPageSoldVendor > 2 ? 2 : maxPageSoldVendor) - 1;
};
