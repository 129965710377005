import {BACK_TO, IBackToUrlAction} from "../actions/back_to_url_actions";

export interface IBackToUrlStore extends IBackToUrl {}

export interface IBackToUrl {
    url: string;
    text: string;
}

export const backToUrlReducer = (state: IBackToUrlStore = {url: "", text: ""}, action: IBackToUrlAction): IBackToUrl => {
    if (action.type === BACK_TO) {
        return action.payload;
    }
    return state;
};
