import {combineReducers} from "redux";

import {IPriceReportStore, priceReportReducer} from "./price_report_reducer";

export interface IReportStore {
    price: IPriceReportStore;
}

export const reportReducer = combineReducers({
    price: priceReportReducer
});
