import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {difference, omit} from "@pg-mono/nodash";
import {appendQueryString} from "@pg-mono/request";
import {enable301ResponseState} from "@pg-mono/response-state";
import {rpAppLink} from "@pg-mono/rp-routes";
import {safelyParsePage} from "@pg-mono/string-utils";

import {IRPRequestMeta} from "../../app/rp_request_meta";
import {buildFriendlyPromotionSlug, getPromotionSlugByType} from "../helpers/build_friendly_promotion_slug";
import {knownPromotionQueryTypes} from "../helpers/promotion_url_constants";
import {PromotionType} from "../helpers/PromotionType";
import {fetchPromotionRegionById} from "./fetch_promotion_list_region";

export const conditionallyRedirectToFriendlyPromotionUrlAtRoute = (ctx: IFetchContext<IRPRequestMeta>) => async (dispatch: Dispatch) => {
    const query: Record<string, string | string[] | number> = omit(ctx.route.query, ["page"]);

    const page = safelyParsePage(ctx.route.query.page);
    // Add page to query only when it is defined and its value is greater than `1` - we don't need `&page=1` in URL
    if (page && page > 1) {
        query.page = page;
    }

    if (!query.type && !query.region) {
        // URL does not contain query params that can be converted to friendlySlug - continue fetching
        return query;
    }

    const friendlySlugQuery: Record<string, string | PromotionType> = {};

    if (query.type && getPromotionSlugByType(parseInt(query.type as string))) {
        friendlySlugQuery.type = parseInt(query.type as string);
    }

    if (query.region) {
        const regionId = parseInt(query.region as string);

        if (isFinite(regionId)) {
            const regionById = await dispatch(fetchPromotionRegionById(ctx, regionId));

            // URL contains unknown region id - redirect to promotions list
            if (regionById) {
                friendlySlugQuery.region = regionById.slug;
            }
        }
    }

    const friendlySlug = buildFriendlyPromotionSlug(friendlySlugQuery);

    if (friendlySlug) {
        const friendlyPromotionUrl = rpAppLink.promotions.listFriendly({friendlySlug});
        const redirectUrl = appendQueryString(friendlyPromotionUrl, omit(query, knownPromotionQueryTypes));

        dispatch(enable301ResponseState(redirectUrl));

        return false;
    }

    // Return query but remove params that have not passed validation
    return omit(query, difference(knownPromotionQueryTypes, Object.keys(friendlySlugQuery)));
};
