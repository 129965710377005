import React from "react";

import {SvgIcon} from "../../components/SvgIcon";
import {IIcon} from "../../interfaces/IIcon";

export const YouTubeIcon = (props: IIcon) => {
    return (
        <SvgIcon {...props}>
            <path d="M3.146 5.619h.314V7.18h.304V5.619h.314v-.266h-.932v.266ZM5.011 4.32c.042 0 .075-.01.099-.033a.124.124 0 0 0 .036-.094v-.81a.095.095 0 0 0-.037-.078.149.149 0 0 0-.098-.03.133.133 0 0 0-.09.03.098.098 0 0 0-.034.078v.81c0 .04.01.072.032.094.022.022.053.033.092.033ZM5.682 5.816a.267.267 0 0 0-.125.032.416.416 0 0 0-.115.094v-.59h-.273v1.829h.273v-.104a.348.348 0 0 0 .115.092c.04.02.088.03.141.03.08 0 .142-.026.183-.077a.337.337 0 0 0 .064-.22v-.748c0-.11-.023-.195-.068-.252a.233.233 0 0 0-.195-.086Zm-.015 1.05c0 .043-.008.073-.024.092-.015.02-.04.029-.072.029a.158.158 0 0 1-.065-.015.22.22 0 0 1-.064-.047v-.84a.19.19 0 0 1 .055-.042.126.126 0 0 1 .057-.013c.037 0 .065.011.085.034.018.024.028.059.028.105v.696ZM4.697 6.857a.38.38 0 0 1-.084.072.154.154 0 0 1-.075.028c-.026 0-.044-.007-.055-.021-.011-.014-.017-.037-.017-.07V5.833h-.27V6.96c0 .08.016.14.048.18.032.04.078.06.14.06a.33.33 0 0 0 .157-.042.603.603 0 0 0 .156-.125v.148h.27V5.832h-.27v1.025Z" />
            <path d="M5 .91a4.344 4.344 0 1 0 0 8.686A4.344 4.344 0 0 0 5 .91ZM5.7 3.053h.304v1.14c0 .035.007.06.02.076.012.016.032.024.061.024a.18.18 0 0 0 .085-.031.416.416 0 0 0 .093-.08V3.053h.304V4.54h-.304v-.164a.637.637 0 0 1-.175.138.38.38 0 0 1-.176.048c-.07 0-.123-.022-.158-.067-.036-.044-.054-.11-.054-.199V3.054Zm-1.127.34c0-.115.04-.206.123-.275a.503.503 0 0 1 .33-.102c.125 0 .228.036.308.108.08.071.12.164.12.278v.767c0 .128-.04.227-.117.3a.465.465 0 0 1-.325.107.45.45 0 0 1-.32-.11.396.396 0 0 1-.119-.303v-.77Zm-.827-.868.222.805h.022l.211-.805h.348l-.398 1.179v.835H3.81v-.798l-.407-1.216h.344Zm3.976 4.24a.974.974 0 0 1-.973.973H3.367a.974.974 0 0 1-.973-.974v-.782c0-.538.436-.974.973-.974H6.75c.537 0 .973.436.973.974v.782Z" />
            <path d="M6.496 5.798a.405.405 0 0 0-.295.11.38.38 0 0 0-.115.286v.607c0 .13.035.232.104.305.07.075.165.112.286.112.134 0 .235-.035.302-.105.068-.07.102-.174.102-.312v-.07h-.278v.062c0 .08-.009.13-.026.154-.018.023-.05.034-.094.034-.043 0-.073-.013-.091-.04-.018-.028-.027-.077-.027-.148v-.254h.516v-.345c0-.127-.033-.225-.1-.294-.066-.068-.16-.102-.284-.102Zm.106.53h-.238v-.135c0-.057.009-.098.028-.121.019-.026.05-.038.092-.038.041 0 .072.012.09.038.019.023.028.064.028.12v.137Z" />
        </SvgIcon>
    );
};
