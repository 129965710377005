export const BadgeVariants = {
    LABEL_DEFAULT: "label_default",
    LABEL_PRIMARY: "label_primary",
    LABEL_SECONDARY: "label_secondary",
    LABEL_BG2: "label_bg2",
    LABEL_SUCCESS: "label_success",
    LABEL_INFO: "label_info",
    LABEL_WARNING: "label_warning",
    LABEL_HIGHLIGHT: "label_highlight",
    LABEL_DANGER: "label_danger"
} as const;

type IBadgeVariantType = typeof BadgeVariants;

export type BadgeVariantsUnionType = IBadgeVariantType[keyof IBadgeVariantType];
