/* eslint-disable @typescript-eslint/no-explicit-any */
import {Dispatch, Middleware, MiddlewareAPI} from "redux";

import {consoleError} from "@pg-mono/logger";

import {catch5xx, catch401, catch403, catch404, catch504, catchStalled, catchUnknownError, catchUnknownStatus} from "../error/response_error";
import {IPromiseAction} from "./promise_middleware_types";

export interface IPromiseMiddlewareOptions {
    onErrorAlert?: () => (dispatch: Dispatch) => any;
    onErrorStatus?: (status: number) => (dispatch: Dispatch) => any;
    onError403Status?: () => (dispatch: Dispatch) => any;
    onError401Status?: () => (dispatch: Dispatch) => any;
    notifyError: (error: {name: string; message: string}, context: string) => void;
    logData: {
        url?: string;
    };
}

export const promiseMiddleware =
    (options: IPromiseMiddlewareOptions): Middleware =>
    <TStore>({dispatch, getState}: MiddlewareAPI) =>
    (next: Dispatch): any =>
    (action: IPromiseAction<any, TStore>) => {
        if (typeof action !== "function") {
            // object action
            return next(action);
        }
        const result = action(dispatch, getState);

        //  RTK-Query has requestId in its result, we should pass this on
        if (result && "requestId" in result) {
            return result;
        }

        if (!isPromise(result)) {
            // simple function action
            return result;
        }

        const {onErrorAlert, onErrorStatus, onError401Status, onError403Status, notifyError, logData} = options;
        const reqUrl = logData.url ?? null;

        /**
         * NOTE: when we catch something here that means we made a mistake:
         * Either API responded with something it shouldn't have (backend error), or we did not expect specific response code in action (frontend error).
         *
         * As a result we cancel further data-fetching (return `undefined` or `false`).
         * `undefined` means we should cancel next call (e.g. reduceActions)
         * `false` means we should cancel all future calls (e.g. after strictMapActions)
         */
        // promise action
        return result
            .catch(
                catch401((err) => {
                    consoleError("middleware-error 401", err.message, " ; reqUrl: ", reqUrl);
                    onError401Status && dispatch(onError401Status());
                    onErrorAlert && dispatch(onErrorAlert());
                    notifyError(err, `promiseMiddleware: catch 401: ${reqUrl}`);
                })
            )
            .catch(
                catch403((err) => {
                    consoleError("middleware-error 403", err.message, " ; reqUrl: ", reqUrl);
                    onError403Status && dispatch(onError403Status());
                    onErrorAlert && dispatch(onErrorAlert());
                    notifyError(err, `promiseMiddleware: catch 403: ${reqUrl}`);
                })
            )
            .catch(
                catch404((err) => {
                    consoleError("middleware-error 404", err.message, " ; reqUrl: ", reqUrl);
                    onErrorAlert && dispatch(onErrorAlert());
                    notifyError(err, `promiseMiddleware: catch 404: ${reqUrl}`);
                })
            )
            .catch(
                catch504((err) => {
                    consoleError("middleware-error 504", err.message, " ; reqUrl: ", reqUrl);
                    onErrorStatus && dispatch(onErrorStatus(err.response.status));
                    onErrorAlert && dispatch(onErrorAlert());
                    notifyError(err, `promiseMiddleware: catch 504: ${reqUrl}`);
                    return false;
                })
            )
            .catch(
                catch5xx((err) => {
                    consoleError("middleware-error 5xx", err.response.status, err.message, " ; reqUrl: ", reqUrl);
                    onErrorStatus && dispatch(onErrorStatus(err.response.status));
                    onErrorAlert && dispatch(onErrorAlert());
                    notifyError(err, `promiseMiddleware: catch 5xx: ${reqUrl}`);
                    return false;
                })
            )
            .catch(
                catchStalled((err) => {
                    consoleError("middleware-error stalled", err.message, " ; reqUrl: ", reqUrl);
                })
            )
            .catch(
                catchUnknownStatus((err) => {
                    consoleError("middleware-error unknown status", err.message, " ; reqUrl: ", reqUrl);
                    onErrorAlert && dispatch(onErrorAlert());
                    notifyError(err, `promiseMiddleware: catch unknown status: ${reqUrl}`);
                })
            )
            .catch(
                catchUnknownError((err) => {
                    consoleError("middleware-error unknown error: ", err.url, " ; reqUrl: ", reqUrl, " ; originalError: ", err.originalError);
                    const status = 500;
                    onErrorStatus && dispatch(onErrorStatus(status));
                    onErrorAlert && dispatch(onErrorAlert());
                    notifyError(err.originalError, `promiseMiddleware: catch unknown error, url: ${err.url}, reqUrl: ${reqUrl}`);
                    return false;
                })
            )
            .catch((err) => {
                consoleError("promiseMiddleware catch all", err, " ; reqUrl: ", reqUrl);
                const status = err && err.response && err.response.status ? err.response.status : 500;
                onErrorStatus && dispatch(onErrorStatus(status));
                notifyError(err, `promiseMiddleware: catch all: ${reqUrl}`);
                return false;
            });
    };

// utility function
const isPromise = (value: any) => typeof value === "object" && typeof value.then === "function";
