import {IFetchContext} from "@pg-mono/data-fetcher";

import {IRPRequestMeta} from "../../app/rp_request_meta";
import {concatWithBrandName} from "../../app/utils/concat_with_brand_name";
import {MetaDataDispatch, updateMetaData} from "../../meta_data/actions/update_meta_data";

export const updateCreditLandingMeta = (ctx: IFetchContext<IRPRequestMeta>) => async (dispatch: MetaDataDispatch) => {
    const title = concatWithBrandName(`Kredyt hipoteczny z`);
    const description = `Szukasz kredytu na swoje nowe mieszkanie? Z naszą pomocą otrzymasz kredyt hipoteczny bez stresu. Sprawdź, jak łatwo to zrobić na${concatWithBrandName(
        ""
    )}!`;

    const metaData = updateMetaData({
        title,
        description,
        pathname: ctx.route.pathname
    });

    dispatch(metaData);
    return true;
};

export const updateCreditOffersMeta = (ctx: IFetchContext<IRPRequestMeta>) => async (dispatch: MetaDataDispatch) => {
    const title = concatWithBrandName(`Kalkulator kredytu hipotecznego - oblicz ratę z`);
    const description = `${concatWithBrandName("Znajdź najlepszy kredyt hipoteczny dzięki kalkulatorowi na")}. Porównaj oferty banków i oblicz swoją ratę w kilka chwil. Sprawdź teraz!`;

    const metaData = updateMetaData({
        title,
        description,
        pathname: ctx.route.pathname
    });

    dispatch(metaData);
    return true;
};
