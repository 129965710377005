import {IFetchContext} from "@pg-mono/data-fetcher";

import {IRPStore} from "../../app/rp_reducer";
import {IRPRequestMeta} from "../../app/rp_request_meta";
import {MetaDataDispatch, updateMetaData} from "../../meta_data/actions/update_meta_data";
import {descriptionPropertyDetail} from "../../meta_data/page_description/property_detail";
import {titlePropertyDetail} from "../../meta_data/page_title/property_detail";
import {generateMetaRegionName} from "../../meta_data/utils/generate_meta_region_name";
import {createOfferLink} from "../../offer/helpers/create_offer_link";

export const updatePropertyDetailMetaData =
    ({route}: IFetchContext<IRPRequestMeta>) =>
    async (dispatch: MetaDataDispatch, getState: () => IRPStore) => {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const property = getState().property.property!;
        const offer = property.offer;
        const vendor = property.offer.vendor;
        const region = property.offer.region;
        const title = titlePropertyDetail({
            offerName: offer.name,
            regionName: generateMetaRegionName(offer.region, false),
            offerType: offer.type,
            flatType: property.flat_type,
            rooms: property.rooms,
            area: property.area,
            floor: property.floor
        });
        const description = descriptionPropertyDetail({
            offerType: offer.type,
            flatType: property.flat_type,
            rooms: property.rooms,
            area: property.area,
            price: property.price,
            regionName: region ? generateMetaRegionName(offer.region, false) : "",
            streetName: offer.street_name,
            offerName: offer.name,
            floor: property.floor
        });

        const offerDetailLink = createOfferLink({
            slug: offer.slug,
            id: offer.id,
            vendor: {slug: vendor.slug},
            groups: offer.groups
        });

        const gtmPage = "Widok nieruchomości";
        const robots = "noindex, follow";
        // const alternate = `${currentMobileSite}${route.pathname}`;
        dispatch(
            updateMetaData({
                title,
                description,
                pathname: route.pathname,
                gtmPage,
                robots,
                customCanonicalPath: offerDetailLink
            })
        );
        return true;
    };
