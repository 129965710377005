import React from "react";
import {css} from "@emotion/react";
import styled from "@emotion/styled";

import {isEmpty} from "@pg-mono/nodash";

interface IProps {
    isOpen: boolean;
    setOpen: () => void;
    setClose: () => void;
    onBack: () => void;
    label: string;
    navState: number[];
    arrow: JSX.Element;
    mobileBreakpoint?: number;
}

export const BurgerMenu = (props: IProps) => {
    return (
        <BurgerMenuHolder isActive={props.isOpen} mobileBreakpoint={props.mobileBreakpoint}>
            {props.isOpen && (
                <div css={menuLabelHolder} onClick={props.onBack}>
                    <div css={arrowHolder}>{!isEmpty(props.navState) && props.arrow}</div>
                    {props.label}
                </div>
            )}

            <BurgerButtonHolder isActive={props.isOpen} onClick={props.isOpen ? () => props.setClose() : () => props.setOpen()}>
                <button css={hamburger} className={props.isOpen ? "is-active" : ""} />
            </BurgerButtonHolder>
        </BurgerMenuHolder>
    );
};

const burgerActive = (props: {isActive: boolean; mobileBreakpoint?: number}) => css`
    background: ${props.isActive ? "#f9f9f9" : "#fff"};
    justify-content: ${props.isActive ? "space-between" : "flex-start"};
    width: ${props.isActive ? "320px" : ""};
    position: ${props.isActive ? "fixed" : "absolute"};
    top: 0;
    right: 0;
    z-index: 10000;

    @media (min-width: 320px) {
        width: ${props.isActive ? "320px" : ""};
    }

    @media (min-width: 360px) {
        width: ${props.isActive ? "360px" : ""};
    }

    @media (min-width: 375px) {
        width: ${props.isActive ? "375px" : ""};
    }

    @media (min-width: 414px) {
        width: ${props.isActive ? "414px" : ""};
    }

    @media (min-width: 500px) {
        width: ${props.isActive ? "360px" : ""};
    }
`;

const BurgerMenuHolder = styled.div`
    display: flex;
    height: 60px;
    align-items: center;
    font-size: 1.2rem;
    z-index: 2001;
    ${burgerActive};

    @media (min-width: ${(props) => (props.mobileBreakpoint ? props.mobileBreakpoint + 1 : 1024)}px) {
        display: none;
    }
`;

const menuLabelHolder = css`
    display: flex;
    align-items: center;
`;

const arrowHolder = css`
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const burgerHolderActive = (props: {isActive: boolean}) => css`
    background: ${props.isActive ? "#ededee" : "#fff"};
`;

const BurgerButtonHolder = styled.div`
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    ${burgerHolderActive};
`;

const hamburger = css`
    background: none;
    border: none;
    cursor: pointer;
    &:focus {
        outline: none;
    }

    position: relative;
    width: 24px;
    height: 20px;
    background: transparent;
    border-top: 3px solid;
    border-bottom: 3px solid;
    color: #23232d;
    transition: all 0.25s ease-in-out;

    &:before,
    &:after {
        content: "";
        display: block;
        width: 100%;
        height: 3px;
        position: absolute;
        top: 50%;
        left: 50%;
        background: currentColor;
        transform: translate(-50%, -50%);
        transition: transform 0.25s ease-in-out;
    }

    &:hover {
        color: lighten(#23232d, 15%);
    }

    &.is-active {
        border-color: transparent;

        &:before {
            transform: translate(-50%, -50%) rotate(45deg);
        }

        &:after {
            transform: translate(-50%, -50%) rotate(-45deg);
        }
    }
`;
