import {useEffect, useState} from "react";
import {useParams} from "react-router";

import {DEFAULT_DISTANCE} from "../constants/offer_list";
import {getBrowserDefaultDistance} from "../helpers/get_browser_default_distance";
import {OfferListSubType} from "../types/OfferListSubType";

export const useDefaultDistance = (queryDistance?: number | string) => {
    const params = useParams<{offerListSubType?: OfferListSubType}>();

    const [distance, setDistance] = useState(queryDistance ? +queryDistance : DEFAULT_DISTANCE);

    useEffect(() => {
        setDistance(queryDistance ? +queryDistance : getBrowserDefaultDistance(params.offerListSubType));
    }, [queryDistance, params.offerListSubType]);

    return distance;
};
