import {Dispatch} from "redux";

import {reduceFavouritesToDataType} from "@pg-mono/favourites";

import {favouriteTypes} from "./manage_favourites";
import {getLocalStorageFavourites} from "./manage_local_storage_favourites";

export const loadLocalStorageFavouritesToStore = () => async (dispatch: Dispatch) => {
    const favourites = getLocalStorageFavourites();

    dispatch({type: favouriteTypes.set, list: favourites, ...reduceFavouritesToDataType(favourites)});
};
