import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {appendQueryString, getRequest, IQueryValue} from "@pg-mono/request";
import {createRequestActionTypes} from "@pg-mono/request-state";
import {apiV2Link} from "@pg-mono/rp-api-routes";

import {IRPRequestMeta} from "../../app/rp_request_meta";
import {IPaginatedResponse} from "../../request/IResponse";

const FETCH_PARTNER_LIST_PREFIX = "partner_list/FETCH_PARTNER_LIST_PREFIX";
export const fetchPartnersListTypes = createRequestActionTypes(FETCH_PARTNER_LIST_PREFIX);

export interface IPartner {
    description: string;
    follow_link: boolean;
    logo: {
        p_log_160: string;
    } | null;
    name: string;
    website: string;
}

export interface IPartnerResponse extends IPaginatedResponse {
    results: IPartner[];
}

export const fetchPartnersList = (ctx: IFetchContext<IRPRequestMeta>) => async (dispatch: Dispatch) => {
    dispatch({type: fetchPartnersListTypes.start});
    const query: Record<string, IQueryValue> = {
        // we fetch all available partners
        page_size: 1000
    };
    const url = appendQueryString(apiV2Link.partners.list(null), query);
    return getRequest(ctx.meta, url).then((json: IPartnerResponse) => {
        dispatch({type: fetchPartnersListTypes.success, result: json.results});
        return json;
    });
};
