import React from "react";
import styled from "@emotion/styled";

import {IIcon} from "../interfaces/IIcon";
import {SvgIconWrapper} from "./SvgIconWrapper";

export const FlagIcon: React.FC<IIcon> = (props) => {
    const {size = "1", wrapperColor, wrapperSize, wrapperType, children, onClick} = props;
    const hasWrapper = wrapperSize && wrapperColor;

    const icon = (
        <FlagIconBase
            width={10}
            height={10}
            onClick={!hasWrapper ? onClick : undefined}
            className={!hasWrapper ? props.className : undefined}
            size={size}
            viewBox="0 0 10 10"
            fill="none"
        >
            {children}
        </FlagIconBase>
    );

    return wrapperSize && wrapperColor ? (
        <SvgIconWrapper onClick={onClick} className={props.className} wrapperColor={wrapperColor} wrapperSize={wrapperSize} wrapperType={wrapperType}>
            {icon}
        </SvgIconWrapper>
    ) : (
        icon
    );
};

const FlagIconBase = styled.svg<{size: string}>`
    transform: ${(props) => `scale(${props.size})`};
`;
