import {NotificationsReduxContainer, useNotificationsRouteHandler} from "@pg-design/ds-store";

import {useAppSelector} from "../../store/hooks";

export const Notifications = () => {
    const notifications = useAppSelector((store) => store.notifications);
    useNotificationsRouteHandler();

    return <NotificationsReduxContainer notifications={notifications} />;
};
