import render from "dom-serializer";
import {DomUtils, Element, parseDOM} from "htmlparser2";

import {deburr, kebabCase} from "@pg-mono/nodash";

const DEFAULT_TABLE_OF_CONTENTS_CLASS_NAME = "mce-toc";

export interface ITableOfContentsElement {
    id: string | null;
    text: string | null;
    elements: ITableOfContentsElement[];
}

interface IParsedArticleText {
    articleText: string;
    articleHeaderIds: string[] | null;
    tableOfContents: ITableOfContentsElement[] | null;
    tableOfContentsTitle: string | null;
}

/**
 * Extracts table of contents from article text.
 * @param articleText {string} article text
 * @param tableOfContentsClassName {string} table of contents container tag name
 * @returns {IParsedArticleText} parsed article with extracted table of contents (if exists)
 */
export const extractArticleTableOfContents = (articleText: string, tableOfContentsClassName = DEFAULT_TABLE_OF_CONTENTS_CLASS_NAME): IParsedArticleText => {
    const parsedArticleTextDOM = parseDOM(articleText);

    const tableOfContentsElementWrapperElement = DomUtils.findOne((element: Element) => {
        return DomUtils.getAttributeValue(element, "class") === tableOfContentsClassName;
    }, parsedArticleTextDOM);

    const tocFound = !!tableOfContentsElementWrapperElement;

    if (!tocFound) {
        return {
            tableOfContents: null,
            articleHeaderIds: null,
            tableOfContentsTitle: null,
            articleText
        };
    }
    const tableOfContentsElement = tocFound ? findHTMLElementByTag(tableOfContentsElementWrapperElement.children, "ul") : null;

    const tableOfContentsTitleElement = tocFound ? findHTMLElementByTag(tableOfContentsElementWrapperElement.children, "h2") : null;

    const tableOfContentsData = tableOfContentsElement ? convertTableOfContentsToArray(tableOfContentsElement.children) : null;
    const tableOfContentsTitle = tableOfContentsTitleElement ? DomUtils.getText(tableOfContentsTitleElement) : null;

    if (tableOfContentsElementWrapperElement) {
        DomUtils.removeElement(tableOfContentsElementWrapperElement);
    }

    // let parsedArticleText = DomUtils.getOuterHTML(parsedArticleTextDOM);

    let parsedArticleText = render(parsedArticleTextDOM, {encodeEntities: "utf8"});

    !!tableOfContentsData &&
        tableOfContentsData.articleIdsMap.forEach(([originalId, targetId]) => {
            parsedArticleText = parsedArticleText.replace(originalId, targetId);
        });

    return {
        tableOfContents: !!tableOfContentsData ? tableOfContentsData.tableOfContentsItems : null,
        articleHeaderIds: !!tableOfContentsData ? tableOfContentsData.articleHeaderIds : null,
        tableOfContentsTitle,
        articleText: parsedArticleText
    };
};

/**
 * Returns first found HTML Element which has expected tag
 * @param source {HTMLAllCollection} any HTMLCollection
 * @param tag {string} expected tag name
 * @returns {Element} found HTML Element
 */
const findHTMLElementByTag = (source: Node[], tag: string): Element => {
    return DomUtils.findOne((element: Element) => {
        return element.tagName === tag;
    }, source);
};

/**
 * Returns new table of contents empty item
 * @returns {ITableOfContentsElement} empty table of contents item
 */
export const getTableOfContentsEmptyElement = (): ITableOfContentsElement => ({id: null, text: null, elements: []});

/**
 * Returns table of contents element text formatted like a slug
 * @param value {string} initial table of contents text element value
 * @returns {string} slug for table of contents element
 */
export const createElementSlug = (value: string): string => kebabCase(deburr(value.toLocaleLowerCase()));

/**
 * Converts table of contents HTMLCollection into table of contents elements
 * @param source {HTMLAllCollection} table of contents HTMLCollection
 * @returns {ITableOfContentsElement[]} array of table of contents elements
 */
const convertTableOfContentsToArray = (
    source: HTMLCollection
): {tableOfContentsItems: ITableOfContentsElement[]; articleHeaderIds: string[]; articleIdsMap: string[][]} => {
    const tableOfContentsItems: ITableOfContentsElement[] = [];
    const articleHeaderIds: string[] = [];
    const articleIdsMap: string[][] = [];

    (function loopNodesRecursive(elements: HTMLCollection, parentRef?: ITableOfContentsElement, articleIdsRef?: string[]) {
        Array.from(elements).forEach((node) => {
            let parent = parentRef || getTableOfContentsEmptyElement();
            let articleIds = articleIdsRef || [];

            const nodeTextValue = DomUtils.getText(node);

            if (node.tagName === "a") {
                const nodeHrefAttribute = DomUtils.getAttributeValue(node, "href").replace("#", "");
                /**
                 * If articleIds array has two elements, then we need to create new articleIds array, because we know
                 * that this element already gain required data (href attribute value and slug based on header title).
                 * Finally we need to set a reference to newly created articleIds array in order to share this reference
                 * for the following nodes (exactly TextNodes contained in this `a` tag).
                 */
                if (articleIds.length === 2) {
                    const newArticleIds = [];

                    newArticleIds.push(nodeHrefAttribute);

                    articleIds = newArticleIds;
                } else {
                    articleIds.push(nodeHrefAttribute);
                }

                articleIdsMap.push(articleIds);
            }

            if (node.tagName === "li") {
                if (parent.id) {
                    /**
                     * If parent has id set - then we know that this node should be treated as child. In such situation
                     * we need to create new table of content element and push it to the parent elements array. Also we
                     * need to change reference to parent in order to share this reference for the following nodes.
                     */
                    const newParent = getTableOfContentsEmptyElement();

                    parent.elements.push(newParent);

                    parent = newParent;
                }

                /**
                 * If parentRef doesn't exist then we should treat this node as main parent and push it directly to table
                 * of contents not to elements array of a parent node.
                 */
                if (!parentRef) {
                    tableOfContentsItems.push(parent);
                }
            }

            /**
             * `node.nodeType === 3` equals TextNode. We need to trim such nodes, because htmlparser2
             * treats whitespaces between HTML tags as TextNodes - we don't need them.
             */
            if (node.nodeType === 3 && nodeTextValue.trim()) {
                const articleTargetId = createElementSlug(nodeTextValue);

                parent.text = nodeTextValue;
                parent.id = articleTargetId;

                articleIds.push(articleTargetId);
                articleHeaderIds.push(articleTargetId);
            }

            if (node.children) {
                loopNodesRecursive(node.children, parent, articleIds);
            }
        });
    })(source);

    return {
        tableOfContentsItems,
        articleHeaderIds,
        articleIdsMap
    };
};
