import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {appendQueryString} from "@pg-mono/request";
import {enable301ResponseState, enable404ResponseState} from "@pg-mono/response-state";
import {rpAppLink} from "@pg-mono/rp-routes";

import {IRPRequestMeta} from "../../app/rp_request_meta";
import {fetchPropertyDetailById} from "../../property/api/fetch_property_detail_by_id";

export const redirectPropertyPermalink = (ctx: IFetchContext<IRPRequestMeta>) => async (dispatch: Dispatch) => {
    const propertyId = parseInt(ctx.match.params.propertyId as string, 10);

    if (isFinite(propertyId)) {
        const property = await fetchPropertyDetailById(ctx.meta, propertyId);

        if (property) {
            const redirectUrl = appendQueryString(
                rpAppLink.property.detail.base({
                    vendorSlug: property.offer.vendor.slug,
                    offerSlug: property.offer.slug,
                    offerId: property.offer.id,
                    propertyId: property.id
                }),
                ctx.route.query
            );

            dispatch(enable301ResponseState(redirectUrl));

            return true;
        }
    }

    dispatch(enable404ResponseState());

    return true;
};
