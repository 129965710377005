import {OfferListSubType} from "../../types/OfferListSubType";
import {getFloorChoiceByOfferListSubType} from "./transform/get_floor_choice_by_offer_list_sub_type";
import {getHouseFilterByOfferListSubTypeForQuery} from "./transform/get_house_filter_by_offer_list_sub_type";
import {isOfferListSubTypeValidFloorChoice} from "./validator/is_offer_list_sub_type_valid_floor_choice";
import {isOfferListSubTypeValidHouseFilter} from "./validator/is_offer_list_sub_type_valid_house_filter";

export const getFetchOfferListQuery = (query: Record<string, string | string[] | null>, offerListSubType?: OfferListSubType, offerListSubFilter?: string) => {
    if (offerListSubFilter && isOfferListSubTypeValidFloorChoice(offerListSubFilter)) {
        const floorChoice = getFloorChoiceByOfferListSubType(offerListSubFilter);
        query.floor_choices = [floorChoice.toString()];
    } else if (offerListSubFilter && isOfferListSubTypeValidHouseFilter(offerListSubFilter)) {
        query = {
            ...query,
            ...getHouseFilterByOfferListSubTypeForQuery(offerListSubFilter)
        };
    }

    return query;
};
