import {Dispatch} from "redux";

import {IFormValuesAction} from "@pg-mono/form";
import {loadGoogleMapsApi} from "@pg-mono/google-api";
import {isEmpty} from "@pg-mono/nodash";
import {RequestState} from "@pg-mono/request-state";

import {IRPStore} from "../../app/rp_reducer";
import {ISearchInputValue} from "../components/ISearchInputValue";
import {addNewRegionToCurrentRegions} from "../utils/add_new_region_to_current_regions";
import {googlePlacesDivId} from "../utils/search_constants";
import {SearchInfoWindowCitiesList} from "../utils/search_info_window_cities_list";
import {SearchTab} from "./fetch_search_all_action";
import {AutocompletePrediction} from "./fetch_search_places_action";
import {fetchSingleRegionSimple} from "./fetch_search_regions_action";
import {searchFormActions} from "./search_form_actions";

const getGooglePlaceInfo = async (place: AutocompletePrediction) => {
    await loadGoogleMapsApi(["places"]);

    return new Promise((resolve) => {
        const {place_id: placeId} = place;
        const _googlePlacesTabResults = document.getElementById(googlePlacesDivId);
        const service = new google.maps.places.PlacesService(_googlePlacesTabResults as HTMLDivElement);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        service.getDetails({placeId}, (placeDetail: any) => {
            //TODO: Google maps typings. For now we are copy pasting interfaces for yarn has problems with handling typings for global vars.
            if (placeDetail) {
                const lat = placeDetail.geometry.location.lat();
                const lng = placeDetail.geometry.location.lng();
                return resolve({
                    label: place.description,
                    tabType: SearchTab.Places,
                    place: place,
                    location: {lat, lng}
                });
            }
            return resolve({label: "", tabType: null});
        });
    });
};

export const getItemOnClick = async (option: ISearchInputValue) => {
    if (option.tabType === SearchTab.Places) {
        const placeField = await getGooglePlaceInfo(option.place);
        return placeField;
    }
    return option;
};

export const setItemOnEnter = (disableMultiRegion?: boolean) => async (dispatch: Dispatch<IFormValuesAction<unknown>>, getState: () => IRPStore) => {
    const state = getState().search;
    const activeItem = state.activeDropdownItem;
    const currentTab = state.currentTab;

    const places = state.places;
    const offers = state.offers;
    const regions = state.regions;
    const vendors = state.vendors;
    const {fetchAllRequest} = state;

    const inputValue = state.formValues["search"].label;
    const searchRegions = disableMultiRegion ? [] : state.formValues["search"].regions;

    /**
     * ACTIVE ITEM NOT DEFINED
     */

    if (activeItem.id == null) {
        if (isEmpty(inputValue)) {
            const suggestedRegionMock = SearchInfoWindowCitiesList[0];
            const suggestedRegion = await fetchSingleRegionSimple(suggestedRegionMock.id);
            const activeSuggestedRegionField = {
                label: "",
                regions: addNewRegionToCurrentRegions(searchRegions, suggestedRegion),
                tabType: currentTab
            };
            dispatch(searchFormActions.update({search: activeSuggestedRegionField}));
            return activeSuggestedRegionField;
        }

        switch (currentTab) {
            case SearchTab.Regions:
                if (regions.length) {
                    if (fetchAllRequest === RequestState.Waiting) {
                        return false;
                    }
                    const region = regions[0];
                    const regionField = {
                        label: "",
                        regions: addNewRegionToCurrentRegions(searchRegions, region),
                        tabType: currentTab
                    };
                    dispatch(searchFormActions.update({search: regionField}));
                    return regionField;
                }
                return false;
            case SearchTab.Offers:
                if (offers.length) {
                    if (fetchAllRequest === RequestState.Waiting) {
                        return false;
                    }
                    const offer = offers[0];
                    const offerField = {
                        label: offer.name,
                        offer: offer,
                        type: offer.type,
                        vendor: offer.vendor,
                        tabType: currentTab
                    };
                    dispatch(searchFormActions.update({search: offerField}));
                    return offerField;
                }
                return false;
            case SearchTab.Places:
                if (places.length) {
                    if (fetchAllRequest === RequestState.Waiting) {
                        return false;
                    }
                    const place = places[0];
                    const placeField = await getGooglePlaceInfo(place);
                    dispatch(searchFormActions.update({search: placeField}));
                    return placeField;
                }
                return false;
            case SearchTab.Vendors:
                if (vendors.length) {
                    if (fetchAllRequest === RequestState.Waiting) {
                        return false;
                    }
                    const vendor = vendors[0];
                    const vendorField = {
                        label: vendor.name,
                        vendor,
                        tabType: currentTab
                    };
                    dispatch(searchFormActions.update({search: vendorField}));
                    return vendorField;
                }
                return false;
            default:
                return false;
        }
    }

    /**
     * ACTIVE ITEM IS DEFINED
     */

    if (isEmpty(inputValue)) {
        const suggestedRegionMock = SearchInfoWindowCitiesList[activeItem.id];
        const suggestedRegion = await fetchSingleRegionSimple(suggestedRegionMock.id);
        const activeSuggestedRegionField = {
            label: "",
            regions: addNewRegionToCurrentRegions(searchRegions, suggestedRegion),
            tabType: currentTab
        };
        dispatch(searchFormActions.update({search: activeSuggestedRegionField}));
        return activeSuggestedRegionField;
    }

    switch (currentTab) {
        case SearchTab.Regions:
            const region = regions[activeItem.id];
            const regionField = {
                label: "",
                regions: addNewRegionToCurrentRegions(searchRegions, region),
                tabType: currentTab
            };
            dispatch(searchFormActions.update({search: regionField}));
            return regionField;

        case SearchTab.Offers:
            const offer = offers[activeItem.id];
            const offerField = {
                label: offer.name,
                offer: offer,
                type: offer.type,
                vendor: offer.vendor,
                tabType: currentTab
            };
            dispatch(searchFormActions.update({search: offerField}));
            return offerField;

        case SearchTab.Places:
            const place = places[activeItem.id];
            const placeField = await getGooglePlaceInfo(place);
            dispatch(searchFormActions.update({search: placeField}));
            return placeField;

        case SearchTab.Vendors:
            const vendor = vendors[activeItem.id];
            const vendorField = {
                label: vendor.name,
                vendor,
                tabType: currentTab
            };
            dispatch(searchFormActions.update({search: vendorField}));
            return vendorField;

        default:
            return false;
    }
};
