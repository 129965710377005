import {IRankingItem} from "../types/IRankingItem";

export const ranking2023: IRankingItem[] = [
    {
        regionOrder: 1,
        regionId: 22550,
        rating: 4.88,
        ratingDescription:
            'Urzecze jest pełnoprawnym wielkomiejskim osiedlem, którego standard wykonania nie pozostawia co do tego żadnych wątpliwości. Poziom każdego elementu został podwyższony, natomiast bliskość rzeki, która zawsze stanowi "atrakcję", został dodatkowo wzmocniony tętniącą zielenią.',
        offerId: 13899
    },
    {
        regionOrder: 2,
        regionId: 22550,
        rating: 4.55,
        ratingDescription:
            'Osiedle Symfonia reprezentuje solidny standard, z zewnątrz bez nadzwyczajnych zaskoczeń, ale wewnątrz czeka na klientów "ukryty skarb". Inwestor zadbał o elementy jednoznacznie kojarzące się z wysokim standardem - jak wykładziny i wzory kamienia.',
        offerId: 14633
    },
    {
        regionOrder: 3,
        regionId: 22550,
        rating: 4.17,
        ratingDescription:
            "Inwestor postanowił zadbać o każdy element osiedla. Fasady są bogate w dodakowe okładziny a balkony ponadstandardowo głębokie. Na uwagę zasługują także rozbudowane wnętrza.",
        offerId: 13818
    },
    {
        regionOrder: 4,
        regionId: 22550,
        rating: 4.11,
        ratingDescription: "",
        offerId: 12148
    },
    {
        regionOrder: 5,
        regionId: 22550,
        rating: 3.87,
        ratingDescription: "",
        offerId: 11325
    },
    {
        regionOrder: 1,
        regionId: 46083,
        rating: 5,
        ratingDescription:
            'Projekt "DOKI Living" jest nie tylko inwestycją a "wydarzeniem". Inwestor postawił na bogato ozdobione i rozrzeźbione fasady, a wyposażenie wnętrz w różnorodne materiały, wzory i kolory, co pozwoliło na stworzenie wystroju charakterystycznego dla loftowego stylu.',
        offerId: 13149
    },
    {
        regionOrder: 2,
        regionId: 46083,
        rating: 4.91,
        ratingDescription:
            "Scala traktuje niezwykle poważnie miejsce, w którym się znajduje. Wyrazem tego jest subtelna tektonika fasady w połączeniu z okładziną z płytek. Balkony i zagospodarowanie dopełniają efektu zewnętrznego. Wnętrza klatek i korytarzy są kombinacją wielu różnych materiałów, pozwalającą na stworzenie oryginalnego wystroju.",
        offerId: 12361
    },
    {
        regionOrder: 3,
        regionId: 46083,
        rating: 4.74,
        ratingDescription:
            "Przygotowując inwestycję Bursztynowa Zatoka, firma ATAL postawiła na wyróżnienie balkonów oraz wnętrz. Przeszklone balustrady i duże balkony z pewnością uzupełniają się z dodatkami na elewacji.",
        offerId: 14555
    },
    {
        regionOrder: 4,
        regionId: 46083,
        rating: 4,
        ratingDescription: "",
        offerId: 14497
    },
    {
        regionOrder: 5,
        regionId: 46083,
        rating: 3.85,
        ratingDescription: "",
        offerId: 14510
    },
    {
        regionOrder: 1,
        regionId: 31600,
        rating: 5,
        ratingDescription:
            "Wyjątkowa fasada wymaga wyjątkowych środków. Uskoki przypominające kryształ są dodatkowo wzmocnione szerokimi loggiami o szklanych balustradach. Nie dziwi więc, że w częściach wspólnych, pomieszczeniach dla mieszkańców a nawet garażach, dołożono starań, aby program wykończeń był na adekwatnym poziomie.",
        offerId: 14973
    },
    {
        regionOrder: 2,
        regionId: 31600,
        rating: 4.45,
        ratingDescription:
            'Tak wysokie budynki wymagają wyjątkowych nakładów na fasady, dzięki czemu efekt końcowy przykuwa wzrok. "Global Apartments"  nawiązuje do wzorów znanych nam z drapaczy chmur. Wykończenie przestrzeni, aby nie odbiegało od założonego wizerunku, jest bogate w nowoczesne okładziny ozdabiające drzwi do mieszkań, korytarze i hole wejściowe.',
        offerId: 15235
    },
    {
        regionOrder: 3,
        regionId: 31600,
        rating: 4.04,
        ratingDescription:
            'W przypadku inwestycji "Pierwsza Dzielnica" pewnych rzeczy po prostu nie da się przeoczyć. Imponujące bryły są spowite obudową ze szklanych balkonów. Zieleni przewidziano tak dużo, że budynki zdają się "wyłaniać wprost z bujnego lasu".',
        offerId: 14076
    },
    {
        regionOrder: 4,
        regionId: 31600,
        rating: 3.44,
        ratingDescription: "",
        offerId: 12921
    },
    {
        regionOrder: 5,
        regionId: 31600,
        rating: 2.97,
        ratingDescription: "",
        offerId: 11661
    },
    {
        regionOrder: 1,
        regionId: 11158,
        rating: 4.94,
        ratingDescription:
            'W projekcie "29. Aleja" uskoki fasady zostały użyte jako atut kreujący wizerunek budynku. W połączeniu z dużymi, narożnymi oknami pozwalają na wykreowanie oryginalnego wyglądu. Wnętrza mają drobiazgowo dobrane materiały i ich ilości, aby przykuć wzrok na dłużej.',
        offerId: 15462
    },
    {
        regionOrder: 2,
        regionId: 11158,
        rating: 4.8,
        ratingDescription:
            'W inwestycji "Zacisze Banacha" można zobaczc jak wykorzystać dodatki na elewacjach i we wnetrzach do stworzenia wyróżniającego się projektu. Uzupełnienie elewacji o okładziny pozwoliło na wprowadzenie niecodziennego koloru i wzoru szkliwionych płytek. We wnętrzach rządzą drewniane lamele i duże kontrastowe zabudowy drzwi do mieszkań.',
        offerId: 13941
    },
    {
        regionOrder: 3,
        regionId: 11158,
        rating: 4.14,
        ratingDescription:
            'W inwestycji "Mazowieka 115" wykreowanie budynku przypominającego kilka niezależnych kamienic wymagalo użycia dodatkowych materiałów na fasadach. Ponadto decyzje materiałowe podkreślone są wysokimi kontrastami kolorów wzmacniając wrażenie nasyconych detalem elewacji.',
        offerId: 14404
    },
    {
        regionOrder: 4,
        regionId: 11158,
        rating: 3.84,
        ratingDescription: "",
        offerId: 15082
    },
    {
        regionOrder: 5,
        regionId: 11158,
        rating: 3,
        ratingDescription: "",
        offerId: 13804
    },
    {
        regionOrder: 1,
        regionId: 13220,
        rating: 5,
        ratingDescription:
            "Inwestycję wyróżnia niecodzienna bryła oraz duże obiegające budynek loggie. Nietypowa forma budynku wymaga porzucenia oszczędnego i konwencjonalnego podejścia do tworzenia fasad oraz balkonów.",
        offerId: 13013
    },
    {
        regionOrder: 2,
        regionId: 13220,
        rating: 4.94,
        ratingDescription:
            "Rezydencja Ogród Saski wprowadza mnogość i różnorodność takich elementów jak: zabudowy balkonów, szkło, uskoki bryły. Przygotowanie inwestycji złożonej z kilku budynków w taki sposób, aby każdy miał własny oryginalny wygląd nie mogło obejść się bez uzupełnienia fasad i balkonów o ponadstandardowe elementy.",
        offerId: 15090
    },
    {
        regionOrder: 3,
        regionId: 13220,
        rating: 4.71,
        ratingDescription:
            'Wieniawska 11" wyróżnia się przestrzenną elewacją i dodatkowym nakładem na wysokiej jakości okładziny fasad. Nie bez znaczenia dla takiej inwestycji są też dopracowane wnętrza, wymagające także uzupełnienia zakresu o zabudowy, posadzki, oświetlenie i różne dodatki. "Wieniawska 11" ma to wszystko.',
        offerId: 14081
    },
    {
        regionOrder: 4,
        regionId: 13220,
        rating: 4.55,
        ratingDescription: "",
        offerId: 14200
    },
    {
        regionOrder: 5,
        regionId: 13220,
        rating: 4.17,
        ratingDescription: "",
        offerId: 13337
    },
    {
        regionOrder: 1,
        regionId: 16187,
        rating: 5,
        ratingDescription:
            "Osiedle Primo to ten rodzaj inwestycji, która docenia wagę każdego elementu w kreowaniu wizerunku. Dodatkowe okładziny, szklane balustrady balkonów, duże okna i wiele innych udoskonaleń można zobaczyć przyglądając się uważniej tym budynkom.",
        offerId: 15646
    },
    {
        regionOrder: 2,
        regionId: 16187,
        rating: 4.8,
        ratingDescription:
            "Gwiazdowa Park, wykorzystuje szereg urozmaiceń fasady i balkonów. Nie brakuje okładzin, podziałów elewacji, szkła, oświetlenia balkonów.",
        offerId: 16600
    },
    {
        regionOrder: 3,
        regionId: 16187,
        rating: 3.23,
        ratingDescription:
            'Inwestycja "Nawrot 34" łączy w sobie renowację istniejącej kamienicy oraz nowoczesny design, gdzie postawiono na wyróżniające się fasady z charakterystycznymi lamelami oraz z niezbędną dbałością  zachowywano elewację.',
        offerId: 13929
    },
    {
        regionOrder: 4,
        regionId: 16187,
        rating: 3.16,
        ratingDescription: "",
        offerId: 13099
    },
    {
        regionOrder: 5,
        regionId: 16187,
        rating: 3.13,
        ratingDescription: "",
        offerId: 14449
    },
    {
        regionOrder: 1,
        regionId: 30892,
        rating: 5,
        ratingDescription:
            "W tym projekcie historyczne sąsiedztwo narzuca pewien standard zewnętrznego wyglądu budynku. Jednak najbardziej wyróżniają się w nim wnętrza, w których jakość i ilość użytych materiałów nawiązuje do złożonej obietnicy o luksusowej przestrzeni.",
        offerId: 15349
    },
    {
        regionOrder: 2,
        regionId: 30892,
        rating: 4.48,
        ratingDescription:
            'Aby tworzyć budynki takie jak "Modena", trzeba położyć równomierny i duży nacisk na każdy element wyglądu budynku. W przypadku tej inwestycji należy zwrócić uwagę na tektonikę elewacji, zaokrąglenie balkonów, bujną zieleń i bogate wnętrza. Na nadzwyczajną uwagę zasługują wysokie mieszkania. Ta istotna cecha nie występuje często we współczesnym budownictwie.',
        offerId: 14425
    },
    {
        regionOrder: 3,
        regionId: 30892,
        rating: 3.61,
        ratingDescription:
            'W "Saint Martin Resedences" w oczy rzuca się wyjątkowa fasada i wyeksponowane wnętrza. Mnogość form na elewacjach i wszystkie niezbędne materiały tworzące nowoczesne, eleganckie wnętrza bez wątpienia wyróżniają ją na tle innych projektów.',
        offerId: 14734
    },
    {
        regionOrder: 4,
        regionId: 30892,
        rating: 3.6,
        ratingDescription: "",
        offerId: 13682
    },
    {
        regionOrder: 5,
        regionId: 30892,
        rating: 3.59,
        ratingDescription: "",
        offerId: 13308
    },
    {
        regionOrder: 1,
        regionId: 32657,
        rating: 5,
        ratingDescription:
            'Rzadko spotyka się inwestycję z tak dużymi oknami, jak w "Aloha Residence". W parze idą z nimi szklane balustrady balkonów wykończonych dekiem.',
        offerId: 13356
    },
    {
        regionOrder: 2,
        regionId: 32657,
        rating: 4.24,
        ratingDescription:
            'Rozrzeźbiona fasada "Grand Bulwar" jest najbardziej charakterystycznym elementem inwestycji. Aby podkreślić efekt uskoków, zastosowano w projekcie dodatkowe materiały, obróbki. Balkony miejscami zamieniają się w loggie lub tarasy. A to wszystko zostało uzupełnione dodatkowymi nakładami potrzebnymi na zaprojektowanie tak złożonej bryły. Wyróżniają się także ozdobne wnętrza oraz wysokie mieszkania.',
        offerId: 15127
    },
    {
        regionOrder: 3,
        regionId: 32657,
        rating: 4.08,
        ratingDescription:
            'Budynek położony nad wodą z pewnością jest wyjątkowy, ale budynek z mariną jest bez wątpienia niepowtarzalny. Wyróżniająca jest tym samym fasada budynku "Clubhouse", udekorowana naprzemiennymi przesłonami i rozciągniętymi balkonami. W miejscach, gdzie to było możliwe teren został uzupełniony drzewami, a wnętrza wyposażone w różne rodzaje okładzin oraz tłumiący hałasy dywan na posadzce korytarzy.',
        offerId: 12093
    },
    {
        regionOrder: 4,
        regionId: 32657,
        rating: 3.48,
        ratingDescription: "",
        offerId: 12694
    },
    {
        regionOrder: 5,
        regionId: 32657,
        rating: 3.12,
        ratingDescription: "",
        offerId: 14143
    },
    {
        regionOrder: 1,
        regionId: 8647,
        rating: 5,
        ratingDescription:
            'Na tle warszawskich inwestycji "Osiedle Urbino" pokazuje, w jaki sposób wykorzystać jako wyróżnik nakłady na zieleń na terenie inwestycji oraz wnętrza. Wyższe niż zazwyczaj mieszkania dopełniają efektu.',
        offerId: 13879
    },
    {
        regionOrder: 2,
        regionId: 8647,
        rating: 4.32,
        ratingDescription:
            'Projekt "Krakowska Vita" korzysta z każdego aspektu budującego standard inwestycji. Jest to przykład wyważenia nakładów i osiąganego efektu zarówno na reprezentacyjnej fasadzie, kombinacji balkonów i elementów zagospodarowania.',
        offerId: 14312
    },
    {
        regionOrder: 3,
        regionId: 8647,
        rating: 4.14,
        ratingDescription:
            'Osiedle "Złota Oksza" wyróżnia się zewnętrznym wyglądem, nie zapominając o pozostałych kwestiach, jak wnętrza i wysokość mieszkań. Elewacje zostały pokryte dodatkowymi okładzinami. Powiększone okna i wyższe kondygnacje są niezbywalną cechą tej inwestycji.',
        offerId: 13813
    },
    {
        regionOrder: 4,
        regionId: 8647,
        rating: 4.11,
        ratingDescription: "",
        offerId: 14406
    },
    {
        regionOrder: 5,
        regionId: 8647,
        rating: 3.97,
        ratingDescription: "",
        offerId: 13729
    },
    {
        regionOrder: 1,
        regionId: 26955,
        rating: 5,
        ratingDescription:
            "River Point charakteryzuje się wielością zastosowanych rozwiązań architektonicznych. Wyraźnie odznaczają się duże, przeszklone balkony oraz bogaty w zieleń teren otaczający tę rozległą inwestycję.",
        offerId: 15359
    },
    {
        regionOrder: 2,
        regionId: 26955,
        rating: 4.26,
        ratingDescription:
            "Urozmaicona bryła została obleczona w kostium balkonów wykończonych bezszprosowym szkłem i dekami. Dla uzyskania efektu prestiżowego wnętrza zdecydowano się na dodanie wzorów kamienia, ciemnego drewna w formie dodatkowych okładzin. Zastosowano również nowoczesne oświetlenie.",
        offerId: 15325
    },
    {
        regionOrder: 3,
        regionId: 26955,
        rating: 4.14,
        ratingDescription:
            'Inwestycja "Midori" przykuwa wzrok delikatną, przestrzenną fasadą, skrywającą kaskadowe tarasy. Uzyskanie takiego efektu wymaga pomysłowości projektanta i sporo nakładów pracy. Wnętrza części wspólnych są odbiciem wyglądu zewnętrznego i dopełniają całości.',
        offerId: 15351
    },
    {
        regionOrder: 4,
        regionId: 26955,
        rating: 4.1,
        ratingDescription: "",
        offerId: 16136
    },
    {
        regionOrder: 5,
        regionId: 26955,
        rating: 4.06,
        ratingDescription: "",
        offerId: 15182
    }
];
