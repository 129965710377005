import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {getRequest} from "@pg-mono/request";
import {createRequestActionTypes, RequestState} from "@pg-mono/request-state";
import {apiV2Link, Scenario} from "@pg-mono/rp-api-routes";

import {IRPStore} from "../../app/rp_reducer";
import {IRPRequestMeta} from "../../app/rp_request_meta";
import {IPaginatedResponse} from "../../request/IResponse";

const FOOTER_ARTICLES_PREFIX = "footer_articles/fetch";
export const fetchTopArticlesTypes = createRequestActionTypes(FOOTER_ARTICLES_PREFIX);

export interface ITopArticleLink {
    id: number;
    anchor: string;
    article: {
        id: number;
        slug: string;
    };
}

interface ITopArticlesResponse extends IPaginatedResponse {
    results: ITopArticleLink[];
}

export const fetchTopArticles = (ctx: IFetchContext<IRPRequestMeta>) => async (dispatch: Dispatch, getState: () => IRPStore) => {
    const articlesState = getState().footer?.articles;
    if (articlesState.requestState === RequestState.Success) {
        return articlesState.data;
    }

    dispatch({type: fetchTopArticlesTypes.start});
    // const url = apiLink.article.top();
    const url = apiV2Link.article.top(Scenario.TOP_ARTICLE_LIST);

    return getRequest(ctx.meta, url)
        .then((articlesResponse: ITopArticlesResponse) => {
            const topArticles = articlesResponse.results || [];

            dispatch({type: fetchTopArticlesTypes.success, result: topArticles});
            return topArticles;
        })
        .catch(() => {
            dispatch({type: fetchTopArticlesTypes.reset});
        });
};
