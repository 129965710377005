import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {appendQueryString, catch400, getRequest} from "@pg-mono/request";
import {createRequestActionTypes, RequestActionTypes} from "@pg-mono/request-state";
import {apiV2ListLink, Scenario} from "@pg-mono/rp-api-routes";

import {IRPRequestMeta} from "../../../app/rp_request_meta";
import {detailOfferListConstraints} from "../../constants/detail_offer_list_constraints";
import {DEFAULT_DISTANCE_RELATED_OFFERS} from "../../list/constants/offer_list";
import {IOfferListResponse} from "../../list/types/IOfferListResponse";

const FETCH_OFFER_DETAIL_RELATED_OFFERS_PREFIX = "offer_detail/FETCH_OFFER_DETAIL_RELATED_OFFERS";
export const fetchOfferDetailRelatedOffersTypes = createRequestActionTypes(FETCH_OFFER_DETAIL_RELATED_OFFERS_PREFIX);

const FETCH_POST_APPLICATION_NEARBY_OFFERS_PREFIX = "application/FETCH_POST_APPLICATION_NEARBY_OFFERS";
export const fetchPostApplicationNearbyOffersTypes = createRequestActionTypes(FETCH_POST_APPLICATION_NEARBY_OFFERS_PREFIX);

const offerListApiLink = apiV2ListLink.offer.list(Scenario.OFFER_LIST);

export interface IFilteringOptions {
    type?: number;
    region?: number;
    rooms_0?: number;
    rooms_1?: number;
    area_0?: number;
    area_1?: number;
}

export const fetchOfferDetailOffersList = (actionType: RequestActionTypes) => (meta: {}, query: Record<string, unknown>) => async (dispatch: Dispatch) => {
    dispatch({type: actionType.start});

    const url = appendQueryString(offerListApiLink, {
        page: 1,
        page_size: 20,
        ...query
    });

    return getRequest(meta, url)
        .then(async (json: IOfferListResponse) => {
            // prepare result
            const result = {
                offers: json.results,
                count: json.results.length
            };
            dispatch({type: actionType.success, result});
            return json;
        })
        .catch(
            catch400((error) => {
                // NOTE: when we encounter some error we should skip rendering those related lists
                // one example is to fetch vendor-related offers to given offer/property (where vendor is disabled), then API responds 400 with `vendor` key error saying that it is not valid
                dispatch({type: actionType.error, error: error.appError});
            })
        );
};

// Related to limited view
export const fetchOfferDetailRelatedOffersListAtRoute = ({meta, prevResult}: IFetchContext<IRPRequestMeta>) =>
    fetchOfferDetailOffersList(fetchOfferDetailRelatedOffersTypes)(meta, {
        ...detailOfferListConstraints,
        page_size: 3,
        type: prevResult.offer.type,
        near_by_offer: prevResult.offer.id,
        exclude_offer: prevResult.offer.id,
        distance: DEFAULT_DISTANCE_RELATED_OFFERS
    });

export const fetchPostApplicationOffersNearbyList = (offerId: number | null) =>
    fetchOfferDetailOffersList(fetchPostApplicationNearbyOffersTypes)(
        {},
        {
            ...detailOfferListConstraints,
            ...(offerId != null
                ? {
                      near_by_offer: offerId,
                      exclude_offer: offerId
                  }
                : {}),
            page_size: 3,
            distance: 5
        }
    );
