import {combineReducers} from "redux";

import {reduceRequestState, reduceResponseField, RequestState} from "@pg-mono/request-state";

import {fetchHomepageOfferListTypes} from "../actions/fetch_homepage_offer_list";
import {IHomepageOfferListOffer} from "../interfaces/IHomepageOfferListOffer";

export interface IHomepageStore {
    count: number;
    recommendedOfferList: IHomepageOfferListOffer[];
    requestState: RequestState;
}

export const homepageReducer = combineReducers({
    recommendedOfferList: reduceResponseField<IHomepageOfferListOffer[]>(fetchHomepageOfferListTypes, "offerList", []),
    count: reduceResponseField<number>(fetchHomepageOfferListTypes, "count", null),
    requestState: reduceRequestState(fetchHomepageOfferListTypes)
});
