import {ReactNode, useEffect, useRef, useState} from "react";
import {ActionMeta, GroupBase} from "react-select";
import {css} from "@emotion/react";

import {Button} from "@pg-design/button";
import {calculateRemSize as crs, listUnStyled} from "@pg-design/helpers-css";
import {Modal} from "@pg-design/modal";
import {Radio} from "@pg-design/radio";
import {ISelectProps, Select} from "@pg-design/select";

interface IProps<Option, IsMulti extends boolean = false, Group extends GroupBase<Option> = GroupBase<Option>>
    extends Omit<ISelectProps<Option, IsMulti, Group>, "onChange" | "isMulti"> {
    radioName: string;
    options: IRwdSelectOption[];
    isMobile: boolean;
    initialValue?: IRwdSelectOption["value"];
    modalLabel?: ReactNode;
    onChange?: (newValue: IRwdSelectOption, actionMeta: ActionMeta<IRwdSelectOption>) => void;
}

interface IRwdSelectOption {
    value: string;
    label: ReactNode;
}

type IRwdSelectValue = IRwdSelectOption["value"] | undefined;

export function RwdSelect<Option, IsMulti extends boolean = false, Group extends GroupBase<Option> = GroupBase<Option>>(
    props: Omit<IProps<Option, IsMulti, Group>, "isMulti">
) {
    const {isMobile, modalLabel, radioName, initialValue, onChange, onMenuOpen, ...selectProps} = props;

    const [isModalOpened, setIsModalOpened] = useState(false);
    const [selectValue, setSelectValue] = useState<IRwdSelectValue>(initialValue);
    const [radioValue, setRadioValue] = useState<IRwdSelectValue>(initialValue);
    const prevSelectValueRef = useRef(initialValue);

    const selectedValue = props.options.find((option) => option.value === selectValue);

    useEffect(() => {
        if (selectedValue && onChange && prevSelectValueRef.current !== selectedValue.value) {
            const actionMeta: ActionMeta<IRwdSelectOption> = {action: "select-option", option: undefined};
            prevSelectValueRef.current = selectedValue.value;
            onChange(selectedValue, actionMeta);
        }
    }, [selectValue]);

    const confirmAndQuit = () => {
        setSelectValue(radioValue);
        setIsModalOpened(false);
    };

    const quitWithoutSelection = () => {
        setRadioValue(selectValue);
        setIsModalOpened(false);
    };

    return (
        <>
            <Select
                {...selectProps}
                value={[selectedValue]}
                onChange={(newValue) => {
                    const newOption = newValue as IRwdSelectOption;
                    setSelectValue(newOption.value);
                }}
                menuIsOpen={isMobile ? false : undefined}
                onMenuOpen={() => {
                    if (isMobile) {
                        setIsModalOpened(true);
                    }

                    if (!isMobile && onMenuOpen) {
                        onMenuOpen();
                    }
                }}
            />
            {isMobile && (
                <Modal
                    fullScreen
                    isOpen={isModalOpened}
                    onModalClose={() => {
                        quitWithoutSelection();
                    }}
                >
                    <Modal.Header variant="bar">{modalLabel ? modalLabel : " "}</Modal.Header>
                    <Modal.Content css={modalContent}>
                        <ul css={optionList}>
                            {selectProps.options.map((option) => (
                                <li key={`rsk_${option.value}`} css={optionListItem}>
                                    <Radio
                                        id={`rsid_${option.value}`}
                                        name={radioName}
                                        value={option.value}
                                        isActive={radioValue === option.value}
                                        labelContent={<>{option.label}</>}
                                        onChange={() => {
                                            setRadioValue(option.value);
                                        }}
                                    />
                                </li>
                            ))}
                        </ul>
                        <div css={buttonList}>
                            <Button
                                variant="filled_primary"
                                onClick={() => {
                                    confirmAndQuit();
                                }}
                            >
                                Zatwierdź
                            </Button>
                            <Button
                                variant="none_secondary"
                                onClick={() => {
                                    quitWithoutSelection();
                                }}
                            >
                                Anuluj
                            </Button>
                        </div>
                    </Modal.Content>
                </Modal>
            )}
        </>
    );
}

//  Styles
const modalContent = css`
    display: flex;
    flex-direction: column;
`;

const optionList = css`
    flex: 1;
    ${listUnStyled};
    display: flex;
    flex-direction: column;
    row-gap: ${crs(3)};
`;

const optionListItem = css`
    display: flex;
    align-items: center;
`;

const buttonList = css`
    display: flex;
    flex-direction: column;
    row-gap: ${crs(1)};
`;
