import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {appendQueryString, catch400, getRequest} from "@pg-mono/request";
import {createRequestActionTypes, RequestActionTypes} from "@pg-mono/request-state";
import {apiV2ListLink, Scenario} from "@pg-mono/rp-api-routes";

import {IRPStore} from "../../app/rp_reducer";
import {IRPRequestMeta} from "../../app/rp_request_meta";
import {Country} from "../../region/types/Country";
import {RegionType} from "../../region/types/RegionType";
import {IVendorRegion} from "./fetch_vendor_list_region";

const FETCH_NEARBY_REGIONS_VENDORS_PREFIX = "vendors/FETCH_NEARBY_REGIONS_VENDORS";
export const fetchNearbyRegionsVendorsTypes = createRequestActionTypes(FETCH_NEARBY_REGIONS_VENDORS_PREFIX);

export interface INearbyRegionVendors {
    id: number;
    name: string;
    slug: string;
    stats: {
        properties_all_count_for_sale: number;
        properties_flats_count_for_sale: number;
        properties_houses_count_for_sale: number;
        properties_commercials_count_for_sale: number;
        vendors_count_for_sale: number;
    };
}

interface INearbyRegionVendorsResponse {
    results: INearbyRegionVendors[];
}

const fetchNearbyRegionsVendors =
    (actionType: RequestActionTypes) => (ctx: IFetchContext<IRPRequestMeta>) => async (dispatch: Dispatch, getState: () => IRPStore) => {
        const regions = getState().vendor.list.region;

        dispatch({type: actionType.start});

        const nearbyRegionsListApiLink = apiV2ListLink.region.list(Scenario.REGION_LIST);

        const url = appendQueryString(nearbyRegionsListApiLink, {
            ...getNearbyRegionsVendorsFetchParams(regions),
            country: Country.POLAND
        });
        return getRequest(ctx.meta, url)
            .then((res: INearbyRegionVendorsResponse) => {
                dispatch({type: actionType.success, result: res});
                return res;
            })
            .catch(
                catch400((error) => {
                    dispatch({type: actionType.error, error: error.appError});
                })
            );
    };

const getNearbyRegionsVendorsFetchParams = (regions?: IVendorRegion[] | null) => {
    if (regions && regions.length > 0) {
        return {nearby_regions: regions[0].id};
    }

    return {type: RegionType.VOIVODESHIP};
};

export const fetchNearbyRegionsVendorsAtRoute = (ctx: IFetchContext<IRPRequestMeta>) => {
    return fetchNearbyRegionsVendors(fetchNearbyRegionsVendorsTypes)(ctx);
};
