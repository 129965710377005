import React, {CSSProperties, ForwardedRef, forwardRef, ImgHTMLAttributes, useEffect, useState} from "react";
import {css, SerializedStyles} from "@emotion/react";
import styled from "@emotion/styled";

import {Placeholder} from "./Placeholder";

type AspectRatios = `${number}:${number}`;

interface IWithDefinedWidthAndHeight {
    width: string;
    height: string;

    ratio?: {
        md?: AspectRatios;
        xs: AspectRatios;
    };
}

interface IWithDefinedRatio {
    width: string | undefined;
    height: string | undefined;

    ratio: {
        md?: AspectRatios;
        xs: AspectRatios;
    };
}

export interface IImageProps {
    src?: string;
    src2x?: string;
    alt: string;
    loading?: "lazy";
    className?: string;
    imageClassName?: string;
    imageCss?: SerializedStyles | (SerializedStyles | undefined)[];
    imageStyle?: CSSProperties;
    onClick?: React.MouseEventHandler;
    crossOrigin?: ImgHTMLAttributes<HTMLImageElement>["crossOrigin"];
    onError?: () => void;
    fetchPriority?: "high" | "low" | "auto";
}

export const Image = forwardRef((props: IImageProps & (IWithDefinedRatio | IWithDefinedWidthAndHeight), ref: ForwardedRef<HTMLImageElement>) => {
    const {src, src2x, alt, ratio, loading, width, height, className, imageClassName, imageCss, fetchPriority, imageStyle} = props;
    const [hasError, setHasError] = useState(false);
    const srcSet = src2x ? `${src}, ${src2x} 2x` : undefined;

    useEffect(() => {
        setHasError(false);
    }, [src]);

    const onError = () => {
        setHasError(true);
        props.onError?.();
    };

    return (
        <ImageHolder className={className} ratio={ratio} width={width} height={height} hasError={hasError} onClick={props.onClick}>
            {hasError || !src ? (
                <Placeholder />
            ) : (
                <img
                    ref={ref}
                    src={src}
                    srcSet={srcSet}
                    style={imageStyle}
                    alt={alt}
                    onError={onError}
                    loading={loading}
                    width={width}
                    height={height}
                    className={imageClassName}
                    css={[imageCss, imageBase(ratio)]}
                    fetchpriority={fetchPriority}
                />
            )}
        </ImageHolder>
    );
});

const ImageHolder = styled.div<{
    ratio?: {xs: AspectRatios; md?: AspectRatios};
    height?: string;
    width?: string;
    hasError?: boolean;
}>`
    position: relative;
    line-height: 0;
    display: inline-block;
    overflow: hidden; // For cases when we want to style border radius while using aspect ratio

    // When no ratio is provided height and width needs to be provided in case we use fallback
    ${(props) =>
        props.hasError &&
        !props.ratio &&
        css`
            height: ${props.height};
            width: ${props.width};
            max-width: 100%;
        `}

    // Calculate and use Aspect Ratio Boxes
    ${(props) =>
        props.ratio &&
        css`
            display: block;
            &:before {
                content: "";
                display: block;

                ${props.ratio && getAspectRatioPadding(...aspectRatioToNumber(props.ratio.xs))};

                @media screen and (min-width: ${props.theme.breakpoints.xs}) {
                    ${props.ratio && props.ratio.md && getAspectRatioPadding(...aspectRatioToNumber(props.ratio.md))}
                }
            }
        `}
`;

const imageBase = (ratio?: {md?: AspectRatios; xs: AspectRatios}) => css`
    ${ratio &&
    css`
        position: absolute;
        left: 0;
        top: 0;
        display: block;
        max-width: 100%;
        height: auto;
    `}
`;

const getAspectRatioPadding = (width: number, height: number) => css`
    padding-top: ${(height / width) * 100 + "%"};
`;

const aspectRatioToNumber = (ratio: AspectRatios) => {
    const [width, height] = ratio.split(":").map((item) => parseInt(item));
    return [width, height] as const;
};
