import {combineReducers} from "redux";

import {reduceFormValues} from "@pg-mono/form";
import {reduceRequestState, reduceResponse, reduceResponseField, RequestState} from "@pg-mono/request-state";

import {fromJsonValues} from "../../form/translate/from_json_values";
import {fetchAllListsType, SearchTab} from "../actions/fetch_search_all_action";
import {fetchOfferListTypes, IOfferQueryOffers} from "../actions/fetch_search_offers_action";
import {AutocompletePrediction, fetchPlaceListTypes} from "../actions/fetch_search_places_action";
import {fetchRegionListTypes, IRegionListRegion} from "../actions/fetch_search_regions_action";
import {fetchVendorListTypes, IVendorListVendor} from "../actions/fetch_search_vendors_action";
import {searchFormTypes} from "../actions/search_form_actions";
import {IMultiRegionListStore} from "../actions/set_multi_region_list_state";
import {ITravelTimeStore} from "../actions/set_travel_time";
import {searchFormFields} from "../constants/search_form_constants";
import {activeDropdownItemReducer, IActiveDropdownItemStore} from "./active_dropdown_item_reducer";
import {currentTabReducer} from "./current_tab_reducer";
import {multiRegionListReducer} from "./multi_region_list_reducer";
import {travelTimeReducer} from "./travel_time_reducer";

export interface ISearchStore {
    multiRegionList: IMultiRegionListStore;
    formValues: Record<keyof typeof searchFormFields, any>; // eslint-disable-line @typescript-eslint/no-explicit-any
    currentTab: SearchTab;
    activeDropdownItem: IActiveDropdownItemStore;
    // query
    fetchAllRequest: RequestState;
    // query region
    regions: IRegionListRegion[];
    fetchRegionsRequest: RequestState;
    // query places
    places: AutocompletePrediction[];
    fetchPlacesRequest: RequestState;
    // query offer
    offers: IOfferQueryOffers[];
    fetchOffersRequest: RequestState;
    // query vendors
    vendors: IVendorListVendor[];
    fetchVendorsRequest: RequestState;
    travelTime: ITravelTimeStore;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const reducer: Record<keyof ISearchStore, any> = {
    activeDropdownItem: activeDropdownItemReducer,
    multiRegionList: multiRegionListReducer,
    formValues: reduceFormValues(searchFormTypes, fromJsonValues(searchFormFields, {})),
    currentTab: currentTabReducer,
    // query
    fetchAllRequest: reduceRequestState(fetchAllListsType),
    // query region
    regions: reduceResponse<IRegionListRegion[]>(fetchRegionListTypes, []),
    fetchRegionsRequest: reduceRequestState(fetchRegionListTypes),
    // query places
    places: reduceResponseField<AutocompletePrediction[]>(fetchPlaceListTypes, "places", []),
    fetchPlacesRequest: reduceRequestState(fetchPlaceListTypes),
    // query offer
    offers: reduceResponse<IOfferQueryOffers[]>(fetchOfferListTypes, []),
    fetchOffersRequest: reduceRequestState(fetchOfferListTypes),
    // query vendors
    vendors: reduceResponseField(fetchVendorListTypes, "vendors", []),
    fetchVendorsRequest: reduceRequestState(fetchVendorListTypes),
    travelTime: travelTimeReducer
};

export const searchReducer = combineReducers(reducer);
