export enum PropertyFlatType {
    STANDARD = 1,
    PENTHOUSE = 2,
    APARTMENT = 3
}

export function getPropertyFlatType(propertyFlatType: PropertyFlatType) {
    switch (propertyFlatType) {
        case PropertyFlatType.STANDARD:
            return "Standard";
        case PropertyFlatType.PENTHOUSE:
            return "Penthouse";
        case PropertyFlatType.APARTMENT:
            return "Apartament";
        default:
            throw new Error("Unknown property flat type");
    }
}
