import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {catch400, catch404, getRequest} from "@pg-mono/request";
import {createRequestActionTypes} from "@pg-mono/request-state";
import {apiLink} from "@pg-mono/rp-api-routes";

import {IRPRequestMeta} from "../../app/rp_request_meta";
import {redirectOrEnable404ResponseState} from "../../errors/actions/page_404_actions";

const UNSUBSCRIBE_USER = "unsubscribe_user";
export const unsubscribeUserTypes = createRequestActionTypes(UNSUBSCRIBE_USER);

export const fetchUserUnsubscriptionDataAtRoute = (ctx: IFetchContext<IRPRequestMeta>) => async (dispatch: Dispatch) => {
    const {emailHash, verificationHash} = ctx.match.params;

    const unsubscribeApiLink = apiLink.newsletter.unsubscribe.mailing({})({emailHash, verificationHash});

    return getRequest(ctx.meta, unsubscribeApiLink)
        .then((response: {email: string}) => {
            dispatch({type: unsubscribeUserTypes.success, result: response});
        })
        .catch(
            catch400(async (error) => {
                dispatch({type: unsubscribeUserTypes.error, error: error.appError});
            })
        )
        .catch(
            catch404(async () => {
                await dispatch(redirectOrEnable404ResponseState(ctx.route.pathname, ctx.meta));
            })
        );
};
