import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {appendQueryString, getRequest, IQueryValue} from "@pg-mono/request";
import {createRequestActionTypes} from "@pg-mono/request-state";
import {apiV2ListLink, Scenario} from "@pg-mono/rp-api-routes";

import {IRPStore} from "../../app/rp_reducer";
import {IRPRequestMeta} from "../../app/rp_request_meta";
import {IPaginatedResponse} from "../../request/IResponse";

const AUTHORS_LIST_PREFIX = "authors_list/fetch";
export const fetchAuthorsListTypes = createRequestActionTypes(AUTHORS_LIST_PREFIX);
export const AUTHORS_LIST_PAGE_SIZE = 15;

export interface IAuthorListAuthor {
    id: number;
    name: string;
    description: string;
    image: {
        a_img_40x40: string;
        a_img_130x130: string;
        a_img_360x360: string;
    };
    slug: string;
    stats: {
        articles: number;
        categories: string[];
    };
    facebook: null | string;
    twitter: null | string;
    email: null | string;
}

interface IOfferListResponse extends IPaginatedResponse {
    results: IAuthorListAuthor;
}

const articleListApiLink = apiV2ListLink.article.authors.list;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const fetchAuthorsListAtRoute = (ctx: IFetchContext<IRPRequestMeta>) => (dispatch: Dispatch, getState: () => IRPStore) => {
    const page = parseInt(ctx.route.query.page as string, 10) || 1;

    const query: Record<string, IQueryValue> = {
        ...ctx.route.query
    };

    dispatch({type: fetchAuthorsListTypes.start});
    const url = appendQueryString(articleListApiLink(Scenario.AUTHOR_LIST), query);

    return getRequest(ctx.meta, url).then(async (json: IOfferListResponse) => {
        const result = {
            authors: json.results,
            page: page,
            count: json.count,
            page_size: json.page_size || AUTHORS_LIST_PAGE_SIZE
        };
        dispatch({type: fetchAuthorsListTypes.success, result});
        return json;
    });
};
