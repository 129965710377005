import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {enable301ResponseState} from "@pg-mono/response-state";
import {rpAppLink} from "@pg-mono/rp-routes";

import {IRPRequestMeta} from "../../app/rp_request_meta";

export const redirectToVendorDetail = (ctx: IFetchContext<IRPRequestMeta>) => async (dispatch: Dispatch) => {
    dispatch(enable301ResponseState(rpAppLink.vendor.detail.base(ctx.match.params)));
    return false;
};
