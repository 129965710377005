import React from "react";

import {SvgIcon} from "../../components/SvgIcon";
import {IIcon} from "../../interfaces/IIcon";

export const LinkedinIcon = (props: IIcon) => {
    return (
        <SvgIcon {...props}>
            <path d="M5 .91a4.344 4.344 0 1 0 0 8.686A4.344 4.344 0 0 0 5 .91Zm2.419 6.453c0 .193-.16.35-.358.35H2.93a.354.354 0 0 1-.36-.35V3.184c0-.193.161-.35.36-.35h4.13c.197 0 .358.157.358.35v4.179Z" />
            <path d="M5.838 4.664a.727.727 0 0 0-.66.363v.007h-.004a.04.04 0 0 0 .004-.007v-.312h-.732c.01.207 0 2.202 0 2.202h.732v-1.23c0-.066.005-.13.024-.178.054-.131.174-.268.376-.268.265 0 .371.202.371.498v1.178h.732V5.655c0-.677-.361-.991-.843-.991ZM3.68 3.653c-.25 0-.414.165-.414.381 0 .211.158.38.404.38h.005c.255 0 .414-.169.414-.38-.005-.216-.159-.38-.409-.38ZM4.041 4.715H3.31v2.202h.732V4.715Z" />
        </SvgIcon>
    );
};
