import {pluralize, removeRedundantWhiteSpaces} from "@pg-mono/string-utils";

import {BRAND_NAME} from "../../app/constants/company";

export interface IDescriptionPromotionList {
    count: number;
    region?: string;
    type?: string;
    page?: number;
}

const promotionPlural = pluralize(["ofertę specjalną", "oferty specjalne", "ofert specjalnych"]);

export const getBasePromotionListDescription = (count = 0): string => {
    return `Mamy dla Ciebie ${count} ${promotionPlural(count)} od deweloperów!`;
};

export const descriptionPromotionList = (props: IDescriptionPromotionList): string => {
    const base = getBasePromotionListDescription(props.count);

    const page = typeof props.page === "number" && props.page > 1 ? ` - Strona ${props.page}` : "";

    if (props.region || props.type) {
        return removeRedundantWhiteSpaces(
            `${base} Sprawdź 🔎 najnowsze ${props.type || "promocje, przeceny, dni otwarte"} ${props.region || ""} na ${BRAND_NAME}${page}!`
        );
    }

    return removeRedundantWhiteSpaces(`${base} Sprawdź 🔎 najnowsze promocje, przeceny, dni otwarte na ${BRAND_NAME}${page}!`);
};
