import {Dispatch} from "redux";

import {appendQueryString, parseSearch} from "@pg-mono/request";

import {IRPStore} from "../../app/rp_reducer";
import {addNotification} from "../../notifications/state/notifications_slice";
import {userSignInHit} from "../../tracking/algolytics_hits/user_sign_in_hit";
import {UserSource} from "../../tracking/tracking_utils/user_source";

export const afterRedirectOauthLogin = (isAuthenticated: boolean) => async (dispatch: Dispatch, getState: () => IRPStore) => {
    if (window) {
        const query = parseSearch(location.search);

        if (query.oauth_login) {
            const notification = {
                type: isAuthenticated ? "success" : "failure",
                content: isAuthenticated ? "Zalogowano do konta" : "Wystąpił błąd podczas logowania do konta. Spróbuj ponownie później."
            } as const;

            dispatch(addNotification(notification));

            if (isAuthenticated) {
                const userProfileData = getState().user.profile.data;
                const currentViewType = getState().viewType.current;

                if (userProfileData) {
                    userSignInHit(UserSource.NAVIGATION, userProfileData, currentViewType);
                }
            }

            // Remove `oauth_login` - we don't need it anymore
            delete query.oauth_login;

            window.history.replaceState({}, document.title, appendQueryString(location.pathname, query));
        }
    }
};
