import {ADD_AB_TEST_DATA, IAddTestAction, ISetABTestingInitializedAction, SET_AB_TESTING_INITIALIZED} from "../actions/ab_testing_action";

export type IABTestingStore = {
    tests: Record<string, number>;
    isInitialized: boolean;
};

type ActionType = IAddTestAction | ISetABTestingInitializedAction;

export const abTestingReducer = (state: IABTestingStore = {tests: {}, isInitialized: false}, action: ActionType): IABTestingStore => {
    switch (action.type) {
        case ADD_AB_TEST_DATA:
            const {id, variant} = action.abTest;

            return {...state, tests: {...state.tests, [id]: variant}};
        case SET_AB_TESTING_INITIALIZED: {
            return {...state, isInitialized: true};
        }
        default:
            return state;
    }
};
