import {IFetchContext} from "@pg-mono/data-fetcher";
import {createRequestActionTypes} from "@pg-mono/request-state";

import {IRPRequestMeta} from "../../app/rp_request_meta";
import {fetchOfferDetailOffersList} from "../../offer/detail/actions/fetch_offer_detail_offers_list";
import {getDetailPageRelatedOffersPayload} from "../../offer/utils/get_detail_page_related_offers_payload";

export const FETCH_PROPERTY_DETAIL_RELATED_OFFERS_PREFIX = "property_detail/FETCH_PROPERTY_DETAIL_RELATED_OFFERS";
export const fetchPropertyDetailRelatedOffersTypes = createRequestActionTypes(FETCH_PROPERTY_DETAIL_RELATED_OFFERS_PREFIX);

export const fetchPropertyDetailRelatedOffersListAtRoute = (ctx: IFetchContext<IRPRequestMeta>) =>
    fetchOfferDetailOffersList(fetchPropertyDetailRelatedOffersTypes)(
        ctx.meta,
        getDetailPageRelatedOffersPayload({
            page: 1,
            offer: {
                type: ctx.prevResult.offer.type,
                id: ctx.prevResult.offer.id
            }
        }) as unknown as Record<string, unknown>
    );
