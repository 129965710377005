export type IOSMGeoAssetsPoiType =
    | "childcare"
    | "kindergarten"
    | "school"
    | "college"
    | "university"
    | "library"
    | "atm"
    | "bank"
    | "parcel_locker"
    | "playground"
    | "ars_centre"
    | "cinema"
    | "theatre"
    | "museum"
    | "all_sports"
    | "fitness_centre"
    | "fitness_station"
    | "ice_rink"
    | "pitch"
    | "sports_centre"
    | "stadium"
    | "swimming_pool"
    | "track"
    | "woods"
    | "park"
    | "water"
    | "grass"
    | "all_food"
    | "pubs"
    | "clubs"
    | "amusement"
    | "parking"
    | "fuel_carwash"
    | "roads"
    | "bus_stop"
    | "tram_stop"
    | "subway_stop"
    | "train_stop"
    | "all_shops"
    | "local_shops"
    | "food_shops"
    | "city"
    | "post"
    | "all_doctor"
    | "doctor"
    | "pharmacy"
    | "vet";

export interface IOSMGeoAssetsPoiOriginalPoint {
    original_point: [number, number];
    radius: number;
}

export interface IOSMGeoAssetsPoiGeneralPoint {
    id: `${number}_N` | `${number}_R` | `${number}_W`;
    type: IOSMGeoAssetsPoiType;
    center: [number, number];
    name: string | null;
    nearest_point: [number, number];
    dist: number;
}

export const isOSMGeoAssetsPoiGeneralPoint = (value: {}): value is IOSMGeoAssetsPoiGeneralPoint => {
    return typeof value === "object" && Object.prototype.hasOwnProperty.call(value, "type");
};

export type IOSMGeoAssetsPoi = IOSMGeoAssetsPoiOriginalPoint | IOSMGeoAssetsPoiGeneralPoint;
