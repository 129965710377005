import {GoogleMapsApiConfig} from "@pg-mono/google-api";

import {disableSessionSync} from "../common/app/read_rp_environment_variables";
import {IVisualRoute} from "../common/app/types/IVisualRoute";
import {notifyBugsnag} from "../common/errors/bugsnag/notify_bugsnag";
import {NotFoundView} from "../common/errors/views/NotFoundView";
import {googleMapApiKey} from "../common/maps/constants/google_map_api_key";
import {getSyncUserSessionCookieOnClient} from "../common/user/api/sync_user_session_on_client";
import {getClientStore} from "./utils/get_client_store";
import {hydrateApp} from "./utils/hydrate_app";
import {initClientApp} from "./utils/init_client_app";

export async function rpClient(routes: IVisualRoute[]) {
    const store = getClientStore();

    if (!disableSessionSync) {
        getSyncUserSessionCookieOnClient();
    }

    if (!store) {
        notifyBugsnag(new Error("rpClient: store is not provided"), "rpClient: store is not provided");
        console.warn("rpClient: store is not provided");
        return;
    }

    await initClientApp(store);

    GoogleMapsApiConfig.setApiKey(googleMapApiKey);

    const routesToHydrate = [...routes, {component: NotFoundView}];

    const rootElement = document.getElementById("root") as HTMLDivElement;

    await hydrateApp(store, rootElement, routesToHydrate);
}
