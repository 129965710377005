import {useDispatch} from "react-redux";
import type {Dispatch} from "redux";

import {appendQueryString, IQuery, parseSearch} from "@pg-mono/request";
import {apiUserLink} from "@pg-mono/rp-api-routes";
import {rpAppPath} from "@pg-mono/rp-routes";

import {emptyLeadStorageData} from "../../lead/constants/empty_lead_storage_data";
import {ISentLeadActions, setSentLeadValuesAction} from "../../lead/reducers/sent_lead_reducer";

interface Tracker {}

declare global {
    interface Window {
        ga?: {getAll?: () => Tracker[]};
        gaplugins: {
            Linker: (tracker: Tracker) => {
                decorate: (url: string) => string;
            };
        };
    }
}

export const createLinkWithCrossSiteTracking = (link: string, currentQuery: IQuery = {}) => {
    if (typeof window === "undefined") {
        return link;
    }

    let newQuery = {...currentQuery};
    const tracker = (window.ga?.getAll?.() || [])[0];

    if (tracker && window.gaplugins) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const linker = new (window.gaplugins.Linker as any)(tracker);

        newQuery = {...parseSearch(linker.decorate("/")), ...newQuery};
    }

    return appendQueryString(link, newQuery);
};

export const useRedirectedAuth = () => {
    const dispatch: Dispatch<ISentLeadActions> = useDispatch();

    const onRedirectedLogin = (redirectUriQuery: IQuery = {}) => {
        const query: IQuery = {
            next: location.pathname,
            redirect_uri: appendQueryString(`${location.origin}${rpAppPath.oauth.redirect}`, redirectUriQuery)
        };

        window.location.href = createLinkWithCrossSiteTracking(apiUserLink.oauth.authorize, query);
    };

    const onRedirectedLogout = () => {
        dispatch<ISentLeadActions>(setSentLeadValuesAction(emptyLeadStorageData));
        window.location.href = appendQueryString(apiUserLink.users.logout, {
            next: `${location.origin}${location.pathname}`
        });
    };

    return {
        onRedirectedLogin,
        onRedirectedLogout
    };
};
