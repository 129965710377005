import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {consoleError} from "@pg-mono/logger";
import {appendQueryString, catch400, catch404, getRequest, IQueryValue} from "@pg-mono/request";
import {createRequestActionTypes} from "@pg-mono/request-state";
import {apiV2ListLink, Scenario} from "@pg-mono/rp-api-routes";

import {IRPRequestMeta} from "../../app/rp_request_meta";
import {IArticleListResponse} from "../../article/actions/fetch_article_list";
import {notifyBugsnag} from "../../errors/bugsnag/notify_bugsnag";

const INVESTMENT_OFFER_ARTICLE_LIST_PREFIX = "investment_offer/ARTICLE_LIST";
export const fetchInvestmentOffersArticleListTypes = createRequestActionTypes(INVESTMENT_OFFER_ARTICLE_LIST_PREFIX);

const INVESTMENTS_ARTICLE_PAGE_SIZE = 6;
const INVESTMENTS_CATEGORY = 41;

export const fetchInvestmentOfferLPArticleList = (ctx: IFetchContext<IRPRequestMeta>) => (dispatch: Dispatch) => {
    const query: Record<string, IQueryValue> = {
        category: INVESTMENTS_CATEGORY,
        page: 1,
        page_size: INVESTMENTS_ARTICLE_PAGE_SIZE
    };

    const url = appendQueryString(apiV2ListLink.article.list(Scenario.ARTICLE_LIST), query);

    return getRequest(ctx.meta, url)
        .then((json: IArticleListResponse) => {
            const articles = json.results.map((article) => {
                return {
                    ...article,
                    lead: article.lead && article.lead.replace(/\r\n/g, "\n")
                };
            });

            dispatch({type: fetchInvestmentOffersArticleListTypes.success, result: articles});
            return json;
        })
        .catch(
            catch404(async () => {
                // we should not really expect a 404 here
                dispatch({type: fetchInvestmentOffersArticleListTypes.success, result: []});
                return [];
            })
        )
        .catch(
            catch400(async (err) => {
                // in case someone removes `Investments` category
                const errorMsg = `Investment Offers LP, article category is missing: 'inwestowanie-w-nieruchomosci', id: ${INVESTMENTS_CATEGORY}`;
                notifyBugsnag({message: errorMsg}, err.message);
                consoleError(errorMsg, "; route: ", ctx.route, "; API error.message: ", err.message);

                dispatch({type: fetchInvestmentOffersArticleListTypes.success, result: []});
                return [];
            })
        );
};
