import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {isEqual, isPlainObject} from "@pg-mono/nodash";
import {appendQueryString, catch400, catch404, getRequest} from "@pg-mono/request";
import {createRequestActionTypes} from "@pg-mono/request-state";
import {enable301ResponseState} from "@pg-mono/response-state";
import {apiV2ListLink, Scenario} from "@pg-mono/rp-api-routes";
import {rpAppLink} from "@pg-mono/rp-routes";

import {IRPStore} from "../../../app/rp_reducer";
import {IRPRequestMeta} from "../../../app/rp_request_meta";
import {redirectOrEnable404ResponseState} from "../../../errors/actions/page_404_actions";
import {IRegionListMap} from "../../../region/types/IRegionListMap";

const FETCH_MAP_REGION = "map/FETCH_MAP_REGION";
export const fetchMapRegionTypes = createRequestActionTypes(FETCH_MAP_REGION);

const multiRegionListLink = apiV2ListLink.region.list(Scenario.REGION_LIST_MAP);

export const fetchMapRegionAtRoute = (ctx: IFetchContext<IRPRequestMeta>) => async (dispatch: Dispatch, getState: () => IRPStore) => {
    const isMultiRegion = Array.isArray(ctx.route.query.region);
    const offerListQuery = isPlainObject(ctx.prevResult) ? ctx.prevResult : ctx.route.query;
    const hasRegion = isMultiRegion || isFinite(parseInt(offerListQuery.region, 10));

    if (!hasRegion) {
        dispatch({type: fetchMapRegionTypes.success, result: []});
        return true;
    }
    const query = {
        region: isMultiRegion ? offerListQuery.region?.map((r: string) => parseInt(r, 10)) : offerListQuery.region
    };
    const latestQuery = getState().offerListMap.region.latestQuery;
    if (isEqual(query, latestQuery)) {
        // do not refetch
        return getState().offerListMap.region.data;
    }

    dispatch({type: fetchMapRegionTypes.start, latestQuery: query});
    const url = appendQueryString(multiRegionListLink, query);
    return getRequest(ctx.meta, url)
        .then((json: {results: IRegionListMap[]}) => {
            dispatch({type: fetchMapRegionTypes.success, result: json.results});
            return json;
        })
        .catch(
            catch400(async () => {
                dispatch(enable301ResponseState(rpAppLink.offer.map()));
            })
        )
        .catch(
            catch404(async () => {
                await dispatch(redirectOrEnable404ResponseState(ctx.route.pathname, ctx.meta));
            })
        );
};
