import {BrowserRouter} from "react-router-dom";
import createCache from "@emotion/cache";
import {CacheProvider} from "@emotion/react";
import * as yup from "yup";

import {yupLocales} from "@pg-design/react-hook-forms-utils";

import {EMOTION_CACHE_KEY} from "../common/app/constants/emotion_cache_key";
import {Layout} from "../common/app/Layout";
import {RpCommonProviders} from "../common/app/RpCommonProviders";
import {IVisualRoute} from "../common/app/types/IVisualRoute";
import {getBugsnagErrorBoundary, initBrowserBugsnag} from "../common/errors/bugsnag/init_bugsnag_client";
import {RpStore} from "../common/store/store";

initBrowserBugsnag();

const emotionCache = createCache({key: EMOTION_CACHE_KEY});

yup.setLocale(yupLocales);

interface IProps {
    initialStore: RpStore;
    routes: IVisualRoute[];
}

export function RpClientApp(props: IProps) {
    const {initialStore} = props;

    const BugsnagErrorBoundary = getBugsnagErrorBoundary();

    return (
        <CacheProvider value={emotionCache}>
            <BugsnagErrorBoundary>
                <BrowserRouter>
                    <RpCommonProviders store={initialStore} userAgent={navigator.userAgent}>
                        <Layout routes={props.routes} />
                    </RpCommonProviders>
                </BrowserRouter>
            </BugsnagErrorBoundary>
        </CacheProvider>
    );
}
