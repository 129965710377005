import {hitGoogleTagManager} from "./init_google_tag_manager";

interface GAEvent {
    event: "GAEvent";
    GAEventCategory: "nawigacja";
    GAEventAction: INavLevel;
    GAEventLabel: string;
    GAEventNoInteraction: true;
}
type INavLevel = "1" | "2" | "3" | "4";

export const gtmEventNavigationClick = (navLevel: INavLevel, text: string) => {
    const gtmData: GAEvent = {
        event: "GAEvent",
        GAEventCategory: "nawigacja",
        GAEventAction: navLevel,
        GAEventLabel: text,
        GAEventNoInteraction: true
    };
    hitGoogleTagManager(gtmData);
};
