import {createContext, ReactNode, useEffect, useState} from "react";

import {IInitilizedFn, IWeglot} from "../types/weglot";

//  Context
export interface IWeglotContext {
    weglot: IWeglot | null;
    translatePhrase: (phrase: string) => Promise<string>;
}

export const WeglotContext = createContext<IWeglotContext | null>(null);

//  Provider
export interface IWeglotContextProviderProps {
    children: ReactNode;
    apiKey: string | null;
    excludedTranslationBlocks?: {value: string}[];
    isDevelopment?: boolean;
}

const weglotSrc = "https://cdn.weglot.com/weglot.min.js";

declare const Weglot: IWeglot;

export function WeglotContextProvider(props: IWeglotContextProviderProps) {
    const {children, apiKey, excludedTranslationBlocks} = props;

    const [scriptLoaded, setScriptLoaded] = useState(false);

    useEffect(() => {
        // avoid reinitialization with hot module reload in dev
        const isWeglotInitialized = props.isDevelopment && checkIsWeglotInitialized();

        if (apiKey && !isWeglotInitialized) {
            const weglotScript = document.createElement("script");
            weglotScript.src = weglotSrc;
            document.body.appendChild(weglotScript);

            weglotScript.onload = () => {
                if (Weglot) {
                    Weglot.initialize({
                        api_key: apiKey,
                        hide_switcher: true,
                        excluded_blocks: [
                            {
                                value: ".lang-switcher-wrap"
                            },
                            ...(excludedTranslationBlocks ? excludedTranslationBlocks : [])
                        ]
                    });

                    const onWeglotInitilized: IInitilizedFn = () => {
                        setScriptLoaded(true);
                    };

                    Weglot.on("initialized", onWeglotInitilized);
                }
            };
        } else {
            console.warn("env: Weglot apiKey is not provided");
        }
    }, []);

    return <WeglotContext.Provider value={{weglot: scriptLoaded ? Weglot : null, translatePhrase: translatePhrase}}>{children}</WeglotContext.Provider>;
}

async function translatePhrase(phrase: string) {
    if (!Weglot) {
        throw new Error("Weglot is not initialized");
    }

    const translations = await Weglot.translate({
        words: [
            {
                t: 1,
                w: phrase
            }
        ]
    });

    return translations[0];
}

function checkIsWeglotInitialized() {
    return Boolean(document.querySelector("script[src='" + weglotSrc + "']"));
}
