// Replacement for https://lodash.com/docs/4.17.15#pickBy
export const pickBy = (object: Record<string, unknown>, predicate = (v: unknown) => v) => {
    const obj: Record<string, unknown> = {};

    for (const [key, value] of Object.entries(object)) {
        if (predicate(value)) {
            obj[key] = value;
        }
    }

    return obj;
};
