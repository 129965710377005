import {rpAppLink} from "@pg-mono/rp-routes";

import {OfferMetaRobots} from "../../constants/offer_meta_robots";
import {priceToForIndexation, regionIdsForPriceIndexation} from "../../constants/price_indexation";
import {OfferType} from "../../types/OfferType";
import {offerUrlBuilder} from "../friendly_offer_list/offer_url_builder";
import {IOfferListingSeoRuleParams} from "./constants";
import {doesExistAnyFilterBesidesAllowed, shouldHaveActiveFilters, shouldHaveActiveSomeFilters} from "./offer_listing_canonical_and_robots_helpers";

export const createRobotsPriceToFilterRule = (params: IOfferListingSeoRuleParams) => ({
    condition: () => {
        const isRegionAllowed = params.regions?.length === 1 && regionIdsForPriceIndexation.includes(params.regions[0].id);
        const isPriceToAllowed = priceToForIndexation.includes(Number(params.filters.price_1));

        return (
            shouldHaveActiveFilters(params.filters, ["price_1"]) &&
            !doesExistAnyFilterBesidesAllowed(params.filters, ["price_1"]) &&
            isRegionAllowed &&
            isPriceToAllowed
        );
    },
    robots: OfferMetaRobots.INDEX_FOLLOW,
    canonical: () =>
        rpAppLink.offer.listFriendly({
            friendlySlug: offerUrlBuilder({
                region: params.regions?.[0].slug,
                price_1: Number(params.filters.price_1),
                type: params.offerType
            }).friendlySlug
        })
});

export const createRobotsPriceCommercialRule = (params: IOfferListingSeoRuleParams) => ({
    condition: () => {
        return params.offerType === OfferType.COMMERCIAL && shouldHaveActiveSomeFilters(params.filters, ["price_0", "price_1"]);
    },
    robots: OfferMetaRobots.NOINDEX,
    canonical: () =>
        rpAppLink.offer.listFriendly({
            friendlySlug: offerUrlBuilder({
                region: params.regions?.[0].slug
            }).friendlySlug
        })
});

export const createRobotsPriceFurtherPagesRule = (params: IOfferListingSeoRuleParams) => ({
    condition: () => {
        return !!params.page && params.page > 1 && shouldHaveActiveSomeFilters(params.filters, ["price_0", "price_1"]);
    },
    robots: OfferMetaRobots.NOINDEX,
    canonical: () =>
        rpAppLink.offer.listFriendly({
            friendlySlug: offerUrlBuilder({
                region: params.regions?.[0].slug
            }).friendlySlug
        })
});

export const createRobotsPriceGeneralRule = (params: IOfferListingSeoRuleParams) => ({
    condition: () => {
        return shouldHaveActiveSomeFilters(params.filters, ["price_0", "price_1"]);
    },
    robots: OfferMetaRobots.NOINDEX_FOLLOW,
    canonical: () =>
        rpAppLink.offer.listFriendly({
            friendlySlug: offerUrlBuilder({
                region: params.regions?.[0].slug
            }).friendlySlug
        })
});
