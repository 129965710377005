import {combineReducers} from "redux";

import {reduceLatestQuery, reduceRequestState, reduceResponse, RequestState} from "@pg-mono/request-state";

import {IRegionPathRegion} from "../../../region/fetch_region_path";
import {fetchOfferListSearchActionTypes, ISearch} from "../../helpers/searches/fetch_search";
import {fetchOfferListArticlesTypes, IOfferArticle} from "../actions/fetch_offer_list_articles";
import {fetchOfferListChildrenRegionsTypes, IOfferListChildrenRegion} from "../actions/fetch_offer_list_children_regions";
import {fetchOfferListNearbyRegionsTypes, INearbyRegion} from "../actions/fetch_offer_list_nearby_regions";
import {fetchOfferListRegionPathTypes} from "../actions/fetch_offer_list_parent_regions";
import {fetchSelectedRegionEstimatedPriceTypes, ISelectedRegionEstimatedPrice} from "../actions/fetch_selected_region_estimated_price";
import {fetchSelectedRegionDataTypes, ISelectedRegion} from "../actions/offer_list_selected_data_actions";
import {IOfferListListStore, offerListListReducer} from "./desktop_offer_list_list_reducer";
import {offerListSelectedRegionsReducer} from "./offer_list_selected_regions_reducer";

export interface IOfferListStore {
    list: IOfferListListStore;
    selectedRegions: ISelectedRegion[];
    selectedRegionsLatestQuery: string | string[] | Record<string, string>[];
    selectedRegionEstimatedPrices: ISelectedRegionEstimatedPrice[];
    // related articles
    articles: IOfferArticle[];
    articlesRequest: RequestState;
    articlesLatestQuery: Record<string, string>;
    // children regions for linkoza
    childrenRegions: IOfferListChildrenRegion[];
    childrenRegionsRequestState: RequestState;
    childrenRegionsLatestQuery: Record<string, string>;
    // parent regions for linkoza
    regionPath: IRegionPathRegion[];
    regionPathRequestState: RequestState;
    regionPathLatestQuery: Record<string, string>;
    search: ISearch;
    // nearby regions for linkoza
    nearbyRegions: INearbyRegion[];
    nearbyRegionsRequestState: RequestState;
    nearbyRegionsLatestQuery: Record<string, string>;
}

export const offerListReducer = combineReducers({
    list: offerListListReducer,
    selectedRegions: offerListSelectedRegionsReducer(),
    selectedRegionsLatestQuery: reduceLatestQuery(fetchSelectedRegionDataTypes),
    selectedRegionEstimatedPrices: reduceResponse<ISelectedRegionEstimatedPrice[]>(fetchSelectedRegionEstimatedPriceTypes, []),
    //related articles
    articles: reduceResponse<IOfferArticle[]>(fetchOfferListArticlesTypes, []),
    articlesRequest: reduceRequestState(fetchOfferListArticlesTypes),
    articlesLatestQuery: reduceLatestQuery(fetchOfferListArticlesTypes),
    // children regions
    childrenRegions: reduceResponse<IOfferListChildrenRegion[]>(fetchOfferListChildrenRegionsTypes, []),
    childrenRegionsRequest: reduceRequestState(fetchOfferListChildrenRegionsTypes),
    childrenRegionsLatestQuery: reduceLatestQuery(fetchOfferListChildrenRegionsTypes),
    // region path
    regionPath: reduceResponse<IOfferListChildrenRegion[]>(fetchOfferListRegionPathTypes, []),
    regionPathRequestState: reduceRequestState(fetchOfferListRegionPathTypes),
    regionPathLatestQuery: reduceLatestQuery(fetchOfferListRegionPathTypes),
    search: reduceResponse<ISearch>(fetchOfferListSearchActionTypes),
    // nearby regions for sidebar
    nearbyRegions: reduceResponse<INearbyRegion[]>(fetchOfferListNearbyRegionsTypes, []),
    nearbyRegionsRequestState: reduceRequestState(fetchOfferListNearbyRegionsTypes),
    nearbyRegionsLatestQuery: reduceLatestQuery(fetchOfferListNearbyRegionsTypes)
});
