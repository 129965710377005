import React from "react";
import {useState} from "react";
import {SerializedStyles, Theme} from "@emotion/react";
import styled from "@emotion/styled";

export interface IProps {
    children: React.ReactNode;
    title: JSX.Element;
    panelArrowIcon: JSX.Element;
    open?: boolean;
    alwaysOpen?: boolean;
    headerCss?: SerializedStyles | ((theme: Theme) => SerializedStyles);
    className?: string;
}

export const PanelExpandable: React.FC<IProps> = (props) => {
    const openState = props.alwaysOpen || props.open;

    const [isOpen, setIsOpen] = useState(openState);

    const onClick = () => {
        if (!props.alwaysOpen) {
            setIsOpen(!isOpen);
        }
    };

    return (
        <PanelExpandableWrapper className={props.className}>
            <PanelExpandableHeader onClick={onClick} css={props.headerCss}>
                {props.title}
                {!props.alwaysOpen && <PanelExpandableIconWrapper isOpen={isOpen}>{props.panelArrowIcon}</PanelExpandableIconWrapper>}
            </PanelExpandableHeader>
            <PanelExpandableBody isOpen={isOpen}>{props.children}</PanelExpandableBody>
        </PanelExpandableWrapper>
    );
};

interface IThemeProps {
    theme?: Theme;
    isOpen?: boolean;
}

const PanelExpandableWrapper = styled.div<IThemeProps>`
    width: 100%;

    @media (min-width: ${(props) => props.theme?.breakpoints?.md ?? "1024px"}) {
        border-bottom: none;
    }
`;

const PanelExpandableHeader = styled.div<IThemeProps>`
    display: flex;
    justify-content: space-between;
    font-size: 1.2rem;
    padding: 1.8rem 0;

    @media (min-width: ${(props) => props.theme?.breakpoints?.md ?? "1024px"}) {
        padding: 0 0 1.8rem 0;
    }
`;

const PanelExpandableIconWrapper = styled.div<IThemeProps>`
    display: flex;
    align-items: center;
    transform: ${(props) => (props.isOpen ? "rotate(180deg)" : "")};
    margin-right: 1rem;

    @media (min-width: ${(props) => props.theme?.breakpoints?.md ?? "1024px"}) {
        display: none;
    }
`;

const PanelExpandableBody = styled.div<IThemeProps>`
    font-size: 1.2rem;
    display: flex;
    padding: 0 1rem;

    max-height: ${(props) => (props.isOpen ? "900px" : "0")};
    overflow: hidden;
    flex-direction: column;

    @media (min-width: ${(props) => props.theme?.breakpoints?.sm ?? "768px"}) {
        flex-direction: row;
    }

    @media (min-width: ${(props) => props.theme?.breakpoints?.md ?? "1024px"}) {
        padding: 0;
        max-height: initial;
        overflow: initial;
    }
    transition: max-height 0.2s ease-in-out;
`;
