import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {appendQueryString} from "@pg-mono/request";
import {enable301ResponseState} from "@pg-mono/response-state";
import {rpAppLink} from "@pg-mono/rp-routes";

import {IRPStore} from "../../../app/rp_reducer";
import {IRPRequestMeta} from "../../../app/rp_request_meta";
import {RegionType} from "../../../region/types/RegionType";
import {OfferType} from "../../types/OfferType";
import {getHouseFilterByOfferListSubTypeForQuery} from "../helpers/sub_type/transform/get_house_filter_by_offer_list_sub_type";
import {OfferListSubType} from "../types/OfferListSubType";

export const redirectFromSubFilterToOfferList =
    (ctx: IFetchContext<IRPRequestMeta, {friendlySlug: string; offerListSubFilter?: OfferListSubType}>) =>
    async (dispatch: Dispatch, getState: () => IRPStore) => {
        const {offerListSubFilter, friendlySlug, ...restParams} = ctx.match.params;
        const selectedRegions = getState().offerList.selectedRegions;
        const type = parseInt(ctx.prevResult.type);

        const isHouseListing = type === OfferType.HOUSE;
        const hasExactlyOneRegionBeenSelected = selectedRegions.length === 1;
        const isSelectedRegionCity = selectedRegions[0]?.type === RegionType.CITY;

        if (offerListSubFilter && isHouseListing && !(hasExactlyOneRegionBeenSelected && isSelectedRegionCity)) {
            const houseFilterParams = getHouseFilterByOfferListSubTypeForQuery(offerListSubFilter);
            const newUrl = appendQueryString(rpAppLink.offer.listFriendly({friendlySlug}), {...houseFilterParams, ...restParams});

            dispatch(enable301ResponseState(newUrl));

            return false;
        }

        return ctx.prevResult;
    };
