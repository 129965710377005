import {combineReducers} from "redux";

import {reduceRequestState, reduceResponse, RequestState} from "@pg-mono/request-state";

import {fetchArticleDetailTypes} from "../actions/fetch_article_detail";
import {IArticleListArticle} from "../actions/fetch_article_list";
import {fetchRelatedArticlesTypes} from "../actions/fetch_related_articles";
import {IArticleDetailArticle} from "../actions/IArticleDetail";
import {articleCommentReducer, IArticleCommentStore} from "./article_comment_reducer";

export interface IArticleDetailStore {
    comments: IArticleCommentStore;
    article: IArticleDetailArticle | null;
    requestState: RequestState;
    relatedArticles: IArticleListArticle[];
    relatedArticlesRequestState: RequestState;
}

export const articleDetailReducer = combineReducers({
    comments: articleCommentReducer,
    article: reduceResponse<IArticleDetailArticle>(fetchArticleDetailTypes),
    requestState: reduceRequestState(fetchArticleDetailTypes),
    relatedArticles: reduceResponse(fetchRelatedArticlesTypes),
    relatedArticlesRequestState: reduceRequestState(fetchRelatedArticlesTypes)
});
