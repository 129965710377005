import {useCallback} from "react";
import {useHistory} from "react-router";

import {rpAppLink} from "@pg-mono/rp-routes";

import {buildFriendlyPromotionSlug} from "../../helpers/build_friendly_promotion_slug";
import {PromotionType} from "../../helpers/PromotionType";

export const usePromotionListFilterChange = (): ((type?: PromotionType, region?: string) => void) => {
    const history = useHistory();

    return useCallback((type?: PromotionType, region?: string) => {
        if (!region && !type) {
            history.push(rpAppLink.promotions.list());
        } else {
            const friendlySlug = buildFriendlyPromotionSlug({region, type});

            history.push(rpAppLink.promotions.listFriendly({friendlySlug}));
        }
    }, []);
};
