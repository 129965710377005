import {PropertyFlatType} from "../../offer/detail/constants/PropertyFlatType";

export function getPropertyFlatMetaType(propertyFlatType: PropertyFlatType) {
    switch (propertyFlatType) {
        case PropertyFlatType.PENTHOUSE:
            return "penthouse";
        case PropertyFlatType.APARTMENT:
            return "apartament";
        case PropertyFlatType.STANDARD:
        default:
            return "mieszkanie";
    }
}
