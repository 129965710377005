import {isBrowser} from "../../common/app/read_rp_environment_variables";
import {notifyBugsnag} from "../../common/errors/bugsnag/notify_bugsnag";
import {createRpStore, IRootState} from "../../common/store/store";

declare global {
    interface Window {
        __INITIAL_STATE__?: IRootState;
    }
}

export const getClientStore = () => {
    if (isBrowser) {
        const initialState = window.__INITIAL_STATE__;
        delete window.__INITIAL_STATE__;

        return createRpStore({
            preloadedState: initialState,
            onErrorNotify: (error, context) => {
                notifyBugsnag(error, context);
            }
        });
    }

    return null;
};
