import {IRPStore} from "../../../app/rp_reducer";
import {OfferMetaRobots} from "../../constants/offer_meta_robots";
import {OfferType} from "../../types/OfferType";

export const offerFilterKeys = [
    "area_0",
    "area_1",
    "rooms_0",
    "rooms_1",
    "price_0",
    "price_1",
    "price_lower_than_average",
    "is_luxury",
    "construction_end_date",
    "house_type",
    "house_additional_areas",
    "house_storeys"
] as const;

export type IOfferFilters = Pick<IRPStore["offerList"]["list"]["latestQuery"], (typeof offerFilterKeys)[number]>;

export interface IOfferListingSeoRuleParams {
    filters: IOfferFilters;
    offerType?: OfferType;
    sort: IRPStore["offerList"]["list"]["latestQuery"]["sort"];
    regions?: IRPStore["offerList"]["selectedRegions"];
    page?: number;
}

export interface IOfferListingSeoRule {
    condition(): boolean;
    robots?: OfferMetaRobots;
    canonical?: () => string;
}
