import React, {Fragment} from "react";

import {Footer} from "../../navigation/components/Footer";
import {Navigation} from "../../navigation/components/Navigation";
import {NotFoundView} from "./NotFoundView";

export const NotFoundLayout = () => {
    return (
        <Fragment>
            <Navigation />
            <NotFoundView />
            <Footer />
        </Fragment>
    );
};
