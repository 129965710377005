import React, {useEffect, useState} from "react";
import {css} from "@emotion/react";
import styled from "@emotion/styled";

import {ChevronLeftIcon} from "@pg-design/icons";

interface IProps {
    backToUrl: {
        url: string;
        text: string;
    };
}

export const BackTo = (props: IProps) => {
    const [scrolled, setScrolled] = useState(false);
    const handleScroll = () => {
        if (window.scrollY > 70) {
            setScrolled(true);
        } else {
            setScrolled(false);
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", () => handleScroll);
        };
    }, []);

    return (
        <BackToBase css={!scrolled && backToHidden} href={props.backToUrl.url}>
            <ChevronLeftIcon size="1.4" />
            <span css={backToText}>{props.backToUrl.text}</span>
        </BackToBase>
    );
};

const BackToBase = styled.a`
    color: #23232d;
    min-width: 200px;
    max-width: calc(100% - 110px);
    height: 60px;
    background: white;
    position: absolute;
    top: 0;
    left: 0;
    padding: 2rem;
    transform: translateY(0);
    transition: transform 200ms ease-in-out;
    display: flex;
    flex-direction: row;
    align-items: center;

    &:visited,
    &:hover,
    &:focus,
    &:active {
        color: #23232d;
        text-decoration: none;
    }
`;
const backToText = css`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-transform: uppercase;
    padding-left: 1rem;
    font-weight: 700;
`;

const backToHidden = css`
    transform: translateY(-100%);
    transition: transform 200ms ease-in-out;
    max-width: calc(100% - 110px);
`;
