import {appendQueryString} from "@pg-mono/request";
import {rpAppLink} from "@pg-mono/rp-routes";

import {IFriendlyQuery} from "../../list/types/friendly_query";
import {getFriendlyOfferListLinkParams} from "./get_friendly_offer_list_link_params";

export const buildFriendlyOfferListLink = (query: Partial<IFriendlyQuery>): string => {
    const {friendlySlug, restQueryValues} = getFriendlyOfferListLinkParams(query);

    return appendQueryString(rpAppLink.offer.listFriendly({friendlySlug}), restQueryValues);
};
