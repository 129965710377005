import {Dispatch} from "redux";

import {consoleError} from "@pg-mono/logger";
import {createRequestActionTypes} from "@pg-mono/request-state";

import {IRPStore} from "../../../app/rp_reducer";
import {fetchRegionEstimatedPrice} from "../../../region/api/fetch_region_estimated_price";

const FETCH_SELECTED_REGION_ESTIMATED_PRICE_PREFIX = "offer_list/FETCH_SELECTED_REGION_ESTIMATED_PRICE";
export const fetchSelectedRegionEstimatedPriceTypes = createRequestActionTypes(FETCH_SELECTED_REGION_ESTIMATED_PRICE_PREFIX);

export interface ISelectedRegionEstimatedPrice {
    date: string;
    slug_city: string;
    city: string;
    avg_price_m2: number;
    avg_price_m2_house: number;
}

export const fetchSelectedRegionEstimatedPrice = () => async (dispatch: Dispatch, getState: () => IRPStore) => {
    const selectedRegions = getState().offerList.selectedRegions;
    const isSingleRegionSelected = selectedRegions.length === 1;

    if (!isSingleRegionSelected) {
        return [];
    }

    const selectedRegion = selectedRegions[0];

    return fetchRegionEstimatedPrice(selectedRegion)
        .then((response) => {
            dispatch({type: fetchSelectedRegionEstimatedPriceTypes.success, result: response});

            return response;
        })
        .catch((err) => {
            consoleError("fetchPropertyEstimatedPrice error: ", err);
            dispatch({type: fetchSelectedRegionEstimatedPriceTypes.error, error: err});

            return [];
        });
};
