import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {difference, omit} from "@pg-mono/nodash";
import {appendQueryString} from "@pg-mono/request";
import {enable301ResponseState} from "@pg-mono/response-state";
import {rpAppLink} from "@pg-mono/rp-routes";

import {IRPStore} from "../../app/rp_reducer";
import {IRPRequestMeta} from "../../app/rp_request_meta";
import {buildFriendlyPromotionSlug, getPromotionSlugByType} from "../helpers/build_friendly_promotion_slug";
import {knownPromotionQueryTypes} from "../helpers/promotion_url_constants";
import {PromotionType} from "../helpers/PromotionType";
import {fetchPromotionRegionById} from "./fetch_promotion_list_region";

export const validateFriendlyPromotionUrlAtRoute = (ctx: IFetchContext<IRPRequestMeta>) => async (dispatch: Dispatch, getState: () => IRPStore) => {
    const {prevResult: query} = ctx;
    const {friendlySlug: urlFriendlySlug} = ctx.match.params;

    const friendlySlugQuery: Record<string, string | PromotionType> = {};

    if (!query.type && !query.region) {
        // There are no known query params (from previous action) to validate - redirect
        dispatch(enable301ResponseState(appendQueryString(rpAppLink.promotions.list(), query)));

        return false;
    }

    if (query.type && getPromotionSlugByType(parseInt(query.type))) {
        friendlySlugQuery.type = parseInt(query.type);
    }

    if (query.region) {
        const {region} = getState().promotionList;
        const regionId = parseInt(query.region as string);

        if (region && region.id === regionId) {
            friendlySlugQuery.region = region.slug;
        } else if (isFinite(regionId)) {
            const regionById = await dispatch(fetchPromotionRegionById(ctx, regionId));

            if (regionById) {
                friendlySlugQuery.region = regionById.slug;
            }
        }
    }

    const friendlySlug = buildFriendlyPromotionSlug(friendlySlugQuery);

    if (friendlySlug && friendlySlug !== urlFriendlySlug) {
        const friendlyPromotionUrl = rpAppLink.promotions.listFriendly({friendlySlug});
        const redirectUrl = appendQueryString(friendlyPromotionUrl, omit(query, knownPromotionQueryTypes));

        dispatch(enable301ResponseState(redirectUrl));

        return false;
    }

    // Return query but remove params that have not passed validation
    return omit(query, difference(knownPromotionQueryTypes, Object.keys(friendlySlugQuery)));
};
