import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";

import {IRPRequestMeta} from "../../../app/rp_request_meta";
import {rpEnable404ResponseState} from "../../../errors/actions/rp_enable_404_response_state";
import {PRICES_CITIES_SLUGS} from "../constants/prices_cities";

export const validatePriceReportRegion = (ctx: IFetchContext<IRPRequestMeta, {regionSlug: string}>) => async (dispatch: Dispatch) => {
    if (PRICES_CITIES_SLUGS.includes(ctx.match.params.regionSlug)) {
        return true;
    }

    dispatch(rpEnable404ResponseState(ctx.route.pathname));

    return false;
};
