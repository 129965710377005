import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {appendQueryString, catch400, catch404, getRequest, IQuery} from "@pg-mono/request";
import {createRequestActionTypes} from "@pg-mono/request-state";
import {enable301ResponseState} from "@pg-mono/response-state";
import {apiV2ListLink, Scenario} from "@pg-mono/rp-api-routes";

import {IRPRequestMeta} from "../../../app/rp_request_meta";
import {redirectOrEnable404ResponseState} from "../../../errors/actions/page_404_actions";
import {getSoonFinishedOffersPayload} from "../../utils/get_soon_finished_offers_payload";
import {IOfferListResponse} from "../types/IOfferListResponse";

const SOON_FINISHED_OFFER_LIST = "offer_list/SOON_FINISHED_OFFER_LIST";
export const fetchSoonFinishedOfferListTypes = createRequestActionTypes(SOON_FINISHED_OFFER_LIST);

export const fetchSoonFinishedOfferList = (ctx: IFetchContext<IRPRequestMeta>, originalPage: number, query: IQuery) => async (dispatch: Dispatch) => {
    dispatch({type: fetchSoonFinishedOfferListTypes.start});

    // prepare full-query
    const offerListSoonFinishedLink = apiV2ListLink.offer.list(Scenario.OFFER_LIST);

    const fullQuery: Record<string, string | number | number[] | boolean | null> = {
        ...query,
        ...getSoonFinishedOffersPayload(1)
    };

    // define url and start fetch
    const url = appendQueryString(offerListSoonFinishedLink, fullQuery);

    return getRequest(ctx.meta, url)
        .then((json: IOfferListResponse) => {
            const result = {
                offers: json.results,
                page: fullQuery.page,
                count: json.count
            };

            dispatch({type: fetchSoonFinishedOfferListTypes.success, result});
            return json;
        })
        .catch(
            catch400((error) => {
                // we do not expect to receive 400 at this point because previous list succeeded
                dispatch({type: fetchSoonFinishedOfferListTypes.error, error: error.appError});
            })
        )
        .catch(
            catch404(async () => {
                // standard 404
                if (originalPage > 1) {
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    const {page, ...restQuery} = ctx.route.query;
                    const firstPageUrl = appendQueryString(ctx.route.pathname, restQuery);
                    dispatch(enable301ResponseState(firstPageUrl));
                } else {
                    await dispatch(redirectOrEnable404ResponseState(ctx.route.pathname, ctx.meta));
                }
            })
        );
};

export const resetSoonFinishedOfferList = () => ({type: fetchSoonFinishedOfferListTypes.reset});
