import {Dispatch} from "redux";

import {RequestState} from "@pg-mono/request-state";

import {IRPStore} from "../../app/rp_reducer";
import {loadFavouritesToStore} from "./load_favourites_to_store";
import {loadLocalStorageFavouritesToStore} from "./load_local_storage_favourites_to_store";

export const initializeAppStoreFavourites = () => async (dispatch: Dispatch, getState: () => IRPStore) => {
    const {
        isAuthenticated,
        favourite: {fetchRequest}
    } = getState();

    if (!isAuthenticated) {
        return dispatch(loadLocalStorageFavouritesToStore());
    }

    if (fetchRequest !== RequestState.Success) {
        dispatch(loadFavouritesToStore());
    }

    return;
};
