import {combineReducers} from "redux";

import {reduceResponse} from "@pg-mono/request-state";

import {IArticleListArticle} from "../../article/actions/fetch_article_list";
import {fetchInvestmentOffersArticleListTypes} from "../actions/fetch_investment_offer_article_list";
import {fetchInvestmentOfferStatsTypes, IInvestmentOfferStats} from "../actions/fetch_investment_offer_stats";
import {IParsedInvestmentOfferCategories} from "../list/actions/parse_investment_offer_url";
import {IInvestmentOffersListStore, investmentOffersListReducer} from "../list/reducers/investment_offer_list_reducer";
import {investmentOfferCategoriesReducer} from "./investment_offer_categories_reducer";

export interface IInvestmentOffersStore {
    articles: IArticleListArticle[];
    categories: IParsedInvestmentOfferCategories;
    list: IInvestmentOffersListStore;
    stats: IInvestmentOfferStats;
}

export const investmentOffersReducer = combineReducers({
    articles: reduceResponse<IArticleListArticle[]>(fetchInvestmentOffersArticleListTypes, []),
    stats: reduceResponse<IInvestmentOfferStats>(fetchInvestmentOfferStatsTypes),
    list: investmentOffersListReducer,
    categories: investmentOfferCategoriesReducer
});
