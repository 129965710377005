import {useMemo} from "react";
import {useSelector} from "react-redux";

import {IFooterMenuData} from "@pg-design/footer";
import {rpAppLink} from "@pg-mono/rp-routes";

import {IRPStore} from "../../app/rp_reducer";

export const useTopArticlesLinks = () => {
    const topArticles = useSelector((store: IRPStore) => store.footer.articles.data);

    return useMemo(() => {
        return topArticles?.map((topArticlesLink) => ({
            title: topArticlesLink?.anchor,
            url: rpAppLink.article.detail({articleId: topArticlesLink.article.id, articleSlug: topArticlesLink.article.slug})
        })) as IFooterMenuData["topArticles"];
    }, [topArticles]);
};
