import React from "react";
import {css} from "@emotion/react";

import {Badge} from "@pg-design/badge";
import {p, position, pv} from "@pg-design/helpers-css";
import {CloseIcon, UserIcon} from "@pg-design/icons";
import {Popover} from "@pg-design/notifications";
import {pluralize} from "@pg-mono/string-utils";

interface IProps {
    isPopoverActive: boolean;
    recommendedCount: number;
    onPopoverClose: () => void;
}

export const UserIconWithRecommendationPopover = (props: IProps) => {
    const onCloseIconClick = (e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        props.onPopoverClose();
    };

    return (
        <div css={[position("relative")]}>
            <Popover
                type="success"
                arrow
                isActive={props.isPopoverActive}
                popoverPlace="bottom-end"
                appendTo="parent"
                body={
                    <div css={popoverContentWrapperStyle}>
                        Przygotowaliśmy dla Ciebie{" "}
                        <strong>
                            {props.recommendedCount} {recommendationNewPluralize(props.recommendedCount)}{" "}
                            {recommendationPartOnePluralize(props.recommendedCount)}
                        </strong>{" "}
                        {recommendationPartTwoPluralize(props.recommendedCount)} twoje kryteria
                        <CloseIcon size="1.6" css={closeIconStyle} onClick={onCloseIconClick} />
                    </div>
                }
                openOnMount
                arrowStyle={arrowStyle}
                wrapperStyle={popoverWrapperStyle}
            >
                <div css={[pv(1), position("relative")]}>
                    {props.recommendedCount ? (
                        <Badge variant="label_danger" css={badgeStyle}>
                            1
                        </Badge>
                    ) : null}
                    <UserIcon size="2" />
                </div>
            </Popover>
        </div>
    );
};

const recommendationPartOnePluralize = pluralize(["rekomendację", "rekomendacje", "rekomendacji"]);
const recommendationNewPluralize = pluralize(["nową", "nowe", "nowych"]);
const recommendationPartTwoPluralize = pluralize(["oferty, spełniającą", "ofert, spełniających", "ofert, spełniających"]);

const popoverWrapperStyle = css`
    margin-right: -2rem;
`;

const arrowStyle = css`
    left: auto;
    right: 0.4rem;
    top: -0.4rem;
    width: 22px;
    overflow: hidden;

    &:before {
        width: 28px;
    }
`;

const popoverContentWrapperStyle = css`
    ${p(0.5, 3, 0.5, 1)};
    position: relative;
    text-wrap: initial;
    min-width: 23rem;
    line-height: 1.6rem;
    white-space: normal;
`;

const closeIconStyle = css`
    position: absolute;
    right: 0;
    top: 0;
`;

const badgeStyle = css`
    position: absolute;
    right: -1.6rem;
    top: -0.2rem;
    transform: scale(0.7);
`;
