import {configureStore, Dispatch} from "@reduxjs/toolkit";

import {IPromiseMiddlewareOptions, promiseMiddleware} from "@pg-mono/request";
import {enableErrorResponseState} from "@pg-mono/response-state";

import {isDevelopment} from "../app/read_rp_environment_variables";
import {addNotification} from "../notifications/state/notifications_slice";
import {apiMiddlewares, rootReducer} from "./root_reducer";

interface ICreateStoreOptions {
    preloadedState?: IRootState;
    logData?: IPromiseMiddlewareOptions["logData"];
    onErrorNotify?: IPromiseMiddlewareOptions["notifyError"];
    onErrorAlert?: () => void;
}

export function createRpStore(options?: ICreateStoreOptions) {
    const promiseMiddlewareLegacy = promiseMiddleware({
        onErrorAlert: () => {
            addNotification({type: "failure", content: "Wystąpił problem przy ładowaniu danych. Spróbuj ponownie."});

            if (options && options.onErrorAlert) {
                options.onErrorAlert();
            }

            return async () => undefined;
        },
        onErrorStatus: (status: number) => async (dispatch: Dispatch) => dispatch(enableErrorResponseState(status)),
        notifyError: (error, context) => {
            if (options && options.onErrorNotify) {
                options.onErrorNotify(error, context);
            }

            return null;
        },
        logData: options?.logData || {}
    });

    const middlewares = [promiseMiddlewareLegacy, ...apiMiddlewares];

    const defaultMiddlewareConfig = {
        thunk: false,
        /**
         * TODO: We should rethink our approach to notifications and errors, currently their values might be non serializable
         *     https://redux-toolkit.js.org/usage/usage-guide#working-with-non-serializable-data
         */
        serializableCheck: false,
        immutableCheck: {
            // TODO: remove this warnAfter value after me migrate all apis to use redux-toolkit
            warnAfter: 2000
        }
    };

    return configureStore({
        // @ts-ignore
        preloadedState: options?.preloadedState || undefined,
        reducer: rootReducer,
        // @ts-ignore
        middleware: (getDefaultMiddleware) => getDefaultMiddleware(defaultMiddlewareConfig).concat(middlewares),
        devTools: isDevelopment
    });
}

type IRootReducer = typeof rootReducer;
//  TODO: After moving all reducers to redux-toolkit, remember, to migrate RpStore to IRpStore
export type RpStore = ReturnType<typeof createRpStore>;
export type IRootState = ReturnType<IRootReducer>;
export type IAppDispatch = RpStore["dispatch"];
