import {Dispatch} from "redux";

import {consoleError} from "@pg-mono/logger";
import {catch5xx, catch400, catch504, catchTimeout, catchUnknownError, ResponseError, ResponseTimeoutError} from "@pg-mono/request";
import {createRequestActionTypes} from "@pg-mono/request-state";

import {IRPRequestMeta} from "../../app/rp_request_meta";
import {notifyBugsnag} from "../../errors/bugsnag/notify_bugsnag";
import {getPois} from "../api/get_pois";
import {IPoi} from "../types/IPoi";
import {IPoiAround} from "../types/IPoiAround";
import {PoiType, TransportPoiType} from "../utils/PoiType";
import {transformOSMGeoAssetsPoi} from "../utils/transform_osm_geo_assets_poi";

const FETCH_DETAIL_POI = "overpass/FETCH_DETAIL_POI";
export const fetchDetailPoiTypes = createRequestActionTypes(FETCH_DETAIL_POI);

type IFetchDetailPoiResults = Record<Exclude<PoiType, PoiType.TRANSPORT>, IPoi[]> & Record<PoiType.TRANSPORT, Record<TransportPoiType, IPoi[]>>;

export const fetchDetailPoi =
    (meta: IRPRequestMeta, around: IPoiAround) =>
    async (dispatch: Dispatch): Promise<IFetchDetailPoiResults | true> => {
        dispatch({type: fetchDetailPoiTypes.start});

        return getPois(meta, around)
            .then((response) => {
                const results = transformOSMGeoAssetsPoi(response);

                dispatch({
                    type: fetchDetailPoiTypes.success,
                    result: {
                        pois: results,
                        requestedArea: {
                            ...around
                        }
                    }
                });

                return results;
            })
            .catch(catch400((err) => handleFetchPoiError(dispatch, err, fetchDetailPoiTypes.success)))
            .catch(catchTimeout((err) => handleFetchPoiError(dispatch, err, fetchDetailPoiTypes.success)))
            .catch(catch504((err) => handleFetchPoiError(dispatch, err, fetchDetailPoiTypes.success)))
            .catch(catch5xx((err) => handleFetchPoiError(dispatch, err, fetchDetailPoiTypes.success)))
            .catch(catchUnknownError((err) => handleFetchPoiError(dispatch, err, fetchDetailPoiTypes.success)));
    };

const handleFetchPoiError = (dispatch: Dispatch, err: ResponseError | ResponseTimeoutError, successRequestType: string): true => {
    const message = "OSM: fetchDetailPoi error";

    notifyBugsnag(err, message);
    consoleError(message, err);

    dispatch({type: successRequestType, result: {}});

    // Return `true` we don't want to crash app on error
    return true;
};
