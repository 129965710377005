import {combineReducers} from "redux";

import {reduceRequestState, reduceResponse, RequestState} from "@pg-mono/request-state";

import {fetchInvestmentOfferSeoContentActionTypes, IInvestmentOfferSeoContent} from "../actions/fetch_investment_offer_seo_content";

export interface IInvestmentOfferSeoContentStore {
    seoContent: IInvestmentOfferSeoContent | null;
    requestState: RequestState;
}

export const investmentOfferSeoContentReducer = combineReducers({
    seoContent: reduceResponse(fetchInvestmentOfferSeoContentActionTypes),
    requestState: reduceRequestState(fetchInvestmentOfferSeoContentActionTypes)
});
