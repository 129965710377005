import {IFetchContextRoute} from "@pg-mono/data-fetcher";
import {omit} from "@pg-mono/nodash";

import {RegionType} from "../../../region/types/RegionType";
import {OfferMetaRobots} from "../../constants/offer_meta_robots";
import {OfferListSubType} from "../../list/types/OfferListSubType";
import {OfferType} from "../../types/OfferType";
import {IOfferListingSeoRuleParams} from "./constants";

export const getOfferListSubTypeRobotsAndCanonicalRule = (
    offerListSubType: OfferListSubType | undefined,
    seoParams: IOfferListingSeoRuleParams,
    offersCount: number,
    route: IFetchContextRoute
) => {
    const region = seoParams.regions?.[0];

    const hasExtraFilters = Boolean(Object.keys(omit(seoParams.filters, ["price_1", "type"] as (keyof typeof seoParams.filters)[])).length);
    const indexedOfferType = !!seoParams.offerType && [OfferType.FLAT, OfferType.HOUSE].includes(seoParams.offerType);

    return [
        {
            condition: () => !!offerListSubType && offerListSubType === OfferListSubType.CREDIT_TWO_PERCENT,
            robots:
                !seoParams.page &&
                offersCount > 0 &&
                !hasExtraFilters &&
                indexedOfferType &&
                (!region || (region && [RegionType.CITY, RegionType.TOWN].includes(region.type)))
                    ? OfferMetaRobots.INDEX_FOLLOW
                    : OfferMetaRobots.NOINDEX,
            canonical: () => route.pathname
        }
    ];
};
