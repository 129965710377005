import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {isEmpty} from "@pg-mono/nodash";
import {appendQueryString, catch404, getRequest} from "@pg-mono/request";
import {createRequestActionTypes, RequestActionTypes} from "@pg-mono/request-state";
import {apiV2ListLink, Scenario} from "@pg-mono/rp-api-routes";

import {IRPRequestMeta} from "../../app/rp_request_meta";
import {redirectOrEnable404ResponseState} from "../../errors/actions/page_404_actions";
import {RegionType} from "../../region/types/RegionType";
import {IPaginatedResponse} from "../../request/IResponse";
import {IRegionListResponse} from "../../search/actions/fetch_search_regions_action";

export interface ISitemapRegionListRegion {
    id: number;
    name: string;
    parent: number;
    slug: string;
    stats: {
        family: {
            county: string | null;
            aggregation: string | null;
            voivodeship: string | null;
        };
        properties_flats_count_for_sale: number;
        properties_houses_count_for_sale: number;
        properties_commercials_count_for_sale: number;
    };
    name_declension_where: string;
}

export interface ISitemapRegionListRegionShort {
    name: string;
    id: number;
    slug: string;
}

export interface ISiteMapRegionListResponse extends IPaginatedResponse {
    results: ISitemapRegionListRegion[];
}

const FETCH_SELECTED_REGION_DATA = "SITE_MAP/FETCH_SELECTED_REGION_DATA";
export const fetchSelectedRegionDataTypes = createRequestActionTypes(FETCH_SELECTED_REGION_DATA);

const regionListApiLink = apiV2ListLink.region.list(Scenario.SITEMAP_REGION_BY_SLUG);
export const fetchSiteMapSelectedRegionBySlug = (slug: string, ctx: IFetchContext<IRPRequestMeta>) => (dispatch: Dispatch) => {
    dispatch({type: fetchSelectedRegionDataTypes.start});

    const url = appendQueryString(regionListApiLink, {slug: slug});
    return getRequest(ctx.meta, url).then((response: IRegionListResponse) => {
        const region = isEmpty(response.results) ? null : response.results[0];

        dispatch({type: fetchSelectedRegionDataTypes.success, result: region});
        return region;
    });
};

const createFetchRegionByType = (actionTypes: RequestActionTypes, regionType: RegionType) => (ctx: IFetchContext<IRPRequestMeta>) => (dispatch: Dispatch) => {
    dispatch({type: actionTypes.start});

    const fullQuery = {
        type: regionType,
        page_size: 1000
    };
    const url = appendQueryString(apiV2ListLink.region.list(Scenario.SITEMAP_REGION_BY_TYPE), fullQuery);
    return getRequest(ctx.meta, url)
        .then((res: ISiteMapRegionListResponse) => {
            dispatch({type: actionTypes.success, result: res.results});
            return res;
        })
        .catch(
            catch404(async () => {
                await dispatch(redirectOrEnable404ResponseState(ctx.route.pathname, ctx.meta));
            })
        );
};

const FETCH_SITE_MAP_REGION_LIST_BY_VOIVODESHIP = "SITE_MAP/REGION/FETCH_VOIVODESHIP_LIST";
export const fetchSiteMapRegionVoivodeshipListActionTypes = createRequestActionTypes(FETCH_SITE_MAP_REGION_LIST_BY_VOIVODESHIP);
export const fetchSiteMapRegionVoivodeshipList = createFetchRegionByType(fetchSiteMapRegionVoivodeshipListActionTypes, RegionType.VOIVODESHIP);

const FETCH_SITE_MAP_REGION_LIST_BY_AGGREGATION = "SITE_MAP/REGION/FETCH_AGGREGATION_LIST";
export const fetchSiteMapRegionAggregationListActionTypes = createRequestActionTypes(FETCH_SITE_MAP_REGION_LIST_BY_AGGREGATION);
export const fetchSiteMapRegionAggregationList = createFetchRegionByType(fetchSiteMapRegionAggregationListActionTypes, RegionType.AGGREGATION);

const FETCH_SITE_MAP_REGION_LIST_BY_COUNTY = "SITE_MAP/REGION/FETCH_COUNTY_LIST";
export const fetchSiteMapRegionCountyListActionTypes = createRequestActionTypes(FETCH_SITE_MAP_REGION_LIST_BY_COUNTY);
export const fetchSiteMapRegionCountyList = createFetchRegionByType(fetchSiteMapRegionCountyListActionTypes, RegionType.COUNTY);

const FETCH_SITE_MAP_REGION_LIST_BY_CITY = "SITE_MAP/REGION/FETCH_CITY_LIST";
export const fetchSiteMapRegionCityListActionTypes = createRequestActionTypes(FETCH_SITE_MAP_REGION_LIST_BY_CITY);
export const fetchSiteMapRegionCityList = createFetchRegionByType(fetchSiteMapRegionCityListActionTypes, RegionType.CITY);
