import {pluralize} from "@pg-mono/string-utils";

import {SearchTab} from "../actions/fetch_search_all_action";
import {ISearchInputValue} from "../components/ISearchInputValue";

export const generatePillLabel = (isDropdownOpen: boolean, searchFieldPropsValue: ISearchInputValue): string | null => {
    if (searchFieldPropsValue.tabType === SearchTab.Regions && searchFieldPropsValue.regions) {
        if (searchFieldPropsValue.regions.length > 1) {
            const regionsCount = searchFieldPropsValue.regions.length;
            return `+${regionsCount.toString()} ${pluralize(["lokalizacja", "lokalizacje", "lokalizacji"])(regionsCount)}`;
        } else if (searchFieldPropsValue.regions.length) {
            return searchFieldPropsValue.regions[0].name;
        }
    }
    return null;
};
