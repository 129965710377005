export const PRICES_CITIES: {name: string; slug: string; where: string; hsl: [number, number, number]}[] = [
    {name: "Białystok", slug: "bialystok", where: "Białymstoku", hsl: [288, 55, 61]},
    {name: "Bydgoszcz", slug: "bydgoszcz", where: "Bydgoszczy", hsl: [338, 66, 63]},
    {name: "Gdańsk", slug: "gdansk", where: "Gdańsku", hsl: [27, 87, 59]},
    {name: "Katowice", slug: "katowice", where: "Katowicach", hsl: [204, 81, 60]},
    {name: "Kielce", slug: "kielce", where: "Kielcach", hsl: [50, 87, 59]},
    {name: "Kraków", slug: "krakow", where: "Krakowie", hsl: [94, 61, 57]},
    {name: "Lublin", slug: "lublin", where: "Lublinie", hsl: [173, 66, 63]},
    {name: "Łódź", slug: "lodz", where: "Łodzi", hsl: [133, 66, 63]},
    {name: "Olsztyn", slug: "olsztyn", where: "Olszytnie", hsl: [72, 66, 63]},
    {name: "Poznań", slug: "poznan", where: "Poznaniu", hsl: [50, 66, 63]},
    {name: "Rzeszów", slug: "rzeszow", where: "Rzeszowie", hsl: [0, 66, 63]},
    {name: "Szczecin", slug: "szczecin", where: "Szczecinie", hsl: [184, 66, 63]},
    {name: "Warszawa", slug: "warszawa", where: "Warszawie", hsl: [4, 66, 63]},
    {name: "Wrocław", slug: "wroclaw", where: "Wrocławiu", hsl: [58, 66, 63]}
];

export const PRICES_CITIES_SLUGS = PRICES_CITIES.map((city) => city.slug);
