import React from "react";

import {FlagIcon} from "../../components/FlagIcon";
import {IIcon} from "../../interfaces/IIcon";

export const FlagPlIcon = (props: IIcon) => {
    return (
        <FlagIcon {...props}>
            <g clipPath="url(#clip0_12221_2198)">
                <rect x="1.07143" y="2.07143" width="7.85714" height="5.57143" rx="0.5" fill="white" stroke="#D3D3D5" strokeWidth="0.142857" />
                <mask id="mask0_12221_2198" style={{maskType: "luminance"}} maskUnits="userSpaceOnUse" x="1" y="2" width="8" height="6">
                    <rect x="1" y="2" width="8" height="5.71429" rx="0.571429" fill="white" />
                </mask>
                <g mask="url(#mask0_12221_2198)">
                    <path fillRule="evenodd" clipRule="evenodd" d="M1 7.71432H9V4.85718H1V7.71432Z" fill="#EB2A50" />
                </g>
            </g>
            <rect x="1.14286" y="2.14286" width="7.71429" height="5.42857" rx="0.428571" stroke="#F5F5F5" strokeWidth="0.285714" />
            <defs>
                <clipPath id="clip0_12221_2198">
                    <rect x="1" y="2" width="8" height="5.71429" rx="0.571429" fill="white" />
                </clipPath>
            </defs>
        </FlagIcon>
    );
};
