import {combineReducers} from "redux";

import {reduceFormValues} from "@pg-mono/form";
import {IAppError, reduceLatestQuery, reduceRequestState, reduceResponse, reduceResponseField, RequestState} from "@pg-mono/request-state";

import {fromJsonValues} from "../../../form/translate/from_json_values";
import {IOfferListOffer} from "../../../offer/list/types/IOfferListResponse";
import {IInvestmentOfferListFiltersFields, investmentOfferListFiltersFields} from "../../hooks/use_investment_offer_filters";
import {IInvestmentOfferMapSelectedOfferStore, investmentOfferMapSelectedOfferReducer} from "../../map/reducers/investment_offer_map_reducer";
import {fetchInvestmentOfferListTypes} from "../actions/fetch_investment_offer_list";
import {fetchInvestmentOffersPropertiesCountTypes} from "../actions/fetch_investment_offers_properties_count";
import {investmentOfferListFilterFormActionTypes} from "../actions/investment_offer_list_filter_form_actions";
import {IInvestmentOfferSeoContentStore, investmentOfferSeoContentReducer} from "./investment_offer_seo_content_reducer";

export interface IInvestmentOffersListStore {
    offers: IInvestmentOffersOffersStore;
    seo: IInvestmentOfferSeoContentStore;
    request: RequestState;
    errors: IAppError | null;
    form: {
        formValues: IInvestmentOfferListFiltersFields;
    };
    map: IInvestmentOfferMapSelectedOfferStore;
    propertiesCount: number;
    propertiesCountRequestState: RequestState;
}

export interface IInvestmentOffersOffersStore {
    offerList: IOfferListOffer[];
    count: number;
    page_size: number;
    page: number;
    requestState: RequestState;
    latestQuery: Record<string, string | number | string[]>;
}

export const investmentOffersListReducer = combineReducers({
    offers: combineReducers({
        offerList: reduceResponseField<IOfferListOffer[]>(fetchInvestmentOfferListTypes, "offers", []),
        count: reduceResponseField<number>(fetchInvestmentOfferListTypes, "count", 1),
        pageSize: reduceResponseField<number>(fetchInvestmentOfferListTypes, "pageSize", 0),
        page: reduceResponseField<number>(fetchInvestmentOfferListTypes, "page", 0),
        requestState: reduceRequestState(fetchInvestmentOfferListTypes),
        latestQuery: reduceLatestQuery(fetchInvestmentOfferListTypes)
    }),
    seo: investmentOfferSeoContentReducer,
    form: combineReducers({
        formValues: reduceFormValues(investmentOfferListFilterFormActionTypes, fromJsonValues(investmentOfferListFiltersFields, {}))
    }),
    map: investmentOfferMapSelectedOfferReducer,
    propertiesCount: reduceResponse(fetchInvestmentOffersPropertiesCountTypes, 0),
    propertiesCountRequestState: reduceRequestState(fetchInvestmentOffersPropertiesCountTypes)
});
