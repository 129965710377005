import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {isEmpty} from "@pg-mono/nodash";
import {appendQueryString, catch404, getRequest} from "@pg-mono/request";
import {createRequestActionTypes} from "@pg-mono/request-state";
import {apiV2Link, apiV2ListLink, Scenario} from "@pg-mono/rp-api-routes";

import {IRPRequestMeta} from "../../app/rp_request_meta";
import {redirectOrEnable404ResponseState} from "../../errors/actions/page_404_actions";
import {IRegionAncestor} from "../../offer/list/actions/offer_list_selected_data_actions";
import {RegionType} from "../../region/types/RegionType";

export interface IVendorRegion {
    id: number;
    short_name: string;
    name_declension_what: string;
    name_declension_where: string;
    full_name_reverted: string;
    is_duplicate: boolean;
    parent: {
        id: number;
        name: string;
    } | null;
    seo_description?: string;
    short_name_reverted: string;
    slug: string;
    stats: {
        offers_count_for_sale: number;
        vendors_count_sold: number;
        region_type_city: IRegionAncestor | null;
        region_type_county: IRegionAncestor | null;
        region_type_district: IRegionAncestor | null;
        region_type_housing_estate: IRegionAncestor | null;
        region_type_town: IRegionAncestor | null;
        region_type_voivodeship: IRegionAncestor | null;
    };
    name: string;
    full_name: string;
    type: RegionType;
}

const FETCH_VENDOR_REGION = "vendor_list/FETCH_REGION";
export const fetchVendorRegionTypes = createRequestActionTypes(FETCH_VENDOR_REGION);

export const fetchVendorListRegion =
    ({meta, route}: IFetchContext<IRPRequestMeta>, regionId: number) =>
    async (dispatch: Dispatch): Promise<IVendorRegion | void> => {
        dispatch({type: fetchVendorRegionTypes.start});
        const url = apiV2Link.region.detail(Scenario.REGION_DETAIL, {regionId});
        return getRequest(meta, url)
            .then((response: IVendorRegion) => {
                dispatch({type: fetchVendorRegionTypes.success, result: response});
                return response;
            })
            .catch(
                catch404(async () => {
                    await dispatch(redirectOrEnable404ResponseState(route.pathname, meta));
                })
            );
    };

interface IVendorRegionResponse {
    results: IVendorRegion[];
}

export const fetchVendorRegionAtRoute =
    (ctx: IFetchContext<IRPRequestMeta>) =>
    (dispatch: Dispatch): Promise<IVendorRegion | null> => {
        const regionListApiLink = apiV2ListLink.region.list(Scenario.VENDOR_LIST_REGION);
        const slug = ctx.match.params.friendlySlug;
        const url = appendQueryString(regionListApiLink, {slug});
        dispatch({type: fetchVendorRegionTypes.start});

        return getRequest(ctx.meta, url).then(async (response: IVendorRegionResponse) => {
            if (!isEmpty(response.results)) {
                dispatch({type: fetchVendorRegionTypes.success, result: response.results});
                return response.results[0];
            } else {
                await dispatch(redirectOrEnable404ResponseState(ctx.route.pathname, ctx.meta));
                return null;
            }
        });
    };

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const resetVendorRegionList = (ctx: IFetchContext<IRPRequestMeta>) => async (dispatch: Dispatch) => {
    dispatch({type: fetchVendorRegionTypes.reset});
    return true;
};
