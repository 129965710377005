import {Dispatch, UnknownAction} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {catch404, getRequest} from "@pg-mono/request";
import {createRequestActionTypes} from "@pg-mono/request-state";
import {enable301ResponseState} from "@pg-mono/response-state";
import {apiV2Link, Scenario} from "@pg-mono/rp-api-routes";
import {rpAppLink} from "@pg-mono/rp-routes";

import {IRPRequestMeta} from "../../app/rp_request_meta";
import {redirectOrEnable404ResponseState} from "../../errors/actions/page_404_actions";
import {extractArticleTableOfContents} from "../utils/extract_article_table_of_contents";
import {IArticleDetailArticle} from "./IArticleDetail";

const ARTICLE_DETAIL_PREFIX = "article_detail/fetch";
export const fetchArticleDetailTypes = createRequestActionTypes(ARTICLE_DETAIL_PREFIX);

export const fetchArticleDetail = (ctx: IFetchContext<IRPRequestMeta>) => (dispatch: Dispatch) => {
    dispatch({type: fetchArticleDetailTypes.start});
    const articleDetailApiLink = apiV2Link.article.detail(Scenario.ARTICLE_DETAIL, {articleId: ctx.match.params.articleId});

    return getRequest(ctx.meta, articleDetailApiLink)
        .then((article: IArticleDetailArticle) => {
            // validate slug
            if (ctx.match.params.articleSlug !== article.slug) {
                const link = rpAppLink.article.detail({articleId: article.id, articleSlug: article.slug});
                dispatch(enable301ResponseState(link));
                return false;
            }

            const {tableOfContents, tableOfContentsTitle, articleText, articleHeaderIds} = extractArticleTableOfContents(article.text || "");

            dispatch({
                type: fetchArticleDetailTypes.success,
                result: {
                    ...article,
                    lead: article.lead && article.lead.replace(/[\n\r]/g, "\n"),
                    table_of_contents: tableOfContents,
                    table_of_contents_title: tableOfContentsTitle,
                    text:
                        articleText &&
                        articleText
                            .replace(/&oacute;/g, "ó")
                            .replace(/&Oacute;/g, "Ó")
                            .replace(/[\n\r]/g, "\n"),
                    article_header_ids: articleHeaderIds
                }
            });
            return article;
        })
        .catch(
            catch404(async () => {
                await dispatch(redirectOrEnable404ResponseState(ctx.route.pathname, ctx.meta));
            })
        );
};

export const resetArticleDetail = (): UnknownAction => ({type: fetchArticleDetailTypes.reset});
