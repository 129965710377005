import {getRequest} from "@pg-mono/request";
import {apiV2Link, Scenario} from "@pg-mono/rp-api-routes";

import {clientRpRequestMeta} from "../../client/client_rp_request_meta";
import {RegionType} from "./types/RegionType";

export interface IRegion {
    id: number;
    name: string;
    slug: string;
    type: RegionType;
}

export interface IRegionPathRegion {
    id: number;
    name: string;
    name_declension_what: string;
    parents: IRegion[];
    slug: string;
    type: RegionType;
    stats: {
        properties_all_count_for_sale: number;
        properties_flats_count_for_sale: number;
        properties_houses_count_for_sale: number;
        properties_commercials_count_for_sale: number;
        vendors_count_for_sale: number;
    };
}

const byRegionTypeDesc = (regionA: IRegion, regionB: IRegion) => regionA.type - regionB.type;

/*
 * Fetches all ancestor regions, includes current region. Returns array sorted by region type, "biggest" region first.
 * ie. [voivodeship, city, district, neighborhood]
 * @param regionId - ID of current region
 */
export const fetchRegionPath = async (regionId: number) => {
    const url = apiV2Link.region.detail(Scenario.SEARCH_REGION_PARENT, {regionId});
    const responseRegionAndParents: IRegionPathRegion | undefined = await getRequest(clientRpRequestMeta, url);

    return responseRegionAndParents ? [...responseRegionAndParents.parents, responseRegionAndParents].sort(byRegionTypeDesc) : [];
};
