import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {appendQueryString, catch404, getRequest, IQueryValue} from "@pg-mono/request";
import {createRequestActionTypes} from "@pg-mono/request-state";
import {apiV2Link, Scenario} from "@pg-mono/rp-api-routes";

import {IRPRequestMeta} from "../../app/rp_request_meta";
import {redirectOrEnable404ResponseState} from "../../errors/actions/page_404_actions";
import {IPaginatedResponse} from "../../request/IResponse";

const AUTHOR_DETAIL_PREFIX = "authors_detail/fetch";
export const fetchAuthorDetailTypes = createRequestActionTypes(AUTHOR_DETAIL_PREFIX);
export const AUTHORS_LIST_PAGE_SIZE = 15;

export interface IArticleAuthorDetail {
    id: number;
    name: string;
    description: string;
    image: {
        a_img_40x40: string;
        a_img_130x130: string;
        a_img_360x360: string;
    };
    email: string;
    facebook: string | null;
    linkedin: string | null;
    twitter: string | null;
    website: string | null;
    meta_description: string | null;
    meta_title: string | null;
    slug: string;
    stats: {
        articles: number;
        categories: string[];
    };
}

interface IOfferListResponse extends IPaginatedResponse {
    results: IArticleAuthorDetail;
}

export const fetchAuthorDetailAtRoute = (ctx: IFetchContext<IRPRequestMeta>) => (dispatch: Dispatch) => {
    const articleDetailApiLink = apiV2Link.author.detail(Scenario.AUTHOR_DETAIL, {authorId: ctx.match.params.authorId});
    const query: Record<string, IQueryValue> = {
        ...ctx.route.query
    };

    dispatch({type: fetchAuthorDetailTypes.start});
    const url = appendQueryString(articleDetailApiLink, query);

    return getRequest(ctx.meta, url)
        .then(async (json: IOfferListResponse) => {
            dispatch({type: fetchAuthorDetailTypes.success, result: json});
            return json;
        })
        .catch(
            catch404(async () => {
                await dispatch(redirectOrEnable404ResponseState(ctx.route.pathname, ctx.meta));
            })
        );
};
