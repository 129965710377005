import React from "react";
import {useSelector} from "react-redux";

import {UserIcon} from "@pg-design/icons";
import {useIsMobile} from "@pg-mono/hooks";
import {kmAppLink} from "@pg-mono/km-routes";

import {IRPStore} from "../../app/rp_reducer";
import {createLinkWithCrossSiteTracking, useRedirectedAuth} from "../../auth/hooks/use_redirected_auth";
import {UserIconWithRecommendationPopover} from "../../user/components/UserIconWithRecommendationPopover";
import {useUserRecommendationPopover} from "../../user/hooks/use_user_recommendation_popover";
import {getFullKMLink} from "../../utils/get_full_km_link";
import {NAV_MOBILE_BREAKPOINT} from "../components/Navigation";

export const useNavigationUserElements = () => {
    const isMobile = useIsMobile(true, NAV_MOBILE_BREAKPOINT);
    const userRecommendationPopoverProps = useUserRecommendationPopover();

    const isAuthenticated = useSelector((state: IRPStore) => state.isAuthenticated);

    const {onRedirectedLogin, onRedirectedLogout} = useRedirectedAuth();

    const onLogin = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();

        // Redirect user to Konto Mieszkaniowe login page
        onRedirectedLogin();
    };

    const onLogout = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();

        onRedirectedLogout();
    };

    const recommendationsRedirect = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();

        window.open(createLinkWithCrossSiteTracking(getFullKMLink(kmAppLink.site.offers.recommendedWithParams)), "_blank");
    };

    const authenticatedUserNavigationFieldFlat = {
        title: isMobile ? "Konto" : "",
        icon: <UserIconWithRecommendationPopover {...userRecommendationPopoverProps} />,
        onClick: recommendationsRedirect
    };

    const authenticatedUserNavigationField = {
        ...authenticatedUserNavigationFieldFlat,
        ...(userRecommendationPopoverProps.recommendedCount > 0
            ? {
                  target: "_blank",
                  url: getFullKMLink(kmAppLink.site.offers.recommendedWithParams),
                  onClick: userRecommendationPopoverProps.onPopoverClose,
                  ...(isMobile ? {icon: <UserIcon size="2" />} : {})
              }
            : {onClick: undefined}),
        subElements: userRecommendationPopoverProps.isPopoverActive
            ? undefined
            : [
                  {
                      title: "Moje oferty",
                      url: getFullKMLink(kmAppLink.site.offers.baseWithParams),
                      target: "_blank"
                  },
                  {
                      title: "Ustawienia konta",
                      url: getFullKMLink(kmAppLink.site.user.baseWithParams),
                      target: "_blank"
                  },
                  {
                      title: "Wyloguj",
                      onClick: onLogout
                  }
              ]
    };

    const unauthenticatedUserNavigationField = {
        title: isMobile ? "Twoje konto" : "",
        rel: "nofollow",
        icon: <UserIcon size="2" />,
        onClick: onLogin
    };

    return {
        navRightElements: [isAuthenticated ? authenticatedUserNavigationField : unauthenticatedUserNavigationField],
        navMobileMiddleElements: [isAuthenticated ? authenticatedUserNavigationFieldFlat : unauthenticatedUserNavigationField]
    };
};
