import {IUiActions, SET_BOTTOM_FIXED_ELEMENT_HEIGHT} from "../actions/ui_actions";

/*
 * INFO: Please use this reducer as the last resort - we should store UI data in Redux only when there is no better solution and
 * there is a need to pass props on many levels.
 */
export interface IUiStore {
    /*
     * On some views (e.g. property, offer) we have several components located at the bottom of the screen.
     * The components do not know about each other - so we need to measure the height of the lowest component - in order to
     * correctly position the other components.
     */
    bottomFixedElementHeight: number | null;
}

export const uiReducer = (state: IUiStore = {bottomFixedElementHeight: null}, action: IUiActions) => {
    switch (action.type) {
        case SET_BOTTOM_FIXED_ELEMENT_HEIGHT: {
            return {
                ...state,
                bottomFixedElementHeight: action.value
            };
        }
        default:
            return state;
    }
};
