import {getRequest} from "@pg-mono/request";
import {apiV2Link, Scenario} from "@pg-mono/rp-api-routes";

import {IRPRequestMeta} from "../../app/rp_request_meta";
import {IArticleDetailArticle} from "../actions/IArticleDetail";

export const fetchArticleDetailById = (meta: IRPRequestMeta, articleId: number): Promise<IArticleDetailArticle> => {
    const requestUrl = apiV2Link.article.detail(Scenario.ARTICLE_DETAIL, {articleId});

    return getRequest(meta, requestUrl);
};
