import {
    IMultiRegionListStore,
    ISetMultiRegionListStateAction,
    RESET_MULTI_REGION_LIST_STATE,
    SET_MULTI_REGION_LIST_STATE
} from "../actions/set_multi_region_list_state";

const initialState = {showMultiRegionList: false, regions: []};

export const multiRegionListReducer = (state: IMultiRegionListStore = initialState, action: ISetMultiRegionListStateAction) => {
    switch (action.type) {
        case SET_MULTI_REGION_LIST_STATE:
            return action.multiRegionListState;
        case RESET_MULTI_REGION_LIST_STATE:
            return initialState;
        default:
            return state;
    }
};
