import {IFavourite} from "../interfaces/IFavourite";

type ReducedFavouritesDataType = {offers: number[]; properties: number[]};

export const reduceFavouritesToDataType = (favourites: IFavourite[]): ReducedFavouritesDataType => {
    return favourites.reduce<ReducedFavouritesDataType>(
        (acc, favourite) => {
            if (favourite.property) {
                acc.properties.push(favourite.property.rp_id);
                return acc;
            }

            if (favourite.offer) {
                acc.offers.push(favourite.offer.rp_id);
            }

            return acc;
        },
        {offers: [], properties: []}
    );
};
