import {combineReducers} from "redux";

import {reduceRequestState, reduceResponseField, RequestState} from "@pg-mono/request-state";

import {AUTHORS_LIST_PAGE_SIZE, fetchAuthorsListTypes, IAuthorListAuthor} from "../actions/fetch_authors_list";

export interface IAuthorsListStore {
    authors: IAuthorListAuthor[];
    page: number;
    count: number;
    pageSize: number;
    requestState: RequestState;
}

export const authorsListReducer = combineReducers({
    authors: reduceResponseField<IAuthorListAuthor[]>(fetchAuthorsListTypes, "authors", []),
    page: reduceResponseField<number>(fetchAuthorsListTypes, "page", 1),
    count: reduceResponseField<number>(fetchAuthorsListTypes, "count", 0),
    pageSize: reduceResponseField<number>(fetchAuthorsListTypes, "page_size", AUTHORS_LIST_PAGE_SIZE),
    requestState: reduceRequestState(fetchAuthorsListTypes)
});
