import {getCookie} from "@pg-mono/request";

import {isBrowser} from "../../app/read_rp_environment_variables";
import {notifyBugsnag} from "../../errors/bugsnag/notify_bugsnag";
import {syncSessionMarkerCookieName, syncUserSession} from "./sync_user_session";

/**
 * This clientside function fetches Konto Mieszkaniowe cookie when user has no cookies stored on his machine.
 * Unfortunately we can't access and check HttpOnly `usersessionid` cookie directly in client,
 * so we mark the user with additional cookie on SSR and fetch session if it is available.
 *
 * When HTML is returned from cache this request will be the first one to user-sync API and should set the usersessionid cookie for further use.
 */
export const getSyncUserSessionCookieOnClient = () => {
    if (!isBrowser) {
        throw new Error("syncUserSessionCookieOnClient should not be used in server environment");
    }

    if (getCookie(syncSessionMarkerCookieName)) {
        syncUserSession({}, "").catch((error) => {
            notifyBugsnag(error, "syncUserSession: clientside error");
        });
    }
};
