import {IPoi} from "../types/IPoi";
import {PoiType, TransportPoiType} from "./PoiType";

export type IPoiCategorizedStructure = Record<Exclude<PoiType, PoiType.TRANSPORT>, IPoi[]> & Record<PoiType.TRANSPORT, Record<TransportPoiType, IPoi[]>>;
export const createEmptyPoiCategorizedStructure = (): IPoiCategorizedStructure => ({
    [PoiType.TRANSPORT]: {
        [TransportPoiType.BUS]: [],
        [TransportPoiType.RAILWAY]: [],
        [TransportPoiType.SUBWAY]: [],
        [TransportPoiType.TRAM]: []
    },
    [PoiType.HEALTH]: [],
    [PoiType.EDUCATION]: [],
    [PoiType.FOOD]: [],
    [PoiType.SHOPS]: [],
    [PoiType.SPORT]: [],
    [PoiType.ENTERTAINMENT]: [],
    [PoiType.ALL]: [],
    [PoiType.OFFERS]: [],
    [PoiType.USER]: []
});
