import {IAction} from "@pg-mono/request-state";

import {mapUserPreferencesActionTypes} from "../actions/user_map_preferences_actions";

export interface UserMapPreferencesStore {
    showPublicTransportOnMap: boolean | null;
}

const userMapPreferencesStoreDefault: UserMapPreferencesStore = {
    showPublicTransportOnMap: null
};

export interface IUserMapPreferencesAction extends IAction {
    payload: {
        showPublicTransportOnMap: boolean;
    };
}

export const userMapPreferencesReducer = (state: UserMapPreferencesStore = userMapPreferencesStoreDefault, action: IUserMapPreferencesAction) => {
    switch (action.type) {
        case mapUserPreferencesActionTypes.savePublicTransportChoice: {
            return {
                ...state,
                showPublicTransportOnMap: action.payload.showPublicTransportOnMap
            };
        }
        default:
            return state;
    }
};
