import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {isEmpty, omit} from "@pg-mono/nodash";
import {safelyParsePage} from "@pg-mono/string-utils";

import {IRPRequestMeta} from "../../app/rp_request_meta";
import {IPromotionSlugParsed, promotionUrlParser} from "../helpers/promotion_url_parser";
import {fetchPromotionRegionBySlug} from "./fetch_promotion_list_region";
import {fetchPromotionRegionStatisticById} from "./fetch_promotion_region_statistic";

export const parseFriendlyPromotionUrlAtRoute = (ctx: IFetchContext<IRPRequestMeta>) => async (dispatch: Dispatch) => {
    const {friendlySlug} = ctx.match.params;

    const parsedFriendlySlug: Partial<IPromotionSlugParsed> | null = friendlySlug ? promotionUrlParser(friendlySlug) : null;

    const query: Record<string, string | string[] | number> = omit(ctx.route.query, ["page"]);

    const page = safelyParsePage(ctx.route.query.page);
    // Add page to query only when it is defined and its value is greater than `1` - we don't need `&page=1` in URL
    if (page && page > 1) {
        query.page = page;
    }

    // Not valid slug - return query without parsed values from friendlySlug
    if (parsedFriendlySlug === null || isEmpty(parsedFriendlySlug)) {
        return query;
    }

    if (parsedFriendlySlug.type) {
        query.type = parsedFriendlySlug.type;
    }

    if (parsedFriendlySlug.region) {
        const region = await dispatch(fetchPromotionRegionBySlug(ctx, parsedFriendlySlug.region));

        if (region) {
            const regionId = region.id;
            await dispatch(fetchPromotionRegionStatisticById(ctx, regionId));

            query.region = regionId;
        }
    }

    return query;
};
