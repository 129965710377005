import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {appendQueryString} from "@pg-mono/request";
import {enable301ResponseState} from "@pg-mono/response-state";

import {IRPRequestMeta} from "../../../app/rp_request_meta";

export const redirectWithoutFirstPage = (ctx: IFetchContext<IRPRequestMeta>) => async (dispatch: Dispatch) => {
    const {page: strPage, ...restQuery} = ctx.route.query;
    const intPage = parseInt(strPage as string, 10);
    if (!Number.isNaN(intPage) && intPage === 1) {
        dispatch(enable301ResponseState(appendQueryString(ctx.route.pathname, restQuery)));
        return false; // stop data-fetcher
    }
    return true; // continue data-fetcher
};
