export interface IFiltersParam {
    house_type?: number | number[];
    house_additional_areas?: number[];
    house_storeys?: number | number[];
}
export const isSingleHouseFilterSelected = (filters: IFiltersParam) => {
    const houseTypeLength = Array.isArray(filters.house_type) ? filters.house_type?.length || 0 : filters.house_type ? 1 : 0;
    const houseAdditionalAreasLength = filters.house_additional_areas?.length || 0;
    const houseFloorOptionsLength = Array.isArray(filters.house_storeys) ? filters.house_storeys?.length || 0 : filters.house_storeys ? 1 : 0;

    // [houseAdditionalAreasLength > 0, houseAdditionalAreasLength > 1].length: 0 - no filter selected, 1 - one value selected, 2 - more than one value selected
    return [houseTypeLength === 1, houseAdditionalAreasLength > 0, houseAdditionalAreasLength > 1, houseFloorOptionsLength === 1].filter(Boolean).length === 1;
};
