import {OSMElementTypeToPoiElementType} from "../constants/osm_element_type_to_poi_element_type";
import {OSMGeoAssetsPoiTypeCategories} from "../constants/osm_geo_assets_poi_type_categories";
import {OSMGeoAssetsPoiTypeNames} from "../constants/osm_geo_assets_poi_type_names";
import {IOSMGeoAssetsPoi, IOSMGeoAssetsPoiGeneralPoint, isOSMGeoAssetsPoiGeneralPoint} from "../types/IOSMGeoAssetsPoi";
import {IPoi} from "../types/IPoi";
import {createEmptyPoiCategorizedStructure} from "./create_empty_poi_categorized_structure";
import {PoiElementType, PoiType} from "./PoiType";

const handleRecordId = (recordId: IOSMGeoAssetsPoiGeneralPoint["id"]) => {
    const idSplit = recordId.split("_");
    const id = parseInt(idSplit[0]);
    const elementType: PoiElementType = OSMElementTypeToPoiElementType[idSplit[1] as keyof typeof OSMElementTypeToPoiElementType] || PoiElementType.NODE;

    return {id, elementType};
};

const sortPoisByDistance = (poiList: IPoi[]) => {
    return poiList.sort((firstPoi, secondPoi) => {
        return firstPoi.distance - secondPoi.distance;
    });
};

const sortRecursive = <T extends {}>(baseObject: T) => {
    return Object.keys(baseObject).reduce((result, key) => {
        const poiListOrSubStructure = baseObject[key as keyof T];

        result[key as keyof T] = (
            Array.isArray(poiListOrSubStructure) ? sortPoisByDistance(poiListOrSubStructure) : sortRecursive(poiListOrSubStructure as T)
        ) as T[keyof T];

        return result;
    }, {} as T);
};

export const transformOSMGeoAssetsPoi = (geoAssetsPois: IOSMGeoAssetsPoi[]) => {
    const results = createEmptyPoiCategorizedStructure();

    geoAssetsPois.forEach((record) => {
        if (isOSMGeoAssetsPoiGeneralPoint(record)) {
            const {id, elementType} = handleRecordId(record.id);

            const poi: IPoi = {
                distance: record.dist,
                id: id,
                lat: record.center[0],
                lng: record.center[1],
                name: record.name || OSMGeoAssetsPoiTypeNames[record.type],
                tags: {},
                type: elementType
            };

            switch (record.type) {
                case "bus_stop":
                    results.transport.bus.push(poi);
                    break;
                case "subway_stop":
                    results.transport.subway.push(poi);
                    break;
                case "train_stop":
                    results.transport.railway.push(poi);
                    break;
                case "tram_stop":
                    results.transport.tram.push(poi);
                    break;
                default:
                    const recordCategory = OSMGeoAssetsPoiTypeCategories[record.type] as Exclude<PoiType, PoiType.TRANSPORT>;
                    results[recordCategory]?.push(poi);
            }
        }
    });

    return sortRecursive(results);
};
