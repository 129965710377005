import React, {MutableRefObject, ReactNode, useEffect, useRef, useState} from "react";
import {connect, useDispatch} from "react-redux";
import {css, Interpolation, Theme, useTheme} from "@emotion/react";
import styled from "@emotion/styled";
import {bindActionCreators, Dispatch} from "redux";

import {elevation} from "@pg-design/elevation";
import {backgroundColor, borderRadius, calculateRemSize, flex, flexAbsoluteCenter, flexDirection, mb, mv, p, pr, truncate, w100} from "@pg-design/helpers-css";
import {MapMarkerOutlineIcon} from "@pg-design/icons";
import {InputWrapper} from "@pg-design/inputs";
import {Pill} from "@pg-design/pill";
import {form, IFormProps} from "@pg-mono/form";
import {usePrevious} from "@pg-mono/hooks";
import {isEmpty, isEqual} from "@pg-mono/nodash";

import {IRPStore} from "../../../app/rp_reducer";
import {useClickOutside} from "../../../hooks/handle_click_outside";
import {DEFAULT_DISTANCE_ON_BROWSER, SEARCH_DISTANCE_VALUES} from "../../../offer/list/constants/offer_list";
import {useDefaultDistance} from "../../../offer/list/hooks/use_default_distance";
import {BoundAction} from "../../../store/utils/bound_action";
import {resetFetchAllSearchLists, SearchTab, stopFetchAllSearchLists} from "../../actions/fetch_search_all_action";
import {optimizedFetchPlaceList, resetPlaceList} from "../../actions/fetch_search_places_action";
import {clearLabelOfActiveValue, resetActiveDropdownItem, setNextDropdownItem, setPreviousDropdownItem} from "../../actions/set_active_dropdown_item";
import {getItemOnClick, setItemOnEnter} from "../../actions/set_item_on_enter";
import {setTravelTimeData} from "../../actions/set_travel_time";
import {updateCurrentTab} from "../../actions/update_current_tab";
import {ISearchStore} from "../../reducers/search_reducer";
import {addNewRegionToCurrentRegions} from "../../utils/add_new_region_to_current_regions";
import {generatePillLabel} from "../../utils/generate_pill_label";
import {getActiveItemLabel} from "../../utils/get_active_item_label";
import {fadeInAnimation} from "../atoms/atoms";
import {ISearchInputValue} from "../ISearchInputValue";
import {SearchDistanceFilter} from "../SearchDistanceFilter";
import {IDropdownListOwnProps} from "./SearchAutocompleteDropdown";
import {SearchAutocompleteInputWithSlot, SlotOrderType} from "./SearchAutocompleteInputWithSlot";

export interface ISearchAutocompleteFormValues {
    search: ISearchInputValue;
    distance?: number;
}

interface IStateProps {
    search: ISearchStore;
}

interface IActionsProps {
    setNextDropdownItem: typeof setNextDropdownItem;
    setPreviousDropdownItem: typeof setPreviousDropdownItem;
    updateCurrentTab: typeof updateCurrentTab;
    optimizedFetchPlaceList: (searchInput: string) => Promise<Dispatch>;
    resetActiveDropdownItem: typeof resetActiveDropdownItem;
    setItemOnEnter: BoundAction<typeof setItemOnEnter>;
    clearLabelOfActiveValue: typeof clearLabelOfActiveValue;
    stopFetchAllSearchLists: typeof stopFetchAllSearchLists;
    resetFetchAllSearchLists: typeof resetFetchAllSearchLists;
    setTravelTimeData: typeof setTravelTimeData;
}

interface IOwnProps extends IFormProps<ISearchAutocompleteFormValues> {
    autoFocus?: boolean;
    onDropdownStatusChange: (isOpen: boolean) => void;
    onAfterTabSwitch: () => void;
    inputIcon?: JSX.Element;
    inputWrapperCss?: Interpolation<Theme>;
    inputContainerCss?: Interpolation<Theme>;
    iconWrapperCss?: Interpolation<Theme>;
    inputCss?: Interpolation<Theme>;
    regionPillWrapperCss?: Interpolation<Theme>;
    placeholder?: string;
    addedRegionPlaceholder?: (isMultiRegion: boolean) => string;
    disableMultiRegion?: boolean;
    fetchAllSearchLists: (searchInput: string) => Promise<SearchTab>;
    isInModal?: boolean;
    hideArrowIcon?: boolean;
    renderDropdownList: React.FC<IDropdownListOwnProps>;
    onInputMount?: (ref: MutableRefObject<HTMLInputElement | null>) => void;
    includeDistanceFilter?: boolean;
    showSelectedUnderInput?: boolean;
    disableExtraMargins?: boolean;
    onInputClick?: () => void;
    inputDisabled?: boolean;
    slot?: ReactNode;
    slotOrder?: SlotOrderType;
    showArrowOnMobile?: boolean;
    isHomepage?: boolean;
    isTravelTimeTabActive?: boolean;
}

interface IProps extends IOwnProps, IStateProps, IActionsProps {}

const SearchAutocompleteC: React.FC<IProps> = (props) => {
    const theme = useTheme();
    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const [isSearchButtonActive, setIsSearchButtonActive] = useState(false);
    const dispatch = useDispatch();
    const wrapperRef = useRef<HTMLDivElement>(null);
    let latestSearchedLabel: string | null = null;
    const searchProps = props.getFieldProps("search");
    const prevSearchProps = usePrevious(searchProps, searchProps);

    /**
     * Lifecycle
     */

    useEffect(() => {
        const {label} = searchProps.value;
        if (isEmpty(label)) {
            return;
        }

        latestSearchedLabel = label;
        (async () => {
            const searchTab = await props.fetchAllSearchLists(label);
            if (searchTab != null) {
                switchTab(searchTab, true);
            }
        })();
    }, []);

    useEffect(() => {
        const {label: prevLabel, ...prevRestProps} = prevSearchProps.value;
        const {label, ...restProps} = searchProps.value;

        if (isEmpty(label) || isEmpty(places)) {
            setIsSearchButtonActive(false);
        }
        if (isEmpty(label) && props.search.travelTime.isActive) {
            dispatch(resetPlaceList());
        }
        if (isEmpty(label)) {
            return;
        }
        if (prevLabel === label && latestSearchedLabel === label) {
            return;
        }
        if (searchProps.value.tabType === SearchTab.Regions && searchProps.value.regions.length >= 3) {
            return;
        }
        // fetch again only when input value changes or on the first LOAD when we do not have prevRestProps (for PLACES TAB)
        if (isEqual(prevRestProps, restProps) || (isEmpty(prevRestProps) && restProps)) {
            (async () => {
                latestSearchedLabel = label;
                const searchTab = await props.fetchAllSearchLists(label);
                props.clearLabelOfActiveValue();
                if (searchTab != null) {
                    switchTab(searchTab, true);
                }
            })();
        }
    }, [searchProps.value]);

    useEffect(() => {
        if ((isDropdownOpen || props.isInModal) && props.search.currentTab === SearchTab.Places) {
            setIsSearchButtonActive(true);
        }
    }, [isDropdownOpen, props.isInModal]);

    useEffect(() => {
        return () => {
            // all fetches should stop on unmount
            props.stopFetchAllSearchLists();
            // prevent old data to appear after re-visit component
            props.resetFetchAllSearchLists();
        };
    }, []);

    /**
     * Handle blur
     */

    const handleClickOutside = () => {
        if (!props.isInModal) {
            closeDropdown();
            props.resetActiveDropdownItem();
        }
    };
    useClickOutside(wrapperRef, handleClickOutside);

    /**
     * Callbacks - lifecycle
     */

    const switchTab = (tabType: SearchTab, automatedInitialChange = false) => {
        const searchProps = props.getFieldProps("search");

        // update store tab
        props.updateCurrentTab(tabType);
        // this allows to update `distance` according to next selected tab
        if (props.search.currentTab !== tabType || automatedInitialChange) {
            props.onAfterTabSwitch();
        }
        // fetch places data on user interaction change (not automated)
        if (!automatedInitialChange && props.search.currentTab !== tabType && tabType === SearchTab.Places) {
            props.optimizedFetchPlaceList(searchProps.value.label);
        }
    };

    /**
     * Callbacks - state manipulation
     */

    const openDropdown = () => {
        setDropdownOpen(true);
        props.onDropdownStatusChange(true);
    };

    const closeDropdown = () => {
        setDropdownOpen(false);
        props.onDropdownStatusChange(false);
    };

    /**
     * Callbacks - Dropdown
     */

    const onKeyDownHandler = async (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === "Escape") {
            closeDropdown();
            props.resetActiveDropdownItem();
        }
        if (e.key === "ArrowDown") {
            e.preventDefault();
            openDropdown();
            props.setNextDropdownItem();
        }
        if (e.key === "ArrowUp") {
            e.preventDefault();
            openDropdown();
            props.setPreviousDropdownItem();
        }
        if (e.key === "Enter") {
            if (isDropdownOpen) {
                const item = await props.setItemOnEnter(props.disableMultiRegion);
                if (item) {
                    closeDropdown();
                    props.resetActiveDropdownItem();
                    props.onAfterChange?.("search", item as ISearchInputValue);
                }
                // NOTE: we do not close dropdown when Enter did not found item
            } else {
                closeDropdown();
                // we submit on every enter
                props.onSubmit?.();
            }
        }
        setTimeout(() => {
            const searchProps = props.getFieldProps("search");
            props.onAfterChange?.(searchProps.name, searchProps.value);
        }, 0);
    };

    const onLinkClick = async (option: ISearchInputValue) => {
        const searchProps = props.getFieldProps("search");
        if (props.isTravelTimeTabActive) {
            setIsSearchButtonActive(true);
        } else {
            closeDropdown();
        }
        if (option.tabType === SearchTab.Regions) {
            let value = {...option, label: ""};
            if (searchProps.value.tabType === SearchTab.Regions) {
                value = {
                    ...searchProps.value,
                    label: "",
                    regions: props.disableMultiRegion ? option.regions : addNewRegionToCurrentRegions(searchProps.value.regions, option.regions[0])
                };
            }
            props.onChange({search: value});
            setTimeout(() => props.onAfterChange?.(searchProps.name, value), 0);
            return;
        }
        const createdOption = (await getItemOnClick(option)) as ISearchInputValue;
        props.onChange({search: createdOption});
        props.resetActiveDropdownItem();
        if (!props.isTravelTimeTabActive) {
            setTimeout(() => props.onAfterChange?.(searchProps.name, option), 0);
        }
    };

    const onRemoveRegionTagClick = (regionId: number | null) => {
        const searchProps = props.getFieldProps("search");
        if (searchProps.value.tabType === SearchTab.Regions) {
            const filteredRegions = regionId == null ? [] : searchProps.value.regions.filter((region) => region.id !== regionId);
            const value = {...searchProps.value, regions: filteredRegions};
            props.onChange({search: value});
            props.onAfterChange && props.onAfterChange(searchProps.name, value);
            return closeDropdown();
        }
    };

    const onRemoveAllRegions = () => {
        const searchProps = props.getFieldProps("search");
        if (searchProps.value.tabType === SearchTab.Regions) {
            const value = {...searchProps.value, regions: []};
            props.onChange({search: value});
            props.onAfterChange && props.onAfterChange(searchProps.name, value);
            return closeDropdown();
        }
    };

    /**
     * Render
     */

    const {fetchRegionsRequest, fetchPlacesRequest, fetchOffersRequest, fetchVendorsRequest} = props.search;
    const requestStateObj = {fetchRegionsRequest, fetchPlacesRequest, fetchOffersRequest, fetchVendorsRequest};
    const {offers, vendors, regions, places} = props.search;
    const regionPillLabel = generatePillLabel(isDropdownOpen, searchProps.value);
    const isAbroadOrHolidayTab = [SearchTab.Abroad, SearchTab.Holiday].some((tab) => tab === props.search.currentTab);
    const defaultPlaceholder = props.placeholder ?? isAbroadOrHolidayTab ? "Wybierz lokalizację" : "Gdzie chcesz mieszkać?";
    const defaultAddRegionPlaceholder = props.disableMultiRegion ? "Zmień lokalizację" : "Dodaj lokalizację";
    const addRegionPlaceholder = props.addedRegionPlaceholder?.(!props.disableMultiRegion) ?? defaultAddRegionPlaceholder;
    const isRegionSelected = searchProps.value.tabType === SearchTab.Regions && searchProps.value.regions.length >= 1;
    const placeholder = isRegionSelected ? addRegionPlaceholder : defaultPlaceholder;
    const activeItemLabel = getActiveItemLabel(props.search.activeDropdownItem.id, props.search);
    const defaultDistanceValue = useDefaultDistance(props.values?.distance);
    const pillDistanceValue = typeof props.values?.distance === "number" ? props.values.distance : 5;
    const prevDistance =
        typeof props.search.travelTime.prevDistance === "number"
            ? props.search.travelTime.prevDistance
            : props.search.travelTime.prevDistance &&
                !isNaN(parseInt(props.search.travelTime.prevDistance)) &&
                SEARCH_DISTANCE_VALUES.includes(parseInt(props.search.travelTime.prevDistance))
              ? props.search.travelTime.prevDistance
              : DEFAULT_DISTANCE_ON_BROWSER;

    const onTabTypeClick = (newTabType: SearchTab) => {
        props.updateCurrentTab(newTabType);
        const searchProps = props.getFieldProps("search");
        searchProps.onChange(searchProps.name, {...searchProps.value, label: ""});
        props.resetFetchAllSearchLists();
    };

    return (
        <RelativeWrapper onKeyDown={onKeyDownHandler} ref={wrapperRef} css={(props.showSelectedUnderInput || props.disableExtraMargins) && p(0)}>
            {props.isHomepage && (
                <div css={tabsWrapper}>
                    <StyledTab onClick={() => onTabTypeClick(SearchTab.Regions)} isActive={props.search.currentTab === SearchTab.Regions}>
                        W Polsce
                    </StyledTab>
                    <StyledTab onClick={() => onTabTypeClick(SearchTab.Abroad)} isActive={props.search.currentTab === SearchTab.Abroad}>
                        Za granicą
                    </StyledTab>
                    <StyledTab onClick={() => onTabTypeClick(SearchTab.Holiday)} isActive={props.search.currentTab === SearchTab.Holiday}>
                        Wakacyjne
                    </StyledTab>
                </div>
            )}
            <SearchbarContainer disableExtraMargins={props.showSelectedUnderInput || props.disableExtraMargins}>
                <SearchAutocompleteInputWithSlot
                    {...searchProps}
                    autoFocus={props.autoFocus}
                    onAfterChange={() => null}
                    openDropdown={openDropdown}
                    closeDropdown={closeDropdown}
                    activeItemLabel={activeItemLabel}
                    placeholder={placeholder}
                    regionPillLabel={regionPillLabel}
                    isDropdownOpen={isDropdownOpen}
                    inputIcon={props.inputIcon}
                    inputWrapperCss={props.inputWrapperCss}
                    inputContainerCss={props.inputContainerCss}
                    iconWrapperCss={props.iconWrapperCss}
                    inputCss={props.inputCss}
                    regionPillWrapperCss={props.regionPillWrapperCss}
                    hideArrowIcon={props.hideArrowIcon}
                    slot={props.slot}
                    slotOrder={props.slotOrder}
                    onRemoveAllRegions={onRemoveAllRegions}
                    onInputMount={props.onInputMount}
                    isInputWithDistanceFilter={props.includeDistanceFilter}
                    showArrowOnMobile={props.showArrowOnMobile}
                    onInputClick={props.onInputClick}
                    isTravelTimeTabActive={props.isTravelTimeTabActive}
                />
                {props.includeDistanceFilter && (
                    <InputWrapper css={[p(0), distanceWrapperRightStyle]}>
                        <SearchDistanceFilter
                            defaultValue={defaultDistanceValue}
                            values={props.values}
                            onAfterChange={props.onAfterChange}
                            onChange={props.onChange}
                            onSubmit={() => null}
                            setDesktopTypeBlendActive={() => null}
                            onFilterOpen={closeDropdown}
                            isMobileDropdown
                        />
                    </InputWrapper>
                )}
            </SearchbarContainer>
            <SearchDropdown
                isOpen={isDropdownOpen}
                isInModal={props.isInModal}
                data-testid="search-autocomplete-dropdown"
                isInputWithDistanceFilter={props.includeDistanceFilter}
                isHomepage={props.isHomepage}
            >
                {props.renderDropdownList({
                    dropdownIsOpen: isDropdownOpen,
                    search: searchProps.value,
                    offers: offers,
                    requestStateObj: requestStateObj,
                    vendors: vendors,
                    activeItem: props.search.activeDropdownItem,
                    places: places,
                    regions: regions,
                    selectedTab: props.search.currentTab,
                    onLinkClick: onLinkClick,
                    removeRegionTag: onRemoveRegionTagClick,
                    onClose: () => {
                        closeDropdown();
                        setIsSearchButtonActive(false);
                        props.resetActiveDropdownItem();
                    },
                    getFieldProps: props.getFieldProps,
                    setTravelTimeData: props.setTravelTimeData,
                    prevDistance: prevDistance,
                    isSearchButtonActive: isSearchButtonActive
                })}
            </SearchDropdown>
            {props.showSelectedUnderInput &&
                props.values?.search &&
                "regions" in props.values.search &&
                !!props.values.search?.regions?.length &&
                !props.isTravelTimeTabActive && (
                    <div css={regionsHolderStyle}>
                        {props.values.search?.regions.map((region) => (
                            <Pill key={region.id} size="large" css={regionPillStyle} onCloseClick={() => onRemoveRegionTagClick(region.id)}>
                                {!props.includeDistanceFilter && <MapMarkerOutlineIcon fill={theme.colors.secondary} size="2.4" css={pr(1)} />}
                                <div css={[titleHolderStyle, truncate]}>
                                    {region.full_name_reverted}
                                    {props.includeDistanceFilter && !!pillDistanceValue && ` (+${pillDistanceValue}km)`}
                                </div>
                            </Pill>
                        ))}
                    </div>
                )}
        </RelativeWrapper>
    );
};

export const SearchAutocomplete = connect(mapStateToProps, mapActionsToProps)(form<ISearchAutocompleteFormValues, IProps>(SearchAutocompleteC));

function mapStateToProps(state: IRPStore): IStateProps {
    return {
        search: state.search
    };
}

function mapActionsToProps(dispatch: Dispatch): IActionsProps {
    return {
        ...bindActionCreators(
            {
                resetActiveDropdownItem,
                setNextDropdownItem,
                setPreviousDropdownItem,
                stopFetchAllSearchLists,
                resetFetchAllSearchLists,
                updateCurrentTab,
                optimizedFetchPlaceList,
                setItemOnEnter,
                clearLabelOfActiveValue,
                setTravelTimeData
            },
            dispatch
        )
    };
}

interface IThemeProps {}

export const RelativeWrapper = styled.div<IThemeProps>`
    position: relative;
    flex-grow: 1;
    flex-shrink: 1;
    height: calc(100% - 6rem);
    padding: 2rem;
    display: flex;
    flex-direction: column;

    @media (min-width: ${(props) => props.theme.breakpoints.md}) {
        padding: 0;
        max-height: 100%;
        width: 100%;
    }
`;

interface ISearchDropdown {
    isOpen: boolean;
    isInModal?: boolean;
    isInputWithDistanceFilter?: boolean;
    isHomepage?: boolean;
}

export const SearchDropdown = styled.div<ISearchDropdown>`
    width: 100%;
    max-height: calc(100vh - 17rem);
    display: ${(props) => (props.isOpen || props.isInModal ? "flex" : "none")};
    flex-direction: column;
    ${fadeInAnimation("0.2s")};

    @media (min-width: ${(props) => props.theme.breakpoints.md}) {
        ${elevation(4)};
    }

    ${(props) =>
        !props.isInModal &&
        css`
            position: absolute;
            padding: 2rem;
            margin: 0;

            z-index: 1001;
            top: ${props.isHomepage ? "11rem" : "6rem"};
            left: ${props.isInputWithDistanceFilter ? "120px" : 0};
            width: calc(100% - ${props.isInputWithDistanceFilter ? "120px" : "0px"});
            max-height: 64.8rem;
            ${borderRadius(2)}
            background: #fff;

            @media (max-width: ${props.theme.breakpoints.xs}) {
                left: 0;
                width: 100%;
            }
        `};
`;

const distanceWrapperRightStyle = css`
    max-width: 120px;
    width: 30%;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left-width: 0;
    height: 50px;
`;

export const SearchbarContainer = styled.div<{disableExtraMargins?: boolean}>`
    display: flex;
    align-items: center;
    width: 100%;
    ${({disableExtraMargins}) => disableExtraMargins && mb(0.5)};
    max-height: ${calculateRemSize(6)};

    > div:nth-of-type(2) {
        margin-bottom: 0;
        width: 100%;
        z-index: initial;
    }

    @media (max-width: ${(props) => props.theme.breakpoints.md}) {
        ${({disableExtraMargins}) => (disableExtraMargins ? mb(0.5) : mb(3))};
    }
`;

const StyledTab = styled.div<{isActive: boolean}>`
    ${w100};
    ${borderRadius(0)};
    background-color: ${(props) => props.theme.colors.gray[props.isActive ? 300 : 200]};
    ${flexAbsoluteCenter};
    height: ${calculateRemSize(6)};
    cursor: pointer;

    :hover {
        background-color: ${(props) => props.theme.colors.gray[300]};
    }
`;

const tabsWrapper = (theme: Theme) => css`
    ${flex("center")};
    ${w100};
    ${borderRadius(2, 2, 0, 0)};
    ${backgroundColor(theme.colors.gray[200])};
    overflow: hidden;
    max-height: ${calculateRemSize(6)};
`;

const regionPillStyle = css`
    ${p(0, 1)};
    height: 4.8rem;
    ${flex("center")};
    ${flexDirection("row")};
`;

const regionsHolderStyle = () => css`
    ${mv(2)};
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    gap: ${calculateRemSize(1)};

    div:last-of-type {
        margin-right: 0;
    }
`;

const titleHolderStyle = css`
    ${pr(1)};
    flex-grow: 1;
`;
