import {apiLink} from "@pg-mono/rp-api-routes";

import {rpApi} from "../../app/api/rp_api";
import {ICampaignDetails} from "../types/ICampaignDetails";
import {ICampaignQuery} from "../types/ICampaignQuery";

interface ICampaignDetailsPayload extends ICampaignQuery {
    campaignUuid: string;
}

export const getCampaignDetails = rpApi.injectEndpoints({
    endpoints: (build) => ({
        getCampaignDetails: build.query<ICampaignDetails, ICampaignDetailsPayload>({
            query: ({campaignUuid, ...params}) => ({
                url: apiLink.opendays.campaign.detail({})({campaignUuid}),
                params
            })
        })
    })
});

export const {useGetCampaignDetailsQuery} = getCampaignDetails;
