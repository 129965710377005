import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {enable301ResponseState} from "@pg-mono/response-state";

import {IRPRequestMeta} from "../../../app/rp_request_meta";
import {createOfferLink} from "../../helpers/create_offer_link";

export const redirectToOfferDetail = (ctx: IFetchContext<IRPRequestMeta>) => async (dispatch: Dispatch) => {
    const {vendorSlug, offerSlug, offerId} = ctx.match.params || {};
    const link = createOfferLink({
        id: offerId,
        slug: offerSlug,
        vendor: {slug: vendorSlug},
        groups: null
    });
    dispatch(enable301ResponseState(link));
    return false;
};
