import {IFetchContext} from "@pg-mono/data-fetcher";
import {rpAppLink} from "@pg-mono/rp-routes";
import {pluralize, safelyParsePage} from "@pg-mono/string-utils";

import {BRAND_NAME} from "../../../app/constants/company";
import {IRPStore} from "../../../app/rp_reducer";
import {IRPRequestMeta} from "../../../app/rp_request_meta";
import {concatWithBrandName} from "../../../app/utils/concat_with_brand_name";
import {MetaDataDispatch, updateMetaData} from "../../../meta_data/actions/update_meta_data";
import {generateMetaPageNumber} from "../../../meta_data/utils/generate_meta_page_number";
import {IInvestmentOfferStats} from "../../actions/fetch_investment_offer_stats";
import {InvestmentOfferTypeSlug} from "../../constants/investment_offer_constants";
import {prepareSubCategoryViewMetaData} from "../../list/actions/update_investment_offer_list_meta_data";

const offersPlural = pluralize(["oferta", "oferty", "ofert"]);
const prepareCategoryViewMetadata = (
    categories: {category: string | null},
    stats: IInvestmentOfferStats
): {title: string; description: string; pathname?: string; robots?: string} => {
    switch (categories.category) {
        case InvestmentOfferTypeSlug.CONDOHOTEL:
            return {
                title: concatWithBrandName("Condohotele mapa - zysk 5-8% w skali roku. Zainwestuj już dziś! -"),
                description:
                    "Oferty condohoteli na mapie, w które możesz zainwestować już dziś. Gwarancja zwrotu bez konieczności zarządzania inwestycja. Sprawdź ile możesz zyskać 💰"
            };
        case InvestmentOfferTypeSlug.HOLIDAY:
            return {
                title: concatWithBrandName("Apartamenty wakacyjne mapa - zarabiaj na nieruchomościach w kurortach -"),
                description: `${stats.totals.is_holiday_location} ${offersPlural(
                    stats.totals.is_holiday_location
                )} apartamentów wakacyjnych na mapie. Porównaj oferty i wybierz nieruchomość w którą warto zainwestować. Zarabiaj nawet 8% 💰`
            };
        case InvestmentOfferTypeSlug.INVESTMENT:
            return {
                title: concatWithBrandName("Apartamenty inwestycyjne mapa - zarabiaj na nieruchomościach w całej Polsce -"),
                description: `${stats.totals.is_investment_apartment} ${offersPlural(
                    stats.totals.is_investment_apartment
                )} apartamentów inwestycyjnych na mapie. Porównaj oferty i wybierz nieruchomość w którą warto zainwestować. Zarabiaj nawet 8% 💰`
            };
        case InvestmentOfferTypeSlug.FOR_RENT:
            return {
                title: concatWithBrandName("Oferty pod wynajem mapa - zarabiaj na nieruchomościach w całej Polsce -"),
                description: `${stats.totals.is_dedicated_for_rent} ${offersPlural(
                    stats.totals.is_dedicated_for_rent
                )} pod wynajem na mapie. Porównaj oferty i wybierz nieruchomość w którą warto zainwestować. Zarabiaj nawet 8% 💰`,
                pathname: rpAppLink.investmentOffer.investmentCategory.base({category: InvestmentOfferTypeSlug.INVESTMENT})
            };
        default:
            return {
                title: concatWithBrandName("Oferty nieruchomości idealnych pod inwestycje -"),
                description:
                    "Chcesz zainwestować swoje pieniądze? &amp;#9989; Szukasz mieszkań, które idealnie nadają się pod inwestycje? &amp;#9989; Sprawdź ile możesz zarobić na nieruchomości inwestycyjnej!"
            };
    }
};

export const updateInvestmentOfferListMapMetaDataAtRoute =
    (ctx: IFetchContext<IRPRequestMeta>) => async (dispatch: MetaDataDispatch, getState: () => IRPStore) => {
        const {offers} = getState().investmentOffer.list;
        if (!offers) {
            return false;
        }
        const {count: offerCount} = offers;
        const {categories, stats} = getState().investmentOffer;

        const parsedPage = safelyParsePage(ctx.route.query.page as string);
        const query = ctx.route.query;

        if (categories && categories.category && !categories.subcategory) {
            const categoryViewMetaData = prepareCategoryViewMetadata(categories, stats);
            const robots = query && query.sort ? "noindex, follow" : categoryViewMetaData.robots;
            const title = `${categoryViewMetaData.title + generateMetaPageNumber(parsedPage)}`;
            dispatch(
                updateMetaData({
                    title,
                    description: categoryViewMetaData.description + generateMetaPageNumber(parsedPage),
                    pathname: categoryViewMetaData.pathname ? categoryViewMetaData.pathname : ctx.route.pathname,
                    pageNumber: parsedPage,
                    robots
                })
            );
        }

        if (categories && categories.category && categories.subcategory) {
            const subcategoryViewMetaData = prepareSubCategoryViewMetaData(categories, offerCount, true);
            const robots = query && query.sort ? "noindex, follow" : subcategoryViewMetaData.robots;
            const title = `${subcategoryViewMetaData.title.replace(BRAND_NAME, concatWithBrandName("mapa -")) + generateMetaPageNumber(parsedPage)}`;
            dispatch(
                updateMetaData({
                    title,
                    description: subcategoryViewMetaData.description + generateMetaPageNumber(parsedPage),
                    pathname: ctx.route.pathname,
                    pageNumber: parsedPage,
                    robots
                })
            );
        }

        return Promise.resolve();
    };
