import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {isEmpty} from "@pg-mono/nodash";
import {appendQueryString, catch404, getRequest} from "@pg-mono/request";
import {createRequestActionTypes} from "@pg-mono/request-state";
import {apiV2Link, apiV2ListLink, Scenario} from "@pg-mono/rp-api-routes";

import {IRPRequestMeta} from "../../app/rp_request_meta";

const FETCH_PROMOTION_REGION = "promotion_list/FETCH_REGION";
export const fetchPromotionListRegionTypes = createRequestActionTypes(FETCH_PROMOTION_REGION);

export interface IPromotionRegion {
    id: number;
    name: string;
    name_declension_what: string;
    name_declension_where: string;
    parent: {name: string; slug: string} | null;
    short_name: string;
    slug: string;
    type: number;
}

interface IRegionListResponse {
    results: IPromotionRegion[];
}

export const fetchPromotionRegionBySlug =
    (ctx: IFetchContext<IRPRequestMeta>, slug: string | number) =>
    (dispatch: Dispatch): Promise<IPromotionRegion | null> => {
        const regionListApiLink = apiV2ListLink.region.list(Scenario.SEARCH_REGION_DETAIL);
        const url = appendQueryString(regionListApiLink, {slug});

        dispatch({type: fetchPromotionListRegionTypes.start});
        return getRequest(ctx.meta, url).then((response: IRegionListResponse) => {
            const regions = response.results;

            if (!isEmpty(regions)) {
                dispatch({type: fetchPromotionListRegionTypes.success, result: regions[0]});
                return regions[0];
            } else {
                dispatch({type: fetchPromotionListRegionTypes.success, result: []});
                return null;
            }
        });
    };

export const fetchPromotionRegionById =
    (
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        {meta, route}: IFetchContext<IRPRequestMeta>,
        regionId: number
    ) =>
    async (dispatch: Dispatch): Promise<IPromotionRegion | void> => {
        const url = apiV2Link.region.detail(Scenario.REGION_DETAIL, {regionId});

        dispatch({type: fetchPromotionListRegionTypes.start});
        return getRequest(meta, url)
            .then((response: IPromotionRegion) => {
                dispatch({type: fetchPromotionListRegionTypes.success, result: response || null});
                return response;
            })
            .catch(
                catch404(async (error) => {
                    dispatch({type: fetchPromotionListRegionTypes.error, error});
                })
            );
    };

export const updatePromotionRegionList = (region: IPromotionRegion | null) => {
    return {type: fetchPromotionListRegionTypes.success, result: region};
};
