import {combineReducers} from "redux";

import {reduceResponse} from "@pg-mono/request-state";

import {
    fetchSiteMapRegionAggregationListActionTypes,
    fetchSiteMapRegionCityListActionTypes,
    fetchSiteMapRegionCountyListActionTypes,
    fetchSiteMapRegionVoivodeshipListActionTypes,
    ISitemapRegionListRegion,
    ISitemapRegionListRegionShort
} from "../actions/fetch_site_map_region";

export interface ISitemapRegionsStore {
    voivodeship: ISitemapRegionListRegion[] | ISitemapRegionListRegionShort[];
    aggregation: ISitemapRegionListRegion[];
    county: ISitemapRegionListRegion[];
    city: ISitemapRegionListRegion[];
}

export const siteMapRegionsReducer = combineReducers({
    voivodeship: reduceResponse(fetchSiteMapRegionVoivodeshipListActionTypes),
    aggregation: reduceResponse(fetchSiteMapRegionAggregationListActionTypes),
    county: reduceResponse(fetchSiteMapRegionCountyListActionTypes),
    city: reduceResponse(fetchSiteMapRegionCityListActionTypes)
});
