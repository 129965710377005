import {combineReducers} from "redux";

import {reduceResponseField} from "@pg-mono/request-state";

import {fetchNearbyRegionsVendorsTypes, INearbyRegionVendors} from "../actions/fetch_nearby_regions_vendors";
import {fetchVendorChildrenRegionsVendorsTypes, IVendorsChildrenRegion} from "../actions/fetch_vendor_children_regions_vendors";

export interface INearbyRegionVendorsStore {
    nearbyRegions: INearbyRegionVendors[];
    childrenRegions: IVendorsChildrenRegion[];
}

export const vendorNearbyVendorsReducer = combineReducers({
    nearbyRegions: reduceResponseField(fetchNearbyRegionsVendorsTypes, "results", []),
    childrenRegions: reduceResponseField(fetchVendorChildrenRegionsVendorsTypes, "results", [])
});
