import {SET_VIEW_TYPE} from "./constants/set_view_type";
import {ISetViewTypeAction, IViewTypeMetaData} from "./view_type_actions";
import {ViewType} from "./ViewType";

export interface IViewTypeStore {
    current: ViewType | null;
    currentData: Partial<IViewTypeMetaData>;
    previous: ViewType | null;
}

const initialState: IViewTypeStore = {
    current: null,
    currentData: {},
    previous: null
};

export const viewTypeReducer = (state: IViewTypeStore = initialState, action: ISetViewTypeAction): IViewTypeStore => {
    switch (action.type) {
        case SET_VIEW_TYPE:
            return {
                current: action.current,
                currentData: action.data,
                previous: state.current
            };
        default:
            return state;
    }
};
