import {combineReducers} from "redux";

import {reduceFormValues} from "@pg-mono/form";
import {IAppError, reduceLatestQuery, reduceRequestErrors, reduceRequestState, reduceResponse, reduceResponseField, RequestState} from "@pg-mono/request-state";

import {fromJsonValues} from "../../form/translate/from_json_values";
import {fetchPromotionListTypes} from "../actions/fetch_promotion_list";
import {fetchPromotionListRegionTypes, IPromotionRegion} from "../actions/fetch_promotion_list_region";
import {fetchPromotionRegionStatisticTypes, IPromotionRegionStatistic} from "../actions/fetch_promotion_region_statistic";
import {promotionListFilterFormActionTypes} from "../actions/promotion_list_filter_fields_actions";
import {IPromotionListPromotion} from "../interfaces/IPromotionListResponse";
import {IPromotionListFilterFields, promotionListFilterFields} from "../list/components/filters/PromotionListFilters";

export interface IPromotionListListStore {
    promotions: IPromotionListPromotion[];
    region: IPromotionRegion | null;
    statistic: IPromotionRegionStatistic[] | null;
    count: number;
    page: number;
    page_size: number;
    requestState: RequestState;
    latestQuery: Record<string, string | string[]>;
    error: IAppError;
    form: {
        formValues: IPromotionListFilterFields;
    };
}

export const promotionListListReducer = combineReducers({
    promotions: reduceResponseField<IPromotionListPromotion[]>(fetchPromotionListTypes, "promotions", []),
    region: reduceResponse<IPromotionRegion>(fetchPromotionListRegionTypes),
    statistic: reduceResponse<IPromotionRegionStatistic>(fetchPromotionRegionStatisticTypes),
    count: reduceResponseField<number>(fetchPromotionListTypes, "count", 0),
    page: reduceResponseField<number>(fetchPromotionListTypes, "page", 0),
    page_size: reduceResponseField<number>(fetchPromotionListTypes, "page_size", 0),
    requestState: reduceRequestState(fetchPromotionListTypes),
    latestQuery: reduceLatestQuery(fetchPromotionListTypes),
    error: reduceRequestErrors(fetchPromotionListTypes),
    form: combineReducers({
        formValues: reduceFormValues(promotionListFilterFormActionTypes, fromJsonValues(promotionListFilterFields, {}))
    })
});
