import {friendlyQueryToHouseFilters} from "../../constants/house_filter";
import {floorFilterFriendlyQueryValues} from "../../list/constants/floor_filter_friendly_query_values";
import {getFloorChoiceByOfferListSubType} from "../../list/helpers/sub_type/transform/get_floor_choice_by_offer_list_sub_type";
import {ISlugParsed} from "./offer_url_parser";

export function getOfferListSubFilterParsed(offerListSubFilter?: string): Partial<ISlugParsed> {
    const floorChoice =
        offerListSubFilter && floorFilterFriendlyQueryValues.includes(offerListSubFilter) ? getFloorChoiceByOfferListSubType(offerListSubFilter) : null;

    if (floorChoice) {
        return {
            floor_choices: floorChoice
        };
    }

    const houseFilter = offerListSubFilter && friendlyQueryToHouseFilters[offerListSubFilter];
    if (houseFilter) {
        return houseFilter;
    }

    return {};
}
