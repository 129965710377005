import {combineReducers} from "redux";

import {reduceLatestQuery} from "@pg-mono/request-state";

import {fetchSelectedRegionDataTypes, ISelectedRegion} from "../../list/actions/offer_list_selected_data_actions";
import {IMapLayersStore, mapLayersSlice} from "../state/map_layers_slice";
import {appliedOffersReducer, IAppliedOffersStore} from "./applied_offers_reducer";
import {IMapSelectedOfferStore, mapSelectedOfferReducer} from "./map_offer_detail_reducer";
import {IOfferListMapListStore, offerListMapListReducer} from "./map_offer_list_reducer";
import {IMapRegionStore, mapRegionReducer} from "./map_region_reducer";
import {IMapSelectedPropertyStore, mapSelectedPropertyReducer} from "./map_selected_property_reducer";
import {offerListSelectedDataReducer} from "./offer_list_selected_data_reducer";

export interface IOfferListMapStore {
    list: IOfferListMapListStore;
    selectedOffer: IMapSelectedOfferStore;
    selectedProperty: IMapSelectedPropertyStore;
    region: IMapRegionStore;
    selectedRegion: ISelectedRegion[];
    appliedOffers: IAppliedOffersStore;
    layers: IMapLayersStore;
}

export const offerListMapReducer = combineReducers({
    list: offerListMapListReducer,
    selectedOffer: mapSelectedOfferReducer,
    selectedProperty: mapSelectedPropertyReducer,
    appliedOffers: appliedOffersReducer,
    region: mapRegionReducer,
    selectedRegion: offerListSelectedDataReducer(),
    selectedRegionLatestQuery: reduceLatestQuery(fetchSelectedRegionDataTypes),
    [mapLayersSlice.name]: mapLayersSlice.reducer
});
