import {IFetchContext} from "@pg-mono/data-fetcher";
import {safelyParsePage} from "@pg-mono/string-utils";

import {IRPStore} from "../../app/rp_reducer";
import {IRPRequestMeta} from "../../app/rp_request_meta";
import {MetaDataDispatch, updateMetaData} from "../../meta_data/actions/update_meta_data";
import {descriptionPromotionList} from "../../meta_data/page_description/promotion_list";
import {titlePromotionList} from "../../meta_data/page_title/promotion_list";
import {getPromotionMetaDataTypeName} from "../helpers/PromotionType";

export const updatePromotionListMetaDataAtRoute =
    ({route}: IFetchContext<IRPRequestMeta>) =>
    async (dispatch: MetaDataDispatch, getState: () => IRPStore) => {
        const count = getState().promotionList.count;
        const page = parseInt(route.query?.page as string);
        const title = titlePromotionList({page});
        const description = descriptionPromotionList({count, page});
        const robots = !!count ? "index, follow" : "noindex, follow";
        const parsedPage = safelyParsePage(route.query.page);

        dispatch(
            updateMetaData({
                title,
                description,
                pathname: route.pathname,
                robots,
                pageNumber: parsedPage
            })
        );

        return true;
    };

export const updateFriendlyPromotionListMetaDataAtRoute =
    ({route}: IFetchContext<IRPRequestMeta>) =>
    async (dispatch: MetaDataDispatch, getState: () => IRPStore) => {
        const {count, region, latestQuery} = getState().promotionList;

        const type = latestQuery?.type ? getPromotionMetaDataTypeName(parseInt(latestQuery.type as string))?.toLocaleLowerCase() : "";
        const page = parseInt(route.query?.page as string);
        const title = titlePromotionList({type, region: region?.short_name, page});
        const description = descriptionPromotionList({count, region: region?.name_declension_where, type, page});
        const robots = !!count ? "index, follow" : "noindex, follow";
        const parsedPage = safelyParsePage(route.query?.page);

        dispatch(
            updateMetaData({
                title,
                description,
                pathname: route.pathname,
                robots,
                pageNumber: parsedPage
            })
        );

        return true;
    };
