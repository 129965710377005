import {Dispatch} from "redux";

import {reduceFavouritesToDataType} from "@pg-mono/favourites";

import {notifyBugsnag} from "../../errors/bugsnag/notify_bugsnag";
import {fetchFavourites} from "../api/fetch_favourites";
import {getServerFavourite, updateFavourites} from "../api/update_favourites";
import {favouriteTypes} from "./manage_favourites";
import {getLocalStorageFavourites, localStorageFavouritesService} from "./manage_local_storage_favourites";

const isErrorResponse = (error: unknown): error is {response: {status?: unknown}} => {
    return Object.prototype.hasOwnProperty.call(error, "response");
};

// Load favourites data for authenticated user
export const loadFavouritesToStore = () => async (dispatch: Dispatch) => {
    const localStorageFavourites = getLocalStorageFavourites();

    if (!localStorageFavourites.length) {
        // If local storage favourites doesn't exist - fetch and set favourites from API
        const favourites = await fetchFavourites({hide_deleted: true});

        dispatch({type: favouriteTypes.set, list: favourites, ...reduceFavouritesToDataType(favourites)});

        return;
    }

    // Update user server favourites state based on the data saved in local storage
    try {
        await updateFavourites(localStorageFavourites.map((favourite) => getServerFavourite(favourite)));
    } catch (error) {
        notifyBugsnag(
            {message: `loadFavouritesToStore: ${isErrorResponse(error) ? error.response?.status : ""}`, filename: "load_favourites_to_store.ts", error},
            "API update favourites error during load favourites to the store"
        );

        return;
    }

    const favourites = await fetchFavourites({hide_deleted: true});

    dispatch({type: favouriteTypes.set, list: favourites, ...reduceFavouritesToDataType(favourites)});

    // Finally, clear the data saved in the local storage - we don't need this data anymore
    localStorageFavouritesService.clearLocalStorageFavourites();
};
