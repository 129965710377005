import {isEmpty} from "@pg-mono/nodash";
import {RequestState} from "@pg-mono/request-state";

import {SearchTab} from "../actions/fetch_search_all_action";
import {ISearchStore} from "../reducers/search_reducer";
import {SearchInfoWindowCitiesList} from "./search_info_window_cities_list";

export const getActiveItemLabel = (id: number | null, search: ISearchStore): string | undefined => {
    if (id == null) {
        return;
    }

    const {currentTab, offers, regions, places, vendors, fetchOffersRequest, fetchRegionsRequest, fetchPlacesRequest, fetchVendorsRequest} = search;

    const inputValue = search.formValues.search.label;
    if (isEmpty(inputValue)) {
        if (id < SearchInfoWindowCitiesList.length) {
            const currentCity = SearchInfoWindowCitiesList[id];
            return currentCity.label;
        }
        return;
    }
    if (currentTab === SearchTab.Offers) {
        if (fetchOffersRequest === RequestState.Success) {
            return offers[id] && offers[id].name;
        }
        return;
    }
    if (currentTab === SearchTab.Regions) {
        if (fetchRegionsRequest === RequestState.Success) {
            return regions[id] && regions[id].full_name;
        }
        return;
    }
    if (currentTab === SearchTab.Places) {
        if (fetchPlacesRequest === RequestState.Success) {
            return places[id] && places[id].description;
        }
        return;
    }
    if (currentTab === SearchTab.Vendors) {
        if (fetchVendorsRequest === RequestState.Success) {
            return vendors[id] && vendors[id].name;
        }
    }
    return;
};
