import React from "react";

import {DropdownListElement} from "./DropdownListElement";
import {INavRightElement} from "./Navigation";
import {NavigationElement} from "./NavigationElement";

interface IProps {
    navElements: INavRightElement[];
    setBlendVisible: (isVisible: boolean) => void;
    arrows: {
        left: JSX.Element;
        right: JSX.Element;
    };
    setNavState: (update: (prev: number[]) => number[]) => void;
    navState: number[];
    isRightElement?: boolean;
    isMobile?: boolean;
    mobileBreakpoint?: number;
}

export const NavigationElementWithDropdown = (props: IProps) => {
    return (
        <>
            {props.navElements.map((element, itemIndex) => {
                if (element.subElements) {
                    return (
                        <DropdownListElement
                            setNavState={props.setNavState}
                            navState={props.navState}
                            dropdownElements={element.subElements}
                            key={element.title}
                            title={element.title}
                            url={element.url}
                            itemIndex={itemIndex}
                            arrow={props.arrows.right}
                            setBlendVisible={props.setBlendVisible}
                            icon={element.icon}
                            isRightElement={props.isRightElement}
                            mobileBreakpoint={props.mobileBreakpoint}
                            style={element.style}
                        />
                    );
                }

                return (
                    <NavigationElement
                        title={element.title}
                        key={itemIndex}
                        icon={element.icon}
                        url={element.url}
                        onClick={element.onClick}
                        rel={element.rel}
                        count={element.count}
                        target={element.target}
                        isMobile={props.isMobile}
                        style={element.style}
                        mobileBreakpoint={props.mobileBreakpoint}
                        hideOnMobile={element.hideOnMobile}
                    />
                );
            })}
        </>
    );
};
