import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {appendQueryString, getRequest} from "@pg-mono/request";
import {createRequestActionTypes, RequestActionTypes} from "@pg-mono/request-state";
import {apiV2ListLink, Scenario} from "@pg-mono/rp-api-routes";

import {IRPRequestMeta} from "../../../app/rp_request_meta";

const FETCH_OFFER_DETAIL_NEARBY_REGIONS_PREFIX = "offer_detail/FETCH_OFFER_DETAIL_NEARBY_REGIONS";
export const fetchOfferDetailNearbyRegionsTypes = createRequestActionTypes(FETCH_OFFER_DETAIL_NEARBY_REGIONS_PREFIX);

const FETCH_PROPERTY_DETAIL_NEARBY_REGIONS_PREFIX = "property_detail/FETCH_PROPERTY_DETAIL_NEARBY_REGIONS";
export const fetchPropertyDetailNearbyRegionsTypes = createRequestActionTypes(FETCH_PROPERTY_DETAIL_NEARBY_REGIONS_PREFIX);

interface IOfferDetailNearbyRegionsResponse {
    results: {
        regions: INearbyRegion[];
    };
}

export interface INearbyRegion {
    id: number;
    name: string;
    slug: string;
    stats: {
        properties_all_count_for_sale: number;
        properties_flats_count_for_sale: number;
        properties_houses_count_for_sale: number;
        properties_commercials_count_for_sale: number;
    } | null;
}

const nearbyRegionsListApiLink = apiV2ListLink.region.list(Scenario.REGION_LIST);
const fetchOfferNearbyRegions = (actionType: RequestActionTypes) => (meta: IRPRequestMeta, query: Record<string, number | string>) => (dispatch: Dispatch) => {
    dispatch({type: actionType.start});

    const url = appendQueryString(nearbyRegionsListApiLink, query);

    return getRequest(meta, url).then((res: IOfferDetailNearbyRegionsResponse) => {
        dispatch({type: actionType.success, result: res.results});
        return res;
    });
};

export const fetchOfferDetailNearbyRegionsAtRoute = (ctx: IFetchContext<IRPRequestMeta>) =>
    fetchOfferNearbyRegions(fetchOfferDetailNearbyRegionsTypes)(ctx.meta, {
        nearby_regions: ctx.prevResult.offer.region.id
    });

export const fetchPropertyDetailNearbyRegionsAtRoute = (ctx: IFetchContext<IRPRequestMeta>) =>
    fetchOfferNearbyRegions(fetchPropertyDetailNearbyRegionsTypes)(ctx.meta, {
        nearby_regions: ctx.prevResult.offer.region.id
    });
