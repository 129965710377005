import {IFetchContext} from "@pg-mono/data-fetcher";

import {IRPStore} from "../../../app/rp_reducer";
import {IRPRequestMeta} from "../../../app/rp_request_meta";
import {concatWithBrandName} from "../../../app/utils/concat_with_brand_name";
import {MetaDataDispatch, updateMetaData} from "../../../meta_data/actions/update_meta_data";

export const updateSiteMapArticleListMetaData = (ctx: IFetchContext<IRPRequestMeta>) => (dispatch: MetaDataDispatch, getState: () => IRPStore) => {
    const categorySlug = ctx.match.params?.categorySlug;
    const categoryObj = getState().siteMap.articleCategories.find((category) => category.slug === categorySlug);

    const title = `Mapa strony - Artykuły - ${concatWithBrandName(categoryObj ? ` kategoria ${categoryObj.name} - ` : "")}`;
    const description = concatWithBrandName(
        `Mapa strony - Artykuły - wszystkie artykuły z kategorii ${categoryObj ? categoryObj.name : ""} w Strefie Wiedzy portalu`
    );
    dispatch(updateMetaData({title, description, pathname: ctx.route.pathname}));

    return Promise.resolve();
};
