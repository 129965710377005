import {combineReducers} from "redux";

import {reduceResponse} from "@pg-mono/request-state";

import {fetchMarketplaceArchitectsType, IMarketplaceArchitect} from "../actions/fetch_marketplace_architects";
import {fetchMarketplaceRegionsType, IMarketplaceRegion} from "../actions/fetch_marketplace_regions";

export interface IMarketplaceStore {
    regions: IMarketplaceRegion[];
    architectList: IMarketplaceArchitect[];
}

export const marketplaceReducer = combineReducers({
    regions: reduceResponse<IMarketplaceRegion[]>(fetchMarketplaceRegionsType, []),
    architectList: reduceResponse<IMarketplaceRegion[]>(fetchMarketplaceArchitectsType, [])
});
