import {IFetchContext} from "@pg-mono/data-fetcher";
import {safelyParsePage} from "@pg-mono/string-utils";

import {BRAND_NAME} from "../../app/constants/company";
import {IRPStore} from "../../app/rp_reducer";
import {IRPRequestMeta} from "../../app/rp_request_meta";
import {MetaDataDispatch, updateMetaData} from "../../meta_data/actions/update_meta_data";

export const updateAuthorDetailMetaData = (ctx: IFetchContext<IRPRequestMeta>) => (dispatch: MetaDataDispatch, getState: () => IRPStore) => {
    const authorName = getState().article.authorDetail.author.name;
    const apiMetaTitleOverride = getState().article.authorDetail.author.meta_title;
    const apiMetaDescriptionOverride = getState().article.authorDetail.author.meta_description;
    const {page} = ctx.route.query;
    const pageNumber = safelyParsePage(page);
    const title = apiMetaTitleOverride ?? `${authorName} - autor ${BRAND_NAME} ${pageNumber > 1 ? `- Strona ${pageNumber}` : ""}`;
    const description =
        apiMetaDescriptionOverride ??
        `${authorName} - twórca artykułów na portalu ${BRAND_NAME} ${pageNumber > 1 ? `- Strona ${pageNumber}` : ""}. Zapoznaj się z publikacjami tego autora.`;
    const robots = page && pageNumber > 1 ? "noindex, follow" : "index, follow";

    const ogImage = getState().article.authorDetail.author.image?.a_img_360x360;

    dispatch(
        updateMetaData({
            title,
            ogTitle: title,
            ogType: "website",
            ogImage,
            pageNumber,
            robots,
            description,
            pathname: ctx.route.pathname
        })
    );
    return Promise.resolve();
};
