import {combineReducers} from "redux";

import {FavouritesActionTypes} from "@pg-mono/favourites";
import {IAction, reduceRequestState, reduceResponse, RequestState} from "@pg-mono/request-state";

import {IOfferDetail} from "../../offer/detail/types/IOfferDetail";
import {IPropertyDetail} from "../../property/interfaces/IPropertyDetail";
import {fetchFavouriteOffersListTypes, fetchFavouritePropertiesListTypes} from "../actions/fetch_my_offers_favourites_list";
import {fetchMyOffersOffersListTypes} from "../actions/fetch_my_offers_offers_list";
import {fetchMyOffersPropertiesListTypes} from "../actions/fetch_my_offers_properties_list";

export interface IMyOffersListType<T> {
    list: T[];
    fetchRequest: RequestState;
}

export interface IMyOffersListStore {
    favourites: {
        offers: IMyOffersListType<IOfferDetail>;
        properties: IMyOffersListType<IPropertyDetail>;
    };
    applied: {
        offers: IMyOffersListType<IOfferDetail>;
        properties: IMyOffersListType<IPropertyDetail>;
    };
}

export const myOffersReducer = combineReducers({
    favourites: combineReducers({
        offers: createMyOffersFavouritesReducer<IOfferDetail>(fetchFavouriteOffersListTypes),
        properties: createMyOffersFavouritesReducer<IPropertyDetail>(fetchFavouritePropertiesListTypes)
    }),
    applied: combineReducers({
        offers: combineReducers({
            list: reduceResponse<IOfferDetail[]>(fetchMyOffersOffersListTypes, []),
            fetchRequest: reduceRequestState(fetchMyOffersOffersListTypes)
        }),
        properties: combineReducers({
            list: reduceResponse<IPropertyDetail[]>(fetchMyOffersPropertiesListTypes, []),
            fetchRequest: reduceRequestState(fetchMyOffersPropertiesListTypes)
        })
    })
});

export interface IFavouritesToStore<T> extends IAction {
    favourites?: T[];
    favouritesToRemove?: number[];
}

function createMyOffersFavouritesReducer<T extends {id: number}>(actionTypes: FavouritesActionTypes) {
    return (state: IMyOffersListType<T> = {list: [], fetchRequest: RequestState.None}, action: IFavouritesToStore<T>): IMyOffersListType<T> => {
        switch (action.type) {
            case actionTypes.set: {
                return {list: action.favourites || [], fetchRequest: RequestState.Success} || state;
            }
            case actionTypes.add: {
                return action.favourites ? {...state, list: action.favourites.concat(state.list)} : state;
            }
            case actionTypes.remove: {
                return action.favouritesToRemove
                    ? {...state, list: state.list.filter((favourite) => !action.favouritesToRemove?.includes(favourite.id))}
                    : state;
            }
            default: {
                return state;
            }
        }
    };
}
