import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {appendQueryString, catch404, getRequest} from "@pg-mono/request";
import {createRequestActionTypes} from "@pg-mono/request-state";
import {apiV2ListLink, Scenario} from "@pg-mono/rp-api-routes";
import {safelyParsePage} from "@pg-mono/string-utils";

import {IRPRequestMeta} from "../../../app/rp_request_meta";
import {redirectOrEnable404ResponseState} from "../../../errors/actions/page_404_actions";
import {redirectOnList404} from "../../../offer/helpers/friendly_offer_list/redirect_on_list_404";
import {getOfferListSortName} from "../../../offer/helpers/get_offer_list_sort_name";
import {SearchSortString} from "../../../offer/list/constants/SearchSort";
import {IOfferListOffer} from "../../../offer/list/types/IOfferListResponse";
import {IPaginatedResponse} from "../../../request/IResponse";
import {investmentOfferListConstrains} from "../../constants/investment_offer_list_constraints";
import {getInvestmentOfferListQuery} from "../../utils/get_investment_offer_list_query";
import {fetchInvestmentOfferSeoContent} from "./fetch_investment_offer_seo_content";
import {fetchInvestmentOffersPropertiesCount} from "./fetch_investment_offers_properties_count";
import {IParsedInvestmentOfferCategories} from "./parse_investment_offer_url";

export const INVESTMENT_OFFER_LIST_PAGE_SIZE = 24;
const INVESTMENT_OFFER_LIST_MAP_PAGE_SIZE = 1000;

export interface IInvestmentOfferListResponse extends IPaginatedResponse {
    results: IOfferListOffer[];
}

const FETCH_INVESTMENT_OFFER_LIST = "investment_offer_list/FETCH_LIST";
export const fetchInvestmentOfferListTypes = createRequestActionTypes(FETCH_INVESTMENT_OFFER_LIST);

const fetchInvestmentOfferList =
    (pageSize: number, scenario: Scenario.OFFER_LIST | Scenario.OFFER_LIST_MAP) => (ctx: IFetchContext<IRPRequestMeta>) => async (dispatch: Dispatch) => {
        const investmentOfferApiLink = apiV2ListLink.offer.list(scenario);

        const investmentQuery = getInvestmentOfferListQuery(ctx.prevResult as IParsedInvestmentOfferCategories);

        if (!investmentQuery) {
            return dispatch(redirectOrEnable404ResponseState(ctx.route.pathname, ctx.meta));
        }

        dispatch({type: fetchInvestmentOfferListTypes.start, latestQuery: {...investmentQuery, ...ctx.route.query}});
        const parsedPage = safelyParsePage(ctx.route.query.page as string);
        const fullQuery = {
            ...ctx.route.query,
            ...investmentOfferListConstrains,
            ...investmentQuery,
            sort: getOfferListSortName(ctx.route.query.sort as SearchSortString),
            page: parsedPage,
            page_size: pageSize
        };

        const url = appendQueryString(investmentOfferApiLink, fullQuery);

        const [, offerListResponse] = await Promise.all([
            dispatch(fetchInvestmentOffersPropertiesCount(ctx, {...fullQuery, ...investmentOfferListConstrains})),
            getRequest(ctx.meta, url)
                .then(async (json: IInvestmentOfferListResponse) => {
                    await dispatch(fetchInvestmentOfferSeoContent(investmentQuery, ctx));
                    const result = {
                        offers: json.results,
                        page: json.page,
                        count: json.count,
                        page_size: json.page_size
                    };
                    dispatch({type: fetchInvestmentOfferListTypes.success, result});
                    return json;
                })
                .catch(
                    catch404(async (error) => {
                        dispatch({type: fetchInvestmentOfferListTypes.error, error});
                        return dispatch(redirectOnList404(ctx.route, parsedPage, ctx.meta));
                    })
                )
        ]);
        return offerListResponse;
    };

export const resetInvestmentOfferList = () => ({type: fetchInvestmentOfferListTypes.reset});

export const fetchInvestmentOfferListAtRoute = fetchInvestmentOfferList(INVESTMENT_OFFER_LIST_PAGE_SIZE, Scenario.OFFER_LIST);
export const fetchInvestmentOfferListMapAtRoute = fetchInvestmentOfferList(INVESTMENT_OFFER_LIST_MAP_PAGE_SIZE, Scenario.OFFER_LIST_MAP);
