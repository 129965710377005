// Replacement for https://lodash.com/docs/4.17.15#omit
export const omit = <TObject extends object, TKey extends keyof TObject>(object: TObject, keys: TKey[]): Omit<TObject, TKey> => {
    return keys.reduce(
        (result, key) => {
            delete result[key];

            return result;
        },
        {...object}
    );
};
