import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import {clientRpRequestMeta} from "../../../client/client_rp_request_meta";
import {IRPStore} from "../../app/rp_reducer";
import {fetchUserRecommendationsStateAction} from "../actions/fetch_user_recommendations_state_action";

export const useUserRecommendationState = () => {
    const dispatch = useDispatch();
    const isAuthenticated = useSelector((state: IRPStore) => state.isAuthenticated);
    const recommendationsStateStats = useSelector((state: IRPStore) => state.user.recommendationsState.data?.stats);

    useEffect(() => {
        if (isAuthenticated && !recommendationsStateStats) {
            dispatch(fetchUserRecommendationsStateAction({meta: clientRpRequestMeta}));
        }
    }, [isAuthenticated, recommendationsStateStats]);

    return {
        recommendationsStateStats,
        recommendedCount:
            (recommendationsStateStats?.not_seen_count &&
                recommendationsStateStats?.sold_not_seen_count &&
                recommendationsStateStats.not_seen_count - recommendationsStateStats.sold_not_seen_count) ||
            0
    };
};
