import {DEFAULT_DISTANCE, DEFAULT_DISTANCE_ON_BROWSER} from "../constants/offer_list";
import {OfferListSubType} from "../types/OfferListSubType";

export const getBrowserDefaultDistance = (offerListSubType?: OfferListSubType) => {
    if (offerListSubType) {
        switch (offerListSubType) {
            case OfferListSubType.CREDIT_TWO_PERCENT: {
                return DEFAULT_DISTANCE;
            }
        }
    }

    return DEFAULT_DISTANCE_ON_BROWSER;
};
