import {combineReducers} from "redux";

import {reduceRequestState, reduceResponse, reduceResponseField, RequestState} from "@pg-mono/request-state";

import {IArticleListArticle} from "../actions/fetch_article_list";
import {fetchArticlesWrittenByAuthorTypes} from "../actions/fetch_articles_written_by_author";
import {fetchAuthorDetailTypes} from "../actions/fetch_author_detail";
import {IArticleAuthorDetail} from "../actions/fetch_author_detail";

export interface IAuthorsDetailStore {
    author: IArticleAuthorDetail;
    authorRequestState: RequestState;
    articles: IArticleListArticle[];
    articlesCount: number;
    articlesPage: number | null;
}

export const authorDetailReducer = combineReducers({
    author: reduceResponse(fetchAuthorDetailTypes),
    articles: reduceResponseField<IArticleListArticle[]>(fetchArticlesWrittenByAuthorTypes, "articles", []),
    authorRequestState: reduceRequestState(fetchAuthorDetailTypes),
    articlesPage: reduceResponseField<IArticleListArticle[]>(fetchArticlesWrittenByAuthorTypes, "page", null),
    articlesCount: reduceResponseField<number>(fetchArticlesWrittenByAuthorTypes, "count", null)
});
