import {notifyBugsnagClient} from "@pg-mono/bugsnag-client";
import {consoleError} from "@pg-mono/logger";
import {appendQueryString, getExternalRequest} from "@pg-mono/request";
import {apiBigDataLink} from "@pg-mono/rp-api-routes";

import {bigDataApiUrl} from "../../../app/read_rp_environment_variables";
import {IAveragePricesQuery} from "../types/IAveragePricesQuery";
import {IPriceReportAveragePrices} from "../types/IPriceReportAveragePrices";

export const fetchAveragePricesBySlug = (query: IAveragePricesQuery): Promise<IPriceReportAveragePrices[]> => {
    const endpointLink = apiBigDataLink.monthCityTypeStats.base;
    const url = appendQueryString(endpointLink(bigDataApiUrl), {...query});

    return getExternalRequest({}, url, 5000).catch((err) => {
        notifyBugsnagClient(err, "");

        consoleError("fetchPricesBySlug error: ", err);
        return [];
    });
};
