import React from "react";

import {FlagIcon} from "../../components/FlagIcon";
import {IIcon} from "../../interfaces/IIcon";

export const FlagGbIcon = (props: IIcon) => {
    return (
        <FlagIcon {...props}>
            <g clipPath="url(#clip0_12221_2199)">
                <rect x="1" y="2" width="8" height="5.71429" rx="0.571429" fill="white" />
                <mask id="mask0_12221_2199" style={{maskType: "luminance"}} maskUnits="userSpaceOnUse" x="1" y="2" width="8" height="6">
                    <rect x="1" y="2" width="8" height="5.71429" rx="0.571429" fill="white" />
                </mask>
                <g mask="url(#mask0_12221_2199)">
                    <rect x="1" y="2" width="8" height="5.71429" fill="#0A17A7" />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M2.90679 5.80946L0.20752 7.63014L0.633571 8.26179L4.04755 5.95903V8.09517H5.95231V5.95896L9.36642 8.26181L9.79247 7.63016L7.09317 5.80946H8.99993V3.9047H7.09323L9.79246 2.08404L9.36641 1.45239L5.95231 3.75523V1.61898H4.04755V3.75516L0.63358 1.45241L0.207528 2.08406L2.90674 3.9047H0.99993V5.80946H2.90679Z"
                        fill="white"
                    />
                    <path d="M6.3335 3.80909L9.95217 1.42847" stroke="#DB1F35" strokeWidth="0.190476" strokeLinecap="round" />
                    <path d="M6.71777 5.91357L9.96173 8.1001" stroke="#DB1F35" strokeWidth="0.190476" strokeLinecap="round" />
                    <path d="M3.28753 3.80312L-0.0961914 1.52271" stroke="#DB1F35" strokeWidth="0.190476" strokeLinecap="round" />
                    <path d="M3.65453 5.88721L-0.0961914 8.37451" stroke="#DB1F35" strokeWidth="0.190476" strokeLinecap="round" />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M1 5.42857H4.42857V7.71429H5.57143V5.42857H9V4.28571H5.57143V2H4.42857V4.28571H1V5.42857Z"
                        fill="#E6273E"
                    />
                </g>
            </g>
            <rect x="1.14286" y="2.14286" width="7.71429" height="5.42857" rx="0.428571" stroke="#F5F5F5" strokeWidth="0.285714" />
            <defs>
                <clipPath id="clip0_12221_2199">
                    <rect x="1" y="2" width="8" height="5.71429" rx="0.571429" fill="white" />
                </clipPath>
            </defs>
        </FlagIcon>
    );
};
