import React, {PropsWithChildren} from "react";
import {css} from "@emotion/react";

import {FieldLabel} from "../../components/FieldLabel";
import {FieldMessage} from "../../components/FieldMessage";
import {IFieldState} from "../../types";

export interface IFieldWrapperProps
    extends PropsWithChildren<{
        labelContent?: string | JSX.Element;
        htmlFor?: string;
        message?: string;
        fieldState?: IFieldState;
        className?: string;
        onClick?: (e: React.MouseEvent) => void;
        required?: boolean;
    }> {}

export const FieldWrapper = (props: IFieldWrapperProps) => {
    const {children, labelContent, htmlFor, message, fieldState, onClick, required} = props;

    const parseMessage = (message?: string) => {
        if (!message) {
            return null;
        }
        if (message.includes("\n")) {
            const messages = message.split("\n");
            return messages.map((message) => (
                <FieldMessage key={message} fieldState={fieldState}>
                    {message}
                </FieldMessage>
            ));
        }
        return <FieldMessage fieldState={fieldState}>{message}</FieldMessage>;
    };

    return (
        <div className={props.className} onClick={onClick}>
            {labelContent && (
                <FieldLabel css={labelStyle} htmlFor={htmlFor}>
                    {required ? "*" : ""}
                    {labelContent}
                </FieldLabel>
            )}

            {children}
            {parseMessage(message)}
        </div>
    );
};

const labelStyle = css`
    margin: 0 0 0.4rem 0;
    display: inline-block;
`;
