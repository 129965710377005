import {combineReducers} from "redux";

import {reduceLatestQuery, reduceRequestState, reduceResponse, RequestState} from "@pg-mono/request-state";

import {IRegionListMap} from "../../../region/types/IRegionListMap";
import {fetchMapRegionTypes} from "../actions/fetch_map_region";

export interface IMapRegionStore {
    data: IRegionListMap[];
    requestState: RequestState;
    latestQuery: Record<string, unknown>;
}

export const mapRegionReducer = combineReducers({
    data: reduceResponse<IRegionListMap[]>(fetchMapRegionTypes, []),
    requestState: reduceRequestState(fetchMapRegionTypes),
    latestQuery: reduceLatestQuery(fetchMapRegionTypes)
});
