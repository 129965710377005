import * as lscache from "lscache";

import {delayHit, hitAlgolytics} from "@pg-mono/algolytics";
import {getCookie} from "@pg-mono/request";

import {USER_LAST_ACTIVITY} from "../../app/constants/storage_keys";
import {getTrackedSiteData} from "./tracked_utils/tracked_site";

export const userReturningVisitHitAlgolytics = delayHit((payload: Object) => hitAlgolytics("user_returning_visit", payload), 500);

export const userReturningVisitHit = (lastVisitTimestamp: Date | undefined, algolyticsId: string | undefined) => {
    const payload = {
        ...getTrackedSiteData(),
        last_visit: lastVisitTimestamp,
        ext_sceuid: algolyticsId
    };
    userReturningVisitHitAlgolytics(payload);
};

export const saveUserActivityTimestamp = (hitAction: typeof userReturningVisitHit) => {
    const lastVisitTimestamp = lscache.get(USER_LAST_ACTIVITY);
    const algolyticsId = getCookie("sceuidjs");

    if (lastVisitTimestamp && algolyticsId) {
        hitAction(lastVisitTimestamp, algolyticsId);
    }
    setInterval(() => lscache.set(USER_LAST_ACTIVITY, Date.now()), 10 * 1000);
};
