import {IAction} from "@pg-mono/request-state";

import {TravelMode} from "../../property/utils/TravelMode";
import {IActivePoiDirections, IPoiDirectionsData, setPoiTravelDirectionsActionTypes} from "../actions/set_poi_travel_directions";
import {IPoi} from "../types/IPoi";
import {IPublicTransportRoute} from "../types/IPublicTransportRoute";
import {IUserPoi} from "../types/IUserPoi";
import {PoiType} from "../utils/PoiType";

export interface IPoiTravelDirectionsStore {
    activePoi: IPoi | IUserPoi | null;
    activePoiType: PoiType | null;
    activePoiDirections: IActivePoiDirections | null;
    poisDirections: Record<number, Record<string, IPoiDirectionsData>>;
    activePoiRoute: IPublicTransportRoute | null;
}

const poiTravelDirectionsInitialState = {
    activePoi: null,
    activePoiType: null,
    activePoiDirections: null,
    poisDirections: {},
    activePoiRoute: null
};

export interface IPoiTravelDirectionsAction extends IAction {
    payload: {
        activePoiDirections: IActivePoiDirections | null;
        poi: IPoi | IUserPoi | null;
        poiType: PoiType | null;
        poiDirections: {id: number; travelMode: TravelMode; data: IPoiDirectionsData};
        poiRoute: IPublicTransportRoute | null;
    };
}

export const poiTravelDirectionsReducer = (state: IPoiTravelDirectionsStore = poiTravelDirectionsInitialState, action: IPoiTravelDirectionsAction) => {
    switch (action.type) {
        case setPoiTravelDirectionsActionTypes.setActivePoi: {
            const isSamePoiClicked = action.payload.poi?.id === state.activePoi?.id;
            return {
                ...state,
                activePoi: !isSamePoiClicked ? action.payload.poi : null,
                activePoiType: !isSamePoiClicked ? action.payload.poiType : null,
                ...(isSamePoiClicked && {
                    activePoiDirections: null,
                    poiDirections: null
                })
            };
        }
        case setPoiTravelDirectionsActionTypes.setActivePoiDirections: {
            return {
                ...state,
                activePoiDirections: action.payload.activePoiDirections
            };
        }
        case setPoiTravelDirectionsActionTypes.setPoisDirections: {
            const {data, id, travelMode} = action.payload.poiDirections;

            const poiDirectionsStoreData = state.poisDirections[id] || {};

            return {
                ...state,
                poisDirections: {
                    ...state.poisDirections,
                    [id]: {
                        ...poiDirectionsStoreData,
                        [travelMode]: data
                    }
                }
            };
        }
        case setPoiTravelDirectionsActionTypes.setActivePoiRoute: {
            return {
                ...state,
                activePoiRoute: action.payload.poiRoute
            };
        }
        default:
            return state;
    }
};
