import {parseSearch} from "@pg-mono/request";

export const readUtmParams = () => {
    const query = parseSearch(window.location.search);
    const {utm_campaign, utm_medium, utm_source} = query as Record<string, string>;

    return {
        ...(utm_campaign ? {utm_campaign} : {}),
        ...(utm_medium ? {utm_medium} : {}),
        ...(utm_source ? {utm_source} : {})
    };
};
