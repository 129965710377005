import {truncate} from "@pg-mono/string-utils";

import {concatWithBrandName} from "../../app/utils/concat_with_brand_name";
import {ArticleCategory, getNameByArticleCategory} from "../../article/actions/ArticleCategory";

export const descriptionArticleDetail = (articleText: string, articleLead: string) => {
    return articleLead ? articleLead : truncate(articleText, 30);
};

const descriptionArticleList = `Informacje z rynku mieszkaniowego, wiadomości lokalne, wyposażenie wnętrz, finanse, porady, prawo i podatki ✍️ ${concatWithBrandName(
    "Czytaj na"
)}`;

export const descriptionArticleCategory = (category: ArticleCategory | null, page: number) => {
    if (category) {
        return getPageDescription(
            `Najnowsze informacje i artykuły ekspertów z kategorii ${getNameByArticleCategory(category)} ${concatWithBrandName("prezentowane w portalu")}`,
            page
        );
    }
    return getPageDescription(descriptionArticleList, page);
};

export const getDescriptionArticleList = (page: number) => {
    return getPageDescription(descriptionArticleList, page);
};

const getPageDescription = (title: string, page?: number) => {
    const result: string[] = [title];
    if (typeof page !== "undefined" && page > 1) {
        result.push(` - Strona ${page}. Sprawdź! ✔`);
    } else {
        result.push(". Sprawdź! ✔");
    }

    return result.join("");
};
