import React from "react";
import {useHistory} from "react-router";
import {useTheme} from "@emotion/react";

import {Button} from "@pg-design/button";
import {rpAppLink} from "@pg-mono/rp-routes";

import {ErrorSection} from "../components/ErrorSection";
import {button} from "./NotFoundView";

const img500Mobile1x = require("../images/500_mobile_1x.png");
const img500Mobile2x = require("../images/500_mobile_2x.png");
const img500Desktop1x = require("../images/500_desktop_1x.png");
const img500Desktop2x = require("../images/500_desktop_2x.png");

export const ErrorView = () => {
    const theme = useTheme();
    const history = useHistory();

    return (
        <ErrorSection
            header="Serwis chwilowo niedostępny"
            subheader="Przepraszamy za utrudnienia"
            infoGraphic={{
                alt: "500",
                sources: [
                    {minWidthPX: 0, width: 336, height: 306, srcSet: `${img500Mobile1x} 1x, ${img500Mobile2x} 2x`},
                    {minWidthPX: theme.breakpoints.numeric.md, width: 383, height: 346, srcSet: `${img500Desktop1x} 1x, ${img500Desktop2x} 2x`}
                ]
            }}
        >
            <Button css={button} variant="filled_primary" onClick={() => window.location.reload()}>
                Spróbuj ponownie
            </Button>

            <Button css={button} variant="outlined_secondary" onClick={() => history.replace(rpAppLink.base())}>
                Strona główna
            </Button>
        </ErrorSection>
    );
};
