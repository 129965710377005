import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {v4 as uuid} from "uuid";

import {INotification} from "@pg-design/notifications";

export type INotificationsState = INotification[];

const initialState: INotificationsState = [];

export type IAddNotificationPayload = Omit<INotification, "id" | "timeout"> & Partial<Pick<INotification, "timeout">>;

export const notificationsSlice = createSlice({
    name: "notifications",
    initialState: initialState,
    reducers: {
        addNotification: (state, action: PayloadAction<IAddNotificationPayload>) => {
            const newNotification = action.payload;

            const notification = {
                ...newNotification,
                id: uuid(),
                timeout: newNotification.timeout ? newNotification.timeout : 2000
            };

            const isDuplicate = state.some((n) => n.type === notification.type && n.content === notification.content);

            if (!isDuplicate) {
                state.push(notification);
            }
        },
        removeNotification: (state, action: PayloadAction<{id: string}>) => {
            const {id} = action.payload;

            return state.filter((notification) => notification.id !== id);
        },
        dropAllTtlAndRemoveInvalid: (state) => {
            return state.reduce((acc, notification) => {
                if (notification.timeout === -1) {
                    // infinite timeout
                    return [...acc, notification];
                }
                // drop timeout by 1 when is high enough
                if (notification.timeout > 1) {
                    return [...acc, {...notification, timeout: notification.timeout - 1}];
                }
                // filter out alert whose timeout is 1 or less
                return acc;
            }, [] as INotification[]);
        }
    }
});

/*
    Actions
 */
export const {addNotification, removeNotification, dropAllTtlAndRemoveInvalid} = notificationsSlice.actions;
