import {combineReducers} from "redux";

import {ITestimonialsStore, testimonialsReducer} from "./testimonials_reducer";

export interface IOurOfferStore {
    testimonials: ITestimonialsStore;
}

export const ourOfferReducer = combineReducers({
    testimonials: testimonialsReducer
});
