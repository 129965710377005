import React from "react";

import {SvgIcon} from "../../components/SvgIcon";
import {IIcon} from "../../interfaces/IIcon";

export const UserIcon = (props: IIcon) => {
    return (
        <SvgIcon {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.963 4.175a1.734 1.734 0 1 1-1.925-2.884 1.734 1.734 0 0 1 1.925 2.884Zm-.297-2.44a1.2 1.2 0 1 0-1.333 1.996 1.2 1.2 0 0 0 1.333-1.996Z"
            />
            <path d="M2.172 6.172A4 4 0 0 1 9 9h-.533a3.467 3.467 0 1 0-6.934 0H1a4 4 0 0 1 1.172-2.828Z" />
        </SvgIcon>
    );
};
