export const campaignConstraints = {
    address: true,
    landing_page_url: true,
    offer: {
        region: {
            id: true,
            full_name: true
        },
        type: true
    }
};
