import React, {Fragment} from "react";
import styled from "@emotion/styled";

import {Hidden} from "./Hidden";

interface IProps {
    as?: React.ElementType;
    desktop: React.ReactNode;
    isMobile?: boolean | null;
    mobile: React.ReactNode;
}
/*
 * If the props provides information about the user's device - than this info will be used to render mobile or desktop content.
 *
 * Bear in mind that without isMobile prop this component will be based on CSS - this will result in a double HTML rendered
 * for mobile and desktop. Due to this, don't use for features important for SEO.
 */
export const ResponsiveRender = (props: IProps) => {
    if (typeof props.isMobile === "boolean") {
        return <Wrapper as={props.as}>{props.isMobile ? props.mobile : props.desktop}</Wrapper>;
    }

    return (
        <Fragment>
            <Hidden as={props.as} on="desktop">
                {props.mobile}
            </Hidden>
            <Hidden as={props.as} on="mobile">
                {props.desktop}
            </Hidden>
        </Fragment>
    );
};

// Just for the possibility of using the `as` prop.
const Wrapper = styled.div``;
