import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {catch404, getRequest} from "@pg-mono/request";
import {createRequestActionTypes} from "@pg-mono/request-state";
import {enable301ResponseState, enable302ResponseState} from "@pg-mono/response-state";
import {apiV2Link, Scenario} from "@pg-mono/rp-api-routes";
import {rpAppLink} from "@pg-mono/rp-routes";

import {IRPRequestMeta} from "../../../app/rp_request_meta";
import {redirectOrEnable404ResponseState} from "../../../errors/actions/page_404_actions";
import {notifyBugsnagServer} from "../../../errors/bugsnag/init_bugsnag_server";
import {getAbroadOfferRedirectLink} from "../../../investment_offer/utils/get_abroad_offer_redirect_link";
import {Country} from "../../../region/types/Country";
import {
    createOfferLink,
    getOfferCurrentStage,
    getOfferPrimaryStage,
    getStageByStageId,
    OFFER_DETAIL_ALLOW_SHOW_SOLD_STAGE_PARAM,
    OFFER_DETAIL_STAGE_QUERY_PARAM
} from "../../helpers/create_offer_link";
import {offerUrlBuilder} from "../../helpers/friendly_offer_list/offer_url_builder";
import {OfferDisplayType} from "../../helpers/OfferDisplayType";
import {IOfferDetail} from "../types/IOfferDetail";
import type {IOfferGroupsSimplified} from "../types/IOfferGroups";
import {StageStatus} from "../types/IOfferGroups";

const OFFER_DETAIL_PREFIX = "offer_detail/fetch";
export const fetchOfferDetailTypes = createRequestActionTypes(OFFER_DETAIL_PREFIX);

export const fetchOfferDetail = (offerId: number, meta?: IRPRequestMeta) => async (dispatch: Dispatch) => {
    const offerDetailApiLink = apiV2Link.offer.detail(Scenario.OFFER_DETAIL, {offerId});

    dispatch({type: fetchOfferDetailTypes.start});

    return getRequest(meta ? meta : {}, offerDetailApiLink)
        .then((offer: IRedirectOfferDetail) => {
            dispatch({type: fetchOfferDetailTypes.success, result: offer});
        })
        .catch(catch404((err) => dispatch({type: fetchOfferDetailTypes.error, result: err})));
};

export const fetchOfferDetailAtRoute = (ctx: IFetchContext<IRPRequestMeta>) => async (dispatch: Dispatch) => {
    const createOfferDetailApiLink = (offerId: number) => apiV2Link.offer.detail(Scenario.OFFER_DETAIL, {offerId});

    return getRequest(ctx.meta, createOfferDetailApiLink(ctx.match.params.offerId))
        .then(async (offer: IOfferDetail) => {
            if (!offer.groups) {
                return {offer};
            }

            // validate current group's stage
            const stageIdRaw = ctx.route.query?.[OFFER_DETAIL_STAGE_QUERY_PARAM] as string;
            const allowSoldStage = ctx.route.query?.[OFFER_DETAIL_ALLOW_SHOW_SOLD_STAGE_PARAM] as string;
            const stageId = parseInt(stageIdRaw as string, 10);
            const stageByStageIdParam = getStageByStageId(offer, stageId);
            const primaryStage = getOfferPrimaryStage(offer);
            const currentStage = getOfferCurrentStage(offer);
            const isStageByParamCurrent = stageByStageIdParam?.id === currentStage?.id;
            const hasBaseOfferMatchPrimaryStage = primaryStage?.offer.id === offer.id;
            const hasBaseOfferMatchCurrentStage = currentStage?.offer.id === offer.id;

            if (hasBaseOfferMatchPrimaryStage && !stageId && hasBaseOfferMatchCurrentStage) {
                // no stageId, offer is primary & current, redirect only if sold
                const isPrimaryOfferSold = offer?.configuration.display_type === OfferDisplayType.SOLD;
                if (isPrimaryOfferSold && !allowSoldStage) {
                    // redirect to current stage
                    dispatch(enable301ResponseState(createOfferLink(offer, {[OFFER_DETAIL_ALLOW_SHOW_SOLD_STAGE_PARAM]: true})));
                }

                return {offer};
            }

            if (hasBaseOfferMatchPrimaryStage && !stageId && !hasBaseOfferMatchCurrentStage) {
                // no stageId, offer is primary but no current, fetching current stage
                const isStageOfferSold = currentStage?.status === StageStatus.SOLD;
                if (isStageOfferSold && !allowSoldStage) {
                    // if current is sold redirect to possibly next stage (not sold with the highest sort value)
                    const stageToRedirect = offer.groups.stages
                        ?.sort((a, b) => (b.sort || 0) - (a.sort || 0))
                        .filter((stage) => stage.status !== StageStatus.SOLD)[0];

                    // redirect to possibly next stage if exists
                    if (stageToRedirect) {
                        dispatch(enable301ResponseState(createOfferLink(offer, {[OFFER_DETAIL_STAGE_QUERY_PARAM]: stageToRedirect.id})));
                    }
                }

                // otherwise fetch current stage and display
                const stageOffer: IOfferDetail = await getRequest(ctx.meta, createOfferDetailApiLink(currentStage?.offer.id || 0));
                return {offer, stageOffer};
            }

            if (hasBaseOfferMatchPrimaryStage && isStageByParamCurrent) {
                // remove current stage param, add allowSoldStage param if passed
                const isSold = offer?.configuration.display_type === OfferDisplayType.SOLD;
                dispatch(enable301ResponseState(createOfferLink(offer, isSold || allowSoldStage ? {[OFFER_DETAIL_ALLOW_SHOW_SOLD_STAGE_PARAM]: true} : {})));
                return false;
            }

            if (hasBaseOfferMatchPrimaryStage && stageByStageIdParam) {
                // re-fetch offer detail with stage.offer.id
                const stageOffer: IOfferDetail = await getRequest(ctx.meta, createOfferDetailApiLink(stageByStageIdParam.offer.id));
                const isSold = stageOffer?.configuration.display_type === OfferDisplayType.SOLD;

                if (isSold && !allowSoldStage) {
                    // redirect to current stage
                    dispatch(enable301ResponseState(createOfferLink(offer, {[OFFER_DETAIL_STAGE_QUERY_PARAM]: undefined})));
                    return false;
                }

                return {offer, stageOffer};
            }

            // base offer.id and stage.id does not match, redirect to current stage url is required
            const referer = ctx.req?.headers?.referer;
            const isDomesticReferer = !!referer && referer.includes("rynekpierwotny");
            if (isDomesticReferer) {
                const errorMessage = `Detected incorrect offer link for offerId: ${offer.id}, stageId: ${stageId}, referer: ${referer}`;
                notifyBugsnagServer(
                    {
                        name: "Incorrect offer link detected",
                        message: errorMessage
                    },
                    errorMessage
                );
            }

            dispatch(enable301ResponseState(createOfferLink(offer)));
            return false;
        })
        .then(async (offers: {offer: IOfferDetail; stageOffer?: IOfferDetail} | false) => {
            if (!offers) {
                return false;
            }

            const {offer, stageOffer} = offers;
            const result = stageOffer ? {...stageOffer, group_primary_offer: offer} : offer;

            // validate slug
            if (ctx.match.params.vendorSlug !== offer.vendor.slug || ctx.match.params.offerSlug !== offer.slug) {
                dispatch(enable301ResponseState(createOfferLink(offer)));
                return false;
            }
            if (!offer.is_active) {
                // special redirect for current offer
                if (offer.configuration.offer_to_redirect_when_inactive) {
                    const redirectOffer = await fetchRedirectOfferDetail(ctx.meta, offer.configuration.offer_to_redirect_when_inactive);
                    if (redirectOffer) {
                        const redirectLink = createOfferLink(redirectOffer);
                        offer.configuration.redirect_type === 302
                            ? dispatch(enable302ResponseState(redirectLink))
                            : dispatch(enable301ResponseState(redirectLink));
                        return false;
                    }
                }

                let use302Redirect = offer.configuration.redirect_type === 302;

                // Offers whose `display_type` has a value of `OfferDisplayType.IN_PREPARATION` should not be redirected with status 301 - because of browser cache
                if (offer.configuration.display_type === OfferDisplayType.PLANNED) {
                    use302Redirect = true;
                }
                // standard redirect to offer-list with region and type
                const query = {type: offer.type, region: offer.region.slug};
                const friendlySlug = offerUrlBuilder(query).friendlySlug;
                // For now, we don't want to show abroad regions on offers listing view - we need to redirect to investment offers view
                const link =
                    offer.region.country !== Country.POLAND ? getAbroadOfferRedirectLink(offer.region.country) : rpAppLink.offer.listFriendly({friendlySlug});
                use302Redirect ? dispatch(enable302ResponseState(link)) : dispatch(enable301ResponseState(link));
                return;
            }

            dispatch({type: fetchOfferDetailTypes.success, result});
            return {offer: result}; // to mimic old api v1 - TO BE changed in all usage of prevResult in dataFetcher
        })
        .catch(
            catch404(async () => {
                await dispatch(redirectOrEnable404ResponseState(ctx.route.pathname, ctx.meta));
            })
        );
};

interface IRedirectOfferDetail {
    id: number;
    slug: string;
    vendor: {
        slug: string;
    };
    groups: IOfferGroupsSimplified | null;
}

const fetchRedirectOfferDetail = (meta: IRPRequestMeta, offerId: number): Promise<IRedirectOfferDetail | void> => {
    const redirectOfferDetailApiLink = apiV2Link.offer.detail(Scenario.OFFER_DETAIL, {offerId}); // TODO Change to simpler scenario

    return getRequest(meta, redirectOfferDetailApiLink)
        .then((offer: IRedirectOfferDetail) => offer)
        .catch(catch404(() => undefined));
};
