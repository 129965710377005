import {createSlice, PayloadAction} from "@reduxjs/toolkit";

import {IRPStore} from "../../app/rp_reducer";
import {PinState} from "../types/pin_state";
export interface INavigationStore {
    // pinState refers to React-Headroom possible states
    pinState: PinState;
}

const initialState: INavigationStore = {
    pinState: "unfixed"
};

export const navigationSlice = createSlice({
    name: "navigation",
    initialState,
    reducers: {
        setNavigationState: (state, action: PayloadAction<PinState>) => ({
            pinState: action.payload
        })
    }
});

export const {setNavigationState} = navigationSlice.actions;

export const selectLayer = (state: IRPStore) => state.navigation;
