import type {BaseQueryFn, FetchArgs, FetchBaseQueryError} from "@reduxjs/toolkit/query";
import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import qs from "query-string";

import {getCookie, matchApiUrlByApiPrefix} from "@pg-mono/request";

import {serverApiUrls} from "../constants/server_api_urls";
import {isServer} from "../read_rp_environment_variables";

const rpBaseQuery = fetchBaseQuery({
    paramsSerializer: qs.stringify,
    prepareHeaders: (headers, api) => {
        //TODO if needed add logic for adding X-CSRFToken on server side
        const customCsrfToken = headers.get("X-CSRFToken");
        const csrf = !isServer ? getCookie("csrftoken") : null;
        if (api.type === "mutation" && !customCsrfToken && csrf) {
            headers.set("X-CSRFToken", csrf);
        }
        return headers;
    }
});

const rpServicesBaseQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (args, api, extraOptions) => {
    const endpointUrl = typeof args === "string" ? args : args.url;
    const serverApiUrl = matchApiUrlByApiPrefix(serverApiUrls, endpointUrl);

    const baseUrl = isServer ? serverApiUrl : "";
    const adjustedArgs = typeof args === "string" ? `${baseUrl}${endpointUrl}` : {...args, url: `${baseUrl}${endpointUrl}`};

    return rpBaseQuery(adjustedArgs, api, extraOptions);
};

export const rpApi = createApi({
    reducerPath: "rpApi",
    baseQuery: rpServicesBaseQuery,
    endpoints: () => ({}),
    tagTypes: ["ModularHouseOffer"]
});
