import {hitAlgolytics} from "@pg-mono/algolytics";

import {ViewType} from "../../view_type/ViewType";
import {getTrackedSiteData} from "./tracked_utils/tracked_site";

export const viewSessionTimeHit = (startTime: number, endTime: number, url: string, viewType?: ViewType | null) => {
    const payload = {
        ...getTrackedSiteData(),
        end_time: endTime,
        start_time: startTime,
        url,
        ...(viewType ? {view_type: viewType} : {})
    };

    hitAlgolytics("tab_session_time", payload);
};
