export const regionIdsForPriceIndexation = [
    9692, // Białystok
    22550, // Bydgoszcz
    25289, // Elbląg
    46083, // Gdańsk
    6196, // Gdynia
    31825, // Gliwice
    31600, // Katowice
    36190, // Kielce
    14102, // Kołobrzeg
    11158, // Kraków
    16187, // Łódź
    13220, // Lublin
    26804, // Olsztyn
    30892, // Poznań
    33659, // Rzeszów
    10847, // Sopot
    28754, // Świnoujście
    32657, // Szczecin
    19894, // Toruń
    8647, // Warszawa
    26955 // Wrocław
];

export const priceToForIndexation = [100000, 150000, 200000, 250000, 300000, 400000, 500000, 800000, 1000000, 2000000];
