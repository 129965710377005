import {combineReducers} from "redux";

import {reduceLatestQuery, reduceRequestState, reduceResponse, reduceResponseField, RequestState} from "@pg-mono/request-state";

import {ARTICLE_LIST_PAGE_SIZE, fetchArticleListFeaturedTypes, fetchArticleListTypes, IArticleListArticle} from "../actions/fetch_article_list";
import {fetchCategoryDescriptionTypes, ICategoryDescription} from "../actions/fetch_category_description";

export interface IArticleListStore {
    articles: IArticleListArticle[];
    page: number;
    count: number;
    pageSize: number;
    requestState: RequestState;
    latestQuery: Record<string, string | string[]>;
    articlesFeatured: IArticleListArticle[];
    category: ICategoryDescription;
}

export const articleListReducer = combineReducers({
    articles: reduceResponseField<IArticleListArticle[]>(fetchArticleListTypes, "articles", []),
    page: reduceResponseField<number>(fetchArticleListTypes, "page", 1),
    count: reduceResponseField<number>(fetchArticleListTypes, "count", 0),
    pageSize: reduceResponseField<number>(fetchArticleListTypes, "page_size", ARTICLE_LIST_PAGE_SIZE),
    requestState: reduceRequestState(fetchArticleListTypes),
    latestQuery: reduceLatestQuery(fetchArticleListTypes),
    articlesFeatured: reduceResponse<IArticleListArticle[]>(fetchArticleListFeaturedTypes, []),
    category: reduceResponse<ICategoryDescription>(fetchCategoryDescriptionTypes)
});
