import React from "react";

import {FlagIcon} from "../../components/FlagIcon";
import {IIcon} from "../../interfaces/IIcon";

export const FlagUaIcon = (props: IIcon) => {
    return (
        <FlagIcon {...props}>
            <g clipPath="url(#clip0_12221_2217)">
                <path
                    d="M8.83871 2H1.16129C1.07221 2 1 2.07268 1 2.16234V7.55195C1 7.6416 1.07221 7.71428 1.16129 7.71428H8.83871C8.92779 7.71428 9 7.6416 9 7.55195V2.16234C9 2.07268 8.92779 2 8.83871 2Z"
                    fill="#2A75E6"
                />
                <path
                    d="M1 4.85718H9V7.55198C9 7.59504 8.98301 7.63633 8.95276 7.66677C8.92251 7.69722 8.88149 7.71432 8.83871 7.71432H1.16129C1.11851 7.71432 1.07749 7.69722 1.04724 7.66677C1.01699 7.63633 1 7.59504 1 7.55198V4.85718Z"
                    fill="#F9D549"
                />
            </g>
            <rect x="1.14286" y="2.14286" width="7.71429" height="5.42857" rx="0.428571" stroke="#F5F5F5" strokeWidth="0.285714" />
            <defs>
                <clipPath id="clip0_12221_2217">
                    <rect x="1" y="2" width="8" height="5.71429" rx="0.571429" fill="white" />
                </clipPath>
            </defs>
        </FlagIcon>
    );
};
