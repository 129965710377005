import React from "react";

import {SvgIcon} from "../../components/SvgIcon";
import {IIcon} from "../../interfaces/IIcon";

export const ChevronRightIcon = (props: IIcon) => {
    return (
        <SvgIcon {...props}>
            <path d="M2.084.833 6.25 5 2.083 9.167h2.083L8.333 5 4.167.833H2.084Z" />
        </SvgIcon>
    );
};
