import React from "react";

import {FlagIcon} from "../../components/FlagIcon";
import {IIcon} from "../../interfaces/IIcon";

export const FlagEsIcon = (props: IIcon) => {
    return (
        <FlagIcon {...props}>
            <g clipPath="url(#a)">
                <rect width={8} height={5.714} x={1} y={2} fill="#fff" rx={0.571} />
                <mask
                    id="b"
                    width={8}
                    height={6}
                    x={1}
                    y={2}
                    maskUnits="userSpaceOnUse"
                    style={{
                        maskType: "luminance"
                    }}
                >
                    <rect width={8} height={5.714} x={1} y={2} fill="#fff" rx={0.571} />
                </mask>
                <g mask="url(#b)">
                    <path fill="#DD172C" fillRule="evenodd" d="M1 3.524h8V2H1v1.524ZM1 7.714h8V6.19H1v1.524Z" clipRule="evenodd" />
                    <path fill="#FFD133" fillRule="evenodd" d="M1 6.19h8V3.524H1V6.19Z" clipRule="evenodd" />
                    <path fill="#FFEDB1" fillRule="evenodd" d="M3.095 4.667h.381v.19h-.38v-.19Z" clipRule="evenodd" />
                    <path
                        stroke="#A41517"
                        strokeWidth={0.19}
                        d="M2.826 4.572h.539c.055 0 .099.047.094.103l-.049.587a.286.286 0 0 1-.284.262h-.061a.286.286 0 0 1-.285-.262l-.049-.587a.095.095 0 0 1 .095-.103Z"
                    />
                    <path fill="#A41517" fillRule="evenodd" d="M2.714 4.857h.762v.19h-.19l-.19.382-.191-.381h-.19v-.19Z" clipRule="evenodd" />
                    <rect width={0.381} height={1.333} x={2.143} y={4.286} fill="#A41517" rx={0.19} />
                    <rect width={0.381} height={1.333} x={3.667} y={4.286} fill="#A41517" rx={0.19} />
                    <path
                        fill="#A41517"
                        d="M2.714 4.21c0-.169.137-.305.305-.305h.152c.169 0 .305.136.305.305a.076.076 0 0 1-.076.076h-.61a.076.076 0 0 1-.076-.076Z"
                    />
                </g>
            </g>
            <rect width={7.714} height={5.429} x={1.143} y={2.143} stroke="#F5F5F5" strokeWidth={0.286} rx={0.429} />
            <defs>
                <clipPath id="a">
                    <rect width={8} height={5.714} x={1} y={2} fill="#fff" rx={0.571} />
                </clipPath>
            </defs>
        </FlagIcon>
    );
};
