import {appendQueryString, getExternalRequest} from "@pg-mono/request";

import {osmApiUrl} from "../../app/read_rp_environment_variables";
import {IRPRequestMeta} from "../../app/rp_request_meta";
import {IOSMGeoAssetsPoi} from "../types/IOSMGeoAssetsPoi";
import {IPoiAround} from "../types/IPoiAround";

type IGetPoisQuery = {
    point: string;
    dist: number;
    cache_timeout?: number;
};

export async function getPois(meta: IRPRequestMeta, around: IPoiAround, cacheTimeout?: number) {
    const query: IGetPoisQuery = {
        point: `${around.latitude},${around.longitude}`,
        dist: around.radius
    };

    if (cacheTimeout) {
        query.cache_timeout = cacheTimeout;
    }

    const url = appendQueryString(`${osmApiUrl}/geo-assets/poi/`, query);

    return getExternalRequest(meta, url, 5000) as Promise<IOSMGeoAssetsPoi[]>;
}
