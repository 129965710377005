import {rpServerUserApiUrl} from "../../app/read_rp_environment_variables";
import {IRPRequestMeta} from "../../app/rp_request_meta";

export function getUserApiRequestMeta(meta: IRPRequestMeta): IRPRequestMeta {
    if (!rpServerUserApiUrl) {
        throw new Error("RP_USER_API_URL is not defined");
    }

    return {
        ...meta,
        serverApiUrl: {
            main: rpServerUserApiUrl,
            internal: rpServerUserApiUrl
        }
    };
}
