import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {IAction} from "@pg-mono/request-state";

import {IRPStore} from "../app/rp_reducer";
import {IRPRequestMeta} from "../app/rp_request_meta";
import {getArticleCategoryBySlug, getNameByArticleCategory} from "../article/actions/ArticleCategory";
import {IArticleDetailCategory} from "../article/actions/IArticleDetail";
import {setViewTypeAction} from "./actions/set_view_type_action";
import {ViewType} from "./ViewType";

// import {IArticleDetailCategory} from "../article/actions/IArticleDetail";

export interface ISetViewTypeAction extends IAction {
    current: ViewType;
    data: Partial<IViewTypeMetaData>;
}

export interface IViewTypeMetaData {
    contentRegion: string;
    contentDeveloperName: string;
    blogCategory: string[];
}

const setViewType =
    (viewType: ViewType, data: Partial<IViewTypeMetaData> = {}) =>
    async (dispatch: Dispatch) => {
        dispatch(setViewTypeAction(viewType, data));
        return true;
    };

export const setHomepageViewTypeAtRoute = () => setViewType(ViewType.HOMEPAGE);
// offer
export const setOfferDetailViewTypeAtRoute = () => async (dispatch: Dispatch, getState: () => IRPStore) => {
    const offer = getState().offerDetail.offer;
    const contentDeveloperName = offer?.vendor?.name ?? undefined;
    const contentRegion = offer?.region?.full_name ?? undefined;
    return dispatch(setViewType(ViewType.OFFER, {contentDeveloperName, contentRegion}));
};
export const setOfferListViewTypeAtRoute = () => async (dispatch: Dispatch, getState: () => IRPStore) => {
    const regions = getState().offerList.selectedRegions;
    const singleRegion = regions?.length === 1 ? regions[0] : null;
    const contentRegion = singleRegion?.full_name ?? undefined;
    return dispatch(setViewType(ViewType.OFFER_LIST, {contentRegion}));
};
export const setOfferListMapViewTypeAtRoute = () => async (dispatch: Dispatch, getState: () => IRPStore) => {
    const regions = getState().offerListMap.selectedRegion;
    const singleRegion = regions.length === 1 ? regions[0] : null;
    const contentRegion = singleRegion?.full_name ?? undefined;
    return dispatch(setViewType(ViewType.OFFER_LIST_MAP, {contentRegion}));
};
// property
export const setPropertyDetailViewTypeAtRoute = () => async (dispatch: Dispatch, getState: () => IRPStore) => {
    const property = getState().property.property;
    const contentDeveloperName = property?.offer?.vendor?.name ?? undefined;
    const contentRegion = property?.offer?.region?.full_name ?? undefined;
    return dispatch(setViewType(ViewType.PROPERTY, {contentDeveloperName, contentRegion}));
};
// vendor
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const setVendorDetailViewTypeAtRoute = () => async (dispatch: Dispatch, getState: () => IRPStore) => {
    // const vendor = getState().vendor.detail.vendor;
    // const contentDeveloperName = vendor?.name ?? undefined;
    // return dispatch(setViewType(ViewType.VENDOR, {contentDeveloperName}));
    return dispatch(setViewType(ViewType.VENDOR));
};
export const setVendorListViewTypeAtRoute = () => setViewType(ViewType.VENDOR_LIST);

// article
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const setArticleDetailViewTypeAtRoute = () => async (dispatch: Dispatch, getState: () => IRPStore) => {
    const blogCategory = getState().article.detail.article?.categories as IArticleDetailCategory[] | undefined;
    const blogCategoryNames = blogCategory?.map((category) => category.name);
    return dispatch(setViewType(ViewType.ARTICLE, {blogCategory: blogCategoryNames}));
};
export const setArticleListViewTypeAtRoute = (ctx: IFetchContext<IRPRequestMeta>) => {
    const category = getArticleCategoryBySlug(ctx.match.params.categorySlug);
    if (category) {
        const categoryName = getNameByArticleCategory(category);
        return setViewType(ViewType.ARTICLE_LIST, {blogCategory: [categoryName]});
    }
    return setViewType(ViewType.ARTICLE_LIST);
};

// for investments
export const setForInvestmentsViewTypeAtRoute = () => setViewType(ViewType.FOR_INVESTMENT);
export const setForInvestmentsListViewTypeAtRoute = () => setViewType(ViewType.FOR_INVESTMENT_LIST);
export const setForInvestmentsMapViewTypeAtRoute = () => setViewType(ViewType.FOR_INVESTMENT_LIST_MAP);

// promotion
export const setPromotionViewTypeAtRoute = () => setViewType(ViewType.PROMOTION_LIST);

//  favourites
export const setMyListViewTypeAtRoute = () => setViewType(ViewType.MY_FAVOURITES_LIST);
export const setComparisonToolViewTypeAtRoute = () => setViewType(ViewType.COMPARISON_TOOL);

//  buyland
export const setBuylandoLandingViewTypeAtRoute = () => setViewType(ViewType.BUYLANDO_LANDING);
// credits
export const setCreditsLandingViewTypeAtRoute = () => setViewType(ViewType.CREDIT_CALCULATOR);
export const setCreditsOfferLandingViewTypeAtRoute = () => setViewType(ViewType.CREDIT_OFFERS);
