import {appendQueryString, getRequest} from "@pg-mono/request";
import {apiV2ListLink, Scenario} from "@pg-mono/rp-api-routes";

import {clientRpRequestMeta} from "../../../client/client_rp_request_meta";
import {IPaginatedResponse} from "../../request/IPaginatedResponse";
import {IRegionReportPrice} from "../types/IRegionReportPrice";

export const fetchRegionReportPriceBySlug = (regionSlugs: string[]): Promise<IRegionReportPrice[]> => {
    const url = appendQueryString(apiV2ListLink.region.list(Scenario.REGION_REPORT_PRICE), {
        slug: regionSlugs.map((regionSlug) => regionSlug)
    });

    return getRequest(clientRpRequestMeta, url).then((response: IPaginatedResponse<IRegionReportPrice>) => {
        return response.results;
    });
};
