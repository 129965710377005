import {IOSMGeoAssetsPoiType} from "../types/IOSMGeoAssetsPoi";

export const OSMGeoAssetsPoiTypeNames: {[key in IOSMGeoAssetsPoiType]: string} = {
    childcare: "żłobek",
    kindergarten: "przedszkole",
    school: "szkoła",
    college: "szkoła wyższa",
    university: "uczelnia",
    library: "biblioteka",
    atm: "bankomat",
    bank: "bank",
    parcel_locker: "automat paczkowy",
    playground: "plac zabaw",
    ars_centre: "galeria sztuki",
    cinema: "kino",
    theatre: "teatr / opera / filharmonia",
    museum: "muzeum",
    all_sports: "obiekt sportowy",
    fitness_centre: "siłownia",
    fitness_station: "siłownia na świeżym powietrzu",
    ice_rink: "lodowisko",
    pitch: "boisko / kort",
    sports_centre: "centrum sportowe",
    stadium: "stadion",
    swimming_pool: "basen",
    track: "bieżnia / tor",
    woods: "teren zalesiony",
    park: "park",
    water: "zbiornik wodny",
    grass: "teren trawiasty",
    all_food: "obiekt gastronomiczny",
    pubs: "pub / bar",
    clubs: "klub",
    amusement: "park rozrywki",
    parking: "parking",
    fuel_carwash: "stacja benzynowa lub myjnia",
    roads: "droga",
    bus_stop: "przystanek autobusowy",
    tram_stop: "przystanek tramwajowy",
    subway_stop: "stacja metra",
    train_stop: "stacja kolejowa",
    all_shops: "sklep",
    local_shops: "sklep osiedlowy",
    food_shops: "sklep z żywnością",
    city: "urząd miejski",
    post: "urząd pocztowy",
    all_doctor: "obiekt opieki zdrowotnej",
    doctor: "gabinet lekarski / przychodnia / szpital",
    pharmacy: "apteka",
    vet: "gabinet weterynaryjny"
};
