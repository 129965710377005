import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {appendQueryString, catch400, getRequest} from "@pg-mono/request";
import {createRequestActionTypes} from "@pg-mono/request-state";
import {apiV2Link, Scenario} from "@pg-mono/rp-api-routes";

import {IRPRequestMeta} from "../../../app/rp_request_meta";
import {IPaginatedResponse} from "../../../request/IResponse";
import {DEFAULT_DISTANCE} from "../constants/offer_list";

const OFFER_LIST_PROPERTIES_COUNT_PREFIX = "offer_list/property_list/PROPERTIES_COUNT";
export const fetchPropertiesCountTypes = createRequestActionTypes(OFFER_LIST_PROPERTIES_COUNT_PREFIX);

const propertyListApiLink = apiV2Link.property.list(Scenario.PROPERTY_LIST);

export const fetchPropertiesCount =
    (ctx: IFetchContext<IRPRequestMeta>, query: Record<string, string | string[] | number | number[] | boolean | null>) =>
    async (dispatch: Dispatch): Promise<number | null> => {
        dispatch({type: fetchPropertiesCountTypes.start});

        // get rid of unnecessary query params
        const {page: _page, ...validQuery} = query;
        const fullQuery = {
            distance: DEFAULT_DISTANCE,
            ...validQuery,
            page_size: 0 // this speeds up backend response to get only count
        };

        // save latest query and
        const url = appendQueryString(propertyListApiLink, fullQuery);
        // services connected with favourites, endpoint might return different values
        return getRequest(ctx.meta, url)
            .then((json: IPaginatedResponse) => {
                dispatch({type: fetchPropertiesCountTypes.success, result: json.count});
                return json.count;
            })
            .catch(
                catch400(() => {
                    dispatch({type: fetchPropertiesCountTypes.error});
                    return null;
                })
            );
    };
