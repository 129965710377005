import {OFFER_LIST_CONSTRAINTS, OFFER_LIST_PAGE_SIZE} from "../list/constants/offer_list";
import {IGetOfferListPayload} from "../types/GetOfferListPayload";

const CONSTRUCTION_ENDS_IN_MONTHS = 6;

export function getSoonFinishedOffersPayload(page: number): IGetOfferListPayload {
    return {
        ...OFFER_LIST_CONSTRAINTS,
        page_size: OFFER_LIST_PAGE_SIZE,
        construction_end_date: CONSTRUCTION_ENDS_IN_MONTHS,
        sort: "update_date",
        page
    };
}
