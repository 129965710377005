import {IFetchContext} from "@pg-mono/data-fetcher";

import {IRPRequestMeta} from "../../../app/rp_request_meta";
import {concatWithBrandName} from "../../../app/utils/concat_with_brand_name";
import {MetaDataDispatch, updateMetaData} from "../../../meta_data/actions/update_meta_data";

const createUpdateSiteMapMetaDataAction = (title: string, description: string) => (ctx: IFetchContext<IRPRequestMeta>) => (dispatch: MetaDataDispatch) => {
    dispatch(updateMetaData({title, description, pathname: ctx.route.pathname}));
    return Promise.resolve();
};

export const updateSiteMapMetaData = createUpdateSiteMapMetaDataAction(concatWithBrandName("Mapa strony -"), "Mapa strony - dodatkowa nawigacja");
export const updateSiteMapArticleCategoriesMetaData = createUpdateSiteMapMetaDataAction(
    concatWithBrandName("Mapa strony - Artykuły -"),
    "Mapa strony - dodatkowa nawigacja dla kategorii Artykuły"
);
export const updateSiteMapOfferVoivodeshipListMetaData = createUpdateSiteMapMetaDataAction(
    concatWithBrandName("Mapa strony - Oferty -"),
    "Mapa strony - dodatkowa nawigacja dla kategorii Oferty"
);
export const updateSiteMapVendorListMetaData = createUpdateSiteMapMetaDataAction(
    concatWithBrandName("Mapa strony - Deweloperzy -"),
    "Mapa strony - dodatkowa nawigacja dla kategorii Deweloperzy"
);
