import {getRequest} from "@pg-mono/request";
import {apiV2Link, Scenario} from "@pg-mono/rp-api-routes";

import {IRPRequestMeta} from "../../app/rp_request_meta";
import {IOfferDetail} from "../detail/types/IOfferDetail";

export const fetchOfferDetailById = (meta: IRPRequestMeta, offerId: number): Promise<IOfferDetail> => {
    const requestUrl = apiV2Link.offer.detail(Scenario.OFFER_DETAIL, {offerId});

    return getRequest(meta, requestUrl);
};
