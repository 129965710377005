import {RequestActionTypes} from "./create_request_action_types";
import {IAction} from "./reduce_response";

export interface IAppError {
    status: number;
    fieldErrors: Record<string, string[]>;
    nonFieldErrors: string[];
}

export interface IErrorAction extends IAction {
    error: IAppError | null;
}

export const reduceRequestErrors =
    (requestTypes: RequestActionTypes) =>
    (state: IAppError | null = null, action: IErrorAction): IAppError | null => {
        switch (action.type) {
            case requestTypes.success:
            case requestTypes.reset: // reset is usually called on unmout, so we need to clear error data
                return null;
            case requestTypes.error:
                return action.error;
            default:
                return state;
        }
    };
