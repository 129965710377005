import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {catch404, getRequest} from "@pg-mono/request";
import {apiLink} from "@pg-mono/rp-api-routes";

import {IRPRequestMeta} from "../../app/rp_request_meta";
import {redirectOrEnable404ResponseState} from "../../errors/actions/page_404_actions";

export const unsubscribeUserFromSmsAtRoute = (ctx: IFetchContext<IRPRequestMeta>) => async (dispatch: Dispatch) => {
    const {subscriberUuid} = ctx.match.params;

    const unsubscribeApiLink = apiLink.newsletter.unsubscribe.sms({})({subscriberUuid});

    return getRequest(ctx.meta, unsubscribeApiLink).catch(
        catch404(async () => {
            await dispatch(redirectOrEnable404ResponseState(ctx.route.pathname, ctx.meta));
        })
    );
};
