import type {LocaleObject} from "yup";

export const yupLocales: LocaleObject = {
    date: {},
    mixed: {
        required: "To pole nie może być puste"
    },
    string: {
        email: "Podaj poprawny adres e-mail"
    }
};
