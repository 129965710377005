import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {isEqual} from "@pg-mono/nodash";
import {appendQueryString, catch400, getRequest} from "@pg-mono/request";
import {createRequestActionTypes, RequestActionTypes} from "@pg-mono/request-state";
import {apiV2ListLink, Scenario} from "@pg-mono/rp-api-routes";

import {IRPStore} from "../../../app/rp_reducer";
import {IRPRequestMeta} from "../../../app/rp_request_meta";
import {Country} from "../../../region/types/Country";

const FETCH_OFFER_LIST_NEARBY_REGIONS_PREFIX = "offer_list/FETCH_OFFER_LIST_NEARBY_REGIONS";
export const fetchOfferListNearbyRegionsTypes = createRequestActionTypes(FETCH_OFFER_LIST_NEARBY_REGIONS_PREFIX);

export interface INearbyRegion {
    id: number;
    name: string;
    slug: string;
    stats: {
        properties_all_count_for_sale: number;
        properties_flats_count_for_sale: number;
        properties_houses_count_for_sale: number;
        properties_commercials_count_for_sale: number;
        vendors_count_for_sale: number;
    };
}

interface IOfferListNearbyRegionsResponse {
    results: INearbyRegion[];
}

const fetchOfferListNearbyRegions =
    (actionType: RequestActionTypes) => (ctx: IFetchContext<IRPRequestMeta>) => async (dispatch: Dispatch, getState: () => IRPStore) => {
        const region = ctx.prevResult.region;
        if (!region) {
            return false;
        }

        const query = {nearby_regions: region[0], country: Country.POLAND};
        if (isEqual(query, getState().offerList.nearbyRegionsLatestQuery)) {
            // do not fetch the same data again
            return getState().offerList.nearbyRegions;
        }

        dispatch({type: actionType.start, latestQuery: query});
        const nearbyRegionsListApiLink = apiV2ListLink.region.list(Scenario.REGION_LIST);

        const url = appendQueryString(nearbyRegionsListApiLink, query);
        return getRequest(ctx.meta, url)
            .then((res: IOfferListNearbyRegionsResponse) => {
                dispatch({type: actionType.success, result: res.results});
                return res.results;
            })
            .catch(
                catch400((error) => {
                    dispatch({type: actionType.error, error: error.appError});
                })
            );
    };

export const fetchOfferListNearbyRegionsAtRoute = (ctx: IFetchContext<IRPRequestMeta>) => {
    return fetchOfferListNearbyRegions(fetchOfferListNearbyRegionsTypes)(ctx);
};
