import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {createFormActions, IFormValuesAction} from "@pg-mono/form";
import {isEmpty, pick} from "@pg-mono/nodash";

import {IRPStore} from "../../app/rp_reducer";
import {IRPRequestMeta} from "../../app/rp_request_meta";
import {fetchAllListsType, SearchTab} from "../../search/actions/fetch_search_all_action";
import {fetchRegionList, IRegionListResponse} from "../../search/actions/fetch_search_regions_action";
import {searchFormTypes} from "../../search/actions/search_form_actions";
import {IPromotionSearchFormValuesType} from "../list/components/filters/PromotionListSearchFilter";
import {promotionListFilterFormActions} from "./promotion_list_filter_fields_actions";

export const promotionListSearchFormActions = createFormActions<IPromotionSearchFormValuesType>(searchFormTypes);

export const fetchPromotionSearchList =
    (searchInput: string) =>
    async (dispatch: Dispatch): Promise<SearchTab> => {
        const regionsResponse: IRegionListResponse = await dispatch(fetchRegionList(searchInput));

        if (regionsResponse && !isEmpty(regionsResponse.results)) {
            dispatch({type: fetchAllListsType.success});
        }

        return SearchTab.Regions;
    };

export const resetClientOnlyPromotionSearchFormAtRoute =
    (ctx: IFetchContext<IRPRequestMeta>) => async (dispatch: Dispatch<IFormValuesAction<unknown>>, getState: () => IRPStore) => {
        const {region} = getState().promotionList;
        const tabType = region ? SearchTab.Regions : null;

        dispatch(
            promotionListSearchFormActions.replace({
                search: {
                    label: "",
                    tabType,
                    regions: region ? [region] : null
                }
            })
        );

        dispatch(promotionListFilterFormActions.update(pick(ctx.prevResult, ["type"])));

        return true;
    };
