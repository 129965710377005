export const addRegionSlugToQuery = <TQuery extends {region?: unknown}>(query: TQuery, region: {slug: string; type: number} | null): TQuery => {
    // if query does not have `region` key do nothing
    if (!query.region) {
        return query;
    }
    // region's slug has to be defined
    if (region && region.slug) {
        return {...query, region: region.slug, region_type: region.type};
    } else {
        console.error("addRegionSlugToQuery: `slug` not defined in region ", region, ", for query ", query);
        return query;
    }
};

// additional check to prevent error in following `addRegionSlugToQuery` function
export const validateRegionSlugAvailability = (query: Record<string, string | string[]>, region: {slug: string} | null) => {
    if (!query.region) {
        return true;
    }
    return !!(region && region.slug);
};
