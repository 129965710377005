import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {catch400, catch403, getRequest} from "@pg-mono/request";
import {createRequestActionTypes} from "@pg-mono/request-state";
import {apiUserLink} from "@pg-mono/rp-api-routes";

import {IRPRequestMeta} from "../../app/rp_request_meta";

const FETCH_USER_RECOMMENDATIONS_STATE_PREFIX = "user/FETCH_USER_RECOMMENDATIONS_STATE";
export const fetchUserRecommendationsStateRequestType = createRequestActionTypes(FETCH_USER_RECOMMENDATIONS_STATE_PREFIX);

export interface IRecommendationsStateResponse {
    last_updated: string;
    state: number;
    stats: {
        all_count: number;
        application_sent_count: number;
        clicked_count: number;
        dismissed_count: number;
        favorite_count: number;
        not_seen_count: number;
        recommended_count: number;
        sold_not_seen_count: number;
        sold_recommendations_count: number;
    };
}

export const fetchUserRecommendationsStateAction = (ctx: Pick<IFetchContext<IRPRequestMeta>, "meta">) => async (dispatch: Dispatch) => {
    dispatch({type: fetchUserRecommendationsStateRequestType.start});

    return getRequest(ctx.meta, apiUserLink.recommendations.state)
        .then((recommendationsState: IRecommendationsStateResponse) => {
            dispatch({type: fetchUserRecommendationsStateRequestType.success, result: recommendationsState});

            return recommendationsState;
        })
        .catch(
            catch403(() => {
                dispatch({type: fetchUserRecommendationsStateRequestType.reset});
            })
        )
        .catch(
            catch400((error) => {
                dispatch({type: fetchUserRecommendationsStateRequestType.error, error: error.appError});
            })
        );
};
