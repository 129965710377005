import {matchPath} from "react-router";

import {rpAppPath} from "@pg-mono/rp-routes";

import {InvestmentOfferCategory, InvestmentOfferSubCategory} from "../constants/investment_offer_constants";

export function getParsedInvestmentOfferListUrl(pathname: string) {
    const matchedCategory = matchPath<{category: InvestmentOfferCategory}>(pathname, {
        path: rpAppPath.investmentOffer.investmentCategory.base
    });
    const matchedSubcategory = matchPath<{category: InvestmentOfferCategory; subcategory: InvestmentOfferSubCategory | "mapa"}>(pathname, {
        path: rpAppPath.investmentOffer.investmentCategory.subcategory.base
    });

    return {
        category: matchedCategory && matchedCategory.params.category,
        subcategory: matchedSubcategory && matchedSubcategory.params.subcategory !== "mapa" ? matchedSubcategory.params.subcategory : null
    };
}
