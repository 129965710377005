import {useContext} from "react";

import {AccordionContext} from "../components/AccordionCore";

export const useAccordionContext = (componentName: string) => {
    const context = useContext(AccordionContext);

    if (typeof context === "undefined") {
        throw new Error(`Accordion.${componentName} must be within AccordionContext`);
    }

    return context;
};
