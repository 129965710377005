import {ISetTravelTimeAction, ITravelTimeStore, SET_PREVIOUS_DISTANCE, SET_TRAVEL_TIME_ACTIVE, SET_TRAVEL_TIME_DATA} from "../actions/set_travel_time";
import {TravelTimeTransport} from "../types/TravelTimeTransport";
import {TravelTimeValues} from "../types/TravelTimeValues";

const initialState: ITravelTimeStore = {
    isActive: false,
    data: {
        transport_type: TravelTimeTransport.CAR,
        travel_time: TravelTimeValues.TEN
    },
    prevDistance: ""
};

export const travelTimeReducer = (state: ITravelTimeStore = initialState, action: ISetTravelTimeAction): ITravelTimeStore => {
    switch (action.type) {
        case SET_TRAVEL_TIME_DATA:
            if (action.currentTravelTime) {
                return {...state, data: action.currentTravelTime};
            }
            return state;
        case SET_TRAVEL_TIME_ACTIVE:
            return {...state, isActive: action.isActive || false};
        case SET_PREVIOUS_DISTANCE:
            return {...state, prevDistance: action.prevDistance};
        default:
            return state;
    }
};
