import {IOSMGeoAssetsPoiType} from "../types/IOSMGeoAssetsPoi";
import {PoiType} from "../utils/PoiType";

export const OSMGeoAssetsPoiTypeCategories: {[key in IOSMGeoAssetsPoiType]: PoiType} = {
    childcare: PoiType.EDUCATION,
    kindergarten: PoiType.EDUCATION,
    school: PoiType.EDUCATION,
    college: PoiType.EDUCATION,
    university: PoiType.EDUCATION,
    library: PoiType.ALL,
    atm: PoiType.ALL,
    bank: PoiType.ALL,
    parcel_locker: PoiType.ALL,
    playground: PoiType.ENTERTAINMENT,
    ars_centre: PoiType.ENTERTAINMENT,
    cinema: PoiType.ALL,
    theatre: PoiType.ALL,
    museum: PoiType.ALL,
    all_sports: PoiType.SPORT,
    fitness_centre: PoiType.SHOPS,
    fitness_station: PoiType.SPORT,
    ice_rink: PoiType.SPORT,
    pitch: PoiType.SPORT,
    sports_centre: PoiType.SPORT,
    stadium: PoiType.SPORT,
    swimming_pool: PoiType.SPORT,
    track: PoiType.SPORT,
    woods: PoiType.ALL,
    park: PoiType.ALL,
    water: PoiType.ALL,
    grass: PoiType.ALL,
    all_food: PoiType.FOOD,
    pubs: PoiType.FOOD,
    clubs: PoiType.ALL,
    amusement: PoiType.ALL,
    parking: PoiType.ALL,
    fuel_carwash: PoiType.ALL,
    roads: PoiType.ALL,
    bus_stop: PoiType.TRANSPORT,
    tram_stop: PoiType.TRANSPORT,
    subway_stop: PoiType.TRANSPORT,
    train_stop: PoiType.TRANSPORT,
    all_shops: PoiType.SHOPS,
    local_shops: PoiType.SHOPS,
    food_shops: PoiType.SHOPS,
    city: PoiType.ALL,
    post: PoiType.ALL,
    all_doctor: PoiType.HEALTH,
    doctor: PoiType.HEALTH,
    pharmacy: PoiType.ALL,
    vet: PoiType.ALL
};
