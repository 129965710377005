import {combineReducers} from "redux";

import {reduceRequestState, reduceResponseField, RequestActionTypes, RequestState} from "@pg-mono/request-state";

import {IPropertyListProperty} from "../../../property/types/PropertyListProperty";
import {fetchOfferDetailPropertiesTypes, IOfferDetailPropertiesResponse} from "../actions/fetch_offer_detail_properties";
import {OFFER_DETAIL_PROPERTY_LIST_DEFAULT_PAGE_SIZE} from "../constants/offer_detail_properties";

interface ISinglePropertiesStore {
    properties: IPropertyListProperty[];
    requestState: RequestState;
    page: number;
    count: number;
    pageSize: number;
}

export interface IOfferPropertiesStore {
    filter: ISinglePropertiesStore;
    investment: ISinglePropertiesStore;
    modal: ISinglePropertiesStore;
}

/**
 * Factories
 */

interface IOfferPropertiesRequestTypes {
    filter: RequestActionTypes;
    investment: RequestActionTypes;
    modal: RequestActionTypes;
}
const createOfferPropertiesReducer = (requestTypesObj: IOfferPropertiesRequestTypes) =>
    combineReducers({
        filter: combineReducers({
            properties: reduceResponseField<IOfferDetailPropertiesResponse[]>(requestTypesObj.filter, "properties", []),
            requestState: reduceRequestState(requestTypesObj.filter),
            page: reduceResponseField<number>(requestTypesObj.filter, "page", 1),
            count: reduceResponseField<number>(requestTypesObj.filter, "count", 0),
            pageSize: reduceResponseField<number>(requestTypesObj.filter, "page_size", OFFER_DETAIL_PROPERTY_LIST_DEFAULT_PAGE_SIZE)
        }),
        investment: combineReducers({
            properties: reduceResponseField<IOfferDetailPropertiesResponse[]>(requestTypesObj.investment, "properties", []),
            requestState: reduceRequestState(requestTypesObj.investment),
            page: reduceResponseField<number>(requestTypesObj.investment, "page", 1),
            count: reduceResponseField<number>(requestTypesObj.investment, "count", 0),
            pageSize: reduceResponseField<number>(requestTypesObj.investment, "page_size", OFFER_DETAIL_PROPERTY_LIST_DEFAULT_PAGE_SIZE)
        }),
        modal: combineReducers({
            properties: reduceResponseField<IOfferDetailPropertiesResponse[]>(requestTypesObj.modal, "properties", []),
            requestState: reduceRequestState(requestTypesObj.modal),
            page: reduceResponseField<number>(requestTypesObj.modal, "page", 1),
            count: reduceResponseField<number>(requestTypesObj.modal, "count", 0),
            pageSize: reduceResponseField<number>(requestTypesObj.modal, "page_size", OFFER_DETAIL_PROPERTY_LIST_DEFAULT_PAGE_SIZE)
        })
    });

/**
 * Reducers
 */
export const offerDetailPropertiesReducer = createOfferPropertiesReducer(fetchOfferDetailPropertiesTypes);
