// Replacement for https://lodash.com/docs/4.17.15#range

const isNumberDefined = (value?: number): value is number => typeof value !== "undefined";

export const range = (start = 0, end?: number, increment?: number) => {
    // if the end is not defined...
    // ...the first argument should be the end of the range...
    const endNormalized = isNumberDefined(end) ? end : start;
    // ...and 0 should be the start
    start = isNumberDefined(end) ? start : 0;

    // if the increment is not defined, we could need a +1 or -1
    // depending on whether we are going up or down
    const incrementNormalized = isNumberDefined(increment) ? increment : Math.sign(endNormalized - start);

    // calculating the length of the array, which has always to be positive
    const length = Math.abs((endNormalized - start) / (incrementNormalized || 1));

    // In order to return the right result, we need to create a new array
    // with the calculated length and fill it with the items starting from
    // the start value + the value of increment.
    const {result} = Array.from({length}).reduce<{result: number[]; current: number}>(
        ({result, current}) => ({
            // append the current value to the result array
            result: [...result, current],
            // adding the increment to the current item
            // to be used in the next iteration
            current: current + incrementNormalized
        }),
        {current: start, result: []}
    );

    return result;
};
