import {IFetchContext} from "@pg-mono/data-fetcher";
import {rpAppLink} from "@pg-mono/rp-routes";
import {safelyParsePage} from "@pg-mono/string-utils";

import {rpCommonUrl} from "../../app/read_rp_environment_variables";
import {IRPStore} from "../../app/rp_reducer";
import {IRPRequestMeta} from "../../app/rp_request_meta";
import {MetaDataDispatch, updateMetaData} from "../../meta_data/actions/update_meta_data";
import {descriptionArticleCategory, descriptionArticleDetail, getDescriptionArticleList} from "../../meta_data/page_description/article_description";
import {getTitleArticleList, titleArticleCategory, titleArticleDetail} from "../../meta_data/page_title/article_title";
import {generatePrevNextMetaLinks} from "../../meta_data/utils/generate_prev_next_links";
import {getArticleCategoryBySlug} from "./ArticleCategory";
import {ARTICLE_LIST_PAGE_SIZE} from "./fetch_article_list";

export const updateArticleListMetaData = (ctx: IFetchContext<IRPRequestMeta>) => async (dispatch: MetaDataDispatch, getState: () => IRPStore) => {
    const {count} = getState().article.list;
    const {page, phrase, ...restQuery} = ctx.route.query;

    const parsedPage = safelyParsePage(page);
    const title = getTitleArticleList(parsedPage);
    const description = getDescriptionArticleList(parsedPage);

    const hostAndPathname = `${rpCommonUrl}${ctx.route.pathname}`;
    // age link, but provide prev link-rel
    const maxListPage = count > 0 ? Math.ceil(count / ARTICLE_LIST_PAGE_SIZE) : 1;
    const maxPage = maxListPage - 1;
    const {prev, next} = generatePrevNextMetaLinks(parsedPage, maxPage, hostAndPathname, restQuery);
    const robots = (page && parsedPage > 1) || phrase ? "noindex, follow" : "index, follow";

    const metaData = updateMetaData({
        title,
        description,
        pathname: ctx.route.pathname,
        prev,
        next,
        robots,
        pageNumber: parsedPage
    });
    dispatch(metaData);
    return true;
};

const getHreflangFullUrlForArticles = (data: {id: number; language_code: "uk" | "pl"; slug: string}[] | undefined) => {
    if (!data) {
        return [];
    }

    return data.map(({language_code, slug, id}) => {
        return {language_code, slug: rpCommonUrl + rpAppLink.article.detail({articleSlug: slug, articleId: id})};
    });
};

export const updateArticleDetailMetaData =
    ({route}: IFetchContext<IRPRequestMeta>) =>
    async (dispatch: MetaDataDispatch, getState: () => IRPStore) => {
        const {article} = getState().article.detail;
        if (article == null) {
            return false;
        }

        const title = titleArticleDetail(article.title);
        const description = descriptionArticleDetail(article.text || "", article.lead || "");
        const robots = article.is_indexing_allowed === false ? "noindex, follow, max-image-preview:large" : "index, follow, max-image-preview:large"; // WARNING: undefined should give "index"
        const imgSrc = article.gallery[0]?.image?.g_img_1160x638;
        const ogTitle = article.header;
        const ogPublishDate = article.date_published;
        const customCanonicalPath = article.canonical_url?.includes("https://") ? new URL(article.canonical_url).pathname : undefined;

        const metaData = updateMetaData({
            title,
            description,
            pathname: route.pathname,
            robots,
            ogImage: imgSrc,
            ogTitle,
            ogType: "article",
            ogPublishDate,
            customCanonicalPath,
            hreflang: getHreflangFullUrlForArticles(article.language_versions_group?.localized_articles),
            lang: article.language_code
        });
        dispatch(metaData);
        return true;
    };

export const updateArticleCategoryMetaData = (ctx: IFetchContext<IRPRequestMeta>) => async (dispatch: MetaDataDispatch, getState: () => IRPStore) => {
    const {categorySlug} = ctx.match.params;
    const {page, phrase, ...restQuery} = ctx.route.query;
    const {count} = getState().article.list;
    const parsedPage = safelyParsePage(page);

    const category = getArticleCategoryBySlug(categorySlug);
    const title = titleArticleCategory(category, parsedPage);
    const description = descriptionArticleCategory(category, parsedPage);

    const maxListPage = count > 0 ? Math.ceil(count / ARTICLE_LIST_PAGE_SIZE) : 1;
    const maxPage = maxListPage - 1;
    const hostAndPathname = `${rpCommonUrl}${ctx.route.pathname}`;
    const {prev, next} = generatePrevNextMetaLinks(parsedPage, maxPage, hostAndPathname, restQuery);
    const robots = (page && parsedPage > 1) || phrase ? "noindex, follow" : "index, follow";

    const metaData = updateMetaData({
        title,
        description,
        pathname: ctx.route.pathname,
        prev,
        next,
        robots,
        pageNumber: parsedPage
    });
    dispatch(metaData);
    return true;
};
