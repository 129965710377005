export const NAVIGATION_REGIONS = [
    {
        name: "dolnośląskie",
        suffix: "dolnoslaskie",
        cities_flats: [
            {name: "Wrocław", suffix: "wroclaw"},
            {name: "Legnica", suffix: "legnica"},
            {name: "Lubin", suffix: "lubin"},
            {name: "Zgorzelec", suffix: "zgorzelec"},
            {name: "Wałbrzych", suffix: "walbrzych"},
            {name: "Brzeg Dolny", suffix: "brzeg-dolny"},
            {name: "Świdnica", suffix: "swidnica"},
            {name: "Kłodzko", suffix: "klodzko"},
            {name: "Szklarska Poręba", suffix: "szklarska-poreba"},
            {name: "Karpacz", suffix: "karpacz"},
            {name: "Jelenia Góra", suffix: "jelenia-gora"},
            {name: "Siechnice", suffix: "siechnice"},
            {name: "Smolec", suffix: "wroclawski-smolec"},
            {name: "Sobótka", suffix: "sobotka"},
            {name: "Trzebnica", suffix: "trzebnica"}
        ],
        cities_houses: [
            {name: "Wrocław", suffix: "wroclaw"},
            {name: "Oleśnica", suffix: "olesnica"},
            {name: "Lubin", suffix: "lubin"},
            {name: "Kłodzko", suffix: "klodzko"},
            {name: "Bielawa", suffix: "bielawa"},
            {name: "Jelenia Góra", suffix: "jelenia-gora"},
            {name: "Głogów", suffix: "glogow"},
            {name: "Wałbrzych", suffix: "walbrzych"},
            {name: "Świdnica", suffix: "swidnica"},
            {name: "Domaszczyn", suffix: "wroclawski-domaszczyn"},
            {name: "Siechnice", suffix: "siechnice"},
            {name: "Smolec", suffix: "wroclawski-smolec"},
            {name: "Bielany Wrocławskie", suffix: "wroclawski-bielany-wroclawskie"},
            {name: "Święta Katarzyna", suffix: "wroclawski-swieta-katarzyna"}
        ]
    },
    {
        name: "kujawsko-pomorskie",
        suffix: "kujawsko-pomorskie",
        cities_flats: [
            {name: "Bydgoszcz", suffix: "bydgoszcz"},
            {name: "Toruń", suffix: "torun"},
            {name: "Inowrocław", suffix: "inowroclaw"},
            {name: "Włocławek", suffix: "wloclawek"},
            {name: "Grudziądz", suffix: "grudziadz"},
            {name: "Chełmno", suffix: "chelmno"},
            {name: "Solec Kujawski", suffix: "solec-kujawski"},
            {name: "Ciechocinek", suffix: "ciechocinek"},
            {name: "Brodnica", suffix: "brodnica"},
            {name: "Osielsko", suffix: "bydgoski-osielsko"},
            {name: "Gniewkowo", suffix: "gniewkowo"}
        ],
        cities_houses: [
            {name: "Bydgoszcz", suffix: "bydgoszcz"},
            {name: "Toruń", suffix: "torun"},
            {name: "Inowrocław", suffix: "inowroclaw"},
            {name: "Włocławek", suffix: "wloclawek"},
            {name: "Grudziądz", suffix: "grudziadz"},
            {name: "Ciechocinek", suffix: "ciechocinek"},
            {name: "Osielsko", suffix: "bydgoski-osielsko"},
            {name: "Murowaniec", suffix: "bydgoski-murowaniec"}
        ]
    },
    {
        name: "lubelskie",
        suffix: "lubelskie",
        cities_flats: [
            {name: "Zamość", suffix: "zamosc"},
            {name: "Lublin", suffix: "lublin"},
            {name: "Chełm", suffix: "chelm"},
            {name: "Biała Podlaska", suffix: "biala-podlaska"},
            {name: "Świdnik", suffix: "swidnik"},
            {name: "Puławy", suffix: "pulawy"},
            {name: "Kraśnik", suffix: "krasnik"},
            {name: "Łęczna", suffix: "leczna"},
            {name: "Biłgoraj", suffix: "bilgoraj"}
        ],
        cities_houses: [
            {name: "Zamość", suffix: "zamosc"},
            {name: "Lublin", suffix: "lublin"},
            {name: "Chełm", suffix: "chelm"},
            {name: "Biała Podlaska", suffix: "biala-podlaska"},
            {name: "Świdnik", suffix: "swidnik"},
            {name: "Puławy", suffix: "pulawy"},
            {name: "Tomaszów Lubelski", suffix: "tomaszow-lubelski"},
            {name: "Łuków", suffix: "lukow"}
        ]
    },
    {
        name: "lubuskie",
        suffix: "lubuskie",
        cities_flats: [
            {name: "Zielona Góra", suffix: "zielona-gora"},
            {name: "Żagań", suffix: "zagan"},
            {name: "Słubice", suffix: "slubice"},
            {name: "Nowa Sól", suffix: "nowa-sol"},
            {name: "Międzyrzecz", suffix: "miedzyrzecz"},
            {name: "Kostrzyn nad Odrą", suffix: "kostrzyn-nad-odra"},
            {name: "Żary", suffix: "zary"},
            {name: "Gorzów Wielkopolski", suffix: "gorzow-wielkopolski"},
            {name: "Strzelce Krajeńskie", suffix: "strzelce-krajenskie"}
        ],
        cities_houses: [
            {name: "Zielona Góra", suffix: "zielona-gora"},
            {name: "Żagań", suffix: "zagan"},
            {name: "Nowa Sól", suffix: "nowa-sol"},
            {name: "Krosno Odrzańskie", suffix: "krosno-odrzanskie"},
            {name: "Gorzów Wielkopolski", suffix: "gorzow-wielkopolski"}
        ]
    },
    {
        name: "łódzkie",
        suffix: "lodzkie",
        cities_flats: [
            {name: "Łódź", suffix: "lodz"},
            {name: "Kutno", suffix: "kutno"},
            {name: "Tomaszów Mazowiecki", suffix: "tomaszow-mazowiecki"},
            {name: "Skierniewice", suffix: "skierniewice"},
            {name: "Pabianice", suffix: "pabianice"},
            {name: "Koluszki", suffix: "koluszki"},
            {name: "Zduńska Wola", suffix: "zdunska-wola"},
            {name: "Bełchatów", suffix: "belchatow"},
            {name: "Konstantynów Łódzki", suffix: "konstantynow-lodzki"},
            {name: "Piotrków Trybunalski", suffix: "piotrkow-trybunalski"}
        ],
        cities_houses: [
            {name: "Łódź", suffix: "lodz"},
            {name: "Bełchatów", suffix: "belchatow"},
            {name: "Zgierz", suffix: "zgierz"},
            {name: "Zduńska Wola", suffix: "zdunska-wola"},
            {name: "Kutno", suffix: "kutno"},
            {name: "Radomsko", suffix: "radomsko"},
            {name: "Pabianice", suffix: "pabianice"},
            {name: "Aleksandrów Łódzki", suffix: "aleksandrow-lodzki"},
            {name: "Konstantynów Łódzki", suffix: "konstantynow-lodzki"}
        ]
    },
    {
        name: "małopolskie",
        suffix: "malopolskie",
        cities_flats: [
            {name: "Kraków", suffix: "krakow"},
            {name: "Oświęcim", suffix: "oswiecim"},
            {name: "Tarnów", suffix: "tarnow"},
            {name: "Wadowice", suffix: "wadowice"},
            {name: "Nowy Sącz", suffix: "nowy-sacz"},
            {name: "Chrzanów", suffix: "chrzanow"},
            {name: "Wieliczka", suffix: "wieliczka"},
            {name: "Olkusz", suffix: "olkusz"},
            {name: "Andrychów", suffix: "andrychow"},
            {name: "Zakopane", suffix: "zakopane"},
            {name: "Skawina", suffix: "skawina"},
            {name: "Niepołomice", suffix: "niepolomice"},
            {name: "Zielonki", suffix: "krakowski-zielonki"}
        ],
        cities_houses: [
            {name: "Kraków", suffix: "krakow"},
            {name: "Oświęcim", suffix: "oswiecim"},
            {name: "Tarnów", suffix: "tarnow"},
            {name: "Wieliczka", suffix: "wieliczka"},
            {name: "Niepołomice", suffix: "niepolomice"},
            {name: "Zakopane", suffix: "zakopane"},
            {name: "Olkusz", suffix: "olkusz"},
            {name: "Nowy Sącz", suffix: "nowy-sacz"},
            {name: "Michałowice", suffix: "krakowski-michalowice"}
        ]
    },
    {
        name: "mazowieckie",
        suffix: "mazowieckie",
        cities_flats: [
            {name: "Warszawa", suffix: "warszawa"},
            {name: "Nowy Dwór Mazowiecki", suffix: "nowy-dwor-mazowiecki"},
            {name: "Płock", suffix: "plock"},
            {name: "Pruszków", suffix: "pruszkow"},
            {name: "Przasnysz", suffix: "przasnysz"},
            {name: "Piaseczno", suffix: "piaseczno"},
            {name: "Siedlce", suffix: "siedlce"},
            {name: "Radom", suffix: "radom"},
            {name: "Ciechanów", suffix: "ciechanow"},
            {name: "Grodzisk Mazowiecki", suffix: "grodzisk-mazowiecki"},
            {name: "Marki", suffix: "marki"},
            {name: "Ostrołęka", suffix: "ostroleka"},
            {name: "Ożarów Mazowiecki", suffix: "ozarow-mazowiecki"},
            {name: "Legionowo", suffix: "legionowo"},
            {name: "Otwock", suffix: "otwock"},
            {name: "Mińsk Mazowiecki", suffix: "minsk-mazowiecki"},
            {name: "Nowa Wola", suffix: "piaseczynski-nowa-wola"},
            {name: "Józefosław", suffix: "piaseczynski-jozefoslaw"}
        ],
        cities_houses: [
            {name: "Warszawa", suffix: "warszawa"},
            {name: "Ożarów Mazowiecki", suffix: "ozarow-mazowiecki"},
            {name: "Siedlce", suffix: "siedlce"},
            {name: "Radom", suffix: "radom"},
            {name: "Legionowo", suffix: "legionowo"},
            {name: "Grodzisk Mazowiecki", suffix: "grodzisk-mazowiecki"},
            {name: "Żyrardów", suffix: "zyrardow"},
            {name: "Marki", suffix: "marki"},
            {name: "Łomianki", suffix: "lomianki"},
            {name: "Piaseczno", suffix: "piaseczno"},
            {name: "Ostrołęka", suffix: "ostroleka"},
            {name: "Góraszka", suffix: "otwocki-goraszka"},
            {name: "Magdalenka", suffix: "piaseczynski-magdalenka"},
            {name: "Mińsk Mazowiecki", suffix: "minsk-mazowiecki"},
            {name: "Nadarzyn", suffix: "pruszkowski-nadarzyn"},
            {name: "Dawidy Bankowe", suffix: "pruszkowski-dawidy-bankowe"},
            {name: "Zgorzała", suffix: "piaseczynski-zgorzala"},
            {name: "Nowa Wola", suffix: "piaseczynski-nowa-wola"},
            {name: "Józefosław", suffix: "piaseczynski-jozefoslaw"},
            {name: "Falenty Nowe", suffix: "pruszkowski-falenty-nowe"}
        ]
    },
    {
        name: "opolskie",
        suffix: "opolskie",
        cities_flats: [
            {name: "Opole", suffix: "opole"},
            {name: "Brzeg", suffix: "brzeg"},
            {name: "Krapkowice", suffix: "krapkowice"},
            {name: "Nysa", suffix: "nysa"},
            {name: "Namysłów", suffix: "namyslow"}
        ],
        cities_houses: [
            {name: "Opole", suffix: "opole"},
            {name: "Brzeg", suffix: "brzeg"},
            {name: "Nysa", suffix: "nysa"},
            {name: "Namysłów", suffix: "namyslow"}
        ]
    },
    {
        name: "podkarpackie",
        suffix: "podkarpackie",
        cities_flats: [
            {name: "Rzeszów", suffix: "rzeszow"},
            {name: "Przemyśl", suffix: "przemysl"},
            {name: "Tarnobrzeg", suffix: "tarnobrzeg"},
            {name: "Mielec", suffix: "mielec"},
            {name: "Dębica", suffix: "debica"},
            {name: "Stalowa Wola", suffix: "stalowa-wola"},
            {name: "Krosno", suffix: "krosno"},
            {name: "Jarosław", suffix: "jaroslaw"},
            {name: "Sanok", suffix: "sanok"},
            {name: "Łańcut", suffix: "lancut"}
        ],
        cities_houses: [
            {name: "Rzeszów", suffix: "rzeszow"},
            {name: "Mielec", suffix: "mielec"},
            {name: "Sanok", suffix: "sanok"},
            {name: "Stalowa Wola", suffix: "stalowa-wola"},
            {name: "Krosno", suffix: "krosno"},
            {name: "Dębica", suffix: "debica"},
            {name: "Tarnobrzeg", suffix: "tarnobrzeg"}
        ]
    },
    {
        name: "podlaskie",
        suffix: "podlaskie",
        cities_flats: [
            {name: "Białystok", suffix: "bialystok"},
            {name: "Augustów", suffix: "augustow"},
            {name: "Łomża", suffix: "lomza"},
            {name: "Suwałki", suffix: "suwalki"},
            {name: "Bielsk Podlaski", suffix: "bielsk-podlaski"},
            {name: "Wasilków", suffix: "wasilkow"},
            {name: "Siemiatycze", suffix: "siemiatycze"},
            {name: "Łapy", suffix: "lapy"},
            {name: "Hajnówka", suffix: "hajnowka"},
            {name: "Grajewo", suffix: "grajewo"}
        ],
        cities_houses: [
            {name: "Białystok", suffix: "bialystok"},
            {name: "Augustów", suffix: "augustow"},
            {name: "Łomża", suffix: "lomza"},
            {name: "Suwałki", suffix: "suwalki"},
            {name: "Bielsk Podlaski", suffix: "bielsk-podlaski"},
            {name: "Wasilków", suffix: "wasilkow"},
            {name: "Hajnówka", suffix: "hajnowka"}
        ]
    },
    {
        name: "pomorskie",
        suffix: "pomorskie",
        cities_flats: [
            {name: "Gdańsk", suffix: "gdansk"},
            {name: "Gdynia", suffix: "gdynia"},
            {name: "Tczew", suffix: "tczew"},
            {name: "Malbork", suffix: "malbork"},
            {name: "Wejherowo", suffix: "wejherowo"},
            {name: "Słupsk", suffix: "slupsk"},
            {name: "Rumia", suffix: "rumia"},
            {name: "Pruszcz Gdański", suffix: "pruszcz-gdanski"},
            {name: "Kwidzyn", suffix: "kwidzyn"},
            {name: "Starogard Gdański", suffix: "starogard-gdanski"},
            {name: "Sopot", suffix: "sopot"},
            {name: "Puck", suffix: "puck"},
            {name: "Hel", suffix: "hel"},
            {name: "Jastrzębia Góra", suffix: "pucki-jastrzebia-gora"},
            {name: "Pogórze", suffix: "pucki-pogorze"}
        ],
        cities_houses: [
            {name: "Rumia", suffix: "rumia"},
            {name: "Słupsk", suffix: "slupsk"},
            {name: "Gdańsk", suffix: "gdansk"},
            {name: "Chojnice", suffix: "chojnice"},
            {name: "Gdynia", suffix: "gdynia"},
            {name: "Tczew", suffix: "tczew"},
            {name: "Pruszcz Gdański", suffix: "pruszcz-gdanski"},
            {name: "Wejherowo", suffix: "wejherowo"},
            {name: "Malbork", suffix: "malbork"},
            {name: "Reda", suffix: "reda"}
        ]
    },
    {
        name: "śląskie",
        suffix: "slaskie",
        cities_flats: [
            {name: "Katowice", suffix: "katowice"},
            {name: "Gliwice", suffix: "gliwice"},
            {name: "Częstochowa", suffix: "czestochowa"},
            {name: "Zabrze", suffix: "zabrze"},
            {name: "Tychy", suffix: "tychy"},
            {name: "Sosnowiec", suffix: "sosnowiec"},
            {name: "Rybnik", suffix: "rybnik"},
            {name: "Racibórz", suffix: "raciborz"},
            {name: "Jaworzno", suffix: "jaworzno"},
            {name: "Bytom", suffix: "bytom"},
            {name: "Ruda Śląska", suffix: "ruda-slaska"},
            {name: "Dąbrowa Górnicza", suffix: "dabrowa-gornicza"},
            {name: "Chorzów", suffix: "chorzow"},
            {name: "Bielsko", suffix: "bielsko-biala"},
            {name: "Piekary Śląskie", suffix: "piekary-slaskie"},
            {name: "Żywiec", suffix: "zywiec"}
        ],
        cities_houses: [
            {name: "Częstochowa", suffix: "czestochowa"},
            {name: "Zabrze", suffix: "zabrze"},
            {name: "Ruda Śląska", suffix: "ruda-slaska"},
            {name: "Katowice", suffix: "katowice"},
            {name: "Tychy", suffix: "tychy"},
            {name: "Jaworzno", suffix: "jaworzno"},
            {name: "Mikołów", suffix: "mikolow"},
            {name: "Zawiercie", suffix: "zawiercie"},
            {name: "Tarnowskie Góry", suffix: "tarnowskie-gory"},
            {name: "Gliwice", suffix: "gliwice"},
            {name: "Sosnowiec", suffix: "sosnowiec"}
        ]
    },
    {
        name: "świętokrzyskie",
        suffix: "swietokrzyskie",
        cities_flats: [
            {name: "Kielce", suffix: "kielce"},
            {name: "Starachowice", suffix: "starachowice"},
            {name: "Sandomierz", suffix: "sandomierz"},
            {name: "Jędrzejów", suffix: "jedrzejow"},
            {name: "Końskie", suffix: "konskie"},
            {name: "Pińczów", suffix: "pinczow"},
            {name: "Włoszczowa", suffix: "wloszczowa"},
            {name: "Nowiny", suffix: "kielecki-nowiny"}
        ],
        cities_houses: [
            {name: "Kielce", suffix: "kielce"},
            {name: "Starachowice", suffix: "starachowice"},
            {name: "Sandomierz", suffix: "sandomierz"},
            {name: "Ostrowiec Świętokrzyski", suffix: "ostrowiec-swietokrzyski"},
            {name: "Końskie", suffix: "konskie"},
            {name: "Jędrzejów", suffix: "jedrzejow"}
        ]
    },
    {
        name: "warmińsko-mazurskie",
        suffix: "warminsko-mazurskie",
        cities_flats: [
            {name: "Olsztyn", suffix: "olsztyn"},
            {name: "Ełk", suffix: "elk"},
            {name: "Elbląg", suffix: "elblag"},
            {name: "Ostróda", suffix: "ostroda"},
            {name: "Lubawa", suffix: "lubawa"},
            {name: "Iława", suffix: "ilawa"},
            {name: "Giżycko", suffix: "gizycko"},
            {name: "Szczytno", suffix: "szczytno"},
            {name: "Olecko", suffix: "olecko"},
            {name: "Pisz", suffix: "pisz"},
            {name: "Węgorzewo", suffix: "wegorzewo"}
        ],
        cities_houses: [
            {name: "Olsztyn", suffix: "olsztyn"},
            {name: "Ełk", suffix: "elk"},
            {name: "Iława", suffix: "ilawa"},
            {name: "Ostróda", suffix: "ostroda"},
            {name: "Elbląg", suffix: "elblag"},
            {name: "Węgorzewo", suffix: "wegorzewo"}
        ]
    },
    {
        name: "wielkopolskie",
        suffix: "wielkopolskie",
        cities_flats: [
            {name: "Poznań", suffix: "poznan"},
            {name: "Rawicz", suffix: "rawicz"},
            {name: "Gniezno", suffix: "gniezno"},
            {name: "Kalisz", suffix: "kalisz"},
            {name: "Kępno", suffix: "kepno"},
            {name: "Piła", suffix: "pila"},
            {name: "Konin", suffix: "konin"},
            {name: "Jarocin", suffix: "jarocin"},
            {name: "Murowana Goślina", suffix: "murowana-goslina"},
            {name: "Sady", suffix: "poznanski-sady"},
            {name: "Luboń", suffix: "lubon"},
            {name: "Czapury", suffix: "poznanski-czapury"}
        ],
        cities_houses: [
            {name: "Krotoszyn", suffix: "krotoszyn"},
            {name: "Poznań", suffix: "poznan"},
            {name: "Jarocin", suffix: "jarocin"},
            {name: "Swarzędz", suffix: "swarzedz"},
            {name: "Gniezno", suffix: "gniezno"},
            {name: "Wysoka", suffix: "wysoka"},
            {name: "Kalisz", suffix: "kalisz"},
            {name: "Luboń", suffix: "lubon"},
            {name: "Piła", suffix: "pila"},
            {name: "Pobiedziska", suffix: "pobiedziska"},
            {name: "Komorniki", suffix: "poznanski-komorniki"},
            {name: "Szamotuły", suffix: "szamotuly"},
            {name: "Lusówko", suffix: "poznanski-lusowko"}
        ]
    },
    {
        name: "zachodniopomorskie",
        suffix: "zachodniopomorskie",
        cities_flats: [
            {name: "Szczecin", suffix: "szczecin"},
            {name: "Koszalin", suffix: "koszalin"},
            {name: "Police", suffix: "police"},
            {name: "Kołobrzeg", suffix: "kolobrzeg"},
            {name: "Świnoujście", suffix: "swinoujscie"},
            {name: "Wałcz", suffix: "walcz"},
            {name: "Międzyzdroje", suffix: "miedzyzdroje"},
            {name: "Ustronie Morskie", suffix: "kolobrzeski-ustronie-morskie"},
            {name: "Warzymice", suffix: "policki-warzymice"},
            {name: "Mielno", suffix: "mielno"},
            {name: "Darłowo", suffix: "darlowo"},
            {name: "Dziwnów", suffix: "dziwnow"}
        ],
        cities_houses: [
            {name: "Szczecin", suffix: "szczecin"},
            {name: "Koszalin", suffix: "koszalin"},
            {name: "Wolin", suffix: "wolin"},
            {name: "Szczecinek", suffix: "szczecinek"},
            {name: "Police", suffix: "police"},
            {name: "Nowogard", suffix: "nowogard"},
            {name: "Mierzyn", suffix: "policki-mierzyn"},
            {name: "Stargard", suffix: "stargard"}
        ]
    }
];
