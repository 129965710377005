import {detailOfferListConstraints} from "../constants/detail_offer_list_constraints";
import {DEFAULT_DISTANCE_RELATED_OFFERS} from "../list/constants/offer_list";
import {IGetOfferListPayload} from "../types/GetOfferListPayload";
import {OfferType} from "../types/OfferType";

interface IParams {
    page: number;
    offer: {
        id: number;
        type: OfferType;
    };
}

export function getDetailPageRelatedOffersPayload(params: IParams): IGetOfferListPayload {
    const {
        page,
        offer: {id, type: offerType}
    } = params;

    return {
        ...detailOfferListConstraints,
        page,
        page_size: 3,
        type: offerType,
        near_by_offer: id,
        exclude_offer: id,
        distance: DEFAULT_DISTANCE_RELATED_OFFERS
    };
}
