/**
 * OfferDisplayType defines offer status.
 * It is widely used throughout application to manage limitation in display.
 *
 * At some point, it was divided to OfferDisplayType and OfferDisplayStatus.
 * More details can be found in this task - https://app.clickup.com/t/862j2xxf3
 */

export enum OfferDisplayType {
    PLANNED = 0,
    FOR_SALE = 1,
    SOLD = 2,
    ON_HOLD = 3
}

export function getOfferDisplayTypeNameGTM(offerDisplayType: OfferDisplayType) {
    switch (offerDisplayType) {
        case OfferDisplayType.FOR_SALE:
            return "W sprzedaży";
        case OfferDisplayType.SOLD:
            return "Sprzedane";
        case OfferDisplayType.PLANNED:
            return "Planowane";
        case OfferDisplayType.ON_HOLD:
            return "Wstrzymane";
        default:
            throw new Error("Unknown offer display type");
    }
}
