import {AnyAction} from "redux";

import {EXCLUDE_CURRENT_ROOM_FILTER_OPTION} from "../actions/fetch_other_properties_in_offer";

export const excludeCurrentRoomFilterOption = (state = false, action: AnyAction) => {
    switch (action.type) {
        case EXCLUDE_CURRENT_ROOM_FILTER_OPTION:
            return action.flag;
        default:
            return state;
    }
};
