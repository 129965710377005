import {ValidOfferApiParams, validOfferApiParams} from "../../../../generated/valid_offer_api_params";

type QueryParamValueType = string | string[] | number | number[] | null | boolean;
type LooseOfferListQuery = Record<ValidOfferApiParams | string, QueryParamValueType>;
/**
 * this type uses autogenerated list of valid offer list query params.
 * Regenerate with `yarn workspace rp generate:offer-api-params`.
 *
 * Value types are pretty loose and may need to be adjusted in the future,
 * but keep in mind that right now we can use this type to describe a mix of user input from search query and our own code.
 */
export type ValidOfferListQuery = Partial<Record<ValidOfferApiParams, QueryParamValueType>>;

/**
 * Function sanitizes offer list query params, returns query that uses only keys usable by the offer list API. Valid list
 * of keys is generated from OpenApi/Swagger specification of staging API. This will need to be regenerated if the API changes.
 * @param query object containing offer list query params
 */
export const getValidOfferListQuery = (query: LooseOfferListQuery) => {
    const validQuery = {} as ValidOfferListQuery;
    for (const key in query) {
        if (validOfferApiParams.includes(key) || key === "s") {
            validQuery[key as ValidOfferApiParams] = query[key];
        }
    }
    return validQuery;
};
