import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {appendQueryString, getRequest} from "@pg-mono/request";
import {createRequestActionTypes} from "@pg-mono/request-state";
import {apiV2ListLink, Scenario} from "@pg-mono/rp-api-routes";

import {IRPRequestMeta} from "../../app/rp_request_meta";

const FETCH_DESKTOP_VENDOR_DETAIL_RANKINGS_PREFIX = "desktop_vendor_detail/FETCH_VENDOR_DETAIL_RANKINGS2";
export const fetchDesktopVendorDetailRankingsTypes = createRequestActionTypes(FETCH_DESKTOP_VENDOR_DETAIL_RANKINGS_PREFIX);
const DEFAULT_RANKING_RANGE = {position_range_0: 1, position_range_1: 3} as const;

export interface IRanking {
    id: number;
    offer: {
        id: number;
        main_image: {
            // m_img_375x211: string;
            // m_img_440x248: string;
            m_img_760x428: string;
        };
        name: string;
        slug: string;
    };
    position: number;
    region: {
        name: string;
        slug: string;
    };
    quarter: number;
    year: number;
}

interface IRankingResponse {
    results: IRanking[];
}

const quarterRankingListApiLink = apiV2ListLink.ratings.quarterly(Scenario.RATINGS_VENDOR_DETAIL);

export const fetchVendorDetailRankingsAtRoute = (ctx: IFetchContext<IRPRequestMeta>) => async (dispatch: Dispatch) => {
    dispatch({type: fetchDesktopVendorDetailRankingsTypes.start});

    const url = appendQueryString(quarterRankingListApiLink, {
        vendor: ctx.match.params.vendorId,
        ...DEFAULT_RANKING_RANGE
    });

    return getRequest(ctx.meta, url).then((res: IRankingResponse) => {
        dispatch({type: fetchDesktopVendorDetailRankingsTypes.success, result: res.results});
        return res;
    });
};
