import {combineReducers} from "redux";

import {IAppError, reduceLatestQuery, reduceRequestErrors, reduceRequestState, reduceResponse, reduceResponseField, RequestState} from "@pg-mono/request-state";

import {ILatestQuery} from "../../../utils/latest_query";
import {fetchPropertiesCountTypes} from "../../list/actions/fetch_properties_count";
import {IOfferListOffer} from "../../list/types/IOfferListResponse";
import {fetchDesktopMapOfferListTypes} from "../actions/fetch_map_offer_list";

export interface IOfferListMapListStore {
    offers: IOfferListOffer[];
    count: number;
    requestState: RequestState;
    latestQuery: ILatestQuery;
    error: IAppError;
    propertiesCount: number | null;
}

export const offerListMapListReducer = combineReducers({
    offers: reduceResponseField<IOfferListOffer[]>(fetchDesktopMapOfferListTypes, "offers", []),
    count: reduceResponseField<number>(fetchDesktopMapOfferListTypes, "count", 0),
    requestState: reduceRequestState(fetchDesktopMapOfferListTypes),
    latestQuery: reduceLatestQuery(fetchDesktopMapOfferListTypes),
    error: reduceRequestErrors(fetchDesktopMapOfferListTypes),
    propertiesCount: reduceResponse(fetchPropertiesCountTypes)
});
