import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {isEmpty} from "@pg-mono/nodash";
import {catch400, catch404, getCookieFromText, postRequest, setCookieFromResponseToRequest} from "@pg-mono/request";
import {enable302ResponseState, enable404ResponseState} from "@pg-mono/response-state";
import {apiLink} from "@pg-mono/rp-api-routes";

import {rpCommonUrl} from "../../app/read_rp_environment_variables";
import {IRPRequestMeta} from "../../app/rp_request_meta";
import {notifyBugsnag} from "../../errors/bugsnag/notify_bugsnag";
import {fetchOfferDetailById} from "../../offer/api/fetch_offer_detail_by_id";
import {useOfferLink} from "../../offer/detail/hooks/use_offer_link";
import {fetchCookiesOnServer, fetchMpCookiesOnServer} from "../../user/api/fetch_cookies";
import {IApplication} from "../types/IApplication";
import {ApplicationSource} from "../utils/ApplicationSource";
import {generateApplicationText} from "../utils/generate_application_text";

const CSRF_TOKEN_COOKIE_NAME = "csrftoken";
const MP_CSRF_TOKEN_COOKIE_NAME = "mp-csrftoken";

export const generateApplicationAtRoute = (ctx: IFetchContext<IRPRequestMeta>) => async (dispatch: Dispatch) => {
    if (process.env.EXEC_ENV !== "browser") {
        const requestHeaders = (ctx.meta.serverRequestHeaders && ctx.meta.serverRequestHeaders.getHeaders()) || {};
        const responseHeaders = (ctx.meta.serverResponseHeaders && ctx.meta.serverResponseHeaders.getHeaders()) || {};

        // Try to fetch `csrftoken` if it does not exist
        if (isEmpty(requestHeaders) || (requestHeaders && requestHeaders.cookie && !getCookieFromText(CSRF_TOKEN_COOKIE_NAME, requestHeaders.cookie))) {
            await fetchCookiesOnServer(ctx.meta);

            setCookieFromResponseToRequest(ctx.meta, CSRF_TOKEN_COOKIE_NAME);
        }

        if (isEmpty(requestHeaders) || (requestHeaders && requestHeaders.cookie && !getCookieFromText(MP_CSRF_TOKEN_COOKIE_NAME, requestHeaders.cookie))) {
            await fetchMpCookiesOnServer(ctx.meta);

            setCookieFromResponseToRequest(ctx.meta, MP_CSRF_TOKEN_COOKIE_NAME);
        }

        ctx.meta.serverRequestHeaders?.appendHeaders({referer: `${rpCommonUrl}${ctx.route.pathname}`, ...requestHeaders, ...responseHeaders});
    }

    const {applicationUuid, offerId} = ctx.match.params;

    const offer = await fetchOfferDetailById(ctx.meta, offerId);

    if (!offer) {
        dispatch(enable404ResponseState());

        return false;
    }

    const application: Partial<IApplication> = {
        application_uuid: applicationUuid,
        offer: offer.id,
        source: ApplicationSource.Offer,
        text: generateApplicationText({source: ApplicationSource.Offer, offerName: offer.name}),
        utm_campaign: ctx.route.query.utm_campaign as string
    };

    const offerLink = useOfferLink(offer);

    const applicationApiLink = apiLink.application.create({});

    return postRequest(ctx.meta, applicationApiLink(null), application)
        .then(() => {
            dispatch(enable302ResponseState(offerLink));
        })
        .catch(
            catch400((error) => {
                notifyBugsnag(error, "Error in generateApplicationAtRoute", {originalError: JSON.stringify(error)});

                dispatch(enable302ResponseState(offerLink));
            })
        )
        .catch(
            catch404(() => {
                dispatch(enable404ResponseState());
            })
        )
        .finally(() => false);
};
