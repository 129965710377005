interface IRegion {
    id: number;
    tree?: {
        id: number;
        left: number;
        right: number;
    };
}

export const addNewRegionToCurrentRegions = <TRegion extends IRegion>(currentRegions: TRegion[] | undefined, optionRegion: TRegion): TRegion[] => {
    const isRegionIncluded = currentRegions?.some((region) => region.id === optionRegion.id);
    if (currentRegions && isRegionIncluded) {
        return currentRegions;
    }
    const currentRegionsWithoutRelatives = currentRegions?.filter((region) => {
        // only keep unrelated regions
        return !isAnyRegionRelative(region, optionRegion);
    });
    return [optionRegion, ...(currentRegionsWithoutRelatives || [])];
};

type IIsRegionRelatedFn = (currentRegion: IRegion, optionRegion: IRegion) => boolean;

const isAnyRegionRelative: IIsRegionRelatedFn = (currentRegion, optionRegion) => {
    // we don't have region tree in first mocked search suggestions (some of optionRegions)
    // the same region tree id means that regions are in the same voivodeship
    const areOnSameRegionTree = optionRegion.tree && optionRegion.tree.id === currentRegion.tree?.id;
    if (!areOnSameRegionTree) {
        return false;
    }
    if (isFirstDescendantOfSecond(currentRegion, optionRegion)) {
        return true;
    }
    return isFirstAscendantOfSecond(currentRegion, optionRegion);
};

const isFirstDescendantOfSecond: IIsRegionRelatedFn = (currentRegion, optionRegion) => {
    if (currentRegion.tree && optionRegion.tree) {
        // descendant has too fit inside the boundaries of second
        const isInLeftBoundary = currentRegion.tree.left >= optionRegion.tree.left;
        const isInRightBoundary = currentRegion.tree.right <= optionRegion.tree.right;
        return isInLeftBoundary && isInRightBoundary;
    }
    return false;
};

const isFirstAscendantOfSecond: IIsRegionRelatedFn = (currentRegion, optionRegion) => {
    if (currentRegion.tree && optionRegion.tree) {
        // ascendant has to wrap around the boundaries of second
        const isOutsideLeftBoundary = currentRegion.tree.left <= optionRegion.tree.left;
        const isOutsideRightBoundary = currentRegion.tree.right >= optionRegion.tree.right;
        return isOutsideLeftBoundary && isOutsideRightBoundary;
    }
    return false;
};
