import {Dispatch} from "redux";

import {IFetchAction, IFetchContext} from "./create_data_fetcher";

// iterates over actions, until their result is Truthly
export const reduceActions =
    <TMeta, TStore>(...fetchActions: IFetchAction<TMeta, TStore>[]): IFetchAction<TMeta, TStore> =>
    (ctx: IFetchContext<TMeta>) =>
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async (dispatch: Dispatch, getState: () => TStore) => {
        let idx = 0;
        let result: unknown = ctx.prevResult;
        while (result && idx < fetchActions.length) {
            try {
                result = await dispatch(fetchActions[idx++]({...ctx, prevResult: result}));
            } catch (error) {
                return Promise.reject(error);
            }
        }
        return Promise.resolve(result);
    };

// returns Truthly (array value), so following actions will always be executed
export const mapActions =
    <TMeta, TStore>(...fetchActions: IFetchAction<TMeta, TStore>[]): IFetchAction<TMeta, TStore> =>
    (ctx: IFetchContext<TMeta>) =>
    (dispatch: Dispatch) => {
        return Promise.all(fetchActions.map((action) => dispatch(action(ctx))));
    };

// returns true/false based on responses, so following actions may not be executed
export const strictMapActions =
    <TMeta, TStore>(...fetchActions: IFetchAction<TMeta, TStore>[]): IFetchAction<TMeta, TStore> =>
    (ctx: IFetchContext<TMeta>) =>
    async (dispatch: Dispatch): Promise<boolean> => {
        const allResponses = await Promise.all(fetchActions.map((action) => dispatch(action(ctx))));
        for (const res of allResponses) {
            if (res === false) {
                return false;
            }
        }
        return true;
    };

// /**
//  * Pure actions - without services
//  */
// export const reduceActionsPure = (...fetchActions: IFetchActionPure<any>[]): IFetchActionPure<any> => (
//     route: IRouteState<{}>,
//     prevRoute: IPrevRouteState | null,
//     prevActionResult: any = null
// ) => (dispatch: Dispatch) => {
//     return new Promise(async (resolve, reject) => {
//         let idx = 0;
//         let result: any = prevActionResult;
//         while (result && idx < fetchActions.length) {
//             try {s
//                 result = await dispatch(fetchActions[idx++](route, prevRoute, result));
//             } catch (error) {
//                 return reject(error);
//             }
//         }
//         return resolve(result);
//     });
// };
