import {combineReducers} from "redux";

import {reduceRequestState, RequestState} from "@pg-mono/request-state";

import {fetchAllListsType} from "../../search/actions/fetch_search_all_action";
import {vendorSearchFormFields} from "../components/SearchVendor";
import {IVendorDetailStore, vendorDetailReducer} from "./vendor_detail_reducer";
import {IVendorListStore, vendorListReducer} from "./vendor_list_reducer";
import {INearbyRegionVendorsStore, vendorNearbyVendorsReducer} from "./vendor_nearby_vendors_reducer";

export interface IVendorStore {
    list: IVendorListStore;
    detail: IVendorDetailStore;
    searchFormValues: Record<keyof typeof vendorSearchFormFields, any>; // eslint-disable-line @typescript-eslint/no-explicit-any
    fetchAllRequest: RequestState;
    nearbyRegionVendors: INearbyRegionVendorsStore;
}

export const vendorReducer = combineReducers({
    list: vendorListReducer,
    detail: vendorDetailReducer,
    nearbyRegionVendors: vendorNearbyVendorsReducer,
    fetchAllRequest: reduceRequestState(fetchAllListsType)
});
