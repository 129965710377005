export type IActionResponseState =
    | {
          type: "response_state/ENABLE_301" | "response_state/ENABLE_302";
          url: string;
      }
    | {
          type: "response_state/ENABLE_ERROR";
          status: number;
      }
    | {
          type: "response_state/ENABLE_404" | "response_state/DISABLE";
      };

export const ENABLE_301_RESPONSE_STATE = "response_state/ENABLE_301";
export const enable301ResponseState = (url: string): IActionResponseState => ({type: ENABLE_301_RESPONSE_STATE, url});

export const ENABLE_302_RESPONSE_STATE = "response_state/ENABLE_302";
export const enable302ResponseState = (url: string): IActionResponseState => ({type: ENABLE_302_RESPONSE_STATE, url});

export const ENABLE_404_RESPONSE_STATE = "response_state/ENABLE_404";
export const enable404ResponseState = (): IActionResponseState => ({type: ENABLE_404_RESPONSE_STATE});

export const ENABLE_ERROR_RESPONSE_STATE = "response_state/ENABLE_ERROR";
export const enableErrorResponseState = (status: number): IActionResponseState => ({
    type: ENABLE_ERROR_RESPONSE_STATE,
    status
});

export const DISABLE_RESPONSE_STATE = "response_state/DISABLE";
export const disableResponseState = (): IActionResponseState => ({type: DISABLE_RESPONSE_STATE});
