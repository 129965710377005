export const pick = <TObject extends object, TKey extends keyof TObject>(object: TObject | null, keys: TKey[]) => {
    return keys.reduce(
        (result, key) => {
            if (object && key in object) {
                result[key] = object[key];
            }
            return result;
        },
        {} as {[key in TKey]: TObject[key]}
    );
};
