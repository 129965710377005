import {IFetchContext} from "@pg-mono/data-fetcher";
import {IMpRegion} from "@pg-mono/rp-api-routes";
import {IModularHouseOfferParams} from "@pg-mono/rp-routes";
import {areaFormat} from "@pg-mono/string-utils";

import {mpApi} from "../../app/api/mp_api";
import {IRPStore} from "../../app/rp_reducer";
import {IRPRequestMeta} from "../../app/rp_request_meta";
import {rpEnable404ResponseState} from "../../errors/actions/rp_enable_404_response_state";
import {notifyBugsnag} from "../../errors/bugsnag/notify_bugsnag";
import {updateMetaData} from "../../meta_data/actions/update_meta_data";
import {getMarketplaceRegionListApi} from "../../region/api/get_marketplace_region_list";
import {getRegionDetailApi} from "../../region/api/get_region_detail";
import {IAppDispatch} from "../../store/store";
import {isRtkqError} from "../../store/utils/is_rtkq_error";
import {getModularHousesOfferDetailApi} from "../api/get_modular_houses_offer_detail";

export const modularHousesOfferDetailViewAction =
    (ctx: IFetchContext<IRPRequestMeta, IModularHouseOfferParams>) => async (dispatch: IAppDispatch, _getState: () => IRPStore) => {
        const {offerId} = ctx.match.params;

        const modularHouseOffer = await dispatch(getModularHousesOfferDetailApi.endpoints.getModularHousesOfferDetail.initiate({offerId: parseInt(offerId)}));

        if ((modularHouseOffer.isError && isRtkqError(modularHouseOffer.error) && modularHouseOffer.error.status === 404) || !modularHouseOffer.data) {
            await dispatch(rpEnable404ResponseState(ctx.route.pathname));
            return false;
        }

        const mhOffer = modularHouseOffer.data;
        const mpRegionsRes = await dispatch(getMarketplaceRegionListApi.endpoints.getMarketplaceRegionList.initiate()).unwrap();
        const contractorRegion = mpRegionsRes && mhOffer ? mpRegionsRes.results.find((mpRegions) => mpRegions.id === mhOffer.contractor.regions[0]) : null;

        if (!contractorRegion) {
            const errorMessage = `Contractor region ${mhOffer.contractor.regions[0]} not found in MP regions`;
            notifyBugsnag(new Error(errorMessage), errorMessage);
        }

        const regionId = (contractorRegion as IMpRegion).remote_pk;
        await dispatch(getRegionDetailApi.endpoints.getRegionDetail.initiate({regionId}));

        await Promise.all(dispatch(mpApi.util.getRunningQueriesThunk()));

        dispatch(
            updateMetaData({
                title: `Dom modułowy ${mhOffer.name} ${mhOffer.contractor.name} - rynekpierwotny.pl`,
                description: `Dom modułowy ${mhOffer.name} ${mhOffer.contractor.name} - ${areaFormat(parseFloat(mhOffer.area), {precision: 2})}. Sprawdź gotowe domy modułowe - kup projekt już dziś!`,
                pathname: ctx.route.pathname
            })
        );
    };
