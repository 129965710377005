export enum StageStatus {
    FOR_SALE = 1,
    SOLD = 2,
    PLANNED = 3
}

export interface IOfferStage {
    id: number;
    name: string;
    slug: string;
    offer: {
        id: number;
        name: string;
        slug: string;
        vendor: {
            slug: string;
        };
    };
    status: StageStatus;
    sort?: number;
    primary: boolean;
    current: boolean;
}

export interface IOfferGroupsSimplified {
    id: number;
    stages: IOfferStage[];
}

export interface IOfferGroups extends IOfferGroupsSimplified {
    plan: number | null;
    plan_image: string | null;
    plan_image_width: number | null;
    plan_image_height: number | null;
    polygons: unknown;
    vendor: number;
}
