import {BRAND_NAME} from "../../app/constants/company";
import {concatWithBrandName} from "../../app/utils/concat_with_brand_name";
import {IUpdateMetaDataAction, UPDATE_META_DATA} from "../actions/update_meta_data";

export interface IMetaDataStore {
    title: string;
    description: string;
    canonical: string;
    prev?: string;
    next?: string;
    robots?: string;
    dimensionRegion?: string;
    gtmPage?: string;
    ogImage?: string;
    ogDescription?: string;
    ogTitle?: string;
    ogType?: string;
    ogUrl?: string;
    ogPublishDate?: string;
    hreflang?: {language_code: string; slug: string}[];
    lang: string;
}

export const rpCanonicalUrl = "https://rynekpierwotny.pl";

export const defaultMetaData = {
    title: `Oferty nowych mieszkań i domów na sprzedaż - ${BRAND_NAME}`,
    description:
        "Wszystkie oferty nowych mieszkań i domów od deweloperów w jednym miejscu, oferty dla inwestorów, " +
        `aktualne promocje oraz wiadomości z rynku nieruchomości &#10004;. ${concatWithBrandName("Portal")} zaprasza &#128077;`,
    canonical: rpCanonicalUrl,
    lang: "pl"
};

export const metaDataReducer = (state: IMetaDataStore = defaultMetaData, action: IUpdateMetaDataAction): IMetaDataStore => {
    if (action.type === UPDATE_META_DATA) {
        const metaData: IMetaDataStore = {
            title: action.title,
            description: action.description,
            canonical: action.canonical,
            prev: action.prev,
            next: action.next,
            robots: action.robots,
            ogDescription: action.ogDescription,
            ogImage: action.ogImage,
            ogTitle: action.ogTitle,
            ogType: action.ogType,
            ogUrl: action.ogUrl,
            ogPublishDate: action.ogPublishDate,
            dimensionRegion: action.dimensionRegion,
            gtmPage: action.gtmPage,
            hreflang: action.hreflang,
            lang: action.lang
        };
        return metaData;
    }
    return state;
};
