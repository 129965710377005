import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {appendQueryString} from "@pg-mono/request";
import {enable301ResponseState, enable404ResponseState} from "@pg-mono/response-state";

import {IRPRequestMeta} from "../../app/rp_request_meta";
import {fetchOfferDetailById} from "../../offer/api/fetch_offer_detail_by_id";
import {createOfferLink} from "../../offer/helpers/create_offer_link";

export const redirectOfferPermalink = (ctx: IFetchContext<IRPRequestMeta>) => async (dispatch: Dispatch) => {
    const offerId = parseInt(ctx.match.params.offerId as string, 10);

    if (isFinite(offerId)) {
        const offer = await fetchOfferDetailById(ctx.meta, offerId);

        if (offer) {
            const redirectUrl = appendQueryString(createOfferLink(offer), ctx.route.query);

            dispatch(enable301ResponseState(redirectUrl));

            return true;
        }
    }

    dispatch(enable404ResponseState());

    return true;
};
