import {combineReducers} from "redux";

import {reduceRequestState, reduceResponse, RequestState} from "@pg-mono/request-state";

import {fetchRegionStatsTypes} from "../actions/fetch_properties_stats";

export interface IRegionStats {
    area_min: number;
    area_avg: number | null;
    area_max: number;
    price_min: number;
    price_avg: number | null;
    price_max: number;
}

export interface IRegionStatsStore {
    data: IRegionStats | null;
    requestState: RequestState;
}

export const regionStatsReducer = combineReducers({
    data: reduceResponse(fetchRegionStatsTypes),
    requestState: reduceRequestState(fetchRegionStatsTypes)
});
