export const isSessionStorageSupported = () => {
    return typeof window !== "undefined" && "sessionStorage" in window;
};

const encode = (value: unknown): string => JSON.stringify(value);
const decode = <T>(value: string | null): T | null => {
    if (value === null) {
        return null;
    }

    try {
        return JSON.parse(value) as T;
    } catch {
        return null;
    }
};

export const sessionCache = {
    set(key: string, value: boolean | string | number | object) {
        if (!isSessionStorageSupported()) {
            return;
        }

        window.sessionStorage.setItem(key, encode(value));
    },
    get<T>(key: string): T | null {
        if (!isSessionStorageSupported()) {
            return null;
        }

        const rawValue = window.sessionStorage.getItem(key);
        return decode<T>(rawValue);
    },
    remove(key: string) {
        if (!isSessionStorageSupported()) {
            return;
        }

        window.sessionStorage.removeItem(key);
    }
};
