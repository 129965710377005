import {ReactNode, useEffect, useRef, useState} from "react";
import {css, SerializedStyles} from "@emotion/react";
import styled from "@emotion/styled";
import Tippy from "@tippyjs/react";

import {p} from "@pg-design/helpers-css";
import {Text} from "@pg-design/text";
import {useIsMounted} from "@pg-mono/hooks";

import {INotificationType} from "../types";
import {StyledNotification} from "./Notification";

interface IProps {
    children: ReactNode;
    arrow?: boolean;
    arrowStyle?: SerializedStyles;
    body: ReactNode | string;
    inline?: boolean;
    isActive: boolean;
    openOnMount?: boolean;
    openOnMountDelay?: number;
    toggleFocusHandler?: boolean;
    toggleBlurHandler?: boolean;
    toggleClickHandler?: boolean;
    toggleHoverHandlers?: boolean;
    toggleOnClickOutside?: boolean;
    popoverPlace?: IPopoverPlace;
    type?: INotificationType;
    wrapperStyle?: SerializedStyles;
    bodyStyle?: SerializedStyles;
    className?: string;
    zIndex?: number;
    appendTo?: "parent" | HTMLElement;
    onPopoverOpen?: () => void;
    onPopoverClose?: () => void;
}

type IPopoverPlace = "top" | "right" | "bottom" | "bottom-start" | "bottom-end" | "left";

export const Popover: React.FC<IProps> = (props) => {
    const {
        arrow = true,
        type = "info",
        wrapperStyle,
        bodyStyle,
        popoverPlace = "left",
        openOnMount,
        openOnMountDelay = 0,
        zIndex,
        onPopoverClose,
        onPopoverOpen
    } = props;

    const isMounted = useIsMounted();

    const targetRef = useRef<HTMLDivElement>(null);
    const popoverBodyRef = useRef<HTMLDivElement>(null);
    const [popoverIsOpen, setPopoverIsOpen] = useState(false);
    const [bodyContentHovered, setBodyContentHovered] = useState(false);

    const closePopover = () => {
        setPopoverIsOpen(false);

        if (onPopoverClose) {
            onPopoverClose();
        }
    };

    const openPopover = () => {
        setPopoverIsOpen(true);

        if (onPopoverOpen) {
            onPopoverOpen();
        }
    };

    useEffect(() => {
        return function cleanup() {
            if (popoverBodyRef.current) {
                popoverBodyRef.current.removeEventListener("mouseenter", () => setBodyContentHovered(true));
                popoverBodyRef.current.removeEventListener("mouseleave", () => {
                    setBodyContentHovered(false);
                    closePopover();
                });
            }
        };
    }, []);

    const togglePopover = () => {
        if (popoverIsOpen) {
            closePopover();
        } else {
            openPopover();
        }
    };

    const handleBlur = (event: React.FocusEvent) => {
        if (targetRef.current?.contains(event.target as HTMLElement) && popoverIsOpen) {
            // enable user to click a link and close the popover
            setTimeout(() => closePopover(), 10);
        }
    };

    useEffect(() => {
        if (openOnMount) {
            setTimeout(() => {
                openPopover();
            }, openOnMountDelay);
        }
    }, []);

    const handleHover = () => {
        openPopover();
        setBodyContentHovered(true);
    };

    const handleToggleOff = () => {
        // LOGIC to enable hovering on body content
        if (popoverBodyRef.current) {
            popoverBodyRef.current.addEventListener("mouseenter", () => {
                setBodyContentHovered(true);
            });
            popoverBodyRef.current.addEventListener("mouseleave", () => {
                setBodyContentHovered(false);
                closePopover();
            });
            return setTimeout(() => {
                if (!bodyContentHovered) {
                    closePopover();
                }
            }, 250);
        }
    };

    const targetToggleProps = {
        ref: targetRef,
        onFocus: () => props.toggleFocusHandler && openPopover(),
        onBlur: (e: React.FocusEvent) => props.toggleBlurHandler && handleBlur(e),
        onClick: () => props.toggleClickHandler && togglePopover(),
        onMouseEnter: () => props.toggleHoverHandlers && handleHover(),
        onMouseLeave: () => props.toggleHoverHandlers && handleToggleOff()
    };

    const getAppendTo = () => {
        if (props.appendTo) {
            return props.appendTo;
        }

        // default logic if nothing was specified to be appended to
        return isMounted ? document.body : "parent";
    };

    return (
        <div css={props.inline ? popoverInline : null}>
            <Tippy
                content={
                    <StyledNotification css={[bodyStyle, popoverBodyStyle]} type={type} tabIndex={-1} ref={popoverBodyRef} role="tooltip">
                        {typeof props.body === "string" ? <Text variant="info_txt_2">{props.body}</Text> : props.body}
                        {arrow && <Arrow popoverPlace={popoverPlace} css={props.arrowStyle} />}
                    </StyledNotification>
                }
                visible={props.isActive && popoverIsOpen}
                placement={popoverPlace}
                appendTo={getAppendTo()}
                interactive
                onClickOutside={() => props.toggleOnClickOutside && closePopover()}
                arrow={false}
                zIndex={zIndex}
                css={wrapperStyle}
            >
                <div {...targetToggleProps} css={props.inline ? popoverInline : null} className={props.className}>
                    {props.children}
                </div>
            </Tippy>
        </div>
    );
};
const popoverInline = css`
    display: inline;
`;

const popoverBodyStyle = css`
    ${p(2)};
    position: relative;
`;

const Arrow = styled.div<{popoverPlace: IPopoverPlace}>`
    visibility: hidden;
    position: absolute;
    width: 31px;
    height: 22px;
    background: inherit;

    ${(props) => getPopoverArrowStyle(props.popoverPlace)}
    &::before {
        position: absolute;
        width: 31px;
        height: 22px;
        background: inherit;
        visibility: visible;
        content: "";
        transform: rotate(-22deg) skew(45deg);
    }
`;

const getPopoverArrowStyle = (popoverPlace: IPopoverPlace) => {
    switch (popoverPlace) {
        case "top":
            return css`
                bottom: -11px;
                left: 50%;
                transform: translate(-50%);
            `;
        case "bottom":
        case "bottom-start":
        case "bottom-end":
            return css`
                top: -11px;
                left: 50%;
                transform: translate(-50%);
            `;
        case "left":
            return css`
                right: -30px;
                top: 50%;
                transform: translate(-50%) rotate(90deg);
            `;
        case "right":
            return css`
                left: -15px;
                top: 50%;
                transform: translateY(-50%) rotate(90deg);
            `;
    }
};
