import React from "react";
import {useRef, useState} from "react";
import {css, Theme} from "@emotion/react";

import {InputRange, IRange} from "@pg-design/formik-utils/fields";
import {InputWrapper} from "@pg-design/inputs";
import {form, IFormProps} from "@pg-mono/form";
import {useIsMobile} from "@pg-mono/hooks";
import {noop} from "@pg-mono/nodash";

import {useClickOutside} from "../../hooks/handle_click_outside";
import {OfferType} from "../../offer/types/OfferType";
import {getAreaFilterLabel, getConstructionEndDateLabel, getFloorChoiceLabel, getPriceFilterLabel} from "../utils/get_label";
import {getRoomLabel} from "../utils/room_field_data_translations";
import {Filter} from "./filters/SearchFilter";
import {SearchFilterLabel} from "./filters/SearchFilterLabel";
import {FilterFooter} from "./filters/SearchFiltersFooter";
import {SearchFiltersRooms} from "./filters/SearchFiltersRooms";
import {IFilterChildProps, RangeFilter} from "./filters/SearchRangeFilter";
import {ISearchAutocompleteFormValues} from "./search_autocomplete/SearchAutocomplete";
import {ConstructionEndDateSwitch} from "./ConstructionEndDateSwitch";
import {FloorChoicesSwitch} from "./FloorChoicesSwitch";

enum Round {
    PRICE = 10000,
    AREA = 5,
    PRICE_M2 = 500
}

export enum DropdownToOpen {
    AREA,
    PRICE,
    ROOMS,
    FLOORS,
    CONSTRUCTION_END_DATE,
    PROPERTY,
    DISTANCE
}

interface IOwnProps {
    setDesktopFilterBlendActive?: (isOpen: boolean) => void;
    onMobileCloseModal?: () => void;
    onMobileResetForm?: () => void;
}

export interface ISearchFiltersFormValues extends Partial<ISearchAutocompleteFormValues> {
    area: IRange<string>;
    price: IRange<string>;
    rooms: IRange<number | "">;
    floor_choices: number[];
    construction_end_date: number[];
    type: number[];
}

interface IProps extends IOwnProps, IFormProps<ISearchFiltersFormValues> {}

const SearchFullFiltersC = (props: IProps) => {
    /**
     * Ref's
     */
    const filtersWrapperRef = useRef<HTMLElement | null>(null);
    const filtersWrapperRefHandle = (elem: HTMLElement | null) => {
        filtersWrapperRef.current = elem;
    };

    /**
     * State
     */
    const [currentOpenFilter, setCurrentOpenFilter] = useState<DropdownToOpen | null>(null);

    const isMobile = useIsMobile(true);

    /**
     * Utils
     */

    useClickOutside(filtersWrapperRef, () => {
        onDropdownOpen(null);
        props.setDesktopFilterBlendActive && props.setDesktopFilterBlendActive(false);
    });

    /**
     * Mobile specific
     */

    const closeMobileModal = () => {
        onDropdownOpen(null);
        props.setDesktopFilterBlendActive && props.setDesktopFilterBlendActive(false);
        props.onMobileCloseModal && props.onMobileCloseModal();
    };

    const submitMobileModal = (e: React.MouseEvent<HTMLElement>) => {
        closeMobileModal();
        props.onSubmit && props.onSubmit(e);
    };

    /**
     * General
     */
    const onDropdownOpen = (dropdownToOpen: DropdownToOpen | null) => {
        const shouldOpen = dropdownToOpen && Number.isInteger(dropdownToOpen) && dropdownToOpen >= 0;

        if (shouldOpen) {
            props.setDesktopFilterBlendActive && props.setDesktopFilterBlendActive(true);
            setCurrentOpenFilter(dropdownToOpen);
        } else {
            props.setDesktopFilterBlendActive && props.setDesktopFilterBlendActive(false);
            setCurrentOpenFilter(null);
        }
    };

    /**
     * Render
     */

    const areaProps = props.getFieldProps("area");
    const priceProps = props.getFieldProps("price");
    const roomsProps = props.getFieldProps("rooms");
    const floorChoicesProps = props.getFieldProps("floor_choices");
    const constructionEndDateProps = props.getFieldProps("construction_end_date");
    //Negation is used because that default type for flat & house is equal to []
    const shouldShowFloorsFilter = ![OfferType.HOUSE, OfferType.COMMERCIAL].includes(props.values.type[0]);

    return (
        <div css={filtersHolder} ref={filtersWrapperRefHandle}>
            <div css={filtersScroller}>
                <InputWrapper css={inputWrapperStyles}>
                    <RangeFilter
                        label="Pokoje"
                        id="roomFilter"
                        {...roomsProps}
                        getLabel={getRoomLabel}
                        labelId="room-filter-field"
                        errorOnBottom
                        isDropdownOpened={currentOpenFilter === DropdownToOpen.ROOMS}
                        toggleDropdown={onDropdownOpen}
                        dropdownName={DropdownToOpen.ROOMS}
                        isMobileWidth={isMobile}
                    >
                        {(props: IFilterChildProps<number | "">) => <SearchFiltersRooms {...props} showFooter={!isMobile} />}
                    </RangeFilter>
                </InputWrapper>

                <InputWrapper css={inputWrapperStyles}>
                    <RangeFilter
                        label="Powierzchnia"
                        errorOnBottom
                        id="areaFilter"
                        {...areaProps}
                        onChange={areaProps.onChange}
                        onAfterChange={areaProps.onAfterChange}
                        getLabel={getAreaFilterLabel}
                        isDropdownOpened={currentOpenFilter === DropdownToOpen.AREA}
                        toggleDropdown={onDropdownOpen}
                        dropdownName={DropdownToOpen.AREA}
                        isMobileWidth={isMobile}
                    >
                        {(props: IFilterChildProps<string>) => (
                            <>
                                <SearchFilterLabel label={props.label} htmlFor={`${props.name}_lower`} />
                                <InputRange
                                    {...props}
                                    onChange={props.onChange}
                                    callAfterChangeOnEnter
                                    hasDashSeparator
                                    onAfterChange={noop}
                                    roundToMultiplicationOf={Round.AREA}
                                />

                                <FilterFooter
                                    onCancel={() => {
                                        props.clearField();
                                        props.toggleDropdown();
                                    }}
                                    onSave={() => {
                                        props.onChange(props.name, props.value);
                                        props.onAfterChange(props.name, props.value);
                                        props.toggleDropdown();
                                    }}
                                />
                            </>
                        )}
                    </RangeFilter>
                </InputWrapper>

                {shouldShowFloorsFilter && (
                    <InputWrapper css={inputWrapperStyles}>
                        <Filter
                            label="Piętro"
                            id="floorChoicesFilter"
                            {...floorChoicesProps}
                            getLabel={getFloorChoiceLabel}
                            labelId="floor_choices-filter-field"
                            errorOnBottom
                            isDropdownOpened={currentOpenFilter === DropdownToOpen.FLOORS}
                            setDropdownOpen={onDropdownOpen}
                            dropdownName={DropdownToOpen.FLOORS}
                            isMobileWidth={isMobile}
                        >
                            {(props) => (
                                <>
                                    <FloorChoicesSwitch {...props} />

                                    <FilterFooter
                                        onCancel={() => {
                                            props.clearField();
                                            onDropdownOpen(null);
                                        }}
                                        onSave={() => {
                                            props.onChange(props.name, props.value);
                                            props.onAfterChange(props.name, props.value);
                                            onDropdownOpen(null);
                                        }}
                                    />
                                </>
                            )}
                        </Filter>
                    </InputWrapper>
                )}
                <InputWrapper css={inputWrapperStyles}>
                    <Filter
                        label="Termin oddania"
                        id="constructionEndDataFilter"
                        {...constructionEndDateProps}
                        getLabel={getConstructionEndDateLabel}
                        labelId="construction-end-date-field"
                        errorOnBottom
                        isDropdownOpened={currentOpenFilter === DropdownToOpen.CONSTRUCTION_END_DATE}
                        setDropdownOpen={onDropdownOpen}
                        dropdownName={DropdownToOpen.CONSTRUCTION_END_DATE}
                        isMobileWidth={isMobile}
                    >
                        {(props) => (
                            <>
                                <ConstructionEndDateSwitch {...props} />

                                <FilterFooter
                                    onCancel={() => {
                                        props.clearField();
                                        onDropdownOpen(null);
                                    }}
                                    onSave={() => {
                                        props.onChange(props.name, props.value);
                                        props.onAfterChange(props.name, props.value);
                                        onDropdownOpen(null);
                                    }}
                                />
                            </>
                        )}
                    </Filter>
                </InputWrapper>

                <InputWrapper css={inputWrapperStyles}>
                    <RangeFilter
                        label="Cena"
                        id="priceFilter"
                        {...priceProps}
                        onChange={areaProps.onChange}
                        onAfterChange={areaProps.onAfterChange}
                        getLabel={getPriceFilterLabel}
                        isDropdownOpened={currentOpenFilter === DropdownToOpen.PRICE}
                        toggleDropdown={onDropdownOpen}
                        dropdownName={DropdownToOpen.PRICE}
                        isMobileWidth={isMobile}
                        rightDropdown
                    >
                        {(props: IFilterChildProps<string>) => (
                            <>
                                <SearchFilterLabel label={props.label} htmlFor={`${props.name}_lower`} />
                                <InputRange
                                    {...props}
                                    onChange={props.onChange}
                                    callAfterChangeOnEnter
                                    hasDashSeparator
                                    onAfterChange={noop}
                                    roundToMultiplicationOf={Round.AREA}
                                />

                                <FilterFooter
                                    onCancel={() => {
                                        props.clearField();
                                        props.toggleDropdown();
                                    }}
                                    onSave={() => {
                                        props.onChange(props.name, props.value);
                                        props.onAfterChange(props.name, props.value);
                                        props.toggleDropdown();
                                    }}
                                />
                            </>
                        )}
                    </RangeFilter>
                </InputWrapper>
            </div>

            <div css={mobileFiltersFooterWrapper}>
                <FilterFooter
                    onCancel={props.onMobileResetForm || (() => null)}
                    onSave={(e: React.MouseEvent<HTMLElement>) => {
                        submitMobileModal(e);
                    }}
                />
            </div>
        </div>
    );
};

export const SearchFullFilters = form<ISearchFiltersFormValues, IProps>(SearchFullFiltersC);

const filtersHolder = (theme: Theme) => css`
    display: flex;
    flex-direction: column;

    @media (min-width: ${theme.breakpoints.md}) {
        justify-content: space-between;
        flex-direction: row;
        align-items: center;
        flex-grow: 1;
        flex-shrink: 1;
        height: unset;
    }
`;

const filtersScroller = (theme: Theme) => css`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow-y: auto;

    @media (min-width: ${theme.breakpoints.md}) {
        flex-direction: row;
        overflow: initial;

        div:last-child {
            margin-right: 0;
        }
    }
`;

const mobileFiltersFooterWrapper = (theme: Theme) => css`
    width: 100%;
    display: block;
    padding: 0 2rem 2rem;

    @media (min-width: ${theme.breakpoints.md}) {
        display: none;
    }
`;

const inputWrapperStyles = css`
    padding: 0;
    flex: 1 1 20%;
    margin-right: 1.6rem;
`;
