import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {appendQueryString} from "@pg-mono/request";
import {enable301ResponseState} from "@pg-mono/response-state";

import {IRPRequestMeta} from "../../../app/rp_request_meta";
import {AVAILABLE_DISTANCE_VALUES} from "../../list/constants/offer_list";

export const redirectWithoutDistanceIfNotAllowed = (ctx: IFetchContext<IRPRequestMeta>) => async (dispatch: Dispatch) => {
    const {distance, ...restQuery} = ctx.route.query;
    const distanceCasted = +distance;

    if (distanceCasted && !AVAILABLE_DISTANCE_VALUES.includes(distanceCasted)) {
        dispatch(enable301ResponseState(appendQueryString(ctx.route.pathname, restQuery)));
        return false; // stop data-fetcher
    }

    return true; // continue data-fetcher
};
