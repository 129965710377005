import React, {createContext, useEffect, useState} from "react";

export interface IContext {
    background?: string;
    bordered: boolean;
    borderRadius?: string;
    collapsed: boolean;
    color?: string;
    onCollapse: () => void;
}

export const AccordionContext = createContext<IContext | undefined>(undefined);

interface IProps {
    children: React.ReactNode;
    background?: string;
    bordered?: boolean;
    borderRadius?: string;
    color?: string;
    initiallyOpen?: boolean;
    onChange?: (collapsed: boolean) => void;
    className?: string;
}

export const AccordionCore: React.FC<IProps> = (props) => {
    const {bordered = false, initiallyOpen = false, onChange, className, ...restProps} = props;

    const [collapsed, setCollapsed] = useState(!initiallyOpen);

    useEffect(() => {
        if (onChange) {
            onChange(collapsed);
        }
    }, [collapsed]);

    return (
        <AccordionContext.Provider value={{bordered, collapsed, onCollapse: () => setCollapsed((prevState) => !prevState), ...restProps}}>
            <div className={className}>{props.children}</div>
        </AccordionContext.Provider>
    );
};
