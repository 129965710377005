import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {isEqual} from "@pg-mono/nodash";
import {appendQueryString, catch400, getRequest} from "@pg-mono/request";
import {createRequestActionTypes, RequestActionTypes} from "@pg-mono/request-state";
import {apiV2ListLink, Scenario} from "@pg-mono/rp-api-routes";

import {IRPStore} from "../../../app/rp_reducer";
import {IRPRequestMeta} from "../../../app/rp_request_meta";
import {RegionType} from "../../../region/types/RegionType";

const FETCH_OFFER_LIST_CHILDREN_REGIONS_PREFIX = "offer_list/FETCH_OFFER_LIST_CHILDREN_REGIONS";
export const fetchOfferListChildrenRegionsTypes = createRequestActionTypes(FETCH_OFFER_LIST_CHILDREN_REGIONS_PREFIX);

interface IOfferListChildrenRegionsResponse {
    results: IOfferListChildrenRegion[];
}

export interface IOfferListChildrenRegion {
    name: string;
    slug: string;
    id: number;
    stats: {
        properties_all_count_for_sale: number;
        properties_flats_count_for_sale: number;
        properties_houses_count_for_sale: number;
        properties_commercials_count_for_sale: number;
        vendors_count_for_sale: number;
    };
    type: RegionType;
}

const fetchOfferListChildrenRegions =
    (actionType: RequestActionTypes) => (ctx: IFetchContext<IRPRequestMeta>) => async (dispatch: Dispatch, getState: () => IRPStore) => {
        const region = ctx.prevResult.region;
        if (!region) {
            return false;
        }
        const query = {children_regions: region[0]};
        if (isEqual(query, getState().offerList.childrenRegionsLatestQuery)) {
            // do not fetch the same data again
            return getState().offerList.childrenRegions;
        }

        dispatch({type: actionType.start, latestQuery: query});
        const childrenRegionsListApiLink = apiV2ListLink.region.list(Scenario.REGION_LIST);
        const url = appendQueryString(childrenRegionsListApiLink, query);
        return getRequest(ctx.meta, url)
            .then((res: IOfferListChildrenRegionsResponse) => {
                dispatch({type: actionType.success, result: res.results});
                return res.results;
            })
            .catch(
                catch400((error) => {
                    dispatch({type: actionType.error, error: error.appError});
                })
            );
    };

export const fetchOfferListChildrenRegionsAtRoute = (ctx: IFetchContext<IRPRequestMeta>) => {
    return fetchOfferListChildrenRegions(fetchOfferListChildrenRegionsTypes)(ctx);
};
