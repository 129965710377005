import {InvestmentOfferCountrySlug} from "../constants/investment_offer_constants";

export const getCountryDeclensionWhere = (country: InvestmentOfferCountrySlug) => {
    switch (country) {
        case InvestmentOfferCountrySlug.SPAIN:
            return "w Hiszpanii";
        default:
            return "";
    }
};
