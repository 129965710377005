import {Dispatch} from "redux";

import {getRequest} from "@pg-mono/request";
import {createRequestActionTypes} from "@pg-mono/request-state";
import {apiV2Link, Scenario} from "@pg-mono/rp-api-routes";

import {IPropertyDetail} from "../../../property/interfaces/IPropertyDetail";

const FETCH_MAP_PROPERTY_DETAILS = "map/FETCH_MAP_PROPERTY_DETAILS";
export const fetchMapPropertyDetailsTypes = createRequestActionTypes(FETCH_MAP_PROPERTY_DETAILS);

export const fetchMapSelectedProperty = (propertyId: number) => (dispatch: Dispatch) => {
    dispatch({type: fetchMapPropertyDetailsTypes.start});
    const url = apiV2Link.property.detail(Scenario.PROPERTY_DETAIL, {propertyId});
    return getRequest({}, url).then((result: IPropertyDetail) => {
        dispatch({type: fetchMapPropertyDetailsTypes.success, result});
        return result;
    });
};
