import {IFetchContext} from "@pg-mono/data-fetcher";
import {noop} from "@pg-mono/nodash";
import {appendQueryString} from "@pg-mono/request";
import {safelyParsePage} from "@pg-mono/string-utils";

import {rpCommonUrl} from "../../app/read_rp_environment_variables";
import {IRPStore} from "../../app/rp_reducer";
import {IRPRequestMeta} from "../../app/rp_request_meta";
import {MetaDataDispatch, updateMetaData} from "../../meta_data/actions/update_meta_data";
import {descriptionVendorDetail} from "../../meta_data/page_description/vendor_detail";
import {descriptionVendorList} from "../../meta_data/page_description/vendor_list";
import {titleVendorDetail} from "../../meta_data/page_title/vendor_detail";
import {titleVendorList} from "../../meta_data/page_title/vendor_list";
import {generatePrevNextMetaLinks} from "../../meta_data/utils/generate_prev_next_links";
import {trimAggregationFromRegion} from "../../meta_data/utils/trim_aggregation_from_region";
import {calculateVendorListMaxPage} from "../helpers/calculate_vendor_list_max_page";

export const updateVendorListMetaData =
    ({route}: IFetchContext<IRPRequestMeta>) =>
    async (dispatch: MetaDataDispatch, getState: () => IRPStore) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const {page, name, ...restQuery} = route.query;

        const parsedPage = safelyParsePage(page);

        const region = getState().vendor.list.region;
        const {count} = getState().vendor.list;

        const maxPage = calculateVendorListMaxPage(getState().vendor.list);

        const regionSEOMetadata = getState().vendor.list.regionSEOMetadata;

        const fullPath = `${rpCommonUrl}${route.pathname}`;
        const {prev, next} = generatePrevNextMetaLinks(parsedPage, maxPage, fullPath, restQuery);
        const title =
            (regionSEOMetadata && regionSEOMetadata.meta_title) ||
            titleVendorList({
                region: region && region[0],
                page: parsedPage
            });

        const description =
            regionSEOMetadata?.meta_description ||
            descriptionVendorList({
                count,
                region: region && region[0],
                page: parsedPage
            });
        const robots = regionSEOMetadata?.is_indexed || !!region?.length || (!region?.length && parsedPage === 1) ? "index, follow" : "noindex, follow";
        const customCanonicalPath =
            regionSEOMetadata?.canonical_url ?? appendQueryString(trimAggregationFromRegion(route.pathname), {page: parsedPage === 1 ? noop() : parsedPage});

        const metaData = updateMetaData({
            title,
            description,
            pathname: route.pathname,
            robots,
            customCanonicalPath,
            pageNumber: parsedPage,
            next,
            prev
        });
        dispatch(metaData);
        return true;
    };

export const updateVendorDetailMetaData =
    ({route}: IFetchContext<IRPRequestMeta>) =>
    async (dispatch: MetaDataDispatch, getState: () => IRPStore) => {
        const {vendor} = getState().vendor.detail;
        if (vendor) {
            const title = titleVendorDetail(vendor.type, vendor.name);
            const description = descriptionVendorDetail(vendor.type, vendor.name);

            const robots = vendor.configuration.meta_robots ?? vendor.is_indexing_allowed ? "index, follow" : "noindex, follow";

            const metaData = updateMetaData({
                title,
                description,
                pathname: route.pathname,
                robots
            });
            dispatch(metaData);
        }
        return true;
    };
