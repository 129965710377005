import {ILatestQuery} from "../../utils/latest_query";
import {IGetModularHouseOfferPayload} from "../types/IGetModularHouseOfferPayload";

export function getOfferListModularHousesPayload(latestQuery: ILatestQuery) {
    /**
     * TODO - this should be changed in future, urls should be parsed client side,
     *  currently it's a workaround because applying url parsing logic here would be too time consuming
     */
    const {region, ...latestQueryRest} = latestQuery as IGetModularHouseOfferPayload;

    return {...latestQueryRest, page_size: 8, region_remote_pk: region};
}
