import React from "react";
import {css} from "@emotion/react";
import styled from "@emotion/styled";

import {Text, TextVariantUnionType} from "@pg-design/text";

interface IProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
    children: React.ReactNode;
    href?: string;
    underline?: boolean;
    size?: ILinkSize;
    color?: string;
}

type ILinkSize = "x-small" | "small" | "big" | "inherit";

export const Link = (props: IProps) => {
    const {children, size, ...restProps} = props;

    const getTextVariant: () => TextVariantUnionType = () => {
        if (size === "x-small") {
            return "info_txt_2";
        }

        if (size === "small") {
            return "info_txt_1";
        }

        if (size === "big") {
            return "body_copy_1";
        }

        //  Default, mid
        return "body_copy_2";
    };

    return (
        <StyledLink {...restProps}>
            {size === "inherit" ? (
                children
            ) : (
                <Text variant={getTextVariant()} as="span">
                    {children}
                </Text>
            )}
        </StyledLink>
    );
};

const StyledLink = styled.a<{underline?: boolean; color?: string}>`
    ${({theme, underline, color}) => css`
        color: ${color || theme.colors?.secondary || "#23232d"};
        cursor: pointer;

        ${underline &&
        css`
            text-decoration: underline;
            &:hover,
            &:active {
                text-decoration: underline;
            }
        `};

        &:hover {
            color: ${theme.colors?.highlight || "#d7afe1"};
        }

        &:active {
            color: ${theme.colors?.gray["700"] || "#7b7b81"};
        }
    `}
`;
