import type {BaseQueryFn, FetchArgs, FetchBaseQueryError} from "@reduxjs/toolkit/query";
import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import qs from "query-string";

import {getCookie} from "@pg-mono/request";

import {isServer, rpServerMpApiUrl} from "../read_rp_environment_variables";

const mpBaseQuery = fetchBaseQuery({
    paramsSerializer: qs.stringify,
    prepareHeaders: (headers, api) => {
        const csrf = !isServer ? getCookie("mp-csrftoken") : null;

        if (api.type === "mutation" && csrf) {
            headers.set("X-CSRFToken", csrf);
        }

        return headers;
    }
});

const mpServiceBaseQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (args, api, extraOptions) => {
    const endpointUrl = typeof args === "string" ? args : args.url;

    const baseUrl = isServer ? rpServerMpApiUrl : "";
    const adjustedArgs = typeof args === "string" ? `${baseUrl}${endpointUrl}` : {...args, url: `${baseUrl}${endpointUrl}`};

    return mpBaseQuery(adjustedArgs, api, extraOptions);
};

export const mpApi = createApi({
    reducerPath: "mpApi",
    baseQuery: mpServiceBaseQuery,
    endpoints: () => ({}),
    tagTypes: ["ModularHouseOffer"]
});
