import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {appendQueryString, getRequest} from "@pg-mono/request";
import {createRequestActionTypes} from "@pg-mono/request-state";
import {apiV2ListLink, Scenario} from "@pg-mono/rp-api-routes";

import {IRPStore} from "../../app/rp_reducer";
import {IRPRequestMeta} from "../../app/rp_request_meta";
import {IPaginatedResponse} from "../../request/IResponse";

const VENDOR_REGION_SEO_METADATA_PREFIX = "vendor/FETCH_VENDOR_REGION_SEO_METADATA";
export const fetchVendorRegionSEOMetadataTypes = createRequestActionTypes(VENDOR_REGION_SEO_METADATA_PREFIX);

export interface IVendorRegionSEOMetadata {
    canonical_url: string | null;
    description?: string;
    id: number;
    is_indexed: boolean;
    is_published: boolean;
    meta_description?: string;
    meta_title?: string;
    redirect_to: string | null;
    region: {
        full_name_reverted: string;
        id: number;
        name: string;
        slug: string;
    };
}

export interface IVendorRegionSEOMetadataResponse extends IPaginatedResponse {
    results: IVendorRegionSEOMetadata[];
}

const regionsListApiLink = apiV2ListLink.region.vendor.list(Scenario.VENDOR_LIST);

export const fetchVendorRegionSEOMetadataAtRoute = (ctx: IFetchContext<IRPRequestMeta>) => async (dispatch: Dispatch, getState: () => IRPStore) => {
    const region = getState().vendor.list.region;

    // Do not fetch vendor region SEO metadata if region is not selected
    if (!region || (Array.isArray(region) && !region.length)) {
        return true;
    }

    dispatch({type: fetchVendorRegionSEOMetadataTypes.start});

    const url = appendQueryString(regionsListApiLink, {slug: region[0].slug});

    return getRequest(ctx.meta, url).then((response: IVendorRegionSEOMetadataResponse) => {
        const regionSEOMetadata = response.results[0] || null;

        dispatch({type: fetchVendorRegionSEOMetadataTypes.success, result: regionSEOMetadata});

        return response;
    });
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const resetVendorRegionSEOMetadataAtRoute = (ctx: IFetchContext<IRPRequestMeta>) => async (dispatch: Dispatch) => {
    dispatch({type: fetchVendorRegionSEOMetadataTypes.reset});

    return true;
};
