import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {isEqual, omit} from "@pg-mono/nodash";

import {isServer} from "../../../app/read_rp_environment_variables";
import {IRPStore} from "../../../app/rp_reducer";
import {IRPRequestMeta} from "../../../app/rp_request_meta";
import {PLANNER_3D_SEARCH_PARAM} from "../constants/planner_3d_params";

const ignoredClientSideParams = [PLANNER_3D_SEARCH_PARAM];

/**
 * When only some whitelisted params change, we should not trigger new data fetch in client.
 * Example of fetch that could be ignored: user changes history state by opening a modal.
 *
 * This blocking mechanism will be deprecated after switching to RTK Query.
 *
 * @param ctx
 */
export const validateOfferDetailFetchInClient = (ctx: IFetchContext<IRPRequestMeta>) => {
    return async (_: Dispatch, getState: () => IRPStore) => {
        if (isServer) {
            // always fetch on server
            return true;
        }

        const validPrevQuery = ctx.prevRoute?.query && omit(ctx.prevRoute?.query, ignoredClientSideParams);
        const validCurrentQuery = ctx.route.query && omit(ctx.route.query, ignoredClientSideParams);
        const isSameOffer = ctx.match.params.offerId == getState().offerDetail.offer?.id;

        const isQueryChanged = !isEqual(validPrevQuery, validCurrentQuery) && isSameOffer;

        return isQueryChanged;
    };
};
