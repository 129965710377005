import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {isEmpty} from "@pg-mono/nodash";

import {IRPRequestMeta} from "../../../app/rp_request_meta";
import {fetchSelectedRegionBySlug} from "../../list/actions/offer_list_selected_data_actions";
import {fetchSearchParsedOnError} from "../searches/fetch_search";
import {fromParsedObjectToQuery, ISlugParsed, ISlugQuery, offerUrlParser} from "./offer_url_parser";

export const parseFriendlyUrl =
    (ctx: IFetchContext<IRPRequestMeta>) =>
    async (dispatch: Dispatch): Promise<Partial<ISlugQuery> | false> => {
        const slugParsed: Partial<ISlugParsed> | null = offerUrlParser(ctx.match.params.friendlySlug, ctx.match.params.offerListSubFilter);

        if (slugParsed === null || isEmpty(slugParsed)) {
            // not valid slug - return search
            // not parsed properly
            return await getSearchQueryOnError(ctx, dispatch);
        }

        // fetch region id when defined in slug
        const regionId = slugParsed.region ? await getRegionId(ctx, dispatch, slugParsed.region) : null;
        // cannot find proper region - return search
        if (slugParsed.region && !regionId) {
            return await getSearchQueryOnError(ctx, dispatch);
        }

        if (regionId) {
            // region found, we need id
            slugParsed.region = [regionId.toString()];
        }

        // prepare full query (with strings)
        const slugQuery = slugParsed ? fromParsedObjectToQuery(slugParsed) : {};

        return {...ctx.route.query, ...slugQuery};
    };

async function getSearchQueryOnError(ctx: IFetchContext<IRPRequestMeta>, dispatch: Dispatch) {
    const searchQuery = await dispatch(fetchSearchParsedOnError(ctx));
    return searchQuery === null ? false : {...ctx.route.query, ...searchQuery};
}

async function getRegionId(ctx: IFetchContext<IRPRequestMeta>, dispatch: Dispatch, region?: string | string[]) {
    if (!region) {
        return null;
    }

    const parsedRegion = region[0];
    const fetchedRegion = await dispatch(fetchSelectedRegionBySlug(ctx.meta, parsedRegion));

    if (fetchedRegion === null || fetchedRegion.id === null) {
        return null;
    }

    return fetchedRegion.id;
}
