import {IAction} from "@pg-mono/request-state";

export interface ISetGlobals extends IAction {
    value: {
        hasPageProjection2d?: boolean; // this value should be set inside page action on SSR side
    };
}

export const SET_GLOBALS = "app/SET_GLOBALS";

export const setGlobals = (value: ISetGlobals["value"]) => ({
    type: SET_GLOBALS,
    value
});

export type IGlobalsActions = ISetGlobals;
