import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {appendQueryString, getRequest} from "@pg-mono/request";
import {createRequestActionTypes} from "@pg-mono/request-state";
import {apiV2ListLink, Scenario} from "@pg-mono/rp-api-routes";

import {IRPRequestMeta} from "../../app/rp_request_meta";
import {IPaginatedResponse} from "../../request/IResponse";

const DESKTOP_VENDOR_DETAIL_PREFIX = "desktop_vendors_count/fetch";
export const fetchVendorsCountTypes = createRequestActionTypes(DESKTOP_VENDOR_DETAIL_PREFIX);

export interface IVendorsCount {
    count: number;
}

interface IVendorsCountResponse extends IPaginatedResponse {
    count: number;
}

const vendorListApiLink = apiV2ListLink.vendor.list(Scenario.VENDOR_LIST);

export const fetchDesktopVendorsCountAtRoute = (ctx: IFetchContext<IRPRequestMeta>) => (dispatch: Dispatch) => {
    dispatch({type: fetchVendorsCountTypes.start});

    const url = appendQueryString(vendorListApiLink, {page_size: 1});

    return getRequest(ctx.meta, url).then((json: IVendorsCountResponse) => {
        dispatch({type: fetchVendorsCountTypes.success, result: json.count});
        return json;
    });
};
