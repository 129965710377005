import {IFavourite} from "../interfaces/IFavourite";

// Check if `favourite` object exists in a specific collection of records
export const favouriteInCollection = (favourite: IFavourite, collection: IFavourite[]): boolean => {
    return collection.some((collectionFavourite) => {
        const offerCondition = favourite.offer ? collectionFavourite.offer?.rp_id === favourite.offer.rp_id : false;
        const propertyCondition = favourite.property ? collectionFavourite.property?.rp_id === favourite.property.rp_id : false;

        return offerCondition || propertyCondition;
    });
};
