import {IFetchContext} from "@pg-mono/data-fetcher";

import {rpApi} from "../../app/api/rp_api";
import {IRPStore} from "../../app/rp_reducer";
import {IRPRequestMeta} from "../../app/rp_request_meta";
import {getModularHousesOfferListApi} from "../../modular_houses/api/get_modular_houses_offer_list";
import {getOfferListModularHousesPayload} from "../../modular_houses/utils/get_offer_list_modular_houses_payload";
import {getMarketplaceRegionListApi} from "../../region/api/get_marketplace_region_list";
import {IAppDispatch} from "../../store/store";
import {IOfferListingParams} from "../types/OfferListingParams";

/**
 * TODO: There seems to be a consensus, that we want to move to "one action per view" approach.
 *  So please add new "offer list" actions to this file and migrate the logic from the old ones.
 */

export const offerListingAction = (_ctx: IFetchContext<IRPRequestMeta, IOfferListingParams>) => async (dispatch: IAppDispatch, getState: () => IRPStore) => {
    /**
     * TODO: Currently, we "parse" url in a separate action, and pass parsed data as "prevResult" in ctx.
     *   This should be rewritten to a more explicite approach, we can define what "filters" we expect in the url.
     *   Currently the workaround is to use "latestQuery" that was used for fetching offer list.
     *   Make sure this action is placed after fetchOfferListAtRoute action.
     */
    const state = getState();
    await dispatch(
        getModularHousesOfferListApi.endpoints.getModularHouseOfferList.initiate(getOfferListModularHousesPayload(state.offerList.list.latestQuery))
    );

    if (state.offerList.list.latestQuery.region) {
        await dispatch(getMarketplaceRegionListApi.endpoints.getMarketplaceRegionList.initiate());
    }

    await Promise.all(dispatch(rpApi.util.getRunningQueriesThunk()));
};
