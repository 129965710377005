import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {enable301ResponseState} from "@pg-mono/response-state";
import {rpAppLink} from "@pg-mono/rp-routes";

import {IRPRequestMeta} from "../../../app/rp_request_meta";
import {OfferListSubType} from "../types/OfferListSubType";

export const redirectToProperSearchSlug =
    (ctx: IFetchContext<IRPRequestMeta, {friendlySlug?: string; offerListSubType?: OfferListSubType}>) => async (dispatch: Dispatch) => {
        const saveSearchResponseSlug = ctx.prevResult.saveSearchResponse?.slug;
        const friendlySlugFromRoute = ctx.match?.params.friendlySlug;
        const offerListSubType = ctx.match.params.offerListSubType;

        // redirect only if saveSearchResponseSlug not equal to friendlySlug from current url
        // skip redirect check if it is offer list's subtype
        if (!offerListSubType && saveSearchResponseSlug && saveSearchResponseSlug !== friendlySlugFromRoute) {
            dispatch(enable301ResponseState(rpAppLink.offer.listFriendly({friendlySlug: saveSearchResponseSlug})));

            return false;
        }

        return ctx.prevResult;
    };
