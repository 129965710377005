import {Key, pathToRegexp} from "path-to-regexp";

import {identity, pickBy} from "@pg-mono/nodash";

import {promotionUrlConstants} from "./promotion_url_constants";
import {PromotionType} from "./PromotionType";

const typeRegex = ":type(oferty-promocyjne|dni-otwarte|przeceny|last-minute|przedsprzedaz|targi-mieszkaniowe|^$)?";
const beforeRegionDashRegex = ":beforeRegionDash(-)?";
const regionRegex = ":region(.*?)";

export const promotionListRegex = `${typeRegex}${beforeRegionDashRegex}${regionRegex}`;

export interface IPromotionSlugParsed {
    type: PromotionType;
    region: string | number;
}

export const promotionUrlParser = (url: string): Partial<IPromotionSlugParsed> | null => {
    // urls ending with dash are always incorrect, ie. 'dni-otwarte-'
    if (url.match(/-$/gi)) {
        return null;
    }

    const keys: Key[] = [];
    const regexp = pathToRegexp(promotionListRegex, keys);
    const regExpExecArray: RegExpExecArray | null = regexp.exec(url);
    if (regExpExecArray == null) {
        return null;
    }

    const values: Record<string, string | number> = {
        type: promotionUrlConstants.types[regExpExecArray[1]],
        region: regExpExecArray[3]
    };

    return pickBy(values, identity);
};
