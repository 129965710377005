import {ISlugParsed} from "../friendly_offer_list/offer_url_parser";

export const prepareSaveSearchValues = (parsedQuery: ISlugParsed) => {
    const result: Record<string, any> = {}; // eslint-disable-line @typescript-eslint/no-explicit-any
    parsedQuery.area_0 && (result.area = {...result.area, lower: parsedQuery.area_0});
    parsedQuery.area_1 && (result.area = {...result.area, upper: parsedQuery.area_1});
    parsedQuery.price_0 && (result.price = {...result.price, lower: parsedQuery.price_0});
    parsedQuery.price_1 && (result.price = {...result.price, upper: parsedQuery.price_1});
    parsedQuery.rooms_0 && (result.rooms = {...result.rooms, lower: parsedQuery.rooms_0});
    parsedQuery.rooms_1 && (result.rooms = {...result.rooms, upper: parsedQuery.rooms_1});
    parsedQuery.is_luxury && (result.is_luxury = parsedQuery.is_luxury);

    return result;
};
