import React, {FC} from "react";
import {css, Theme} from "@emotion/react";

import {CloseIcon} from "@pg-design/icons";

import {getPillVariantStyle} from "../utils/get_pill_variant_style";
import {PillSizeUnionType} from "../utils/pill_variants";

interface IProps {
    children: React.ReactNode;
    onCloseClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
    size: PillSizeUnionType;
    className?: string;
}

export const Pill: FC<IProps> = (props) => {
    return (
        <div className={props.className} css={[pillBaseStyle, getPillVariantStyle(props.size)]}>
            {props.children}
            {props.onCloseClick && (
                <div onClick={props.onCloseClick} css={[iconHolder]}>
                    <CloseIcon size={props.size === "large" ? "1.4" : "1.2"} />
                </div>
            )}
        </div>
    );
};

const pillBaseStyle = (theme: Theme) => css`
    display: inline-flex;
    align-items: center;
    border-radius: 1.6rem;
    padding: 0.5rem 0.9rem 0.5rem 1.04rem;
    background-color: ${theme.colors.primary};
`;

const iconHolder = css`
    cursor: pointer;
    margin-left: 1rem;
`;
