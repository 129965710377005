import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {appendQueryString, getRequest} from "@pg-mono/request";
import {createRequestActionTypes} from "@pg-mono/request-state";
import {apiV2ListLink, Scenario} from "@pg-mono/rp-api-routes";

import {IRPRequestMeta} from "../../app/rp_request_meta";

const FETCH_VENDOR_DETAIL_ARTICLES_PREFIX = "vendor_detail/FETCH_VENDOR_DETAIL_ARTICLES";
export const fetchVendorDetailArticlesTypes = createRequestActionTypes(FETCH_VENDOR_DETAIL_ARTICLES_PREFIX);

interface IArticleGallery {
    image: {
        g_img_65x65: string;
        g_img_206x206: string;
        g_img_349x239: string;
        g_img_440x440: string;
    } | null;
}
export interface IVendorDetailArticle {
    author: {
        name: string;
    };
    date_published: string | null;
    excerpt: string;
    gallery: IArticleGallery | (IArticleGallery | undefined)[]; // gallery can be either array or plain object. Gallery as object is being used in offer-article-list scenario. It was a backend decision to make this exception
    id: number;
    slug: string;
    title: string;
}

interface IVendorListArticlesResponse {
    results: IVendorDetailArticle[];
}

// TODO: scenario (same as OFFER_ARTICLE_LIST ??)
const articleListApiLink = apiV2ListLink.article.list(Scenario.OFFER_ARTICLE_LIST);

export const fetchVendorDetailArticlesAtRoute = (ctx: IFetchContext<IRPRequestMeta>) => async (dispatch: Dispatch) => {
    dispatch({type: fetchVendorDetailArticlesTypes.start});

    const url = appendQueryString(articleListApiLink, {
        related_vendor: ctx.match.params.vendorId,
        page_size: 4
    });
    return getRequest(ctx.meta, url).then((json: IVendorListArticlesResponse) => {
        dispatch({type: fetchVendorDetailArticlesTypes.success, result: json.results});
        return json;
    });
};
